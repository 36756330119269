<div class="register-page">
    <mat-tab-group  [(selectedIndex)]="demo1TabIndex" mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
        <mat-tab label="FIELD OF SPECIALIZATION"> 
            
            <div class="section-1 row">
                <div class="left-column col-md-6">
                    <div class="content">
                        <img src="../../assets/images/r1.png" alt="">
                    </div>
                </div>
                <div class="right-column col-md-6">
                    <div class="content">
                        <h2 class="label">Field of Specialization</h2>
                        <p>Please select you field of</p>
                        <mat-form-field class="dropdown" appearance="fill">
                            <mat-label>Select Field of Specialization</mat-label>
                            <mat-select>
                              <mat-option *ngFor="let option of options" [value]="option">
                                {{option}}
                              </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <form class="example-form">
                            <button class="add-sp">Add Another Specialization <span>+</span></button>
                            <button (click)="nextTab()" class="next-button">Next</button>
                        </form>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="CLINIC/HOSPITAL DETAILS">
            
            <div class="section-2 row">
                <div class="left-column col-md-6">
                    <div class="content">
                        <img src="../../assets/images/r1.png" alt="">
                    </div>
                </div>
                <div class="right-column col-md-6">
                    <div class="content">
                        <h2 class="label">Clinic/Hospital Details</h2>
                        <button class="skip-button">Skip<mat-icon aria-hidden="false" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
                        </button>
                        <p>Add Clinic/hospital details where you operate</p>
                        
                        <form class="example-form">
                            <mat-form-field class="example-full-width">
                                <mat-label >Clinic/Hospital name</mat-label>
                                <input type="tel" matInput placeholder="Enter Clinic/Hospital name">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Location</mat-label>
                                <input type="tel" matInput placeholder="Enter Location">
                                <mat-icon class="input-field-icon" matSuffix>place</mat-icon>
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Website URL</mat-label>
                                <input type="tel" matInput placeholder="Enter Website URL">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Phone</mat-label>
                                <input type="tel" matInput placeholder="Enter Phone Number">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Opening Hours</mat-label>
                                <input type="tel" matInput placeholder="Enter Opening Hours">
                                <mat-icon class="input-field-icon" matSuffix>schedule</mat-icon>
                            </mat-form-field>
                            <div class="prev-next-buttons">
                                <button (click)="prevTab()" class="previous-button">previous</button>
                                <button (click)="nextTab()" class="next-button">Next</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="PERSONAL DETAILS"> 

            <div class="section-3 row">
                <div class="left-column col-md-6">
                    <div class="content">
                        <img src="../../assets/images/r1.png" alt="">
                    </div>
                </div>
                <div class="right-column col-md-6">
                    <div class="content">
                        <h2 class="label">Personal Details</h2>
                        <p>Tell us a bit about yourself. Add personal details.</p>
                        
                        <form class="example-form">
                            <mat-form-field class="example-full-width">
                                <mat-label >Email</mat-label>
                                <input type="tel" matInput placeholder="Enter Email">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Phone</mat-label>
                                <input type="tel" matInput placeholder="Enter Phone Number">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Website URL</mat-label>
                                <input type="tel" matInput placeholder="Enter Website URL">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Phone</mat-label>
                                <input type="tel" matInput placeholder="Enter Phone Number">
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Date Of Birth</mat-label>
                                <input matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="dropdown" appearance="fill">
                                <mat-label>Gender</mat-label>
                                <mat-select>
                                  <mat-option *ngFor="let option of genders" [value]="option">
                                    {{option}}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="prev-next-buttons">
                                <button (click)="prevTab()" class="previous-button">previous</button>
                                <button (click)="nextTab()" class="next-button">Next</button>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
            
        </mat-tab>
        <mat-tab label="PAYMENT DETAILS">
            <div class="section-4 row">
                <div class="left-column col-md-6">
                    <div class="content">
                        <img src="../../assets/images/r1.png" alt="">
                    </div>
                </div>
                <div class="right-column col-md-6">
                    <div class="content">
                        <h2 class="label">Payment Details</h2>
                        <button class="skip-button">Skip<mat-icon aria-hidden="false" aria-label="Example home icon">keyboard_arrow_right</mat-icon>
                        </button>
                        <p>Add bank details to recieve your</p>
                        <form class="example-form">
                            <mat-form-field class="example-full-width">
                                <mat-label >Name on the Card</mat-label>
                                <input type="tel" matInput placeholder="Enter Full Name">
                            </mat-form-field>
                            <mat-form-field class="example-full-width">
                                <mat-label >Card Number</mat-label>
                                <input type="tel" matInput placeholder="Enter Card Number">
                            </mat-form-field>

                            
                            <!-- <div class="adjacent-input-fields"> -->
                                <mat-form-field class="f1" appearance="fill">
                                    <mat-label>Expiry Date</mat-label>
                                    <input matInput [matDatepicker]="picker1">
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="f2 ">
                                    <mat-label >CVV</mat-label>
                                    <input type="tel" matInput placeholder="Enter CVV Number">
                                </mat-form-field>
                            <!-- </div> -->
                            <div class="prev-next-buttons">
                                <button (click)="prevTab()" class="previous-button">previous</button>
                                <button class="next-button">Finish Setup</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Year Of Diagnosis</mat-label>
    <input required readonly [max]="maxDate" [value]="get_date_value(sel_date)" #da matInput [matDatepicker]="dp"  />
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker
      #dp
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event,dp,da.value)"
      panelClass="example-month-picker"
    >
    </mat-datepicker>
  </mat-form-field>
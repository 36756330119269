import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { AbstractType, Component, Inject, OnInit } from '@angular/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import {ChangeDetectionStrategy, inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
declare let $: any
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false}
  } , DatePipe ]
})
export class HomepageComponent implements OnInit {

  date = new Date();
  data:any;
  domain = environment.xmppDomain
  activityDateTime: any;
  maxDate = new Date();
  today_date:any;
  service_provider_lists: any;
  relationship_list: any;
  new15: any;
  todaydate: any;
  reminders: any;
  status=true;
  menu_list = [{name: 'Documents', link: '/documents', icon: 'far fa-file-alt'}, {name: 'Connections', link: '/connections', icon: 'fas fa-users'},{name: 'Health', link: '/dashboard', icon: 'far fa-heart'}]


  

  constructor(public auth:AuthServiceService ,private dialog: MatDialog,  public dpipe:DatePipe , public toast:ToastrService) { }

  ngOnInit(): void {
    this.today_date = this.dpipe.transform(this.date,'YYYYMMdd')
    //console.log(this.today_date)
    this.auth.get_medical_log().subscribe((res:any)=>{
      console.log(res)
      if(res){
        this.auth.get_activitylog_date(this.today_date).subscribe((res:any)=>{
          this.data = res?.length;
        })
      }
    })

    this.auth.service_provider_list().subscribe((res:any)=>{
  
    
        let lists = res.data.relationships.filter((element)=>{
          return (element.canAccept && !element.isaccepted)
        })
        this.service_provider_lists = lists.length
  
      
    })

    this.new15 = this.dpipe.transform(moment().add(15, 'days').calendar(),'MMM d, y');
    this.todaydate = this.dpipe.transform(new Date(),'MMM d, y')
    
    this.auth.get_reminders().subscribe((res:any)=>{
      
      let data_rem = res.data.reminders?.filter((element=>{
        ////console.log(moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"),element.target )
        return (moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"))
      }))
      this.reminders = data_rem.length
    
   
  })

  if(this.reminders ==0 && this.service_provider_lists ==0 && this.data==0){
    this.status=false
    //console.log(this.status)
  }

  this.auth.get_storage().subscribe((res: any) => {
    //console.log('res',res);
    if(res.data.PayBtnActive === true){
      this.openDialog()
    }
  })

}

  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {  },
      width: '500px',
      height: '220px',
      hasBackdrop: true,
      panelClass: 'hello',
      disableClose: true
    });

  dialogRef.afterClosed().subscribe(result => {
  });
  }
}

//Subscription Dialog
@Component({
  selector: 'app-confirmation-dialog',
  template: `

  <div class="closeDialog"><i class="fa fa-times closeBtn" (click)="closeDialog()" aria-hidden="true"></i></div>
  <mat-dialog-content class="mailoption">
    <h2 class="subneed" mat-dialog-title>Subscription needed!!</h2>
    Your storage limit exceeded please pay now.
  </mat-dialog-content>

  <mat-dialog-actions>
    <div mat-dialog-actions class="dialogSave">
          <button type="cancel" class="btn btnCancel" (click)="closeDialog()">Cancel</button>
          <button type="submit" class="btn btnSave" (click)="payNow()">Pay Now</button>
    </div>
  </mat-dialog-actions>

`,
  styleUrls: ['./homepage.component.scss'],
})
export class ConfirmationDialogComponent {
  pay: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,public toast:ToastrService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  ngOnInit() {

  }
  payNow() {
    let email = localStorage.getItem('email')
    const link = `${environment.payment}?prod=vitagist&consumeremail=${email}`
    this.dialogRef.close('continue');
    window.location.href = link;
  }
  closeDialog() {
    this.dialogRef.close()
  }

}


import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, formatDate } from '@angular/common';
import { DialogAddDocument } from '../documents/documents.component';
import { Utils } from '../utils';
import moment, { Moment } from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import _ from 'lodash';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { MatDatepicker } from '@angular/material/datepicker';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  @BlockUI()
  blockUI!: NgBlockUI; 
  profile_detail: any;
  med_condition: any;
  ethnicity_list:any;
  bloodgrp_list:any;
  gender_list = ["Female","Male","Others"];
  submitted = false;
  gendervalue="";
  ethnicityvalue="";
  bloodgrpvalue="";
  name = new FormControl('');
  // form_selected: any;
  myForm_dialog = new FormGroup({});
  editData = { title: '', form: this.myForm_dialog, form_labels: [{}] };
  updated_profile: any;
  profile_id: any;
  dialog_status = '';
  initial_res : any;
  country:any;
  edit_profile:any;
  isOpen = false;
  height:any;
  weight:any;
  height_value:any;
  visited_dialog:any[]=[];
  digi_prof:any;
  patch_data:any;
  payload:any;
  maxDate = new Date()
  today = new Date()
  equal:any
  
  // list_of_forms = [{title: 'medicalCondition', headings: ['Medical Condition', 'Comment']}, {title: 'allergy', headings: ['Allergy', 'Allergic to', 'Allergic Reaction', 'Severity', 'Comment']}, {title: 'medication', headings: ['Medication']}, {title: 'familyHistory', headings: ['Family History']}, {title: 'insurance', headings: ['Insurance']}, {title: 'immunization', headings: ['Immunization']}];



  constructor(private router: Router , private auth: AuthServiceService , public dialog: MatDialog , private formBuilder: FormBuilder, private toastr: ToastrService , private spin:NgxSpinnerService , private dpipe:DatePipe) { }

  myForm = this.formBuilder.group({
    gender: ['',Validators.required],
    ethnicity: ['',Validators.required],
    height:['' , [Validators.required,Validators.min(0),Validators.max(999),Validators.pattern("^[0-9]*$")]],
    weight:['',[Validators.required,Validators.min(0),Validators.max(999),Validators.pattern("^[0-9]*$")]],
    bloodGroup:['',Validators.required],
    heightUnit: [''],
    weigthtUnit: [''],
    heightinc:[0,[Validators.required,Validators.min(0),Validators.max(11),Validators.pattern("^[0-9]*$")]],
    heightfeet:[0,[Validators.required,Validators.min(0),Validators.max(10),Validators.pattern("^[0-9]*$")]],
    dob:['',Validators.required],
    firstName:[''],
    lastName:['']
  });

  // feet = new FormControl(0,[Validators.required,Validators.min(0), Validators.max(9)])
  // inch = new FormControl(0,[Validators.required,Validators.min(0), Validators.max(9)])
  
  
  
  ngOnInit(): void {
    this.blockUI.start()
    this.auth.get_digicoffer_profile().subscribe((res:any)=>{
      if(res.error==false){
        this.digi_prof = res.data

      }
    })
    
    this.auth.profile_details().subscribe((res:any) =>{
      this.profile_detail = res[0];
      this.initial_res = res;
      if(this.profile_detail){
        this.profile_id = this.profile_detail.id;
        this.med_condition = this.profile_detail["medicalCondition"][0]
      

      } else {
        this.profile_detail = {}
        this.profile_detail.medicalCondition = [];
        this.profile_detail.allergy = [];
        this.profile_detail.medication = [];
        this.profile_detail.familyHistory = [];
        this.profile_detail.insurance = [];
        this.profile_detail.immunization = [];
        this.profile_detail.addon =[];
      }
     
    })
    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
        for (let i = 0; i < res.data.length; i++) {

          if(res.data[i].index == "citizen_primary"){
            this.country = res.data[i].country  


          }
        }
       
        

  
     

        
        
      }

    })
    if(this.country== "usa" || this.country == "united states of america"||this.country=="USA"){
      this.height ="feet"
      var show = true
      this.weight="lbs"

    } else {
      this.height ="cm"
      this.weight="kg"

    }
 

    this.auth.get_ethnicity().subscribe((res:any) =>{
      this.ethnicity_list = res;
     
    })

    this.auth.get_bloodgrps().subscribe((res:any) =>{
      this.bloodgrp_list = res;
      
    })
  this.blockUI.stop()

    
    // this.myForm.get('gender')?.setValue(this.profile_detail['gender']);
  }

  us_unit(ft,inc){
   
    this.height_value = `${ft}.${inc}`
    
  }

  get_heightcm(heigt){
    this.height_value = heigt
  }

 get_ftheight(height){
   if(typeof(height)=="string"){
    return height.split('.')[0]

   } else {
     return 0 
   }
 
 }

 start_loadavg(){
   this.blockUI.start("Saving......")

 }
stop_loading(){
  this.blockUI.stop()
}
 get_incheight(height){
   if(typeof(height)=="string"){
    return height.split('.')[1]

   } else {
     return 0
   }
  
 }
 get_date_value(date){
  return moment(date,"MM-DD-YYYY").format();
  

}
get f(){
  return this.myForm.controls
}

diff_filter(update, visited){
 
  var finalObj = {};
visited.forEach(allowedVal => {
  if (update[allowedVal])
    finalObj[allowedVal] = update[allowedVal]
})

 return finalObj
    
  


 

}
 diff_object(){
  let changedProperties: any[] = [];

  Object.keys(this.myForm.controls).forEach((name) => {
    const currentControl = this.myForm.controls[name];
    
    

    if (currentControl.dirty) {
      this.visited_dialog.push(name)
    }
 
  });

 }
  onSaveClicked(weight, gender , ethnicity, bloodgroup,dob){
    
    //console.log(this.myForm.dirty , this.updated_profile, this.profile_detail.dob ,dob , moment(this.profile_detail.dob).isSame(moment(dob)))
    
    let inital_obj = {
      "dob": this.profile_detail.dob,
      "gender": this.profile_detail.gender,
      "ethnicity": this.profile_detail.ethnicity,
      "height": this.profile_detail.height,
      "weight": this.profile_detail.weight,
      "bloodGroup":this.profile_detail.bloodGroup
    }
    
  let a = this.check_any_changes(dob)
  if(a){
    this.toastr.error("No Changes to Save")
    return
  }
   
    this.submitted = true;
    var form_data;
  
    let date_birth = this.dpipe.transform(dob,"MM-dd-yyyy")
    this.f.dob.setValue(date_birth)
    this.f.firstName.setValue(this.digi_prof.first_name)
    this.f.lastName.setValue(this.digi_prof.last_name)
    if(this.country=="USA"){
    
      this.height = `${this.myForm.get('heightfeet')?.value}.${this.myForm.get('heightinc')?.value}`
      this.myForm.get('height')?.setValue(this.height)
      if(this.f.gender.invalid || this.f.bloodGroup.invalid || this.f.ethnicity.invalid || this.f.weight.invalid || this.f.dob.invalid || this.f.heightfeet.invalid || this.f.heightinc.invalid){
       
      return

      }
    } else {
      this.height = this.myForm.get('height')?.value
      if(this.f.gender.invalid || this.f.bloodGroup.invalid || this.f.ethnicity.invalid || this.f.weight.invalid || this.f.dob.invalid || this.f.height.invalid ){
        
        return
        }

    }
    this.submitted = true;
    //this.isOpen = true
    
    // this.myForm.valueChanges.subscribe(value=>{
    //   //console.log('form value changed' , value)
    // })
   
    //console.log(this.myForm);
    this.start_loadavg()
    if(this.initial_res.length==0){
      if(this.country== "usa" || this.country == "united states of america"||this.country=="USA"){
        
        this.f.heightUnit.setValue("feet")
        this.f.weigthtUnit.setValue("lbs")
      

      } else {
      
        this.f.heightUnit.setValue("cm")
        this.f.weigthtUnit.setValue("kg")
    

      }
      form_data = this.myForm.value
      delete form_data.heightfeet
      delete form_data.heightinc
      if(this.updated_profile==undefined){
        this.payload = (Object.assign(form_data,this.profile_detail))
        //console.log("post", this.payload)

      } else {
       
        this.payload = (Object.assign(form_data,this.updated_profile))
        this.payload.addon =[]
        //console.log("post", this.payload)
      

      }
       
    } else {

      this.f.heightUnit.setValue(this.profile_detail.heightUnit)
      this.f.weigthtUnit.setValue(this.profile_detail.weigthtUnit)
      form_data = this.myForm.value
      delete form_data.heightfeet
      delete form_data.heightinc
      this.diff_object()
        const u_a =  [...new Set(this.visited_dialog)];
      ////console.log(u_a , this.updated_profile) 
      if(this.updated_profile){
        let full = Object.assign(this.updated_profile,form_data)
        this.payload = this.diff_filter(full,u_a)

      } else {
        this.payload = this.diff_filter(form_data,u_a)

      }
      
    //   if(this.updated_profile){
    //     this.diff_object()
    //     const u_a =  [...new Set(this.visited_dialog)];
    //   ////console.log(u_a , this.updated_profile) 
    //    let full = Object.assign(this.updated_profile,form_data)
      
    //    debugger
    //    this.payload = this.diff_filter(full,u_a)
    //    //this.payload = Object.assign(form_data,this.patch_data)
    //    //console.log("patch_obj", this.patch_data)
    //   } else {
        
    // //console.log(form_data)
    // this.payload = form_data
    // //console.log(this.diff_object())
    // //console.log("patch", this.payload)
    //   }
    }

    if(this.initial_res.length == 0){
    
      this.auth.create_profile_list(this.payload).subscribe((res:any)=>{

        if(res){
          this.toastr.success("Profile saved successfully")
          this.router.navigate(['/dashboard'])
          this.stop_loading()
        }
      })
    } else {
      this.auth.update_profile_list(this.profile_id ,this.payload).subscribe((res:any)=>{
        if(res){
          this.toastr.success("Profile saved successfully")
          this.router.navigate(['/dashboard'])
          this.stop_loading()

        }
      })
    }
   
     
    // if(!this.updated_profile){
    //   if(this.profile_detail == undefined){
    //     //console.log('if if');
    //     this.updated_profile = {}
    //     this.updated_profile.gender = gender;
    //     this.updated_profile.height = Number(this.height);
    //     this.updated_profile.weight = Number(weight);
    //     this.updated_profile.ethnicity = ethnicity;
    //     this.updated_profile.bloodGroup = bloodgroup;
    //     this.updated_profile.dob = date_birth
    //     this.updated_profile.firstName = this.digi_prof.first_name;
    //     this.updated_profile.lastName =  this.digi_prof.last_name;
    //     this.updated_profile.addon = []
  
       
      
    //   } else {
    
    //     //console.log('if')
    //     this.updated_profile = this.profile_detail;
    //     this.updated_profile.gender = gender;
    //     this.updated_profile.height = Number(this.height);
    //     this.updated_profile.weight = Number(weight);
    //     this.updated_profile.ethnicity = ethnicity;
    //     this.updated_profile.dob = date_birth
    //     this.updated_profile.bloodGroup = bloodgroup;
    //     this.updated_profile.firstName = this.digi_prof.first_name;
    //     this.updated_profile.lastName =  this.digi_prof.last_name;
    //     this.updated_profile.addon = []
     
    //   }
      
      
    // }  else {
    //   //console.log('else')
    //   if(this.updated_profile){
    //     const u_a =  [...new Set(this.visited_dialog)];
    //     ////console.log(u_a , this.updated_profile) 
    //     this.patch_data= this.diff_filter(this.updated_profile,this.visited_dialog)
    //     this.patch_data.gender = gender;
    //     this.patch_data.height = Number(this.height);
    //     this.patch_data.weight = Number(weight);
    //     this.patch_data.ethnicity = ethnicity;
    //     this.patch_data.bloodGroup = bloodgroup
    //     this.patch_data.dob = date_birth;
        
        

    //   }

    //   this.updated_profile.gender = gender;
    //   this.updated_profile.height = Number(this.height);
    //   this.updated_profile.weight = Number(weight);
    //   this.updated_profile.ethnicity = ethnicity;
    //   this.updated_profile.bloodGroup = bloodgroup;
    //   this.updated_profile.dob = date_birth
    //   this.updated_profile.firstName = this.digi_prof.first_name;
    //   this.updated_profile.lastName =  this.digi_prof.last_name;
    //   this.updated_profile.addon = []
    
   
    // }
    
 

    // if(this.initial_res.length == 0){
    //   if(this.country== "usa" || this.country == "united states of america"||this.country=="USA"){
    //     this.updated_profile.heightUnit ="feet"
    //     this.updated_profile.weigthtUnit="lbs"

    //   } else {
    //     this.updated_profile.heightUnit ="cm"
    //     this.updated_profile.weigthtUnit="kg"

    //   }
    //   this.auth.create_profile_list(this.updated_profile).subscribe((res: any) => {
      
    //     ////console.log(res)
    //     this.toastr.success('Profile saved successfully');
        
    //     this.isOpen= false
    //     this.stop_loading()
    //     this.router.navigate(['/dashboard']);
    //   })
    //  // //console.log('post', this.updated_profile)
  
    // } else {
      
  

    //   this.auth.update_profile_list(this.profile_id ,this.patch_data).subscribe((res:any) => {
    
    //       ////console.log("Okayay here it is",res);
         
    //       this.toastr.success('Profile saved successfully');
    //       this.isOpen = false
    //       this.stop_loading()
        
    //     this.router.navigate(['/dashboard']);
    //   }); 
    
    //  // //console.log('patch', this.updated_profile, this.initial_res);
    
    // }


    
   
  }

  check_any_changes(dob){
    this.equal =  moment(moment(this.profile_detail.dob,"MM-DD-YYYY")).isSame(moment(dob,"MM-DD-YYYY").format())
   
    if(!this.equal){
      this.visited_dialog.push('dob')
    }
    if(this.myForm.controls['heightfeet'].dirty || this.myForm.controls['heightinc'].dirty){
      this.visited_dialog.push('height')
    }
    if((this.myForm.dirty==false) && (this.updated_profile==undefined) && (this.equal) ){
      
      return true
    }
    return false

  }

  
   openDialogEdit(title , i, dialog_status) {
    this.dialog_status = dialog_status;

    // //console.log(this.dialog_status)
    this.visited_dialog.push(title)
    this.dialog.open(MedicalConditionDialog, {
      maxHeight: '450px',
      data: [title, this.profile_detail , i, this.dialog_status , this.edit_profile]
    }).afterClosed().subscribe(result => {
      // //console.log(result);
        this.updated_profile = Object.assign({}, result);
    });
  }

  openDialog(title, dialog_status): void {
    this.dialog_status = dialog_status;
    // //console.log(this.dialog_status)
    // //console.log(title)
    this.visited_dialog.push(title)
    
    this.dialog.open(MedicalConditionDialog, {
      maxHeight: '450px',
      data: [title, this.profile_detail, NaN, this.dialog_status]
    }).afterClosed().subscribe(result => {
      ////console.log(result);
        this.updated_profile = Object.assign({}, result);
    });
  }

  
  edit(name: string) {
    this.editData['field'] = name;
    
  }
  cancel(){
    


  }

}

@Component({
  selector: 'medical-condition-dialog',
  templateUrl: 'medical-condition-dialog.html',
  styleUrls: ['./profile.component.scss']

})
export class MedicalConditionDialog implements OnInit {

 
  maxDate = new Date()

  @BlockUI()
  blockUI!: NgBlockUI; 
  tomorrow =  new Date(this.maxDate.setDate(this.maxDate.getDate()+1));
  
  date1 = new FormControl(new Date());
  submitted= false;
  medcon_list:any;
  conditionName =  '';
  severity = '';
  medCondition = '';
  fh_conditionName = '';
  relationship = '';
  status = '';
  vaccineName = '';
  disable_condition = false;
  user_id: any;
  // medcon: {conditionName: string, yearOfDiagnosis: number, comment: string}[] = [{conditionName: '', yearOfDiagnosis: 0, comment: '' }];
  profile_detail: any;
  // updated_profile_detail: any;
  index_selected:any;
  allery_list:any;
  allergicReaction = '';
  safeurl ="";
  type_con = ''

  

  selected_dialog_title: any;
  medCon: any;
  allergy: any;
  medication: any;
  famhistory: any;
  insurance: any;
  immunization: any;
  vacc_list: any;
  insurance_status_list=['Active','InActive'];
  fam_historty_list = ["mother","father","son", "daughter","friend","others"];
  dialog_status = '';
  myControl = new FormControl();
  freq_list = [ "everyDay", "asNeeded" ];
  frequency:any;
  formattime:any

  current_profile_details={};
  country:any;
  index_country:any;
  list_insurance:any;
  list_vaccine:any;
  time_freq:any[]=[{
    "time":"08:00 AM",
    "dosage": 1

   }];
  ampm_list = ["AM", "PM"];
  hour_value =  Utils._12_hour_format();
  minute_value = new Date().getMinutes().toString();
  am_pm =  new Date().getHours()>12? "PM": "AM";
  counter = Array;
  time:any[]=[];
  medUnits = ["g", "IU", "mcg", "mcg/hr" , "mcg/ml" , "mEq", "mg", "mg/cm2", "mg/g" , "mg/ml", "mL", "%"]
  read = false
  edit_profile:any
  num_of_times=1;
  today = new Date()
  save_Click=false;
  edit_time_status = false;
  year_selected:any;
  // form_selected: any;
  // myForm: any;
  

  constructor(private formBuilder:FormBuilder,private modalService: NgbModal, private auth: AuthServiceService, public datepipe:DatePipe, public dialog: MatDialogRef<MedicalConditionDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public add_doc:MatDialog , private conf:ConfirmBoxService , private toast:ToastrService
   
  ){
    dialog.disableClose = true
    this.selected_dialog_title = data[0]
    this.profile_detail = Object.assign({} ,data[1])
    this.index_selected = data[2]
    this.dialog_status = data[3]
    
  }
  
  
  myForm = this.formBuilder.group({
  })

  clear_url(){
    this.safeurl = ""
    this.type_con = ""
    
  }

  ngOnInit(): void {

    
   


    this.auth.get_medical_condition().subscribe((res:any) =>{
      this.medcon_list = res;
    

    })
    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
        for (let i = 0; i < res.data.length; i++) {

          if(res.data[i].index == "citizen_primary"){
            this.country = res.data[i].country  
            this.index_country = res.data[i].index


          }
        }
       
        

  
     

        
        
      }

    })
  
   
   

  
    

    // this.auth.profile_details().subscribe((res: any)  => {
    //   if(res != []){
    //     this.user_id = res[0].id;
    //     //console.log(this.user_id);
    //   }
      
    // })

    this.auth.get_vaccine_list().subscribe((res:any) =>{
      this.vacc_list = res;
    
      
    })

    this.auth.get_allergic_reaction().subscribe((res:any)=>{
      this.allery_list = res
    
    })

    
    // //console.log(this.index_selected, this.profile_detail);
    // //console.log(this.index_selected.toString() == 'NaN' , Object.keys(this.profile_detail).length == 0)
    if(this.index_selected.toString() == 'NaN'){
      this.index_selected = 0;
      // this.medCon = this.profile_detail.medicalCondition[this.index_selected]
      // this.allergy = this.profile_detail.allergy[this.index_selected]
      // this.medication = this.profile_detail.medication[this.index_selected]
      // this.insurance = this.profile_detail.insurance[this.index_selected]
      // this.immunization = this.profile_detail.immunization[this.index_selected]
      // this.famhistory = this.profile_detail.familyHistory[this.index_selected]
    } else {
      this.medCon = this.profile_detail.medicalCondition[this.index_selected]
      this.allergy = this.profile_detail.allergy[this.index_selected]
      this.medication = this.profile_detail.medication[this.index_selected]
      this.insurance = this.profile_detail.insurance[this.index_selected]
      this.immunization = this.profile_detail.immunization[this.index_selected]
      this.famhistory = this.profile_detail.familyHistory[this.index_selected]
    }
    if(this.selected_dialog_title == 'medication' && this.dialog_status=="edit"){
      this.time_freq = this.medication?.timeFrequency
      this.num_of_times = this.medication?.numberOfTimes

      
      
    }
    
    if(this.selected_dialog_title=="insurance" && this.dialog_status=="edit"){
      console.table(this.insurance)
      this.auth.get_personal_docs_tag(`Health-${this.insurance?.id}`,"citizen_primary").subscribe((res:any)=>{
        if(res.error==false){
          this.list_insurance = res.data
          
        }
      })

    } else if(this.selected_dialog_title=="immunization" && this.dialog_status=="edit"){
      this.auth.get_personal_docs_tag(`Health-${this.immunization?.id}`,"citizen_primary").subscribe((res:any)=>{
        if(res.error==false){
          this.list_vaccine  = res.data
         
        }
      })

    }

    

    switch (this.selected_dialog_title) {
      case 'medicalCondition':

        this.myForm = this.formBuilder.group({
          conditionName: ['', Validators.required],
          yearOfDiagnosis: [0, Validators.required],
          comment: ['']
        })
        break;
      case 'allergy':
        this.myForm = this.formBuilder.group({
          allergicTo: ['', Validators.required],
          allergicReaction: ['', Validators.required],
          severity: ['', Validators.required],
          comment: [''],
        })
        break;
      case 'medication':
        this.myForm = this.formBuilder.group({
          medCondition: ['', Validators.required],
          medicationName: ['', Validators.required],
          numberOfTimes: [1, [Validators.required,Validators.min(0),Validators.max(24)]],
          strength: ['', Validators.required],
          unit:['', Validators.required],
          startDate:['',Validators.required],
          endDate:[''],
          frequency:['',Validators.required],
          qty:[''],
          comment: ['']
        })
        break;
      case 'familyHistory':
        this.myForm = this.formBuilder.group({
          conditionName: ['', Validators.required],
          relationship: ['', Validators.required],
          comment: ['']
        })
        break;
      case 'insurance':
        this.myForm = this.formBuilder.group({
          insuranceCompany: ['', Validators.required],
          policyNumber: ['', Validators.required],
          memberId: [''],
          groupNumber: [''],
          startDate:[''],
          endDate:[''],
          planName: ['', Validators.required],
          status: ['', Validators.required],
          comment: ['']
        })
        break;
      case 'immunization':
        this.myForm = this.formBuilder.group({
          vaccineName: ['', Validators.required],
          adminDate: ['', Validators.required],
          nextDueDate:[''],
          detail: ['']
        })
        break;
    }
    this.myForm.get('qty')?.setValue(1)
    

   }

  //  doFilter() {
  //   this.medcon_list = this.medcon_list
  //     .pipe(map(medcon_list => this.filter(medcon_list)),
  //     )
  // }
  // filter(values) {
  //   //console.log(values)
  //   return values.filter(item => item.toLowerCase().includes(this.medCon.conditionName))
  // }

  get f(): { [key: string]: AbstractControl } {
    return this.myForm.controls;
  }
  start_loading(){
    this.blockUI.start()
  }
  stop_loading(){
    this.blockUI.stop()
  }


  calculateFrequency(freq, sel) 
  {  
    if(this.dialog_status=="edit" && !this.myForm.get('numberOfTimes')?.dirty){
      return this.time_freq
    }
   
    this.time=[]
    this.formattime=[]
    this.time_freq=[]
    var step = 12
    var startTime = 8
   var maxTime = 24 
   if(freq > 24){
     return
   }

   if(sel== "everyDay" && (freq == 1)){
      this.time_freq.push({
      "time":"08:00 AM",
      "dosage": 1

     })
     return this.time_freq


    } else {
    if(freq <= 2) {

      maxTime = 24

  } else if(freq<= 4) {

      maxTime = 12
    

  } else if(freq < 6) {

      maxTime = 16

  } else if(freq == 6) {

      maxTime = 14

  } else if(freq == 7 ){

      maxTime = 16

  } else if(freq == 8) {

      maxTime = 18

  } else if(freq == 9) {

      maxTime = 20

  } else if(freq == 10) {

      maxTime = 22

  } else if(freq == 11) {

      maxTime = 23

  } else  {

      maxTime = 24

  }

  if(freq >= 2) {

      if(freq <= 6) {

          step = (Math.ceil(maxTime / freq))
         // //console.log(step)

      } else {

          step = (Math.ceil(maxTime / freq))

      }

      var index = 1
       for(let i=2; i < maxTime ;step) {

          // Do something

          if(startTime < 12) {
              
             this.formattime = (((startTime == 0 ? 12 : startTime).toString()).padStart(2,'0')).concat(":00 AM") 
              //time.append((String((startTime == 0 ? 12 : startTime)).leftPadding(toLength: 2, withPad: "0")):00 AM)
             // //console.log(this.formattime)
              this.time.push(this.formattime)
              
    
          } else {

              if((startTime-12) < 12) {
                  
                this.formattime =  (((startTime - 12 == 0 ? 12 : startTime - 12).toString()).padStart(2,'0')).concat(":00 PM") 
            
                ////console.log(this.formattime)
                this.time.push(this.formattime)
                  

              } else {
                this.formattime =  (((startTime - 24 == 0 ? 12 : startTime - 24).toString()).padStart(2,'0')).concat(":00 AM") 
                //this.formattime = (((startTime - 24 == 0 ? 12 : startTime - 24).toString()).padStart(2,'0')).concat(":00 AM") 
               // //console.log(this.formattime)
                this.time.push(this.formattime)

              }

          }

          startTime += step

          index += 1

          if(index > freq) {

              break

          }

      }

  }
  ////console.log(this.time)
  this.time.map(item=>{
    this.time_freq.push({"time":item,
    "dosage": 1})
  })
 // //console.log(this.time_freq)
  return this.time_freq

   }
   

}
delete_doc(detail){
  this.conf.confirm("Delete","Deletion is permanent. Once deleted this document cannot be restored.").then((res)=>{
    if(res){
      this.auth.delete_personal_doc(detail.category, detail.id).subscribe((res:any)=>{
        if(res.error==false){
          this.toast.success(res.msg)
          if(this.selected_dialog_title=="insurance"){
            this.auth.get_personal_docs_tag(`Health-${this.insurance?.id}`,"citizen_primary").subscribe((res:any)=>{
              if(res.error==false){
                this.list_insurance = res.data
              }
            })
      
          } else if(this.selected_dialog_title=="immunization"){
            this.auth.get_personal_docs_tag(`Health-${this.immunization?.id}`,"citizen_primary").subscribe((res:any)=>{
              if(res.error==false){
                this.list_vaccine  = res.data
              }
            })
      
          }

          
  
        } 

      })

    }
  })

}

get get_timefreq(): FormArray {
  return this.myForm.get('timeFrequency') as FormArray;
} 


add_time_picker(){
  const timeform = this.formBuilder.group({
    time:['',Validators.required],
    dosage:[1,Validators.required]
  })
  //this.time_freqArray.push(timeform)
}
get_time(time){

  const number = moment(time, ["h:mm A"]).format("HH:mm");
  return number
  
}
get_no(val,freq){
  //console.log(val,freq)
  if(val){
    this.calculateFrequency(val,freq)
    return val 
  
  } else {
    this.calculateFrequency(1,freq)
    return 1
  }
}

upload_doc(){
  var subtag
  if(this.selected_dialog_title == 'immunization'){
   
    subtag = `Health-${this.immunization.id}`

  } else if(this.selected_dialog_title =='insurance'){
    subtag = `Health-${this.insurance.id}`
  }
  this.add_doc.open(DialogAddDocument,{
    data:["Personal",this.index_country,this.country,"Health",subtag]

  }).afterClosed().subscribe(data=>{
    if(data=='true'){
      if(this.selected_dialog_title=='insurance'){
        this.auth.get_personal_docs_tag(`Health-${this.insurance.id}`,"citizen_primary").subscribe((res:any)=>{
          if(res.error==false){
            this.list_insurance = res.data
          }
        })

      } else if(this.selected_dialog_title=='immunization'){
        this.auth.get_personal_docs_tag(`Health-${this.immunization.id}`,"citizen_primary").subscribe((res:any)=>{
          if(res.error==false){
            this.list_vaccine  = res.data
          }
        })

      }
     
    
    }
  })

}
year_emit(yr){
  this.year_selected = yr
}

  onSave_medical_condition(conditionName , yearOfDiagnosis , comment){
    this.start_loading()
    this.submitted = true;
    this.save_Click= true;
    this.myForm.markAllAsTouched()
    
    if(this.myForm.invalid){
      this.stop_loading()
      this.save_Click = false
      return 
      
    }
    yearOfDiagnosis = yearOfDiagnosis.slice(yearOfDiagnosis.length - 4, yearOfDiagnosis.length)
    //this.myForm.controls['yearOfDiagnosis'].setValue(this.year_selected)
    ////console.log(this.profile_detail,this.myForm.value)


      if(!Object.keys(this.profile_detail).includes('medicalCondition')){
        this.profile_detail.medicalCondition = [{"conditionName":conditionName,"yearOfDiagnosis":Number(yearOfDiagnosis),"comment":comment}];
        //this.profile_detail.medicalCondition.push(this.myForm.value)
      } else {
  
        this.profile_detail.medicalCondition.push({"conditionName":conditionName,"yearOfDiagnosis":Number(yearOfDiagnosis),"comment":comment})
      }
      
      this.dialog.close(this.profile_detail);
      this.stop_loading()
    
    
    

  }

  onDelete_medical_condition(conditionName , yearOfDiagnosis , comment){
    this.submitted = true;
    yearOfDiagnosis = yearOfDiagnosis.slice(yearOfDiagnosis.length - 4, yearOfDiagnosis.length)
    ////console.log(this.profile_detail)
    this.conf.confirm("Alert","Are you sure you want to delete Medical Condition?").then((res)=>{
      if(res){
        if(this.myForm.controls['conditionName'].status == 'VALID'){
          this.profile_detail.medicalCondition.splice(this.index_selected,1)
          this.dialog.close(this.profile_detail);
        }

      }
    })
  
 

     
  }
  

  get_date_value(date){
    let d = moment(date,"MM-DD-YYYY").format();
    return d
    

  }
  get_hr(time){
    let d = new Date(time)
   // //console.log(d, d.getHours().toString())
   return d.getHours().toString()
  }
  get_min(time){
    let d = new Date(time)
   return d.getMinutes().toString()
  }
  get_year(yr){
    console.log(yr)
    this.year_selected = yr
  }

  time_edit(time, index , total){
    ////console.log(time, index , total)
    this.edit_time_status = true
    let timef = moment(time,"HH:mm").format("hh:mm A")
    //console.log(timef)
    

   this.time_freq[index].time = timef
  }
  onSave_allergy(allergicTo, comment ){
    this.save_Click= true;
    this.start_loading()
   
     this.myForm.get('comment')?.setValue(comment)
    
    this.submitted = true;
    this.myForm.markAllAsTouched();
    
    if(this.myForm.invalid){
      this.save_Click= false;
      this.stop_loading()
      return
    }
     
      if(!Object.keys(this.profile_detail).includes('allergy')){
        this.profile_detail.allergy = []
        this.profile_detail.allergy.push(this.myForm.value)
      } else {
        this.profile_detail.allergy.push(this.myForm.value)
      }
     
     
    
      this.dialog.close(this.profile_detail);
      this.stop_loading()
    
    
  }
  onDelete_Allergy(){
    this.submitted = true;
   
    this.conf.confirm("Alert","Are you sure you want to delete Allergy?").then((res)=>{
      if(res){
        if(this.myForm.controls['allergicTo'].status == 'VALID' && this.myForm.controls['allergicReaction'].status == 'VALID' && this.myForm.controls['severity'].status == 'VALID'){
          this.profile_detail.allergy.splice(this.index_selected,1)
          this.dialog.close(this.profile_detail);
        } 

      }
    })





  }

  add_time_dosage(element){
    this.get_timefreq.push(element) 
  }



  onSave_medication(medCondition ,medicationNameInput ,strength ,unit,start, end , freq ,commentInput){
  
    this.save_Click= true;
    this.submitted = true;
    this.start_loading()
  
    var endDate
    var startDate
    var Notimes
    // //console.log(medCondition, medicationName, dosage, regimen, comment);
    if(end!=""){
      endDate =  this.datepipe.transform(end,"MM-dd-yyyy");

    } else {
      endDate = ""
    }

    if(start!=""){
      startDate = this.datepipe.transform(start,"MM-dd-yyyy");
      this.myForm.get('startDate')?.setValue(startDate)

    } else {
      startDate = ""
      this.myForm.get('startDate')?.setValue(startDate)

    }

    if(freq == "everyDay"){
      Notimes = this.myForm.get('numberOfTimes')?.value

    } else if(freq == "asNeeded"){
      Notimes = 1
      this.myForm.get('numberOfTimes')?.setValue(1)
      this.time_freq=[]
    }

    
    
   
   
    let data =   {
      "medCondition": medCondition,
      "medicationName": medicationNameInput,
      "numberOfTimes": Number(Notimes),
      "strength": strength,
      "unit": unit,
      "startDate": startDate,
      "endDate": endDate,
      "frequency": freq,
      "timeFrequency": this.time_freq,
      "qty": "1",
      "comment": commentInput
    }
    let nullvalue = Object.keys(data).filter(k=>data[k]=== null);
    
    if(nullvalue.length ! = 0){
      nullvalue.forEach(item=>{
        data[item] = ""
      })
    }
    
    this.myForm.markAllAsTouched();
      if(this.myForm.invalid){
        this.stop_loading()
        this.save_Click= false;
        return
      }
    
      if(!Object.keys(this.profile_detail).includes('medication')){
        this.profile_detail.medication = [];
        this.profile_detail.medication.push(data);
      } else {
        this.profile_detail.medication.push(data);
      }
      
      this.dialog.close(this.profile_detail);
      this.stop_loading()
    
    
  }
  edit_medication(medCondition ,medicationNameInput ,strength ,unit,start, end , freq  ,commentInput){
    this.submitted = true;
    this.start_loading()
 
   // this.myForm.get('timeFrequency')?.setValue(this.calculateFrequency(this.myForm.get('numberOfTimes')?.value))
    let endDate = this.datepipe.transform(end,"MM-dd-yyyy");
    let startDate = this.datepipe.transform(start,"MM-dd-yyyy")
    if(end!=""){
      endDate =  this.datepipe.transform(end,"MM-dd-yyyy");

    } else {
      endDate = ""
    }

    if(start!=""){
      startDate = this.datepipe.transform(start,"MM-dd-yyyy");
      this.myForm.get('startDate')?.setValue(startDate)


    } else {
      startDate = ""
      this.myForm.get('startDate')?.setValue(startDate)

    }

    this.myForm.markAllAsTouched()
    if(this.myForm.invalid){
      this.stop_loading()
      return
    }
    var Notimes

    if(freq == "everyDay"){
      Notimes = this.myForm.get('numberOfTimes')?.value

    } else if(freq == "asNeeded"){
      Notimes = 1
      this.myForm.get('numberOfTimes')?.setValue(1)
      this.time_freq=[]
      
    }

    // this.profile_detail.medication[this.index_selected] = this.myForm.value
    // this.dialog.close(this.profile_detail)
  if(this.myForm.invalid){
    return
  }
  
    if(this.myForm.valid){
      this.profile_detail.medication[this.index_selected].medCondition = medCondition
      this.profile_detail.medication[this.index_selected].medicationName = medicationNameInput
      this.profile_detail.medication[this.index_selected].numberOfTimes = Notimes
      this.profile_detail.medication[this.index_selected].strength = strength
      this.profile_detail.medication[this.index_selected].unit = unit
      this.profile_detail.medication[this.index_selected].startDate = startDate
      this.profile_detail.medication[this.index_selected].endDate = endDate;
      this.profile_detail.medication[this.index_selected].timeFrequency = this.time_freq
      this.profile_detail.medication[this.index_selected].frequency = freq
      
    
      this.dialog.close(this.profile_detail);
      this.stop_loading()

    }
  }
  onDelete_Medication(){
    this.submitted = true;
    ////console.log(this.profile_detail)
    this.conf.confirm("Alert","Are you sure you want to delete Medications?").then((res)=>{
      if(res){
        this.profile_detail.medication.splice(this.index_selected,1)
        this.dialog.close(this.profile_detail);

      }
        
      

    })
    
 

    

    

  }

  onSave_family_history(conditionName, comment){
    this.save_Click= true;
    this.start_loading()
    this.submitted = true;
    this.myForm.get('comment')?.setValue(comment)
    this.myForm.markAllAsTouched();
   
    if(this.myForm.invalid){
      this.stop_loading()
      this.save_Click= false;
      return
    }
  
      if(!Object.keys(this.profile_detail).includes('familyHistory')){
        this.profile_detail.familyHistory = [];
        this.profile_detail.familyHistory.push(this.myForm.value);
      } else {
        this.profile_detail.familyHistory.push(this.myForm.value);
      }
      
      this.dialog.close(this.profile_detail);
      this.stop_loading()
    
    
  }
  onDelete_Family_History(conditionName, comment){
    this.submitted = true;
   
    this.conf.confirm("Alert","Are you sure you want to delete Family History?").then((res)=>{
      if(res){
        if(this.myForm.controls['conditionName'].status == 'VALID' && this.myForm.controls['relationship'].status == 'VALID'){
          this.profile_detail.familyHistory.splice(this.index_selected,1)
          this.dialog.close(this.profile_detail);
        }

      }
    })

  
    
  }

  onSave_insurance(insuranceCompany, policyNumber, memberId, groupNumber, planName, comment,start,end){
    this.save_Click= true;
    this.start_loading()
    this.submitted = true;
   // //console.log(insuranceCompany, policyNumber, memberId, groupNumber, planName, status, comment);
   var sdate
   var edate
   this.myForm.get('comment')?.setValue(comment)
   this.myForm.get('groupNumber')?.setValue(groupNumber)
   this.myForm.get('memberId')?.setValue(memberId)
   if(start!==''){
    sdate = this.datepipe.transform(start,"MM-dd-yyyy")
    this.myForm.get('startDate')?.setValue(sdate)
   } else {
    this.myForm.get('startDate')?.setValue("")
    sdate = ""
   }

   if(end!==''){
    edate = this.datepipe.transform(end,"MM-dd-yyyy")
    this.myForm.get('endDate')?.setValue(edate)
   } else {
    this.myForm.get('endDate')?.setValue("")
    edate = ""
   }
  
   this.myForm.markAllAsTouched()
   if(this.myForm.invalid){
    this.stop_loading()
    this.save_Click= false;
     return
   }
  
    if(this.myForm.valid){
      if(!Object.keys(this.profile_detail).includes('insurance')){
        this.profile_detail.insurance.push(this.myForm.value);
      } else {
        this.profile_detail.insurance.push(this.myForm.value);
      }
      this.dialog.close(this.profile_detail);
      this.stop_loading()
    }
    
  }
  onDelete_insurance(){
    this.submitted = true;
    if(this.myForm.invalid){
      return;
    }
    ////console.log(this.profile_detail)
    this.conf.confirm("Alert","Are you sure you want to delete Insurance?").then((res)=>{
      if(res){
     
          this.profile_detail.insurance.splice(this.index_selected,1)
          this.dialog.close(this.profile_detail);
        

      }
    })
 
  



  }

  onSave_immunization(vaccineName ,daate, nextdate,detail){
    this.save_Click= true;
    this.start_loading()
    this.submitted = true
    
    var nextDueDate
    let adminDate = this.datepipe.transform(daate,"MM-dd-yyyy")
    if(nextdate!=""){
      nextDueDate = this.datepipe.transform(nextdate,"MM-dd-yyyy")
      this.myForm.get('nextDueDate')?.setValue(nextDueDate)

    } else {
      nextDueDate = nextdate
      this.myForm.get('nextDueDate')?.setValue(nextdate)

    }
   
    this.myForm.get('adminDate')?.setValue(adminDate)
    
    this.myForm.markAllAsTouched()
    if(this.myForm.invalid){
      this.stop_loading()
      this.save_Click= false;
      return
    }
    if(this.myForm.controls['vaccineName'].status == 'VALID'){
      if(!Object.keys(this.profile_detail).includes('immunization')){
        this.profile_detail.immunization = [];
        this.profile_detail.immunization.push({vaccineName, adminDate,nextDueDate, detail});
      } else {
        this.profile_detail.immunization.push({vaccineName, adminDate,nextDueDate, detail});
      }
        this.dialog.close(this.profile_detail);
        this.stop_loading()
    }
  }
  onDelete_immunization(vaccineName ,adminDate, detail){
    this.submitted = true;
    this.conf.confirm("Alert","Are you sure you want to delete Immunization?").then((res)=>{
      if(res){
        if(this.myForm.controls['vaccineName'].status == 'VALID'){
          this.profile_detail.immunization.splice(this.index_selected,1)
          this.dialog.close(this.profile_detail);
        } 

      }
    })
 
  
   

  }

  close(){
    this.dialog.close(this.profile_detail);
    
  }

  edit_medical_condition(conditionName , yearOfDiagnosis , comment){
    this.start_loading()
    this.submitted = true;
    if(this.myForm.invalid){
      this.stop_loading()
      return
    }
  
    if(this.myForm.controls['conditionName'].status == 'VALID'){
      this.profile_detail.medicalCondition[this.index_selected].conditionName = conditionName;
      yearOfDiagnosis = yearOfDiagnosis.slice(yearOfDiagnosis.length - 4, yearOfDiagnosis.length);
      this.profile_detail.medicalCondition[this.index_selected].yearOfDiagnosis = Number(yearOfDiagnosis);
      this.profile_detail.medicalCondition[this.index_selected].comment = comment;
      this.dialog.close(this.profile_detail);
      this.stop_loading()
    }
    

  }

  edit_allergy(allergicTo, comment ){
    this.submitted = true;
   
     this.myForm.get('comment')?.setValue(comment)
     if(this.myForm.invalid){
       return
     }

    if(this.myForm.controls['allergicTo'].status == 'VALID' && this.myForm.controls['allergicReaction'].status == 'VALID' && this.myForm.controls['severity'].status == 'VALID'){
      // this.profile_detail.allergy[this.index_selected].allergicTo = allergicTo;
      // this.profile_detail.allergy[this.index_selected].allergicReaction = allergicReaction;
      // this.profile_detail.allergy[this.index_selected].severity = severity;
      // this.profile_detail.allergy[this.index_selected].comment = comment;
      this.profile_detail.allergy[this.index_selected] = this.myForm.value
      this.dialog.close(this.profile_detail);
    }
  }



  edit_family_history(conditionName, comment){
    this.submitted = true;
    this.myForm.get('comment')?.setValue(comment)
    if(this.myForm.invalid){
      return
    }
   
      this.profile_detail.familyHistory[this.index_selected] = this.myForm.value
      this.dialog.close(this.profile_detail);
    
  }

  edit_insurance(insuranceCompany, policyNumber, memberId, groupNumber, planName, comment,start,end){
    this.submitted = true;
    this.myForm.get('comment')?.setValue(comment)
    this.myForm.get('groupNumber')?.setValue(groupNumber)
    this.myForm.get('memberId')?.setValue(memberId)
    var sdate
    var edate
    if(start!==''){
    sdate = this.datepipe.transform(start,"MM-dd-yyyy")
      this.myForm.get('startDate')?.setValue(sdate)
     } else {
      this.myForm.get('startDate')?.setValue("")
      sdate = ""
     }
  
     if(end!==''){
      edate = this.datepipe.transform(end,"MM-dd-yyyy")
      this.myForm.get('endDate')?.setValue(edate)
     } else {
      this.myForm.get('endDate')?.setValue("")
      edate = ""
     }
     var status = this.myForm.get('status')?.value
    
    
    if(this.myForm.invalid){
      return
    }

      this.profile_detail.insurance[this.index_selected].insuranceCompany = insuranceCompany;
      this.profile_detail.insurance[this.index_selected].policyNumber = policyNumber;
      this.profile_detail.insurance[this.index_selected].memberId = memberId;
      this.profile_detail.insurance[this.index_selected].groupNumber = groupNumber;
      this.profile_detail.insurance[this.index_selected].planName = planName;
      this.profile_detail.insurance[this.index_selected].status = status;
      this.profile_detail.insurance[this.index_selected].comment = comment;
      this.profile_detail.insurance[this.index_selected].startDate = sdate;
      this.profile_detail.insurance[this.index_selected].endDate = edate;
      //this.profile_detail.insurance[this.index_selected] = this.myForm.value
      this.dialog.close(this.profile_detail);
    
  }

  edit_immunization(vaccineName ,adminDate, nextdate, detail){
    this.submitted = true;
    var date;
    var ndate;
    if(nextdate!=""){
      ndate = this.datepipe.transform(nextdate,"MM-dd-yyyy")

    }  else {
      ndate=""
    }
   
    date = this.datepipe.transform(adminDate,"MM-dd-yyyy")

    
    if(this.myForm.controls['vaccineName'].status == 'VALID'){
      this.profile_detail.immunization[this.index_selected].vaccineName = vaccineName;
      this.profile_detail.immunization[this.index_selected].adminDate = date;
      this.profile_detail.immunization[this.index_selected].nextDueDate = ndate;
      this.profile_detail.immunization[this.index_selected].detail = detail;
      this.dialog.close(this.profile_detail);
    }
  }

  end_min_value(start){
    if(start!=''){
      var sdate = moment(start).add(1,'day')
      return moment(sdate,"MM-DD-YYYY").format()

    } else {
      return ''
    }
  }
  view_doc_personal(id,type,content){
    //this.dialog.close()
    this.modalService.open(content,{ size: 'lg' }).result.then((res)=>{
      this.clear_url()


    })
    this.safeurl = id
   this.type_con = type

  }

}




<div class="">
  <app-menu-comp></app-menu-comp>
  <div class="card-css main_container">
      <p class="heading">Profile</p>
    
     <form class="example-form"  [formGroup]="myForm_digicoffer" (ngSubmit)="onSaveDigiClicked()" >
       <div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>First Name</mat-label>
            <input matInput required #firstName placeholder="First Name" formControlName ="first_name" [ngModel]="profile_details?.first_name"  />
           
        </mat-form-field>
        <mat-error *ngIf="myForm_digicoffer.controls['first_name'].value=='' ">
          This Field is Required
        </mat-error>
        
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Middle Name</mat-label>
            <input matInput #midName placeholder="Middle Name" formControlName ="middle_name" [ngModel]="profile_details?.middle_name"  />
          
        </mat-form-field>
        <!-- <mat-error *ngIf="myForm_digicoffer.controls['midName'].dirty && myForm_digicoffer.controls['midName'].value==''">
          This Field is Required
        </mat-error> -->
        
      </div>
     
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Last Name</mat-label>
            <input matInput  required #lastName placeholder="Last Name" formControlName ="last_name" [ngModel]="profile_details?.last_name"  />
            
        </mat-form-field>
        <mat-error *ngIf="myForm_digicoffer.controls['last_name'].dirty && myForm_digicoffer.controls['last_name'].value==''">
          This Field is Required
        </mat-error>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Mobile</mat-label>
            <input  type="tel" matInput #mobile placeholder="Mobile Number" formControlName ="mobile" [ngModel]="profile_details?.mobile"  />
         
        </mat-form-field>
        <mat-error *ngIf="myForm_digicoffer.controls['mobile'].dirty && myForm_digicoffer.controls['mobile'].errors?.pattern">Enter 10 digits</mat-error>
        <!-- <mat-error *ngIf="myForm_digicoffer.controls['mobile'].dirty && myForm_digicoffer.controls['mobile'].value==''">
          This Field is Required
        </mat-error> -->
        
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>E-Mail Id</mat-label>
            <input matInput readonly type="email" #email placeholder="E-mail id" formControlName ="email" [ngModel]="profile_details?.email"  />
        </mat-form-field>
        <mat-error *ngIf="myForm_digicoffer.controls['email'].dirty && myForm_digicoffer.controls['email'].errors?.email">Enter Correct Pattern</mat-error>
        <mat-error *ngIf="myForm_digicoffer.controls['email'].dirty && myForm_digicoffer.controls['email'].value=='' ">
          This Field is Required
        </mat-error>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Password</mat-label>
            <input matInput #password type="password" placeholder="Password" formControlName ="password" ngModel="abcdefu"  />
            <mat-icon class="edit-field" title="Click to Edit" (click)="openEditFeild('password','')" matSuffix>edit</mat-icon>
        </mat-form-field>
       
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Country</mat-label>
            <input matInput #country placeholder="country"  [value]="primary_country" readonly />
        </mat-form-field>
      </div>
     
     
      
      
       </div>
    </form>
      
    <form class="example-form2"  [formGroup]="myForm_health">
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Date Of Birth</mat-label>
          <input matInput readonly [matDatepicker]="dp3" [max]="maxDate" #dob placeholder="Date of birth" [value]="getTomorrow()" >
          <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
          <mat-datepicker #dp3 ></mat-datepicker>
        </mat-form-field>
        
      </div>
      <div *ngIf="primary_country=='USA'"  class="height-us" >
        <mat-form-field class="half-width" appearance="fill">
          <mat-label>Feet</mat-label>
          
          <input matInput type="number" maxlength="2" min="0" max="10" #fett formControlName="heightfeet" [ngModel]="profile?.height.split('.')[0]" name="height" ><span matSuffix>ft</span>
         
          <mat-error *ngIf="myForm_health.controls['heightfeet'].errors?.min || myForm_health.controls['heightfeet'].errors?.max">Enter a valid value</mat-error>

          
        </mat-form-field>
        <mat-form-field class="half-width" appearance="fill" >
          <mat-label>Inches</mat-label>
          <input matInput  type="number" maxlength="2" min="0" max="11" #inc [ngModel]="profile?.height.split('.')[1]" formControlName="heightinc" name="height"/><span matSuffix>inch</span>
         
          <mat-error *ngIf="myForm_health.controls['heightinc'].errors?.min || myForm_health.controls['heightinc'].errors?.max">Enter a valid value</mat-error>
          
        </mat-form-field>


      </div>

      <div *ngIf="primary_country!='USA'" >
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Height</mat-label>
            <input type="number" matInput  min="0"  maxlength="3" #height placeholder="height" formControlName ="height" [ngModel]="profile?.height"  />
            <span matSuffix>cm</span>
            <mat-error *ngIf="myForm_health.controls['height'].dirty && myForm_health.controls['height'].errors?.min">
              Enter a positive number
            </mat-error>
            <mat-error *ngIf="myForm_health.controls['height'].errors?.max">Enter a valid number</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Weight</mat-label>
            <input type="number" matInput  min="0" maxlength="4" #weight placeholder="weight" formControlName ="weight" [ngModel]="profile?.weight"   />
            <span matSuffix *ngIf="primary_country=='USA'" >lbs</span>
            <span matSuffix *ngIf="primary_country!='USA'" >kg</span>
            <mat-error *ngIf="myForm_health.controls['weight'].dirty && myForm_health.controls['weight'].errors?.min">
              Enter a positive number
            </mat-error>
            <mat-error *ngIf="myForm_health.controls['weight'].errors?.max">Enter a valid number</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Gender</mat-label>
          <mat-select formControlName="gender" #gender [ngModel]="profile?.gender">
            <mat-option>Select Gender</mat-option>
            <mat-option *ngFor="let item of gender_list" value= "{{item}}">
              {{item}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Ethnicity</mat-label>
          <mat-select formControlName ="ethnicity" #ethnicity [ngModel]="profile?.ethnicity">
            <mat-option>Select Ethnicity</mat-option>
            <mat-option *ngFor="let item of ethnicity_list" value = "{{item.title}}">
              {{item.title}}
            </mat-option>
          </mat-select>
          </mat-form-field>
      </div>
      <div>
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Blood Group</mat-label>
          <mat-select formControlName ="bloodGroup" #bloodgroup [ngModel]="profile?.bloodGroup">
            <mat-option>Select Blood Group</mat-option>
            <mat-option *ngFor="let item of bloodgrp_list" value= "{{item.title}}">
              {{item.title}}
            </mat-option>
          </mat-select>
          </mat-form-field>
          <div class="ent">
            <mat-card>Country Affiliation<button *ngIf="citizen.length < 4" (click)="OpenAddCountryDialog('add','')">+</button></mat-card>
          </div>
          <div class="med-condition">
            <mat-card *ngFor="let item of citizen let i = index">
              <div class="flag-country">
                <img src="/assets/flags/{{item.country}}.svg" alt="{{item.country}}">
                <div class="text-next-icon">{{item.country | uaename }}</div>
                <div class="right-icon pull-right">
                  <button (click)="OpenAddCountryDialog('edit',item)">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </div>
              
              
            </mat-card>
          </div>

      </div>
      <div class="example-button-row">
        <button class="cancel-btn" mat-raised-button routerLink="/dashboard">Cancel</button>
        <button mat-raised-button type="submit" class="save-btn"(click)="onSaveHealth(dob.value)">Save</button>
      </div>
     
      

    </form>
    
  
  
  

     
          

  </div>
</div>
<block-ui></block-ui>

      
 
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterPipe'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, input: any): any {
    
    if (input && value[0]['business_name']) {
      return value.filter(val => val['business_name'].toLowerCase().indexOf(input.toLowerCase()) >= 0);
    } else if (input && value[0]['name']) {
      return value.filter(val => val['name'].toLowerCase().indexOf(input.toLowerCase()) >= 0);
    }else if (input && value[0]['firstName']) {
      return value.filter(val => (val['firstName']+" " + val['lastName']).toLowerCase().indexOf(input.toLowerCase()) >= 0);
    } else {
      return value;
    }
  }

}

@Pipe({
  name: 'FilterPipeSecond'
})

export class FilterPipeSecond implements PipeTransform {
  transform(value: any, input: any): any {
    
    if (input && value[0]['business_name']) {
      return value.filter(val => val['business_name'].toLowerCase().indexOf(input.toLowerCase()) >= 0);
    } else {
      return value;
    }
  }
}


<div class="graph-row">
    <!-- <div class="header">
        <div class="header-left">
            <mat-icon class="input-field-icon" matPrefix>arrow_circle_up</mat-icon>
            <p>Blood Pressure</p>
        </div>
        <div class="header-right">
            <button>W 37 <mat-icon class="input-field-icon" matPrefix>keyboard_arrow_up</mat-icon></button>
            <button>Weekly Stats <mat-icon class="input-field-icon" matPrefix>keyboard_arrow_down</mat-icon></button>
        </div>
    </div> -->
    <div class="">
        <div class="">
            <!-- <div class="">
                <mat-form-field>
                    <mat-label>Select an option</mat-label>
                    <mat-select [(value)]="selected">
                      <mat-option value="0x1810">Blood Pressure</mat-option>
                      <mat-option value="0x181D">Weight</mat-option>
                      <mat-option value="0x1822">Pulse</mat-option>
                      <mat-option value="0x1809">Temperature</mat-option>
                      <mat-option value="0x1808">Blood Sugar</mat-option>
                    </mat-select>
            
                  </mat-form-field>
                  
            </div>  -->
            <div>
                <div *ngIf = "selected!='0x1810'" >
                    <canvas mdbChart
                    [chartType]="chartType"
                    [datasets]="chartDatasets" 
                    [labels]="chartLabels"
                    [colors]="chartColors"
                    [options]="chartOptions"
                    [legend]="false"
                    (chartHover)="chartHovered($event)" 
                    (chartClick)="chartClicked($event)">
                    </canvas>
                </div>
                <div *ngIf = "selected=='0x1810'">
                    <canvas mdbChart
                    [chartType]="chartType2"
                    [datasets]="chartDatasets2" 
                    [labels]="chartLabels"
                    [colors]="chartColors2"
                    [options]="chartOptions"
                    [legend]="true"
                    (chartHover)="chartHovered($event)" 
                    (chartClick)="chartClicked($event)">
                    </canvas>
                </div>
            </div>
         
        </div>
        <!-- <div class="right"> -->
            <!-- <div class="header-right">
                <button>W 37 <mat-icon class="input-field-icon" matPrefix>keyboard_arrow_up</mat-icon></button>
                <button>Weekly Stats <mat-icon class="input-field-icon" matPrefix>keyboard_arrow_down</mat-icon></button>
            </div> -->
            <!-- <div class="tag-groups"> -->
                <!-- <div *ngFor="let item of chartDatasets; index as i" class="value">
                    <mat-icon class="input-field-icon" matPrefix>fiber_manual_record</mat-icon>
                    <div>
                        {{average_value[i]}} mmHg <br>
                        <span>Average {{item.label}}</span>
                    </div> 
                </div> -->
                <!-- <div class="value">
                    <mat-icon class="input-field-icon" matPrefix>fiber_manual_record</mat-icon>
                    <div>
                        75 mmHg <br>
                        <span>Average Diastolic</span>
                    </div>
                </div> 
                <div class="value">
                    <mat-icon class="input-field-icon" matPrefix>fiber_manual_record</mat-icon>
                    <div>
                        66bpm <br>
                        <span>Average Pulse</span>
                    </div>
                    
                </div>
                <div class="value">
                    <mat-icon class="input-field-icon" matPrefix>fiber_manual_record</mat-icon>
                    <div>
                        145 mmHg <br>
                        <span>Average Systolic </span>
                    </div> 
                </div>
                <div class="value">
                    <mat-icon class="input-field-icon" matPrefix>fiber_manual_record</mat-icon>
                    <div>
                        75 mmHg <br>
                        <span>Average Diastolic</span>
                    </div> 
                </div> -->
            <!-- </div> -->
            
        <!-- </div> -->
    </div>
    
</div>
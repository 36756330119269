import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';

declare let $: any
@Component({
  selector: 'app-service-provider',
  templateUrl: './service-provider.component.html',
  styleUrls: ['./service-provider.component.scss']
})

export class ServiceProviderComponent implements OnInit {

  searchbar = 'searchbar';
  display_value: any;
  
  @Input() display_status: any;
  @Input() chevron_left: any;
  @Input() call_message_buttons: any;
  @Input() service_provider_lists: any;
 
  @Input() patient_details: any;
  @Input() patient_selected: any;

  @Output() patientClicked = new EventEmitter();
  @Output() display = new EventEmitter();
 
  accept_text = "Accept";
  accept_class = "accept-button";

  removable = true;
  chips: string[] = [];
  searchText: any;
  searchTextSecond: any;
  bottomSheetRef: any;
  @Input() relationship_list_parent: any;
  relationship_list_data: any;
  care_team_list: any;
  service_provider_name: any;

  displayedColumns: string[] = ['business_name', 'accept_text'];
  dataSource : any;
  dataSource_care_team: any;
  imgurl: any;
  
  

  constructor(private router : Router , public dialog: MatDialog , public auth : AuthServiceService , public toastr: ToastrService) { }

 
  loadData(){
    if(this.service_provider_lists){
      //console.log(this.service_provider_name)
      this.dataSource = this.service_provider_lists
      this.care_team_list = this.service_provider_lists.filter((item) => {
        return item.biztype == 'consumer';
      })
      this.dataSource_care_team = this.care_team_list;
      //console.log(this.care_team_list)
      this.service_provider_name = this.service_provider_lists.filter((item) => {
        return item.biztype == 'prof';
      })
      // this.service_provider_name.forEach(element => {

      //   if(!element.isaccepted){
      //     if(Object.keys(element).includes('canAccept') && element.canAccept ){
      //       this.accept_text = "Accept"
      //     } else if (Object.keys(element).includes('canAccept') && !element.canAccept) {
      //       this.accept_text = "Pending"
      //     }
      //   }


    
      
    }

  }

  ngOnInit(): void {

    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.auth.service_provider_list().subscribe((res: any) => {
      this.service_provider_lists = res.data?.relationships;
      //console.log(res);
      this.loadData();
    })
    this.auth.get_image().subscribe((res:any)=>{
      if(res){
        this.imgurl = res.imageUrl;
      }
    })

    this.auth.relationship_list().subscribe((res: any) => {
      //console.log(res)
      
      this.relationship_list_data = res;
      //console.log("m==> " ,this.relationship_list_parent)
      
      
    })

 
  }

  toggle_patient_column(){
    this.display.emit("none");

  }

  acceptRequest(id){
    
    this.auth.service_provider_accept(id).subscribe((res:any)=>{
      //console.log(res.msg);
      if(res.error && typeof(res.msg) == "string"){
        this.toastr.info(res.msg);

      } else if(!res.error) {
        this.toastr.success(res.msg);
        //add a communication emitter
        
 
      }
      else if(res.msg.description) {
        this.toastr.info(res.msg.description);
      }
       
    })
    //console.log("here")

  }

  remove(chip): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    } 
  }

  pickValue(a:any){
    // this.patientClicked.emit(a);
    // //console.log(a)
    // return a;
  }

  openDialog() {
    this.dialog.open(RelationshipMenuDialog, {
      data: [Object.assign({}, this.relationship_list_data), Object.assign({}, this.relationship_list_data)]
    });
    
  }

}

@Component ({
  selector: 'relationship-menu-dialog', 
  templateUrl: 'relationship-menu-dialog.html',
  styleUrls: ['./service-provider.component.scss']
})

export class RelationshipMenuDialog {
  new_patient:any;
  loop_num = ["a", "b", "c"]
  removable = true;
  chips: string[] = [];
  searchText: any;
  relationship_list: any;
  bottomSheetRef: any;
  filtered_relationship_list: any;
  chips_data_from_child: string[] = [];
  filter_list: any;
  original_list_relationship: any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<RelationshipMenuDialog> ,public filter_dialog: MatDialog , public send_menu_request_dialog: MatDialog , public router: Router) { 
    this.relationship_list = data[0];
    this.filtered_relationship_list = data[0];
    this.filter_list = Object.assign({}, data[0]);
    this.original_list_relationship = Object.assign({}, data[1]);
  }
  ngDoCheck(){
    // var list = Object.assign([],this.relationship_list.entities);
    
    if(this.chips_data_from_child && (this.chips_data_from_child.length != 0 && this.relationship_list.entities)){
      this.filtered_relationship_list.entities = this.filter_list.entities.filter((element) => {
        if(element.country.toLowerCase() == this.chips_data_from_child[0] || this.isEmpty(element.address.city_town).toLowerCase() == this.chips_data_from_child[1] || this.isEmpty(element.address.state).toLowerCase() == this.chips_data_from_child[2] || this.isEmpty(element.address.zipcode) == this.chips_data_from_child[3]){
          return true 
        } else {
          return false;
        }
      })
      
      //console.log("here is the chips list =>", this.chips_data_from_child);
      
    }  

    if(this,this.chips_data_from_child){
      if(this.chips_data_from_child.every((e) => {
        return e == ""
      })){
        //console.log("empty chips")
        if(this.filter_list.entities){
        this.filtered_relationship_list.entities = this.filter_list.entities;
        }
      } else {
        //console.log("chips are there")
      }
    }
    

    //console.log(this.chips_data_from_child);
    //console.log(this.relationship_list.entities);
    //console.log(this.filtered_relationship_list);
  }

  ngOnInit(){    
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    //console.log(this.chips_data_from_child)
  }

  isEmpty(value: any){
    if(value == undefined){
      return "empty"
    }
    return value
  }
  openDialog(){
    this.filter_dialog.open(FilterMenuDialog, {
      data: [this.filter_list, this.original_list_relationship],
      
    }).afterClosed().subscribe((dataFromChild) => {
      this.chips_data_from_child = dataFromChild;
      if(dataFromChild){
        dataFromChild = dataFromChild.filter((e) => {
          return e != ""
        })
        this.chips = dataFromChild;
      
      }
      
      //console.log('recieves data - ', dataFromChild);
    })
    
  }

  closeDialog(){
    this.dialog.close();
  }

  remove(chip): void {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
      // this.chips[index] = ""
    }
    const ind = this.chips_data_from_child.indexOf(chip)
    this.chips_data_from_child[ind] = "";

  }

  openSendRequestDialog(value){
    this.send_menu_request_dialog.open(SendMenuRequest, {
      data: value
    })
  }

}

@Component ({ 
  selector: 'filter-menu-dialog',
  templateUrl:'filter-menu-dialog.html',
  styleUrls: ['./service-provider.component.scss']
})

export class FilterMenuDialog {
  filtered_list_array: any;
  original_list: any;

  filter_category_list: any;
  filter_category: { title: string, items: [{title: string, active: boolean}] }[] = 
  [
    {title: "Country", items: [
      {title: '', active: false}
    ]}, 
    {title: "City", items: [
      {title: '', active: false}
    ]}, 
    {title: "State", items: [
      {title: '', active: false}
    ]}, 
    {title: "Zipcode", items: [
      {title: '', active: false}
    ]}
  ];
  
  filtered_value_list = new Array<string>(4);
 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<FilterMenuDialog> ){
    this.filter_category_list = data[0];
    this.original_list = data[1]
    
  }
  ngOnInit(){
    //console.log("original list ==> ",this.original_list)

    //console.log("filtered list => ", this.filter_category_list)
    this.filter_category[0].items.shift();
    this.filter_category[1].items.shift();
    this.filter_category[2].items.shift();
    this.filter_category[3].items.shift();
    
    this.filter_category_list.entities.forEach((element: any) => {

      if((element.address.country) && element.address.country != undefined){
        this.filter_category[0].items.push({title: element.address.country.toLowerCase(), active: false});
      }
      
      if((element.address.city_town) && element.address.city_town != undefined){
        this.filter_category[1].items.push({title: element.address.city_town.toLowerCase(), active: false});
      }

      if((element.address.state)  && element.address.state != undefined){
        this.filter_category[2].items.push({title: element.address.state.toLowerCase(), active: false});
      }

      if((element.address.zipcode) && element.address.zipcode != undefined){
        this.filter_category[3].items.push({title: element.address.zipcode.toLowerCase(), active: false});
      }      
     
    });    


    this.filtered_list_array = [this.removeDuplicates(this.filter_category[0].items, item => item.title), this.removeDuplicates(this.filter_category[1].items, item => item.title), this.removeDuplicates(this.filter_category[2].items, item => item.title), this.removeDuplicates(this.filter_category[3].items, item => item.title)]
    //console.log(this.filter_category);

    //console.log(this.filtered_list_array[0][0].title);

  }
  removeDuplicates(data, key) {
    return [
      ...new Map(data.map(item => [key(item), item])).values()
    ]
  };

  close_sheet(){
    this.dialog.close();
  }

  btntext(btn, tag, item_index){
    //console.log(btn, tag, this.filtered_list_array[tag]);
    if(btn.active){
      this.filtered_value_list[tag] = ''
    } else {
      this.filtered_value_list[tag] = btn.title;
    }
    
    btn.active = !btn.active;
    //console.log(this.filtered_value_list);
    for(let i = 0; i<this.filtered_list_array[tag].length; i ++ ){
      if(i != item_index ){
        // btn.active = !btn.active;
        this.filtered_list_array[tag][i].active = false;
      }
    }
    
  }

  apply_filter(){
    
    this.dialog.close(this.filtered_value_list);
  }

  reset_filter(){
    this.filtered_list_array.map((item) => {
      item.map((eachItem) => {
        return eachItem.active = false;
      })
    })
    this.filtered_value_list =  new Array<string>(4);
    //console.log(this.filtered_value_list, this.filtered_list_array)
  }

  

}

@Component ({
  selector: 'send-menu-request',
  templateUrl: 'sent-menu-request.html',
  styleUrls: ['./service-provider.component.scss']
})

export class SendMenuRequest {
  name = new FormControl('');
  selected_entity: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<SendMenuRequest>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService){
    this.selected_entity = data;
  }

  myForm = this.formBuilder.group({
    desc: ['Please accept the request']
  })

  get f() { return this.myForm.controls; }

  ngOnInit(){
    //console.log(this.selected_entity)
  }


  cancel(){
    this.dialog.close();
  }

  confirm(desc){
    this.authservice.send_request(desc , this.selected_entity).subscribe((res: any) => {
      //console.log(res.msg);
      if(res.error && typeof(res.msg) == "string"){
        this.toastr.info(res.msg);
        this.dialog.close()
      } else if(!res.error) {
        this.toastr.success(res.msg);
        this.dialog.close();
      }
      else if(res.msg.description) {
        this.toastr.info(res.msg.description);
      }
    });

  }

}
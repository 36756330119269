
<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">Care Team</p>
    <div class="hi-right header-icons">
        <button (click)="openDialog()">
            <i class="fas fa-plus"></i>
        </button>
    </div>
    <div class="searchbar-e">
        <input  type="text" placeholder="Search" [(ngModel)] = searchText>
        <i class="fas fa-search"></i>
    </div>
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of care_team_list | FilterPipe: searchText ;">
            <mat-card >
                <div *ngIf="item.isaccepted" class="count-btn">
                    <button [routerLink]="['/menu/chat', item['id']]"><i class='far fa-comment-alt'></i></button>
                    
                </div>
                <div class="card-cnt">
                    <img  *ngIf="(!item.profileUrl)" src="../../assets/images/owner.png" alt="">
                    <img *ngIf="(item.profileUrl)" src={{item.profileUrl}} alt="">
                    <!-- <img *ngIf="( imgurl)" src={{imgurl}} alt=""> -->
                    <p class="card-title">{{item.business_name}}</p>
                    <p>Consumer</p>
                </div>
                <div class="edit-btn">
                    
                    <button *ngIf="item.canAccept && !item.isaccepted " class="accept-button" (click)="acceptRequest(item.id)">
                        Accept
                    </button>
                    <button *ngIf="!item.canAccept && !item.isaccepted" class="accept-button">
                        Pending
                    </button>
                </div>
            </mat-card>
        </div>
        
    </div>
</div>




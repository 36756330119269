import { AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthServiceService } from '../auth-service.service';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../app.component';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from '../communication.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { SaveAlertService } from '../save-alert/save-alert.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { type } from 'os';





@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI; 
  countries:any;
  p=1;
  ccode:any[]=[];
  country:any;
  displayedColumns: string[] = ['doctype', 'created','docid','expiration_date','action'];
  displayedColumns1: string[] = ['name', 'description','created','expiration_date','action'];
  displya:string[] =['tags','name', 'description','expiration_date','action']
  tag_list=["Health","Legal","Finance","Personal","Others"]

  list:any;
  isClassExpand = false;
  expandclick = false;
  searchText='';
  searchbar = 'searchbar';
  selected:any;
  clickedname="Identity";
  // menu_list = [{name: 'Health', link: '/menu/health-info', icon: 'fas fa-laptop-medical'}, {name: 'Legal & Finance', link: '/menu/add-device', icon: 'fas fa-cubes'},{name: 'Finance', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'},{name: 'Credentails', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'},{name: 'Personal', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'},{name: 'Others', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'}]
  //menu_list=[{name: 'Credentails', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'},{name: 'Personal', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'}]
   
  //tag_list=["Health","Legal","Finance","Credentials","Personal","Others"]
  tag_lists=["Identity","Health","Legal","Finance","LegalFinance","Personal","Others"]
  index_country = "citizen_primary";
  selected_country:any;
  
  credential_len:any;
  count:any[]=[];
  selected_tag="Identity";
  safeurl ="";
  viewDoc = false;
  menu_list = [
    {
        "tagName": "Identity",
        "count": 0
    },
    {
        "tagName": "Health",
        "count": 0
    },
    {
        "tagName": "LegalFinance",
        "count": 0
    },
    {
        "tagName": "Personal",
        "count": 0
    },
    {
        "tagName": "Others",
        "count": 0
    }
]
  type_con:any;
  view_icon="list"
  doc_detials:any;
  sel_doc_id:any;
  sel_name:any;
  status_chk:any;
  files:any[]=[];
  pg_no=0;
  allowedFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/rtf','text/csv','text/rtf'];



  
 
  constructor(public auth:AuthServiceService , public dialog:MatDialog , public toast:ToastrService  , public _tagcomms:CommunicationService , public sanitizer: DomSanitizer , private con : ConfirmBoxService,private modalService: NgbModal) { }
  open_bottom_dialog($event){
    // this.dialog.open(BottomDialog);
    $event.stopPropagation();
    this.isClassExpand = !this.isClassExpand;
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isClassExpand= false;
  }

  @HostListener('document:click', ['$event'])

  clickout() {
   this.isClassExpand = false;
  }
  expand() {
   this.expandclick = true
  }
  openDialog(title) {
    
    this.dialog.open(DialogAddDocument, {
      data:[title,this.selected , this.selected_country,this.selected_tag]
      
      
    }).afterClosed().subscribe((res:any)=>{
   
      if(res=='true'){
        if(title=="Identity"){
          this.auth.get_crediential_docs(this.selected).subscribe((res:any)=>{
            if(res.error==false){
              this._tagcomms.emitChange(res)
            }
          })
        } else {
          this.auth.get_personal_docs_tag(this.selected_tag,this.selected).subscribe((res:any)=>{
            this._tagcomms.emitChange(res)
          })
          
        }

      }
   
     
    })

    
  }
  drag(){
    this.dialog.open(DragDrop,{data:[this.selected_tag]}).afterClosed().subscribe((res:any)=>{
      this.auth.get_personal_docs_tag(this.selected_tag,this.selected).subscribe((res:any)=>{
        this._tagcomms.emitChange(res)
      })
    })
    //this.modalService.open(content, { size: 'xl' });

  }
 
  start_loadavg(){
    this.blockUI.start()
 
  }
 stop_loading(){
   this.blockUI.stop()
 }
  change_view(){

    if(this.view_icon=="image"){
      this.view_icon="list"

    } else {
      this.view_icon ="image"
    
    }
   
  }

  openViewDialog(name){
  
    this.dialog.open(DialogViewDocument,{

      data:[this.clickedname,this.selected,name]
    });

  }

  openCredentailEditDialog(whole_data){
    this.dialog.open(DialogEditDocument, {
      data:["Identity",this.selected , this.selected_country,whole_data]
      
      
    }).afterClosed().subscribe(data=>{
      location.reload()
    })

  }

  EditDialog(whole_data,type){
    
    this.dialog.open(DialogEditDocument, {
      data:[type,this.selected , this.selected_country,whole_data,this.selected_tag]
      
      
    }).afterClosed().subscribe(data=>{
      this.start_loadavg()
        if(type=="Identity"){
          this.auth.get_crediential_docs(this.selected).subscribe((res:any)=>{
            if(res.error==false){
              this._tagcomms.emitChange(res)
            }
          })
        } else {
          this.auth.get_personal_docs_tag(this.selected_tag,this.selected).subscribe((res:any)=>{
            this._tagcomms.emitChange(res)
          })
          
        }
        this.stop_loading()

      
     
     
    })

  }

  openPersonalEditDialog(whole_data){
    this.dialog.open(DialogEditDocument, {
      data:["Personal",this.selected , this.selected_country,whole_data]
      
      
    }).afterClosed().subscribe(
      data=>{
        location.reload()
      }
    )

  }

 

 



  applyFilter(filterValue: string) {
    this.list.filter = filterValue.trim().toLowerCase();
 
    
  }

  ngOnInit(): void {
    this.start_loadavg()
    
    
  

    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
      
        this.countries = res.data
        for (let i = 0; i < this.countries.length; i++) {

          if(this.countries[i].index == "citizen_primary"){
            this.selected = this.countries[i].index
            this.selected_country = this.countries[i].country


          }
        }
       
        
        // this.countries.forEach(element => {
        //   this.ccode.push(i18nIsoCountries.getAlpha2Code(element,'en'))

          
        // });

  
     

        
        
      }

    })
  
    if(this.clickedname=="Identity"){

      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        if(res.error==false){
          this.list = res.data
          //this.menu_list.unshift( {"tagName": "Credentials","count": res.data.length })
         
          this.credential_len = res.data.length
          this.auth.get_personal_docs(this.index_country).subscribe((res1:any)=>{
            if(res1.error==false){
              if(res1.data.length==0 && this.credential_len==0)
              this.openDialog("Identity")
            }
          })
        }
        
      })

    } 
    // tag_lists=["Credentials","Health","Legal","Finance","Personal","Others"]
    
    this.auth.get_tag_counts(this.index_country).subscribe((res:any)=>{
      if(res.error==false){
  
  
      this.count = res.counts
    
      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        if(res.error==false){
          this.menu_list.forEach(item=>{
            if(item.tagName=="Identity"){
              item.count = res.data.length
            }
          })
        
      //     this.menu_list.unshift({"tagName": "Identity","count": crede_len})
      // this.menu_list.splice(1,0,{"tagName": "Health","count": '0'})
      // this.menu_list.splice(2,0,{"tagName": "LegalFinance","count": '0'})
      // this.menu_list.splice(3,0,{"tagName": "Personal","count": '0'})
      // this.menu_list.splice(4,0,{"tagName": "Others","count": '0'})
      // //console.log(this.menu_list)
        }
        
      })

      this.menu_list.forEach(item=>{
        res.counts.forEach(element=>{
          if(item.tagName == element.tagName){
            item.count = element.count
          }
        })
      })
    
      
    
    
      // res.counts.forEach(element => {
      //   if(element.tagName=="Health"){

      //     this.menu_list[1].count = element.count

      //   } else if(element.tagName=="LegalFinance"){
      //     this.menu_list[2].count = element.count
      //   }  else if(element.tagName=="Personal"){
      //     this.menu_list[3].count = element.count
          
      //   } else if(element.tagName=="Others"){
      //     this.menu_list[4].count = element.count
          
      //   } 
      // });
      
    }
    })
   this.stop_loading()
    
    this._tagcomms.changeEmitted$.subscribe(data =>{
      if(data){
        this.list  =  data.data
        this.update_tags()
      
      }
    })


    


 
   

    
  }
  clear_url(){
    this.safeurl = ""
    this.type_con = ""
    delete this.doc_detials
  }


  share_view_doc(item,type){
    this.sel_doc_id = item?.id
    let doc_id
    if(type =='identity'){
      doc_id = item?.doctype

    }  else {
      doc_id = item?.id
    }
    // this.safeurl = item?.url
    this.doctopdfconvertor(item?.url,item.content_type)
    this.auth.get_doc_details(type,item?.category,doc_id).subscribe((res:any)=>{
      if(res.error==false){
        
         this.doc_detials = res.details

      }
    })

  }

  check_box(val,type){
    if(type=='data'){
      return val
    } else {
      return !val
    }

  }

    onChange_per(event,item,index){
      var payload
      var type
      var doctype
      this.status_chk = item.isShared
      if(this.clickedname=='Identity'){
        doctype = "identity"
      } else {
        doctype = "personal"
      }
      if(event.checked){
        payload = {
          "add":[
            {
              "doctype":doctype,
              "docid":this.sel_doc_id
            }
          ]
        }
      } else {
        payload = {
          "remove":[
            {
              "doctype":doctype,
              "docid":this.sel_doc_id
            }
          ]
        }
      }
   
     
      if(event.checked){
        this.con.confirm("Share","Are you sure you want to share this document").then((res:any)=>{
          if(res){

            this.auth.share_realtionship_document(item?.relId,payload).subscribe((res:any)=>{
              if(res.error==false){
                this.toast.success(res.msg)
              } else {
                this.toast.error(res.msg)
              }
            })
         

          } else {
            this.status_chk = !event.checked
            this.doc_detials.sharedWith[index].isShared = !event.checked
            
          }
        })

      } else {
        
        this.con.confirm("Share","Are you sure you want to stop sharing this document").then((res:any)=>{
          if(res){
            this.auth.share_realtionship_document(item?.relId,payload).subscribe((res:any)=>{
              if(res.error==false){
                this.toast.success(res.msg)
              } else {
                this.toast.error(res.msg)
              }
            })
         

          } else {
            this.status_chk = !event.checked
            this.doc_detials.sharedWith[index].isShared = !event.checked
           
           
          }
        })

      }
    
    
      
      
   

    }
  

  view_doc_identity(url, type){
    
    this.type_con = type
    this.viewDoc = true
    if(type.split('/',1)=='image'){
      this.safeurl = url

    } else {
      this.doctopdfconvertor(url,type)
    }

    
  }
  view_doc_personal(id,type){

  //  this.safeurl = id
   this.type_con = type
   if(type.split('/',1)=='image'){
    this.safeurl = id

  } else {
    this.doctopdfconvertor(id,type)
  }
  

  }

  doctopdfconvertor(url,content_type){

    if(this.allowedFileTypes.includes(content_type)){
      let fdata = {'url':url}
      this.auth.doc2pdfurl(fdata).subscribe((ans:any)=>{
          const ansBlob = new Blob([ans], { type: 'application/pdf' });
          const ansUrl = URL.createObjectURL(ansBlob);
          this.safeurl = ansUrl
         
      })
  }  else{
    this.safeurl = url
  }


  }


 
  update_tags(){
    
  
   this.menu_list.splice(0,this.menu_list.length)
   this.menu_list = [
    {
        "tagName": "Identity",
        "count": 0
    },
    {
        "tagName": "Health",
        "count": 0
    },
    {
        "tagName": "LegalFinance",
        "count": 0
    },
    {
        "tagName": "Personal",
        "count": 0
    },
    {
        "tagName": "Others",
        "count": 0
    }
]
  
    this.auth.get_tag_counts(this.index_country).subscribe((res:any)=>{
      if(res.error==false){
  
  
      this.count = res.counts
    
      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        if(res.error==false){
          this.menu_list.forEach(item=>{
            if(item.tagName=="Identity"){
              item.count = res.data.length
            }
          })
        
  
        }
        
      })

      this.menu_list.forEach(item=>{
        res.counts.forEach(element=>{
          if(item.tagName == element.tagName){
            item.count = element.count
          } 
        })
      })
     
      
      
    }
    })

     
  }

  delete_personal(id){
    
    this.con.confirm("Alert","Are you sure you want to delete this?").then((res)=>{
      if(res){
        this.auth.delete_personal_doc(this.index_country,id).subscribe((res:any)=>{
          if(res.error==false){
            this.toast.success(res.msg)
            this.auth.get_personal_docs_tag(this.selected_tag,this.index_country).subscribe((res:any)=>{
              this.list = res.data
              this.update_tags()
    
            })
    
          } 

        })

      }
    })



  }
  
  delete_credential(type){
    this.con.confirm("Alert","Are you sure you want to delete this?").then((res)=>{
      if(res){
        this.auth.delete_credentiall_docs(this.index_country,type).subscribe((res:any)=>{
          if(res.error==false){
            this.toast.success(res.msg)
            this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
              if(res.error==false){
                this.list = res.data
                this.update_tags()
              }
    
            })
    
          } 

        })

      }
    })
  

  }

  download_credential(type){
    
    this.auth.download_credentials(this.index_country,type).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.data.url, '_blank');
      }
      
    })
  }
  download_personal(id){
    this.auth.download_personal(id,this.index_country).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.data.url,'_blank');
      }

    })
  }

  selectedn(index , country){
    
    this.selected = index
    this.selected_country = country
    
    this.index_country = index
    
    this.start_loadavg()
    if(this.clickedname=="Identity"){
      
      this.auth.get_crediential_docs(index).subscribe((res:any)=>{
        
          this.list = res.data
         // this.credential_len = res.data.length
          
        
        
      })
      this.update_tags()
  
 
    } else  {
      
      this.update_tags()
      this.auth.get_personal_docs_tag(this.selected_tag,index).subscribe((res:any)=>{
      
          this.list = res.data
        
        
      })
    }
    
    
    this.stop_loading()
    //this._tagcomms.emitChange(this.menu_list)
   
    


  }
  OnClicked(name){
  
    this.selected_tag = name
    this.start_loadavg()
     if(name=="Identity"){
      
      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        
          this.list = res.data
          //console.log(this.list)
        
        
      })
      this.clickedname="Identity";
 
    } else  {
      
      this.clickedname="Personal";
      this.auth.get_personal_docs_tag(name,this.index_country).subscribe((res:any)=>{
      
          this.list = res.data
          //console.log(this.list)
        
      })
    }
    this.stop_loading()

  }


}


@Component({
  selector: 'dialog-add-document',
  templateUrl: './add-document.html',
  styleUrls: ['./documents.component.scss']
})


export class DialogAddDocument {
  @BlockUI()
  blockUI!: NgBlockUI; 
  submitted = false
  reminder:any;
  maxDate = new Date();
  tomorrow =  new Date(this.maxDate.setDate(this.maxDate.getDate()+1));
  tag_list=["Health","Legal","Finance","Personal","Others"]
  tag_list_id=["Identity"]
  tags:any[]=[];
  files:any;
  datenew:any;
  title:any;
  category:any;
  country:any;
  doclist:any;
  doctype_selected:any;
  tmpindex: number = 0;
  selbutton:any;
  name_tag:any[]=[];
  id:any;
  description:any
  ex_date:any
  dialog_status:any;
  selected_tag:any;
  chkexp = false;
  tag_lf:any;
  allowed_exe =  ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","image/jpg","image/jpeg","image/gif","image/bmp","image/png","text/plain","application/rtf", "application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
  allowed_exe_status = false
COUNTRIES_IDOC_MAP = {
  'Argentina': {'driver_license': 'Driver Licence','nic': 'National Identity Document (DNI)','passport': 'Passport'},
  'Australia': {'driver_license': 'Driver Licence','tfn':'Tax File Number (TFN)','passport': 'Passport'},
  'Austria': {'driver_license': 'Driver Licence','nic': 'National Identity Card (CCR ID)','passport': 'Passport'},
  'Belgium': {'driver_license': 'Driver Licence','nic': 'National Register Number (National ID)','passport': 'Passport'},
  'Bosnia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
  'Brazil': {'cpf':'Cadastro de Pessoas Físicas (CPF)','driver_license': 'Driver Licence','nic': 'National Identity Card','passport': 'Passport','ssc': 'Social Security Card'},
  'Bulgaria': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Civil Number)','passport': 'Passport'},
  'Canada': {'driver_license': 'Driver Licence','sic':'Social Insurance Card','passport': 'Passport'},
  'Chile': {'driver_license': 'Driver Licence','nic': 'National Identity Card (RUN / RUT)','passport': 'Passport'},
  'China': {'driver_license': 'Driver Licence','nic': 'National Identity Card (ID Number)','passport': 'Passport'},
  'Croatia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Identification Number - OIB)','passport': 'Passport'},
  'Czech Republic': {'birth_number':'Birth Number','driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Identification Card Number)','passport': 'Passport'},
  'Denmark': {'cvr_number':'CVR Number','driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Identification - CPR Number)','passport': 'Passport','vat_number':'VAT Registration Number'},
  'Estonia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Identification Code / Number)','passport': 'Passport'},
  'Finland': {'driver_license': 'Driver Licence','hetu':'Personal Identification Number (HETU)','nic': 'National Identity Card','passport': 'Passport'},
  'France': {'driver_license': 'Driver Licence','nic': 'National Identity Card / Number','passport': 'Passport'},
  'Herzegovina': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
  'Iceland': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Kennitala)','passport': 'Passport'},
  'India': {'aadhar': 'AADHAR Card','driver_license': 'Driver Licence','pancard': 'PAN Card','passport': 'Passport','voterid': 'Voter ID'},
  'Indonesia':{'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
  'Israel': {'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
  'Italy': {'driver_license': 'Driver Licence','hic':'Health Insurance Card','nic': 'National Identity Card (Fiscal Code Card)','passport': 'Passport'},
  'Latvia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Code)','passport': 'Passport'},
  'Lithuania': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Code)','passport': 'Passport'},
  'Macedonia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
  'Malaysia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
  'Mexico': {'driver_license': 'Driver Licence','nic': 'National Identity Card (CURP) ','passport': 'Passport','ssc': 'Social Security Card'},
  'Moldova': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Code)','passport': 'Passport'},
  'Montenegro': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
  'Netherlands': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Service / Personal Number)','passport': 'Passport'},
  'New Zealand': {'driver_license': 'Driver Licence','nhi':'National Health Index Number','passport': 'Passport'},
  'Norway': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Birth Number)','passport': 'Passport'},
  'Poland': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Polish National ID Card)','tin':'Tax Identification Number (NIP)','passport': 'Passport','pesel_number':'PESEL Number','regon':'REGON - Identification for Business'},
  'Portugal': {'driver_license': 'Driver Licence','hun':'Health User Number','ic_number':'Civil Identification Number','passport': 'Passport','ssc': 'Social Security Card /  Number','tin':'Tax Identification Number','voterid': 'Voter ID / Number'},
  'Romania': {'driver_license': 'Driver Licence','nic': 'National Identity Card / Personal Numeric Code - CNP','passport': 'Passport'},
  'Serbia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
  'Singapore': {'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
  'Slovakia': {'birth_number':'Birth Number','driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Identification Card Number)','passport': 'Passport'},
  'Slovenia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
  'South Africa': {'driver_license': 'Driver Licence','nic': 'National Identity Card','passport': 'Passport'},
  'South Korea': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Resident Registration Number)','passport': 'Passport'},
  'Spain': {'driver_license': 'Driver Licence','nic': 'National Identity Card (DNI)','nie': 'NIE','passport': 'Passport'},
  'Sweden': {'driver_license': 'Driver Licence','con': 'Co-Ordination Number','orn':'Organisation Number','passport': 'Passport','nic':'National Identity Card (Personal Identity Number - PIN)'},
  'Switzerland': {'driver_license': 'Driver Licence','passport': 'Passport','ssc': 'Social Security Card'},
  'Turkey': {'driver_license': 'Driver Licence','passport': 'Passport','nic': 'National Identity Card (Turkish Identification Number)'},
  'Ukraine': {'driver_license': 'Driver Licence','passport': 'Passport','nic': 'National Identity Card (Individual Identification Number)'},
  'United Kingdom': {'nino':'National Insurance Number','nhsn':'National Health Service Number','driver_license': 'Driver Licence','passport': 'Passport','ssc': 'Social Security Card','nic':'National Identity Card (Identity Card)'},
  'USA':{'driver_license': 'Driver Licence','passport': 'Passport','ssc': 'Social Security Card'},
  'United Arab Emirates':{'nic': 'National ID','passport': 'Passport'},
  

  [Symbol.iterator]: function* () {
    let properties = Object.keys(this);
    for (let i of properties) {
        yield [i, this[i]];
    }
}  

}
  constructor(
    public dialogRef: MatDialogRef<DialogAddDocument>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService , public datepipe:DatePipe , public _tagcomms:CommunicationService,private conf:ConfirmBoxService , private save: SaveAlertService
  ) {
    dialogRef.disableClose = true;
    this.title = data[0]
    this.category = data[1]
    this.country = data[2]
    this.name_tag.push(data[3])
    this.id = data[4]
    this.description = data[5]
    this.ex_date = data[6]
    this.dialog_status = data[7]
    this.selected_tag = data[8]
    //name,id,description,date

  }

  


  myForm = this.formBuilder.group({
  })


  ngOnInit(){
    //console.log(this.title,this.category,this.country , this.name_tag , this.id,this.selected_tag)
    switch (this.title){
      case 'Identity':
         
  this.myForm = this.formBuilder.group({
    id:['',Validators.required],
    type:['',Validators.required],
    target:[''],
    tags:[''],
    file:['',Validators.required]
   });
    break;
    case 'Personal':
      this.myForm = this.formBuilder.group({
        name:['',Validators.required],
        description:['',Validators.required],
        target:[''],
        file:['',Validators.required],
        tags:['', Validators.required]
       });

       break;


    }
  
    // this.doclist = this.COUNTRIES_IDOC_MAP.filter((item) => {
    //   return item == this.country
    // })
  
    for (let  [k, v]  of this.COUNTRIES_IDOC_MAP) {
      if(k==this.country){
        this.doclist = v
        

      }
    
     
      
    }
    //console.log(this.doclist)
  }
  start_loading(){
    this.blockUI.start()
  }
  stop_loading(){
    this.blockUI.stop()
  }


 
 
  get f() { return this.myForm.controls; }

  

  onFileChanged(event: any) {
    this.allowed_exe_status = false
    this.files = event.target.files[0];
  
    //console.log(this.files.type)
    if(this.files.size > 10000000){

      alert("file limit exceded")
      return
    }

    if(this.allowed_exe.includes(this.files.type)==false){
      this.allowed_exe_status = true
      
      return
    }
    
    
   
    
  }

  check_doctype_exp(event:any){
    let doctype = event.value
    if(doctype == "driver_license" || doctype == "passport") {
    this.myForm.get('target')?.setValidators(Validators.required);
    this.myForm.get('target')?.updateValueAndValidity();
    this.chkexp = true
   //console.log(this.chkexp)

  } else {
    
    //delete this.myForm.value.con
    this.chkexp = false
    this.myForm.get('target')?.clearValidators();
    this.myForm.get('target')?.updateValueAndValidity();
    //this.myForm.removeControl('target')

  }

  }
  filternull(value){
    const filtered = {};
    for (let key in value) {
      if (value[key]) {
        filtered[key] = value[key];
      }
    }
    //console.log(filtered)
    return filtered

  }

  add_personal_document(name,description,target,tags){

    var exp_data
    //console.log(this.myForm.valid)
    //console.log(name,description,target,tags ,this.myForm.value)
    this.submitted = true
    if (this.myForm.invalid || this.allowed_exe_status) {
      //console.log("yess")
      return
       }   
    const formData = new FormData(); 
    
    this.datenew = this.datepipe.transform(target,'dd-MM-YYYY')
    if(this.datenew == null){
      exp_data = ""
    } else {
      exp_data = this.datenew
    }
    // const datasend={
    //   "docid": "","doctype": "","expiration_date": datenew,"filename": this.files.name ,"content_type": this.files.type,"category": "citizen_primary","file": formData ,"country": "India"
    // }
     
    const tag_sel =  this.myForm.value.tags
    for( let i = 0; i <tag_sel?.length; i++){
      if(tag_sel[i]=="Legal"||tag_sel[i]=="Finance"){
         tag_sel.push("LegalFinance")
         this.myForm.get('tags')?.setValue(tag_sel)
         this.tag_lf = "LegalFinance"
         //console.log(this.myForm.value.tags)
      }

    }
    this.start_loading()
    
    
    if(this.id == undefined){
          
      formData.append("name",name)
      formData.append("description",description)
      formData.append("expiration_date",exp_data)
      formData.append("filename",this.files.name)
      formData.append("content_type",this.files.type)
      formData.append("category", this.category)
      formData.append("file", this.files)
      let array_tag  = [...new Set(this.myForm.value.tags)];
      formData.append("tags",JSON.stringify(array_tag))
      this.auth.add_personal_doc(this.category,formData).subscribe((res:any)=>{
        if(res.error==false){
            

          
         
          this.stop_loading()
          this.toast.success(res.msg)
           this.close_dialog("true")
        
        } else {
          this.stop_loading()
          this.toast.error(res.msg)
          
        }
      })

     } else {
          
      formData.append("name",name)
      formData.append("description",description)
      formData.append("expiration_date",exp_data)
      formData.append("filename",this.files.name)
      formData.append("content_type",this.files.type)
      formData.append("category", this.category)
      formData.append("file", this.files)
      let array_tag  = [...new Set(this.myForm.value.tags)];
      formData.append("tags",JSON.stringify(array_tag))
      let subtag = [this.id]
      formData.append("subtags", JSON.stringify(subtag))
      this.auth.add_personal_doc(this.category,formData).subscribe((res:any)=>{
        if(res.error==false){
          this.close_dialog("true")
          this.toast.success(res.msg)
         this.stop_loading()
         
         
        } else {
          this.stop_loading()
          this.toast.error(res.msg)
        }
      })
     }
 
  

  }


  add_credential_document(id,type,tags){
    
  
    //console.log(id,type,tags, target ,this.myForm.value)
    this.submitted = true
    if (this.myForm.invalid) {
      
      return
       }   
    const formData = new FormData(); 
    
   
    if(this.chkexp){
      let target = this.myForm.get('target')?.value
      this.datenew = this.datepipe.transform(target,'dd-MM-YYYY')
      formData.append("docid",id)
      formData.append("doctype",type)
      formData.append("expiration_date",this.datenew)
      formData.append("filename",this.files.name)
      formData.append("content_type",this.files.type)
      formData.append("category", this.category)
      formData.append("file", this.files)
      formData.append("country",this.country)
      formData.append("tags",JSON.stringify(this.myForm.value.tags))

    } else {
      formData.append("docid",id)
      formData.append("doctype",type)
      formData.append("filename",this.files.name)
      formData.append("content_type",this.files.type)
      formData.append("category", this.category)
      formData.append("file", this.files)
      formData.append("country",this.country)
      formData.append("tags",JSON.stringify(this.myForm.value.tags))
    }
    this.start_loading()
      ////console.log(formData.getAll("tags"))
      this.auth.add_crediential_docs(this.category,formData).subscribe((res:any)=>{
      
        if(res.error==false){
          this.toast.success(res.msg)
          this.stop_loading()
          this.close_dialog("true")
        } else if(res.error && res.msg?.docid){
          this.toast.error(res.msg?.docid)
          
      this.stop_loading()
        } else if(res.error && res.msg.errors?.expiration_date){
          this.toast.error(res.msg.errors?.expiration_date)
          
      this.stop_loading()
        } else if(res.error){
          this.toast.error(res.msg)
          
      this.stop_loading()
        }

      })



  }


  close_add_dialog(res){
    this.dialogRef.close(res)
  }
  close_dialog(status){
    if(this.submitted==false && this.myForm.dirty){
      this.save.confirm("Alert").then((res)=>{
        if(res){
          this.dialogRef.close()
        }
      })
      

    } else {
      this.dialogRef.close(status)
    }
    
  }
  



}

@Component({
  selector: 'dialog-view-document',
  templateUrl: './view-file.html',
  styleUrls: ['./documents.component.scss']
})

export class DialogViewDocument {

  url:any;
  name:any;
  category:any;
  title:any;
  constructor(
    public dialogRef: MatDialogRef<DialogViewDocument>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService , public datepipe:DatePipe , public _communication:CommunicationService,private sanitizer: DomSanitizer
  ) {
    this.title=data[0]
    this.category=data[1]
    this.name=data[2]
  }
  ngOnInit(){
  // //console.log(this.title,this.category,this.name)
    if(this.title=="Identity"){
      this.auth.view_docs(this.category,this.name).subscribe((res:any)=>{
        
        if(res.error==false){
          this.url= this.sanitizer.bypassSecurityTrustResourceUrl(res.data.url)
          
        }
      })

    } else {
      this.auth.view_personal_doc(this.category,this.name).subscribe((res:any)=>{
        if(res.error==false){
          this.url= this.sanitizer.bypassSecurityTrustResourceUrl(res.data.url)
          
        }
        
      })
    }
   
  }

  close_dialog(){
    this.dialogRef.close();
  }

 
   


}

@Component({
  selector: 'dialog-edit-document',
  templateUrl: './edit-dialog.html',
  styleUrls: ['./documents.component.scss']
})

export class DialogEditDocument {

  doclist:any;
  doctype_selected:any;
  tmpindex: number = 0;
  selbutton:any;
  name_e:any;
  id_e:any;
  description_e:any
  ex_date:any
  dialog_status:any;
  title:any;
  category:any;
  country:any;
  tags:any[]=[];
  tag_list=["Health","Legal","Finance","Personal","Others"]
  tag_list_id=["Identity"]
  maxDate = new Date()
  tomorrow =  new Date(this.maxDate.setDate(this.maxDate.getDate()+1));
  COUNTRIES_IDOC_MAP = {
    'Argentina': {'driver_license': 'Driver Licence','nic': 'National Identity Document (DNI)','passport': 'Passport'},
    'Australia': {'driver_license': 'Driver Licence','tfn':'Tax File Number (TFN)','passport': 'Passport'},
    'Austria': {'driver_license': 'Driver Licence','nic': 'National Identity Card (CCR ID)','passport': 'Passport'},
    'Belgium': {'driver_license': 'Driver Licence','nic': 'National Register Number (National ID)','passport': 'Passport'},
    'Bosnia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
    'Brazil': {'cpf':'Cadastro de Pessoas Físicas (CPF)','driver_license': 'Driver Licence','nic': 'National Identity Card','passport': 'Passport','ssc': 'Social Security Card'},
    'Bulgaria': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Civil Number)','passport': 'Passport'},
    'Canada': {'driver_license': 'Driver Licence','sic':'Social Insurance Card','passport': 'Passport'},
    'Chile': {'driver_license': 'Driver Licence','nic': 'National Identity Card (RUN / RUT)','passport': 'Passport'},
    'China': {'driver_license': 'Driver Licence','nic': 'National Identity Card (ID Number)','passport': 'Passport'},
    'Croatia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Identification Number - OIB)','passport': 'Passport'},
    'Czech Republic': {'birth_number':'Birth Number','driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Identification Card Number)','passport': 'Passport'},
    'Denmark': {'cvr_number':'CVR Number','driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Identification - CPR Number)','passport': 'Passport','vat_number':'VAT Registration Number'},
    'Estonia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Identification Code / Number)','passport': 'Passport'},
    'Finland': {'driver_license': 'Driver Licence','hetu':'Personal Identification Number (HETU)','nic': 'National Identity Card','passport': 'Passport'},
    'France': {'driver_license': 'Driver Licence','nic': 'National Identity Card / Number','passport': 'Passport'},
    'Herzegovina': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
    'Iceland': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Kennitala)','passport': 'Passport'},
    'India': {'aadhar': 'AADHAR Card','driver_license': 'Driver Licence','pancard': 'PAN Card','passport': 'Passport','voterid': 'Voter ID'},
    'Indonesia':{'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
    'Israel': {'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
    'Italy': {'driver_license': 'Driver Licence','hic':'Health Insurance Card','nic': 'National Identity Card (Fiscal Code Card)','passport': 'Passport'},
    'Latvia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Code)','passport': 'Passport'},
    'Lithuania': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Code)','passport': 'Passport'},
    'Macedonia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
    'Malaysia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
    'Mexico': {'driver_license': 'Driver Licence','nic': 'National Identity Card (CURP) ','passport': 'Passport','ssc': 'Social Security Card'},
    'Moldova': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Personal Code)','passport': 'Passport'},
    'Montenegro': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
    'Netherlands': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Service / Personal Number)','passport': 'Passport'},
    'New Zealand': {'driver_license': 'Driver Licence','nhi':'National Health Index Number','passport': 'Passport'},
    'Norway': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Birth Number)','passport': 'Passport'},
    'Poland': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Polish National ID Card)','tin':'Tax Identification Number (NIP)','passport': 'Passport','pesel_number':'PESEL Number','regon':'REGON - Identification for Business'},
    'Portugal': {'driver_license': 'Driver Licence','hun':'Health User Number','ic_number':'Civil Identification Number','passport': 'Passport','ssc': 'Social Security Card /  Number','tin':'Tax Identification Number','voterid': 'Voter ID / Number'},
    'Romania': {'driver_license': 'Driver Licence','nic': 'National Identity Card / Personal Numeric Code - CNP','passport': 'Passport'},
    'Serbia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
    'Singapore': {'driver_license': 'Driver Licence','nic': 'National Identity Card (National ID)','passport': 'Passport'},
    'Slovakia': {'birth_number':'Birth Number','driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Identification Card Number)','passport': 'Passport'},
    'Slovenia': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Citizen Number)','passport': 'Passport'},
    'South Africa': {'driver_license': 'Driver Licence','nic': 'National Identity Card','passport': 'Passport'},
    'South Korea': {'driver_license': 'Driver Licence','nic': 'National Identity Card (Resident Registration Number)','passport': 'Passport'},
    'Spain': {'driver_license': 'Driver Licence','nic': 'National Identity Card (DNI)','nie': 'NIE','passport': 'Passport'},
    'Sweden': {'driver_license': 'Driver Licence','con': 'Co-Ordination Number','orn':'Organisation Number','passport': 'Passport','nic':'National Identity Card (Personal Identity Number - PIN)'},
    'Switzerland': {'driver_license': 'Driver Licence','passport': 'Passport','ssc': 'Social Security Card'},
    'Turkey': {'driver_license': 'Driver Licence','passport': 'Passport','nic': 'National Identity Card (Turkish Identification Number)'},
    'Ukraine': {'driver_license': 'Driver Licence','passport': 'Passport','nic': 'National Identity Card (Individual Identification Number)'},
    'United Kingdom': {'nino':'National Insurance Number','nhsn':'National Health Service Number','driver_license': 'Driver Licence','passport': 'Passport','ssc': 'Social Security Card','nic':'National Identity Card (Identity Card)'},
    'USA':{'driver_license': 'Driver Licence','passport': 'Passport','ssc': 'Social Security Card'},
    'United Arab Emirates':{'nic': 'National ID','passport': 'Passport'},
  
    [Symbol.iterator]: function* () {
      let properties = Object.keys(this);
      for (let i of properties) {
          yield [i, this[i]];
      }
  }  
  
  }
  submitted = false;
  datenew: any;
  details:any;
  sel_tag:any;
  


  constructor(
    public dialogRef: MatDialogRef<DialogEditDocument>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService , public datepipe:DatePipe , public _tagcomms:CommunicationService,private sanitizer: DomSanitizer , private conf:ConfirmBoxService , private save: SaveAlertService
  ) {
    dialogRef.disableClose = true;
    this.title = data[0]
    this.category = data[1]
    this.country = data[2]
    this.details = data[3]
    this.sel_tag = data[4]
    
  }
  myForm = this.formBuilder.group({
  })

  ngOnInit(){
    this.tags = this.details.tags
    

 

    switch (this.title){
      case "Identity":
         
  this.myForm = this.formBuilder.group({
    id:[''],
    type:[''],
    target:[''],
    tags:['', Validators.required]
   });
    break;
    case 'Personal':
      this.myForm = this.formBuilder.group({
        name:[''],
        description:[''],
        target:[''],
        url:[''],
        tags:['', Validators.required]
       });

       break;


    }
  
    // this.doclist = this.COUNTRIES_IDOC_MAP.filter((item) => {
    //   return item == this.country
    // })
  
    for (let  [k, v]  of this.COUNTRIES_IDOC_MAP) {
      if(k==this.country){
        this.doclist = v
        

      }
    
     
      
    }

    



  }
  get_date_value(date){
    let d = moment(date,"MMM Do YYYY").format();
    return d
  
  }

 

  
  get f() { return this.myForm.controls; }
  edit_personal_document(name,description,target,tags){
    ////console.log(name,description,target,tags,this.myForm.value.tags)
    this.submitted = true
    if (this.myForm.invalid) {
      return
       }   
    
    
    this.datenew = this.datepipe.transform(target,'dd-MM-YYYY')
   
    let tag_list = this.myForm.value.tags
    if((tag_list.includes("LegalFinance")==false)&&(tag_list.includes('Legal')||tag_list.includes('Finance'))){
      tag_list.push("LegalFinance")

    }
  
    
      let data={"name":name,"description":description,"expiration_date":this.datenew,"tags":tag_list}
    
      this.auth.update_personal(this.category,this.details.id,data).subscribe((res:any)=>{
        if(res.error==false){
          this.toast.success(res.msg)
          this.close_dialog("true")
        
        } else if(res.error && res.msg?.name){
          this.toast.error(res.msg?.name)
          
        } else if(res.error && res.msg.expiration_date){
          this.toast.error(res.msg.expiration_date)
          
        } else if(res.error){
          this.toast.error(res.msg)
          
        }
      })
  

  }

  edit_credential_document(id,tags,target){
  
    this.submitted = true
    if (this.myForm.invalid) {
      return
       }   
    var dateformated = this.datepipe.transform(target,'dd-MM-YYYY')
   
    let data={"docid":id ,"tags":this.myForm.value.tags,"expiration_date":dateformated}
    this.auth.update_credentails(this.category,this.details.doctype,data).subscribe((res:any)=>{
      if(res.error==false){
        
        this.toast.success(res.msg)
        this.close_dialog("true")

      } else if(res.error && res.msg?.docid){
        this.toast.error(res.msg?.docid)
        
   
      } else if(res.error && res.msg.errors?.expiration_date){
        this.toast.error(res.msg.errors?.expiration_date)
        
    
      } else if(res.error){
        this.toast.error(res.msg)
        
   
      }
    })

  

  }

  close_dialog(status){
    if(this.submitted==false && this.myForm.dirty){
      this.save.confirm("Alert").then((res)=>{
        if(res){
          this.dialogRef.close("false")
        }
      })
      

    } else {
      this.dialogRef.close(status)
    }
    
  }




}

@Component({
  selector: 'drag-drop',
  templateUrl: './drag-drop.html',
  styleUrls: ['./documents.component.scss']
})

export class DragDrop {
  files: any[] = [];
  pg_no=0;
  step = 0;
  tags:any[]=[];
  tag_list=["Health","Legal","Finance","Personal","Others"]
  tag_list_id=["Identity"]
  maxDate = new Date()
  tomorrow =  new Date(this.maxDate.setDate(this.maxDate.getDate()+1));
  all_payload:any[]=[]
  datenew: any;
  submitted = false
  countries: any;
  selected: any;
  selected_country: any;
  index_country: any;
  @BlockUI()
  blockUI!: NgBlockUI; 
  allowed_exe =  ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","image/jpg","image/jpeg","image/gif","image/bmp","image/png","text/plain","application/rtf", "application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
  allowed_exe_status = false;
  error_msg:any[] =[];
  succes_msg:any[]=[];
  counter=0;
  sel_tag:any[]=[];

  

  

  constructor(
    public dialogRef: MatDialogRef<DragDrop>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService , public datepipe:DatePipe , public _tagcomms:CommunicationService
  ) {
    dialogRef.disableClose = true;
   this.sel_tag.push(data[0])
    
  }



      form = this.formBuilder.group({
      
        lessons: this.formBuilder.array([])
    });

    get lessons() {
      return this.form.controls["lessons"] as FormArray;
    }
    addLesson() {
      const lessonForm = this.formBuilder.group({
        name:['',Validators.required],
        description:['',Validators.required],
        target:[''],
        tags:[this.sel_tag, Validators.required]
      });
      this.lessons.push(lessonForm);
    }




   ngOnInit(): void{
    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
        this.countries = res.data
        for (let i = 0; i < this.countries.length; i++) {

          if(this.countries[i].index == "citizen_primary"){
            this.selected = this.countries[i].index
            this.selected_country = this.countries[i].country


          }
        }
       

        
     

        
        
      }

    })
   }
 
   onSubmit(){
    
    if(this.files.length<1){
      this.toast.error("Browse or drop atleast one file")
      return
    }
    this.start_loading()
    this.submitted = true
    this.form.markAllAsTouched()
    if(this.form.invalid){
      this.stop_loading()
      return 
    }
    let values = this.form.value
    
    for(let i=0;i<values.lessons.length;i++){
      

      const formData = new FormData(); 
    
       var exp_data
      let datenew = this.datepipe.transform(values.lessons[i].target,'dd-MM-YYYY')
      if(datenew == null){
       exp_data = ""
      } else {
      exp_data = datenew
      }
      const tag_sel = values.lessons[i].tags
    
      if(tag_sel.includes("Legal")||tag_sel.includes("Finance")){
        tag_sel.push("LegalFinance")
  
      }
 
        formData.append("name",values.lessons[i].name)
        formData.append("description",values.lessons[i].description)
        formData.append("expiration_date",exp_data)
        formData.append("filename",this.files[i].name)
        formData.append("content_type",this.files[i].type)
        formData.append("category", this.selected)
        formData.append("file", this.files[i])
        let array_tag  = [...new Set(tag_sel)];
        formData.append("tags",JSON.stringify(array_tag))
        this.auth.add_personal_doc(this.selected,formData).subscribe((res:any)=>{
          
          if(res.error == false){
            //this.toast.success(res.msg)
            
            this.succes_msg.push(res.msg)
            this.deleteFile(i)
             
          } else {
            this.error_msg.push(res.msg)
            //this.toast.error(res.msg)
            
          }
      
          this.counter++
         
          if(this.counter>=this.files.length){
            if(this.error_msg.length>=1){
              this.stop_loading()
              this.toast.error(this.error_msg[0])
              this.counter=0
              this.error_msg =[]
              this.succes_msg =[]

            } else {
              
              this.toast.success(this.succes_msg[0])
              this.stop_loading()
              this.close_dialog()
            }
          }
          
        })
        
        
     
    }
  

  
  }
   start_loading(){
    this.blockUI.start()
   }
   stop_loading(){
    this.blockUI.stop()
   }
   selectedn(index , country){
    this.index_country = index
    this.selected = index
    this.selected_country = country
    
   }


 

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  next_fn(){
    if(this.files.length<1){
      this.toast.error("Browse or drop atleast one file")
      return
    }
    for(let item of this.files){
      if(this.allowed_exe.includes(item.type)==false){
        this.allowed_exe_status = true
        return

      }
    }
    this.pg_no++
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(event) {
    this.prepareFilesList(event.target.files);
  }
  close_dialog(){
    this.dialogRef.close()
    
  }
  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.lessons.removeAt(index);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 800);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
      this.addLesson()
    }
    this.uploadFilesSimulator(0);
    

  }

 
  formatBytes(bytes) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(bytes, 10) || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }


}

<div class="care-plan">
    <div class="top-header">
        <p class="heading">Today's Events</p>
    <div class="slide">
        Actionable Events&nbsp;<mat-slide-toggle (change)="onChange($event)" [formControl]="toogle"></mat-slide-toggle>
    </div>

    </div>
    <div class="mid" *ngIf="data?.length==0 && toggle_status==true">
        <p>No open Activities which requires your attention</p>

    </div>
    
    
    
 
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let value of data ">
            <mat-card>
                <div class="card-cnt">
                    <div *ngIf= "(value.status !='COMPLETED') && value.canChangeStatus== true" class="status-icon">
                        <!-- <img src="{{card_image}}" alt=""> -->
                        <mat-icon style="color:red">error_outline</mat-icon>
                    </div>
                    <div class="status-icon" *ngIf="value.canChangeStatus == false || value.status == 'COMPLETED'">
                        <mat-icon style="color:green">check_circle_outline</mat-icon>
                    </div>
                    <!-- <img *ngIf="( imgurl)" src={{imgurl}} alt=""> -->
                   
                    
                    <div class="text-in">
                        <p>{{value?.description}}</p>
                        <div class="log-txt">
                            <p>{{value.activityDateTime}}</p>
                            <p *ngIf="value?.completedDateTime" >Logged On : {{value?.completedDateTime}}</p>

                        </div>
                        
                    </div>
                    
                </div>
                <div *ngIf= "(value.status !='COMPLETED') && value.canChangeStatus== true" class="update-buttons">
                                                        
                                                        
                    <div class="date-time">
                       <div class="input-group">
                       
                           <!-- <mat-form-field class="example-full-width" appearance="fill"> -->
                           <!-- <mat-label>Manual Entry</mat-label> -->

                           <input readonly #datetime [min]="maxDate" [owlDateTime]="dt2" placeholder="Manual Entry" [owlDateTimeTrigger]="dt2"/>
                           
                           <owl-date-time #dt2></owl-date-time> 
                           <!-- </mat-form-field> -->
                        </div>
                       
                    </div>
                   
                    
                   <button  mat-mini-fab (click)='OnClickDone(value.id, datetime.value )' class="tick-mark"><mat-icon>done</mat-icon></button>
               </div>
            
        </mat-card>
     </div>
        
    </div>
</div>
<!-- <div class="mid"  *ngIf="data?.length==0" >
    <p>No Activites Pending</p>

</div> -->



import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent implements OnInit {

  searchbar = 'searchbar';
  display_value: any;
  accept_text = "Accept";
  accept_class = "accept-button";

  removable = true;
  chips: string[] = [];
  searchText: any;
  searchTextSecond: any;
  bottomSheetRef: any;
  relationship_list_parent: any;
  relationship_list_data: any;
  care_team_list: any;
  service_provider_name: any;

  displayedColumns: string[] = ['business_name', 'accept_text'];
  dataSource : any;
  dataSource_care_team: any;
  service_provider_lists:any[]=[];
  chevron_left = "fa fa-chevron-left";
  lists:any;
  

  constructor(public auth: AuthServiceService , public toastr:ToastrService , private _commConnection: CommunicationService) { }
  loadData(){
    if(this.service_provider_lists){
      this.lists = this.service_provider_lists.filter((element)=>{
        return (element.canAccept && !element.isaccepted)
      })

    }
  }

  ngOnInit(): void {
   
    this.auth.service_provider_list().subscribe((res: any) => {
     
     this.service_provider_lists = res.data?.relationships;
   
     this.loadData();
    
      
    })
    // this._commConnection.changeEmitted$.subscribe(data =>{
    //   if(data){
    //     this.lists = data.filter((element)=>{
    //       return (element.canAccept && !element.isaccepted)
    //     })
    //   }
    //   this.loadData()
    // })
    
  }

  

  acceptRequest(id , biztype){
    if(biztype=="consumer"){
      this.auth.care_team_accept_request(id).subscribe((res:any)=>{
        
        if(res.error && typeof(res.msg) == "string"){
          this.toastr.info(res.msg);
  
        } else if(!res.error) {
          this.toastr.success(res.msg);
          
          this.auth.service_provider_list().subscribe((res:any)=>{
            this.lists =  res.data.relationships.filter((element)=>{
              return (element.canAccept && !element.isaccepted)
            })
          })
   
        }
        else if(res.msg.description) {
          this.toastr.info(res.msg.description);
        }
         
      })

    } else if(biztype=="prof"){
      this.auth.service_provider_accept(id).subscribe((res:any)=>{
      
        if(res.error && typeof(res.msg) == "string"){
          this.toastr.info(res.msg);
  
        } else if(!res.error) {
          this.toastr.success(res.msg);
          this.auth.service_provider_list().subscribe((res:any)=>{
            this.lists =  res.data.relationships.filter((element)=>{
              return (element.canAccept && !element.isaccepted)
            })
          })
          
          
   
        }
        else if(res.msg.description) {
          this.toastr.info(res.msg.description);
        }
         
      })

    }
   
    

  }
}

<mat-card class="clinical-data-card">
    <div class="plus-icon">
        <i class="fas fa-plus"></i>
    </div>
    <div class="cntr container">
        <div class="clinical-data">
                <p class="heading">Clinical Data</p>
                <p class="update-time">Last Update: 3 Weeks ago on 08/10/2020</p>
        </div>
        <div>
            <!-- <table>
                <tr>
                    <th *ngFor="let item of clinical_data_details">{{item.title}}</th>
                </tr>
                <div *ngFor="let item of clinical_data_details; index as i">
                    <tr  *ngFor="let eachItem of item.value; index as j">
                        <td>{{eachItem}}</td>
                    </tr>
                </div>
                
            </table> -->

            <div class="cd-header">
                <div *ngFor="let item of clinical_data_details">
                    <ul >
                        <li class="header-li" >
                            {{item.title}}
                            <div class="cd-table-content">
                                <!-- <div *ngFor="let item of clinical_data_details; index as i"> -->
                                    <ul>
                                        <li *ngFor="let eachitem of item.value;">
                                            {{eachitem}}
                                        </li>
                                    </ul>
                                <!-- </div> -->
                            </div>
                        </li>
                    </ul >
                </div>
            </div>
            
            
            
            
        </div>
    </div>
</mat-card>
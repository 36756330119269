import { Component, OnInit, Output, VERSION } from '@angular/core';
//import { AjaxService } from '../service/ajax.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  currentApplicationVersion = environment.appVersion;
  @BlockUI()
  blockUI!: NgBlockUI;
  name = new FormControl('');
  submitted = false;
  password_status = "fa fa-eye-slash";
  hide = true;
  login_details: any;
  version = VERSION.full;
  message: string = '';
  subscription: any;

  get_login_json_data: any;

  constructor(private toastr: ToastrService, private data: DataService , private formBuilder: FormBuilder, private router: Router, private authService: AuthServiceService) { }
  myForm = this.formBuilder.group({
		email: ['', [Validators.required, Validators.email]],
		password: ['', Validators.required]
	})

  ngOnInit(): void {
    // debugger;
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('PK');
    
    this.subscription = this.data.currentMessage.subscribe(message => {
      this.message = message;
    });
    
  }

  password_icon(){
    this.password_status = "fa fa-eye";
    this.hide = !this.hide;
    
    if(this.hide){
      this.password_status = "fa fa-eye-slash"
    } else {
      this.password_status = "fa fa-eye"
      this.hide = false;
    }
  }

  get f() { return this.myForm.controls; }
  
  get_details(){
    if(this.get_login_json_data){
      return this.get_login_json_data;
    }
  }
  go_to_register(){
    this.router.navigate(['/register']);
  }
  show_spinner(){
    this.blockUI.start()
  
  }
  stop_spinner(){
    this.blockUI.stop()
  }

  onLoginButtonClicked(email: string, password: string) {
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    
    this.authService.login(email, password, 'email', 'login').subscribe((res: HttpResponse<any>) => {
      this.get_login_json_data = res.body;
        if(!res.body.error && res.body.data.email_verified) {
          this.show_spinner()
          
          ////console.log("dashboard");
          
          this.authService.create_user_vitacape_consumer(this.get_login_json_data.data.pk + "@digicoffer.com", this.get_login_json_data.token.slice(0,20), this.get_login_json_data.data.first_name, this.get_login_json_data.data.pk).subscribe(resp => {
           
            this.router.navigate(['/home'])
            

          })
          this.stop_spinner()
          
          
          
          
         

        } else if (!res.body.error && !res.body.data.email_verified){
          
          this.data.changeMessage(email);
          
          this.authService.resend_token(this.message).subscribe((respons: any) => {
            // //console.log(respons);
          })
          this.router.navigate(['/mail-verification']);
          // //console.log("here is the mail from login - ", this.message);
        }  
        else {
          if(res.body.msg.email)
          {
            this.toastr.error(res.body.msg.email, 'Error!');
          } else {
            this.toastr.error(res.body.msg, 'Error!');
          }
          
        }
        // //console.log("json data - ",this.get_login_json_data);


        // create user for vitacape consumer
        

        ////console.log(this.get_login_json_data.data.pk + this.get_login_json_data.data.email.slice(this.get_login_json_data.data.email.search("@")), this.get_login_json_data.token.slice(0,20), this.get_login_json_data.data.first_name, this.get_login_json_data.data.pk);
    })
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  forgot_password(){
    this.router.navigate(['/forgot-password']);
  }
  

}

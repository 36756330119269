
<div class="">
    <app-menu-comp></app-menu-comp>
    <div class="card-css main_container ">
        <p>Plan Information</p>
        <form class="example-form" [formGroup]="plan_info_form" >
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Medical Condition</mat-label>
                <input type="text" matInput #medCon placeholder="Medical Condition" formControlName="medicalCondition" [matAutocomplete]="auto" [ngModel]="detail_card?.medCondition"/>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option>Select Condition</mat-option>
                    <mat-option *ngFor="let item of medcon_list" value= "{{item.title}}">
                        {{item.title}}
                    </mat-option>
                </mat-autocomplete>
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Title</mat-label>
                    <input matInput #titleInput formControlName='title' name="title" placeholder="Title" [ngModel]="detail_card?.title"/>
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label> Description</mat-label>
                    <input matInput #descriptionInput formControlName='description' name="description" placeholder="description" [ngModel]="detail_card?.description"/>
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" [ngModel]="detail_card?.category.name" #category>
                        <mat-option>Select Condition</mat-option>
                        <mat-option *ngFor="let item of category_list" value= "{{item.id}}">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
    
            </div>

            <div style="display: flex;">
                <mat-form-field class=" num-sel example-full-width" appearance="fill">
                    <mat-label>Select Duration</mat-label>
                    <input matInput type="number" #duration formControlName='duration' name="num" placeholder="Number" [ngModel]="detail_card?.duration" />
                </mat-form-field>
                <mat-form-field class="sel" appearance="fill">
                    <mat-label>Select Type</mat-label>
                    <mat-select formControlName="durationType" #durationType [ngModel]="detail_card?.durationType">
                        <mat-option>Select Condition</mat-option>
                        <mat-option value="DAY">Day</mat-option>
                        <mat-option value="YEAR">Year</mat-option>
                        <mat-option value="MONTH">Month</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Desired Outcome</mat-label>
                    <input matInput #desOut formControlName='desiredOutcome' name="desiredOutcome" placeholder="Desired Outcome" [ngModel]="detail_card?.goal" />
                </mat-form-field>
            </div>

            
        
            
                <div>
                    
                    <div class="top-space ent">
                        <mat-card>Desired Outcome<button (click)="openDialog('desiredOutcome','create')">+</button></mat-card>
                    </div>
                    <div class="med-condition">
                        <mat-card *ngFor="let item of desgoal let i = index">{{item.goalType}}
                            <div class="right-icon">
                                <button (click)="openDialogEdit('desiredOutcome',i,'edit')" >
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </button>
                            </div>
                        </mat-card>
                    </div>
            
                    <div class="ent">
                        <mat-card>Activities<button (click)="openDialog('activities','create')">+</button></mat-card>
                    </div>
                    <div class="med-condition">
                        <mat-card *ngFor="let item of activity let i = index">{{item.title}}
                            <div class="right-icon"> 
                            <button (click)="openDialogEdit('activities',i,'edit')">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                            </div>
                        </mat-card>
                    </div>

                    <div class="ent">
                        <mat-card>Medications<button (click)="openDialog('medication','create')">+</button></mat-card>
                    </div>
                    <div class="med-condition">
                        <mat-card *ngFor="let item of medication let i = index">{{item.medicineName}}
                            <div class="right-icon">
                            <button (click)="openDialogEdit('medication',i,'edit')">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                            </div>
                        </mat-card>
                    </div>  
                </div>  
               
                <div class="delete">
                    <button (click)="clickMethod()" mat-raised-button>Cancel</button>
                </div>


                <div class="example-button-row">
                    
                    
                    <button class="cancel-btn"  mat-raised-button (click)="OnDelete()">Delete</button>
                    <button mat-raised-button (click)="onSaveClicked(medCon.value, titleInput.value,descriptionInput.value, desOut.value , category.value, duration.value, durationType.value)" (click)="toggleDisplay()">Save</button>
                </div>
        </form>
    </div>
</div>

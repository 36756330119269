import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { GraphOpenDialog } from '../cards/cards.component';
import { CommunicationService } from '../communication.service';
import { Utils } from '../utils';



@Component({
  selector: 'app-three-data-card',
  templateUrl: './three-data-card.component.html',
  styleUrls: ['./three-data-card.component.scss']
})
export class ThreeDataCardComponent implements OnInit {  

  @Input() card_details: any;
  @Input() device_list: any;
  @Input() device_names: any;
  card_image: any;
  medium_value1: any;
  high_value1: any;
  medium_value2: any;
  high_value2: any;
  medium_value3: any;
  high_value3: any;
  vpulse:any[]=[];
  vsys:any[]=[];
  vdia:any[]=[];
  xais:any[]=[];

  constructor(public dialog: MatDialog, private authservice: AuthServiceService) { }

  openDialog() {
    this.dialog.open(DialogOpen, {
      data : [this.card_details, this.device_list]
    });
  }

  openGraph(){
    this.dialog.open(GraphOpenDialog,{
      data:[this.card_details , this.vpulse,this.vsys,this.vdia,this.xais]
    })
  }

  ngOnInit(): void {
    this.authservice.get_chartreading(this.card_details.device.systemId).subscribe((res:any)=>{
      this.vpulse = res.map(res=>res.value1)
      this.vsys = res.map(res=>res.value2)
      this.vdia = res.map(res=>res.value3)
      this.xais = res.map(res=>res.readingDateTime)

    })
    ////console.log(this.card_details)
    this.device_names.forEach(element => {
      if(element.systemId == this.card_details.device.systemId){
        this.card_image = element.image;
      }
    });

    this.authservice.healthy_range().subscribe((res: any) => {
      res.forEach(element => {
        if(element.systemId == this.card_details.device.systemId){
          this.medium_value1 = element.dataWatch.data1.medium;
          this.high_value1 = element.dataWatch.data1.high;
          this.medium_value2 = element.dataWatch.data2.medium;
          this.high_value2 = element.dataWatch.data2.high;
          this.medium_value3 = element.dataWatch.data3.medium;
          this.high_value3 = element.dataWatch.data3.high;
        }
      });
    })
  }
}

@Component({
  selector: 'dialog-open',
  templateUrl: 'dialog-open.html',
  styleUrls: ['./three-data-card.component.scss'],
  providers: [DatePipe]
})
export class DialogOpen {
  current_card: any;
  tag_list: any;
  tag_id = 0;
  device_id: any;
  
  date1 = new FormControl(new Date());
  maxDate = new Date();
  ampm_list = ["AM", "PM"];
  hour_value = Utils._12_hour_format();
  minute_value = new Date().getMinutes().toString();
  am_pm =  new Date().getHours()>12? "PM": "AM";
  
  counter = Array;
  submitted = false;
  last_updated: any;

  selected_device: object[] = [];
  device_list_from_parent: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DialogOpen>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, private _communicationService: CommunicationService , public datePipe : DatePipe) {
    this.current_card = data[0];
    this.device_list_from_parent = data[1]
  }

  myForm = this.formBuilder.group({
    firstReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
    secondReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
    thirdReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
    buttonTag: [ Validators.required],
    dateSelected : [ Validators.required]
  });

  ngOnInit(){
    this.authservice.get_tag_list(this.current_card.device.category.id).subscribe((res: any) => {
      //console.log(res)
      this.tag_list = res;
      this.tag_id = res[0]?.id;
    })
    //console.log(this,this.current_card)
    this.selected_device = this.device_list_from_parent.filter(element => {
      if(element.systemId == this.current_card.device.systemId){
        this.device_id = element.id;
        return true
      } else {
        return false
      }
    });

    this.authservice.data_last_reading().subscribe((res: any) => {
      res.forEach(element => {
        if(element.device__systemId == this.current_card.device.systemId){
          if(element.daySince > 0){
            this.last_updated = `${element.daySince} days ago`
          } else if(element.daySince == 0) {
              this.last_updated = `Today`
          }
        }
      });
    })
  }

  get f() { return this.myForm.controls; }

  tag_btn(tag_id: number){
    this.tag_id = tag_id
    //console.log(tag_id)
  }

  save_data(value1: string, value2: string, value3: string, date: string, hour: string, minute: string, am_pm: string, valueUnit1: string, valueUnit2: string, valueUnit3: string){
    let device = this.device_id;
    let tagId = this.tag_id
    let sequence = 0;
    this.submitted = true;
    
    //console.log(typeof(value1))
    if(minute.length == 1){
      minute = `0${minute}`
    }

    var readingDateTime;
     
    if(date == ""){
      date = Utils.formatDate();
      readingDateTime = `${date}`;
    } else {
      let DateTime = `${date}, ${hour}:${minute} ${am_pm}`
      readingDateTime = this.datePipe.transform(DateTime , "MM-dd-yyyy hh:mm a")
    
    }

    //console.log(device, sequence, readingDateTime, Number(value1), Number(value2), Number(value3), valueUnit1, valueUnit2, valueUnit3, tagId)
    
      if(value1 && value2 && value3 && value1 >= '10' && value2 >= '10' && value3 >= '10'){
        this.authservice.add_device_reading(device, sequence, readingDateTime, Number(value1), Number(value2), Number(value3), valueUnit1, valueUnit2, valueUnit3, tagId).subscribe((res: any) => {
          //console.log(res);
          this.toastr.success('Reading created successfully.')
          this.authservice.lastest_device_reading().subscribe((res: any) => {
            //console.log(res)
            this._communicationService.emitChange(res);
          })
          this.dialog.close()
        })
      } else {
        // this.toastr.error('Please fill the required field.')
      }
  }

  

  close_dialog(){
    //console.log(this.current_card)
    this.dialog.close();
    
  }
}

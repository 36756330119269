
<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">Notifications</p>
    <!-- <div class="hi-right header-icons">
        <button (click)="openDialog()">
            <i class="fas fa-plus"></i>
        </button>
    </div> -->
    <div class="searchbar-e">
        <input type="text" placeholder="Search" [(ngModel)] = searchText #filterValue>
        <i class="fas fa-search"></i>
    </div>
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of notifications" [hidden]="getFilterValue(item).indexOf(filterValue.value.toLowerCase()) == -1">
            <mat-card>
                
                <div class="card-cnt">
                    <div *ngIf= "item.status=='unread'" class="status-icon">
                        <!-- <img src="{{card_image}}" alt=""> -->
                        <!-- <i class="fa fa-exclamation-circle" style="color:red"></i> -->
                        <mat-icon style="color:red">error_outline</mat-icon>
                    </div>
                    <div class="status-icon" *ngIf="item.status=='read'">
                        <mat-icon style="color:green">check_circle_outline</mat-icon>
                    </div>
                    <h4>{{item.message}}</h4>
                </div>
                <div class="edit-btn">
                    <button  class="read-btn" *ngIf= "item.status=='unread'" title="read btn" (click)="read_notification(item.id)"><mat-icon>check</mat-icon></button>
                    <button title="delete btn" class="del" (click)="delete_notification(item.id)"><mat-icon>delete</mat-icon></button>

                </div>
                <!-- <mat-card-actions align="end" class="action-grp">
                    <button mat-button *ngIf= "item.status=='unread'" title="read btn" (click)="read_notification(item.id)"><mat-icon>check</mat-icon></button>
                    <button mat-button title="delete btn" (click)="delete_notification(item.id)"><mat-icon>delete</mat-icon></button>
              
                </mat-card-actions> -->
           

            </mat-card>
        </div>
        
    </div>
</div>







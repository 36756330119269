import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  name = new FormControl('');
  countryName = 'select an option';
  countries = [];
  submitted = false;
  password_status = "fa fa-eye-slash";
  hide = true;
  confirm_password_status = "fa fa-eye-slash";
  confirm_hide = true;

  message: string = '';
  subscription: any;

  

  constructor(private toastr: ToastrService, private data: DataService ,private http: HttpClient, private formBuilder: FormBuilder, private router: Router, private authService: AuthServiceService) { }

  myForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    country: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8)]],
    ConfirmPassword: ['', Validators.required]
  }, { validator: this.checkPasswords })
   

  ngOnInit(): void {

    localStorage.removeItem('TOKEN');
    localStorage.removeItem('PK');
    // //console.log(this.countryName);
    this.authService.getCountries().subscribe((resp: any) => {
      this.countries = resp.data.countries;
      this.countries.shift();
      // //console.log(this.countries);
    })

    this.subscription = this.data.currentMessage.subscribe(message => {
      
      this.message = message;
    });
    // //console.log("here is the msg on register", this.message);


  }  

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.ConfirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  get f() { return this.myForm.controls; }

  password_icon(){
    this.password_status = "fa fa-eye";
    this.hide = !this.hide;
    
    if(this.hide){
      this.password_status = "fa fa-eye-slash"
    } else {
      this.password_status = "fa fa-eye"
      this.hide = false;
    }
  }

  confirm_password_icon(){
    this.confirm_password_status = "fa fa-eye";
    this.confirm_hide = !this.confirm_hide;
    
    if(this.confirm_hide){
      this.confirm_password_status = "fa fa-eye-slash"
    } else {
      this.confirm_password_status = "fa fa-eye"
      this.confirm_hide = false;
    }
  }


  onSignUpButtonClicked(firstName: string, lastname: string, country: string, email: string, password: string, ConfirmPassword: string) {
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    let countryName = this.myForm.get('country')?.value
    this.data.changeMessage(email);
    ////console.log(this.message);
    //debugger;
    this.authService.signUp(firstName, lastname, countryName, email, password).subscribe((res: HttpResponse<any>) => {
      if(!res.body.error ) {
        this.router.navigate(['/mail-verification']);
        this.toastr.success(res.body.msg, "Success!");
      } else {
        if(res.body.msg.email){
          this.toastr.error(res.body.msg.email, "Error!");
        } else {
          this.toastr.error(res.body.msg, "Error!");
        }
        
      }
    });    
    
    // //console.log(firstName,lastname,countryName,email,password,ConfirmPassword)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

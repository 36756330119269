<div>
    <div class="section-1 row">
        <div class="left-column col-md-6">
            <div class="content">
                <img src="../../assets/images/vitagist (1).png" alt="">
                
            </div>
        </div>
        <div class="right-column col-md-6">
            <div class="content">
                <h2 class="label">Forgot Password</h2>
                
                <form [formGroup]="myForm" >
                    <!-- <div class="signTitle">Sign In</div> -->
                    <div class="signForm-row " id="email_input">
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                            <input #email formControlName='email' name="email" class="form-control" id='id_email' type="text" placeholder="Email" aria-describedby="basic-addon1"/>
                        </div>
                        
                        <div *ngIf="f['email'].errors && submitted" class="text-danger">
                          <div *ngIf="f['email'].errors.required">This field is required</div>
                          <div *ngIf="f['email'].errors.pattern">Invalid Email</div>
                          <div *ngIf="f['email'].errors.msg">{{f['email'].errors.msg}}</div>
                        </div>
                    </div><br>

                    <div class="signForm-row">
                        <button (click)="onSubmit(email.value)" class="submit-button">Submit</button>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</div>
import { DatePipe } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AuthServiceService } from '../auth-service.service';
import { AddDietReading, AddExerciseReading } from '../cards/cards.component';
import { CommunicationService } from '../communication.service';
import { ShareUpdateService } from '../share-update.service';
import { Utils } from '../utils';
declare let $: any


@Component({
  selector: 'app-patient-profile-page',
  templateUrl: './patient-profile-page.component.html',
  styleUrls: ['./patient-profile-page.component.scss']
})
export class PatientProfilePageComponent implements OnInit {
  records_len: any;
  height_len: any;
  wtunit: any;

  constructor(private authservice: AuthServiceService, private router: Router, public dialog: MatDialog, private _communicationService: CommunicationService, private get_status:ShareUpdateService) {
    
  }
  
  typeOfCard : any;

  patient_selected : any;
  chevron_left = "fa fa-chevron-left";
  call_message_buttons = "call_message_buttons";

  display_type: any;
  display_status = "display_status";
  patient_details_column = "patient-details-column";
  column_expansion = "col-md-3";
  third_column = "third-column";

  average_value: number[] = [];
  chartColors : any;
  extended_icon_status = 0;
  medkit_device: any;  

  device_reading: any;
  update_dr: any;
  service_provider_lists: any;
  relationship_list: any;
  device_list: any[]=[];
  isClassExpand = false;
  device_selected: any;
  vitacape_devices: any;
  dv: any;
  systolic = { data: [] }
  diastolic = { data: [] }
  pulse  = { data: [] }
  chartDatasets:any;
  care_plan :any;
  menu_list = [{name: 'Health Info', link: '/menu/health-info', icon: 'fas fa-laptop-medical'}, {name: 'Devices', link: '/menu/add-device', icon: 'fas fa-cubes'},{name: 'Care Plan', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'}]

   

  device_names = [
    {title: 'Blood Glucose',data_type: 'single', unit: {title: ['Blood Glucose'], unit: ['mg/dl']}, device_type: 'BGDEVICE', systemId: '0x1808' , image: ['fas fa-tint', 'color: #f75b5b'], category: 2, flag: false}, 
    {title: 'Pulse', data_type: 'single', unit: {title: ['Pulse'], unit: ['SpO2%']}, device_type: 'PULSEDEVICE', systemId: '0x1822',  image: ['fas fa-lungs', 'color: #4c7dfe'], category: 4, flag: false}, 
    {title: 'Blood Pressure', data_type: 'three', unit: {title: ['Systolic', 'Diastolic', 'Pulse'], unit: ['mm Hg','mm Hg', 'bpm']}, device_type: 'BPDEVICE', systemId: '0x1810',  image: ['fas fa-heartbeat', 'color: #ed3b3b'], category: 3, flag: false}, 
    {title:  'Weight', data_type: 'single', unit: {title: ['Weight'], unit: []}, device_type: 'WEIGHSCALEDEVICE', systemId: '0x181D', image: ['fas fa-weight', 'color: #52b8c4'], category: 6, flag: false}, 
    {title: 'Temperature', data_type: 'single', unit: {title: ['Temperature'], unit: ['Fahrenheit']}, device_type: 'THERMODEVICE', systemId: '0x1809', image: ['fa fa-thermometer-half', 'color: #f9f44f'], category: 5, flag: false},
   
  ]


  patient_details = [

    {name: 'Blaise Kub', age: '34', gender: 'F', recent_tasks: ['background-color: #db544b','background-color: #26802c', 'background-color: #26802c' , 'background-color: #db544b', 'background-color: #db544b'], photo: '../../assets/images/1.jpg', 
      patient_information: [{title: 'Adherence', value: '80%'},{title: 'Task Completed', value: '12/32'},{title: 'Alerts', value: '00'},{title: 'DOB', value: '10/03/1987'},{title: 'Phone', value: '1234567890'},{title: 'Height', value: '5\'3\"'},{title: 'Weight', value: '57Kg'}],

      all_card_data : [
        {title: ['Pulse','Systolic', 'Diastolic'], value: ['60','110','87'], unit: ['bpm', 'mmHg', 'mmHg'], icon: '../../assets/images/heartbeat-line.png', last_update: '5', style: 'background-color: #28293d'}, 
        
        {title: 'Blood Glucose', value: '96', unit: 'mg/dL', icon: '../../assets/images/card-1.png', last_update: '10', style: 'background-color: #58585e'}, 
        
        {title: ['Pulse','Systolic', 'Diastolic'], value: ['61','111','81'], unit: ['bpm', 'mmHg', 'mmHg'], icon: '../../assets/images/heartbeat-line.png', last_update: '15', style: 'background-color: #58585e'}, 
        
        {title: ['Pulse','Systolic', 'Diastolic'], value: ['60','110','80'], unit: ['bpm', 'mmHg', 'mmHg'], icon: '../../assets/images/heartbeat-line.png', last_update: '20', style: 'background-color: #28293d'}, 
        
        {title: 'Blood Oxygen', value: '99', unit: 'SpO2%', icon: '../../assets/images/card-2.png', last_update: '25', style: 'background-color: #58585e'}
      ],
      clinical_data_details : [
        {title: 'Conditions', value: ['Asthma','Diabetes (Type 2)','Diabetes (Type 2)']}, 
        {title: 'Allergies', value: ['Penicillins','Wasp Sting']}, 
        {title: 'Medication', value: ['Lantus Vial 10mL']}, 
        {title: 'Conditions', value: ['Asthma','Diabetes (Type 2)']}, 
        {title: 'Allergies', value: ['Penicillins','Wasp Sting']}, 
        {title: 'Medication', value: ['Lantus Vial 10mL']}
        // {title: 'Conditions', value: ['Asthma','Diabetes (Type 2)','Diabetes (Type 2)']}, 
        // {title: 'Allergies', value: ['Penicillins','Wasp Sting']}, 
        // {title: 'Medication', value: ['Lantus Vial 10mL']}, 
        // {title: 'Conditions', value: ['Asthma','Diabetes (Type 2)']}, 
        // {title: 'Allergies', value: ['Penicillins','Wasp Sting']}, 
        // {title: 'Medication', value: ['Lantus Vial 10mL']}
      ],
  
      care_program_details : [
        {title: 'Diabetic Follow-Up', right_column : [{title: 'Day', value: '366/548'}, {title: 'Compliant', value: '86%'},{title: 'Tasks Completed', value: '160' }]}, 
        {title: 'Medication Adherence',  right_column : [{title: 'Day', value: '75/365'}, {title: 'Compliant', value: '43%'},{title: 'Tasks Completed', value: '116'}]}
      ],
      activity_feed: [{time: '5:30 PM', title: 'Missed medicine metamorphin 500mcg', update_time: '5 minutes ago', button_text: 'Send a reminder'}, {time: '6:30 PM', title: 'Hurray! today\'s goal completed', update_time: '6 hours ago', button_text: 'Greet for this achievement'}, {time: '7:30 PM', title: 'Diet meal taken Vegetable salad', update_time: '7 hours ago', button_text: 'Comment'}, {time: '5:30 PM', title: 'Missed medicine metamorphin 500mcg', update_time: '5 minutes ago', button_text: 'Send a reminder'}, {time: '6:30 PM', title: 'Hurray! today\'s goal completed', update_time: '6 hours ago', button_text: 'Greet for this achievement'}, {time: '7:30 PM', title: 'Diet meal taken Vegetable salad', update_time: '7 hours ago', button_text: 'Comment'}],

      care_plan: [{title: 'Self Health Questionnaire', value: '86%', due_date: '12/12/2020'}, {title: 'Task 2', value: '65%', due_date: '02/09/2020'}, {title: 'Task 3', value: '32%', due_date: '30/05/2020'}, {title: 'Task 4', value: '90%', due_date: '04/11/2019'}],
      
      chartDatasets: [
        { data: [66, 70, 75, 68, 66, 80, 70], label: 'Pulse' },//value1
        { data: [117,110, 100, 120, 130, 120, 100], label: 'Systolic' },//value2
        { data: [96, 100, 104, 100, 97, 97, 90], label: 'Diastolic' }//value3
      ],
      chartColors: [
        {
          backgroundColor: ' rgba(0, 132, 121, 0.2)',
          borderColor: 'rgba(5, 252, 231, 0.7)',
          borderWidth: 2,
        },{
          backgroundColor: 'rgba(124, 1, 98, 0.2)',
          borderColor: 'rgba(213, 0, 241, 0.7)',
          borderWidth: 2,
        },{
          backgroundColor: 'rgb(252, 144, 3, 0.2)',
          borderColor: 'rgba(247, 188, 111, 0.7)',
          borderWidth: 2,
        }
      ]
    }
  ]

  //patient_index_value : any;
  single_detail  = this.patient_details[0];

  indexValue(a) {
    this.single_detail = this.patient_details[a];
    ////console.log('clicked', a);
    ////console.log(this.single_detail);
    this.calculateAverage();
    ////console.log(this.average_value);
    this.chartColors = this.single_detail.chartColors;
    // return a;
  }

  openDialog(systemId , data_type, title){
    //console.log(data_type,title)
    
    if(this.device_list.length == 0){
      this.router.navigate(['/menu/add-device'])
    } else {
      

      this.device_list.forEach(item => {
        if(item.systemId == systemId){
          this.device_selected = item;
        }
      })
      this.device_names.forEach(item => {
        if(item.systemId == systemId){
          this.dv = item;
          //console.log(item.systemId, systemId)
        }
      })
      if(data_type=='DEVICE'){
        this.dialog.open(AddDataReading, {
          data: [this.dv, this.device_selected,this.device_names, this.isClassExpand]
          })

      } else {
        //console.log("care-plan")
        if(this.device_selected.title=="Exercise"){
          this.dialog.open(AddExerciseReading,{
            maxHeight:'500px',
            maxWidth:'480px',
            data:[this.device_selected, this.device_names]
          })

        } else if(this.device_selected.title=="Diet"){
          this.dialog.open(AddDietReading,{
            maxHeight:'500px',
            data:[this.device_selected, this.device_names]
          })
        }
        
      }
      
      
    }
    //console.log('category selected ==> ', this.device_selected, this.dv)
  }
  open_bottom_dialog($event){
    if(this.device_list.length == 0 || !this.device_list){
      this.router.navigate(['/menu/add-device'])
    }
    //console.log(this.device_list, 'dialog')
    // this.dialog.open(BottomDialog);
    $event.stopPropagation();
    this.isClassExpand = !this.isClassExpand;
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isClassExpand= false;
  }
  

  display_toggle(a){
    //debugger;
    this.display_type = a;
    if(this.extended_icon_status == 0) {

      $("."+ this.patient_details_column).css({"display":"none"});
      this.column_expansion = "col-md-8";
      $(".patients-list .detail").css( {"margin": "0"}).css({"padding-left": "2%"});
      this.chevron_left = "fa fa-chevron-right";
      
      $("."+this.display_status).css({"display": "block"});
      $("."+this.call_message_buttons).css({"display": "flex"});
      

      this.extended_icon_status = 1

    } else {

      this.chevron_left = "fa fa-chevron-left";
      $("."+ this.patient_details_column).css({"display":"block"});
      this.column_expansion = "col-md-3";
      $(".patients-list .detail").css({"margin": "auto"});
      $("."+this.display_status).css({"display": "none"});
      $("."+this.call_message_buttons).css({"display": "none"});

      this.extended_icon_status = 0;
    }
    
    
  }
  @HostListener('document:click', ['$event'])

  clickout() {
   this.isClassExpand = false;
  }
 

  loadDashboard() {
    //debugger;
    this.authservice.get('/dashboard').subscribe(
      (resp) => {
        if (!resp['error']) {
          //console.log('error is false')
        } else {
          //console.log('error is true');
        }
        // this.authservice.getCount()
      }
    )
  }
 
  calculateAverage(){
    this.average_value = [];
    var idx = 0;
    this.single_detail.chartDatasets.map((each_data: any) => {
      
      //if (each_data.data != undefined){
        idx = each_data.data.length;
        var each_avg = Math.floor(each_data.data.reduce((a: number, b: number) => a + b, 0 )/idx);
        this.average_value.push(each_avg);
      //}
      ////console.log(this.average_value);
      //return average;
    });
  }

  



  

  ngOnInit(): void {

    this.authservice.profile_details().subscribe((res:any)=>{
      if(res.length==0){
        this.router.navigate(['/menu/health-info'])
      }
      

    
      if(res==[]){

        
        this.authservice.get_citizenship().subscribe((res:any)=>{
          if(res.error==false){
            for (let i = 0; i < res.data.length; i++) {
    
              if(res.data[i].index == "citizen_primary" && (res.data[i].country =="USA"|| res.data[i].country == "united states of america" )){
                this.device_names.find(item=>item.title=="Weight")
    
    
              } else {
                this.wtunit = "kg"
              }
            } 
            
          }
        })

      } else {
        this.height_len = res[0]?.weigthtUnit
        this.wtunit = res[0]?.weigthtUnit
      }
  
      
    })
    

    this.authservice.device_list().subscribe((res: any) => {
      this.device_list = res;
      //console.log("list ===> ", this.device_list)
      this.device_list.forEach(element => {
        this.device_names.forEach(item => {
          if(item.systemId == element.systemId){
            element['image'] = item.image;
            // //console.log(element['image'])
          }
        });
      });
      this.authservice.get_care_plan().subscribe((res:any)=>{
        this.care_plan = res[0]
        //console.log(this.care_plan)
        if(this.care_plan?.parentCategory.length >= 1){
  
         this.device_list.push({title:'Diet',image:['fa fa-leaf','color: rgb(91, 236, 38)'] , data_type:'diet_field',category:{name:'Diet',type:'CAREPLAN'},systemId:'Diet'})
          this.device_list.push({title: 'Exercise' , image:['fas fa-walking','color: rgb(91, 236, 38)'], data_type:'exe_field' , category:{name:'Exercise',type:'CAREPLAN',systemId:'Exercise'}})
          //console.log(this.device_list)
          
        }
      })

    })
    
   
    //console.log(this.wtunit)


    this.authservice.get_vitacape_devices().subscribe((res) => {
      this.vitacape_devices = res;
      //console.log(this.vitacape_devices)
    })

  

    // this.medkit_device =  [{ title: 'glucometer', systemId:  "0x1808", unit: 'mg/dl'},{title:  'bpmeter', systemId:  "0x1810", unit: 'mm Hg'},{title:  'oximeter' , systemId:  "0x1822", unit: 'SpO2 (%)'},{title:  'weighmeter' , systemId:  "0x181D", unit: 'kg'},{title:  'thermometer', systemId:  "0x1809", unit: ' Celsius / Fahrenheit'}]

    

    this.authservice.service_provider_list().subscribe((res: any) => {
      this.service_provider_lists = res.data?.relationships;
      //console.log(res);
    })
    // , catchError(err => {
    //   if(err.status == 401 || err.status == 403){
    //     //console.log("error => ",err.status)
    //     this.router.navigate(['/login']);
    //   }
    //   return "error"
    // })

    this.authservice.device_reading().subscribe((res: any) => {
      this.device_reading = res;
      
    
      this.device_reading.forEach(element => {
        if(element.device?.systemId === '0x1810'){
          // this.pulse.data.push(element.value1)
          // this.systolic.data.push(element.value2)
          // this.diastolic.data.push(element.value3)
        }
        
      });
      ////console.log(this.pulse , this.systolic , this.diastolic)

      
    })


    this.authservice.lastest_device_reading().subscribe((res:any)=>{
      this.update_dr = res;
      //console.log(this.update_dr);
      this.records_len = this.update_dr.length;

    })

    this.authservice.relationship_list().subscribe((res: any) => {
      this.relationship_list = res;
      //console.log(res)
    })
    // , catchError(err => {
    //   if(err.status == 401 || err.status == 403){
    //     //console.log("error => ",err.status)
    //     this.router.navigate(['/login']);
    //   }
    //   return "error"
    // })


  
    //console.log(this.device_list)

 

    this._communicationService.changeEmitted$.subscribe(data => {
      if(data){
        this.update_dr = data;
      } else {
        this.update_dr = this.device_reading;
      }
      
      //console.log("data ====> ",data)
    })
    this.get_status.status.subscribe((res:any)=>{
      if(res=="true"){
        this.authservice.lastest_device_reading().subscribe((res: any) => {
        if(res){
          this.update_dr = res;
        } else {
          this.update_dr = this.device_reading;
        }
          
        })
      }
    })


    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    


    for(let i =0; i<this.single_detail.all_card_data.length;i++){
      this.typeOfCard = typeof(this.single_detail.all_card_data[i].value)
      ////console.log(this.typeOfCard);
    }

    
    
    
    //this.loadDashboard();
    this.calculateAverage();
    
    
    var x = window.matchMedia("(min-width: 320px)")
    var y = window.matchMedia("(max-width: 1023px)")
    
    
    if(window.matchMedia("(min-width: 1024px)").matches ){
      // $(".sidebar").css({"height": "100%", "background-color": "#000000", "border-bottom-right-radius": "0px"})
      // $(".main_container").css({"padding": "25px 0px 25px 100px"})
      // $(".patient-list-column").css({"margin-left": "22%"})
    } else if(window.matchMedia("(min-width: 320px)").matches && window.matchMedia("(max-width: 1023px)").matches){
      // $(".sidebar").css({"height": "auto","background-color": "#00000087", "border-bottom-right-radius": "10px"})
      // $(".main_container").css({"padding": "82px 0px 25px 15px"})
      // $(".patient-list-column").css({"margin-left": "9%"})
    }
    
    
  }


}

@Component({
  selector: 'add-data-reading',
  templateUrl: './add-data-reading.html',
  styleUrls: ['./patient-profile-page.component.scss'],
  providers: [DatePipe]
})

export class AddDataReading  {
  @BlockUI()
  blockUI!: NgBlockUI; 
  current_card: any;
  tag_list: any;
  tmpindex: number = 0;
  
  tag_id = 0;
  device_names: any;
  system_id_list: any;
  device_id: any;
  device_option: any;
  submitted = false;
  device_reading: any;
  maxDate = new Date();
  date1 = new FormControl(new Date());
  // dateSelected = new FormControl(new Date());
  ampm_list = ["AM", "PM"];
  hour_value = Utils._12_hour_format();
  minute_value = new Date().getMinutes().toString();
  am_pm =  new Date().getHours()>12? "PM": "AM";

  last_updated: any;
  dv: any;
  dialog_status: any;
  
  counter = Array;
  dis_btn = false;
  
  // device_selected : any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, public dialog: MatDialogRef<AddDataReading>, private _communicationService: CommunicationService , public datePipe :DatePipe , private share:ShareUpdateService){
    // this.device_id = data[0];
    dialog.disableClose = true
    this.device_option = data[0];
    this.device_names = data[1];
    this.dv = data[2];
    this.dialog_status = data[3];
  }

  myForm = this.formBuilder.group({
    firstReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
    secondReading: ['', [ Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
    thirdReading: ['', [Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]]
  });

  


  ngOnInit(){
    //console.log(this.device_option)
    if(this.device_option && this.device_option.data_type == 'three'){
      this.myForm.controls['secondReading'].setValidators([Validators.required,Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)])
      this.myForm.controls['thirdReading'].setValidators([Validators.required,Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)])
      this.myForm.controls['secondReading'].updateValueAndValidity();
      this.myForm.controls['thirdReading'].updateValueAndValidity();
    


    }
    this.authservice.data_last_reading().subscribe((res: any) => {
      res.forEach(element => {
        //console.log(res)
        if(element.device__systemId == this.device_names.systemId){
          //console.log(element.device__systemId, this.dv.systemId)
          if(element.daySince > 0){
            this.last_updated = `Last Reading: ${element.daySince} days ago`
          } else if(element.daySince == 0) {
              this.last_updated = `Last Reading: Today`
          }
        } 
      });
    })
    if(this.device_option.systemId=="0x181D"){
      this.authservice.profile_details().subscribe((res1:any)=>{
        //console.log(res1)
        
        if(res1.length!= 0){
          this.device_option.unit.unit[0] = res1[0]?.weigthtUnit
       
          
        } else {
             this.authservice.get_citizenship().subscribe((res:any)=>{
            if(res.error==false){
              for (let i = 0; i < res.data.length; i++) {

                if(res.data[i].index == "citizen_primary" &&  (res.data[i].country =="USA")){
                  this.device_option.unit.unit[0] = "lbs"
      
      
                } else {
                  this.device_option.unit.unit[0] = "kg"
                }
              }
            }
          })
        }
      })

    }

    this.authservice.get_tag_list(this.device_names.category.id).subscribe((res: any) => {
      if(!(res==[])){
        this.tag_list = res;
        this.tag_id = res[0]?.id;

      }
      
    })  


  
  }
 start_loading(){
  this.blockUI.start()
 }
 stop_loading(){
  this.blockUI.stop()
 }

  close_dialog(){
    this.dialog_status = !this.dialog_status;
    this.dialog.close();
  }

  get f() { return this.myForm.controls; }

  tag_btn(tag_id: number){
    this.tag_id = tag_id
    //console.log(tag_id)
  }
  
  

  save_data(value1: string, value2: string, value3: string, date: string, hour: string, minute: string, am_pm: string, valueUnit1: string, valueUnit2: string, valueUnit3: string){
    this.dis_btn = true
    this.submitted = true;
    this.start_loading()
    let category = this.device_names?.id; 
    let tagId = this.tag_id
    let sequence = 0;
    if(this.myForm.invalid){
   
      this.stop_loading()
      this.dis_btn = false
      return
    }
    
   
    
    
    if(!hour || !minute || !am_pm){
      this.stop_loading()
      this.toastr.error("Please enter a valid time")
    }
    if(minute.length == 1){
      minute = `0${minute}`
    }

    if(!value2 && !value3 && !valueUnit2 && !valueUnit3){
      value2 = '0';
      value3 = '0';
      valueUnit2 = "";
      valueUnit3 = "";
    }
    var readingDateTime;
    
    
    if(date == ""){
      date = Utils.formatDate();
      readingDateTime = `${date}`;
    } else {
      let DateTime = `${date}, ${hour}:${minute} ${am_pm}`
      readingDateTime = this.datePipe.transform(DateTime , "MM-dd-yyyy hh:mm a")
    }

    

    


    //console.log(this.device_option.systemId,category, value1, value2, value3, valueUnit1, valueUnit2, valueUnit3, date, hour,minute, am_pm)
    if(this.device_option.data_type == 'three'){
      if(value1 && value2 && value3){
        this.authservice.add_device_reading(category, sequence, readingDateTime, Number(value1), Number(value2), Number(value3), valueUnit1, valueUnit2, valueUnit3, tagId).subscribe((res: any) => {
          //console.log(res);
          this.toastr.success('Reading created successfully.')
          
           
            this.share.change_status("true")
             this.dialog.close()
             this.stop_loading()
          
        })
      } else {
        // this.toastr.error('Please fill the required field.');
        this.stop_loading()
      }
      
    } else if(this.device_option.data_type == 'single'){
      if(parseInt(value1,10) >= parseInt('10',10))
      {
        this.authservice.add_device_reading(category, sequence, readingDateTime, Number(value1), Number(value2), Number(value3), valueUnit1, valueUnit2, valueUnit3, tagId).subscribe((res: any) => {
          //console.log(res);
          this.toastr.success('Reading created successfully.')
          this.share.change_status("true")
        
          
          this.dialog.close()
          this.stop_loading()
        })
      } else {
        // this.toastr.error('Please fill the required field.');
        this.stop_loading()
      }
    } 
    
    
  }

  

}

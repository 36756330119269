<div class=" container">
    <div class=" card-dialog row justify-content-center">
      <div class="left col-4">
        <!-- <p class="card-selected">Glucose</p> -->
        <p [ngClass]="{'card-selected' : item == 'Exercise'}" *ngFor="let item of left_list">{{item}}</p>
      </div>
  
      <div class="right col-8">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
      
        <div>
          <form >
            <div *ngFor="let item of exe_list;index as i">
                <div>
                  <div class="first-row inp">
                      <div *ngIf="item.distance!=0" class="device-input">
                        <p>{{ item.title }}&nbsp;{{item.distance | number: '1.0-0'}}&nbsp;{{ item.distanceUnit}}</p>
                        <div class="signForm-row " id="first_reading">
                          <input pattern="^[0-9]*$"  type="number"  #first min="0" [formControl]='distanceControl' class="form-control" id='id_first_reading'  cdkFocusInitial (keydown)="onKeydown($event)" (change)="add_distance(first.value, item.title ,i)" maxlength="6"/>
                          <mat-error *ngIf="first.value.length >= 6">Enter a valid number</mat-error>
                          <div *ngIf="(first.value=='' )&& submitted" class="text-danger">
                            <div>value is required</div>

                            
                            
                          </div>
                          <!-- <mat-error *ngIf="distanceControl.hasError('pattern') || distanceControl.hasError('min') ">Please enter numeric value</mat-error>
                          <mat-error *ngIf="distanceControl.hasError('required') && submitted">This field is required</mat-error>
                          <mat-error *ngIf="distanceControl.hasError('maxLength') && submitted">Please enter a valid number</mat-error> -->
                          
                        </div>
                      </div>
                      
                      
                   </div>
                    <div *ngIf="item.distance==0" class="device-input">
                      <p>{{ item.title }}&nbsp;{{item.weight | number: '1.0-0'}}&nbsp;{{ item.weightUnit}}</p>
                      <div class="weight-group">
                        
                          <div class="weight-inp">
                              <p>{{item.weightUnit}}</p>
                              <input pattern="^[0-9]*$"  type="number" #wt  min="0"  maxlength="4" (keydown)="onKeydown($event)" [formControl]='weightcontrol'>
                              <mat-error *ngIf="wt.value.length >= 4">Enter a valid number</mat-error>
                              <div *ngIf="(wt.value=='' )&& submitted" class="text-danger">
                                <div>weight is required</div>
                              </div>
                          </div>
                          <div class="rep-inp">
                              <p>Reps</p>
                              <input  pattern="^[0-9]*$" type="number"min="0" #reps  maxlength="4" (keydown)="onKeydown($event)" [formControl]='repsctrl'>
                              <mat-error *ngIf="reps.value.length >= 4">Enter a valid number</mat-error>
                              <div *ngIf="(reps.value=='' )&& submitted" class="text-danger">
                                <div>Reps is required</div>
                              </div>
  
                          </div>
                          <div class="sets">
                              <p>Sets</p>
                              <input pattern="^[0-9]*$" type="number" min="0" #sets  maxlength="4"  (keydown)="onKeydown($event)" (change)="add_wt(wt.value,reps.value,sets.value , item.title,i)" [formControl]='sepsctrl'>
                              <mat-error *ngIf="sets.value.length >= 4">Enter a valid number</mat-error>
                              <div *ngIf="(sets.value=='' )&& submitted" class="text-danger">
                                <div>Sets is required</div>
                              </div>
  
                          </div>
  
                        
                      </div>
                    </div>
                 </div>
  
            </div>
            <div >
              <div class="date-time-select">
                <mat-form-field class="calendar" appearance="fill">
                  <mat-label>Choose a date</mat-label>
                  <!-- <input readonly [formControl] ="dateSelected" matInput [matDatepicker]="dp3" > -->
                  <input [max]="maxDate" required readonly #dateSelected [value]="date1.value"  matInput [matDatepicker]="dp3" >
                  <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                  <mat-datepicker #dp3 ></mat-datepicker>
                </mat-form-field>
              </div>
    
              <div class="time-select">
                <mat-form-field class="set-time" appearance="fill">
                  <mat-label>HH</mat-label>
                  <mat-select required [(value)]="hour_value">
                    <mat-option *ngFor="let item of counter(13);let i= index" value="{{i}}">{{i}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="set-time" appearance="fill">
                  <mat-label>MM</mat-label>
                  <mat-select required [(value)]="minute_value">
                    <mat-option *ngFor="let item of counter(60); index as i" value="{{i}}">{{i}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="set-time" appearance="fill">
                  <mat-label>AM/PM</mat-label>
                  <mat-select required [(value)]="am_pm">
                    
                    <mat-option *ngFor="let item of ampm_list" value="{{item}}">{{item}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            
        
         
             
    
            
           
           
          </form>

        </div>
        <div class="save-button">
          <button (click)="save_data(dateSelected.value,hour_value,minute_value,am_pm )">Save</button> 
        </div>
        
      </div>
      
    </div>   
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationService } from '../communication.service';
import moment from 'moment';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { SaveAlertService } from '../save-alert/save-alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { UaenamePipe } from '../uaename.pipe';

@Component({
  selector: 'app-health-info',
  templateUrl: './health-info.component.html',
  styleUrls: ['./health-info.component.scss'],
  providers: [ DatePipe ]
})
export class HealthInfoComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI; 
 
 today = new Date();
 month = this.today.getMonth();
 year = this.today.getFullYear();
  profile:any;
  maxDate = new Date();
  date1 :any;
  ethnicity_list: any;
  bloodgrp_list: any;
  gender_list = ["Female","Male","Others"];
  countries: any;
  ccode:any;
  primary_country:any;
  profile_details:any
 dob ="22-03-1998";
  //dob = new Date();
  field:any;
  payload:any;
  get_dob:any;
  data_whole:any;  
  citizen:any[]=[]
   con_list:any[]=[]
  

  //date1 = new FormControl(new Date());
  
  // new_str = `01/01/` + value
  // date1 = new Date(new_str)
    
  myForm_digicoffer = this.formbuilder.group({
    first_name:['',Validators.required],
    last_name: ['',Validators.required],
    middle_name:[''],
    mobile:['',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    email:['',[Validators.email]],
    password:[''],
    dob:[''],
  })
  
  myForm_health = this.formbuilder.group({
    gender: [''],
    firstName:[''],
    lastName:[''],
    ethnicity: [''],
    height:['', [Validators.min(0),Validators.max(999)]],
    heightfeet:['', [Validators.min(0),Validators.max(10)]],
    heightinc:['',[Validators.min(0),Validators.max(11)]],
    heightUnit:[''],
    weight:['',[Validators.min(0),Validators.max(999)]],
    weigthtUnit:[""],
    bloodGroup:[''],
    dob:['']

  })
  submitted = false

  constructor(private router: Router, public auth: AuthServiceService , private formbuilder : FormBuilder , public toast:ToastrService , public datepipe: DatePipe,public dialog:MatDialog , public _comm:CommunicationService) { }
  myform = this.formbuilder.group({})

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
       
    i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    this.blockUI.start()

    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
        this.countries = res.data
        // this.countries.forEach(element => {
        //   this.ccode.push(i18nIsoCountries.getAlpha2Code(element,'en'))

          
        // });
        for (let i = 0; i < this.countries.length; i++) {

          if(this.countries[i].index == "citizen_primary"){
            this.primary_country = this.countries[i].country


          }
        }
       
        

        //console.log(this.countries)
     

        
        
      }

    })
    this.auth.get_ethnicity().subscribe((res:any) =>{
      this.ethnicity_list = res;
      //console.log(this.ethnicity_list)
    })

    this.auth.get_bloodgrps().subscribe((res:any) =>{
      this.bloodgrp_list = res;
      //console.log(this.bloodgrp_list)
    })
    this.auth.profile_details().subscribe((res:any)=>{
      this.profile = res[0]
      //console.log(this.profile)
     // this.myForm_health.get('dob')?.setValue(this.profile.dob)
      this.get_dob = this.profile?.dob

      


    })
    this.auth.get_digicoffer_profile().subscribe((res:any)=>{
      if(res.error==false){
        this.profile_details = res.data
        this.loadCitizenshipOrder(res?.data.citizen)
       

      }
     
    })
    this.stop_loading()
    this._comm.changeEmitted$.subscribe((res)=>{
      if(res){
        this.citizen = []
        this.loadCitizenshipOrder(res)
  
      }
    })

    
    
   
    
  }

  start_loading(){
    this.blockUI.start()
  }
  stop_loading(){
    this.blockUI.stop()
  }
  getTomorrow() {
    
    let d = moment(this.get_dob,"MM-DD-YYYY").format();
    return d
  }
  getime(){
    
  }
  OpenAddCountryDialog(status,data){
    this.dialog.open(DialogAddCountry,{

      data:[status,data]
    })

  }
  onclickicon(field , Value){
    this.field = field
    this.submitted=true
    //this.myForm_digicoffer.controls['firstNmae'].
    if(this.myForm_digicoffer.invalid  || Value==''){
      return
    }

    //console.log(field,Value, this.myForm_digicoffer.invalid)

    
    switch (this.field){

   
  
      case "first_name":
        this.payload = {
          "field": this.field,
          "first_name":Value
        }
        break
  
      case "last_name":
        this.payload = {
          "field": this.field,
          "last_name":Value
        }
        break
        case "middle_name":
          this.payload = {
            "field": this.field,
            "middle_name":Value
          }
          break
  
          case "email":
            this.payload = {
              "field": this.field,
              "email":Value
            }
            break
  
            case "mobile":
              this.payload = {
                "field": this.field,
                "mobile":Value
              }
              break
  
              case "dob":
                this.payload = {
                  "field": this.field,
                  "dob":Value
                }
                break
  
              
          
  
  
  
     }
  
    //console.log(this.myForm_digicoffer.value)
    this.auth.update_digi_profile(this.field,this.payload).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg)
      } else {
        this.toast.error(res.msg)
      }
    })
  }

  openEditFeild(field,data){
    this.dialog.open(EditFieldDialog,{
      data:[field,data]

    }
  )

  }
  onSaveDigiClicked(){
    //console.log(this.myForm_digicoffer.value)
    //let date = this.datepipe.transform(dob,"MM-dd-yyyy")
    // this.auth.update_profile_list(this.profile.id ,{firstName,lastName,date}).subscribe((res:any)=>{
    //   if(res){
    //     //console.log(res)
    //     this.toast.success("Updated Succesfully!!")
    //   }
    // })

  }

  filternull(value){
    const filtered = {};
    for (let key in value) {
      if (value[key]) {
        filtered[key] = value[key];
      }
    }
    //console.log(filtered)
    return filtered

  }
  loadCitizenshipOrder(profile_details) {
  
   
    var citizens_primary
    var citizen_second
    var citizen_third 
    var citizen_fourth 
    for (let item in profile_details) {
        // console.log(this.data.citizen)
        if (profile_details[item]['index'] == 'citizen_primary')
        {
            citizens_primary = profile_details[item]
            profile_details['country'] = profile_details[item]['country']
        }
        else if (profile_details[item]['index'] == 'citizen_second')
            citizen_second = profile_details[item]
        else if (profile_details[item]['index'] == 'citizen_third')
            citizen_third = profile_details[item]
        else if (profile_details[item]['index'] == 'citizen_fourth')
            citizen_fourth = profile_details[item]
    }
    this.citizen.push(citizens_primary)
    if (citizen_second != null)
        this.citizen.push(citizen_second)
    if (citizen_third != null)
        this.citizen.push(citizen_third)
    if (citizen_fourth != null)
        this.citizen.push(citizen_fourth)
    profile_details = this.citizen
  
}

  onSaveHealth(dob){
 
    
    //this.auth.update_general_profile()
    let datetrans = this.datepipe.transform(dob,"MM-dd-yyyy")
    let datedigi = this.datepipe.transform(dob,"dd-MM-yyyy")
    this.myForm_health.get('dob')?.setValue(datetrans)
    this.myForm_digicoffer.get('dob')?.setValue(datedigi)
    delete this.myForm_digicoffer.value.password
    //console.log(this.myForm_health.value,this.filternull(this.myForm_digicoffer.value))
    if(this.myForm_digicoffer.invalid||this.myForm_health.invalid){
      return
    }
    if(this.myForm_health.valid){
      if(this.profile == undefined){
        //this.myForm_digicoffer.addControl("weightuniytt",co)
        this.myForm_health.get('lastName')?.setValue(this.myForm_digicoffer.get('last_name')?.value)
        this.myForm_health.get('firstName')?.setValue(this.myForm_digicoffer.get('first_name')?.value)
        if(this.primary_country=="USA"){
          this.myForm_health.get('weigthtUnit')?.setValue("lbs")
          this.myForm_health.get('heightUnit')?.setValue("feet")
          let height = `${this.myForm_health.get('heightfeet')?.value}.${this.myForm_health.get('heightinc')?.value}`
          this.myForm_health.get('height')?.setValue(height)
          delete this.myForm_health.value.heightfeet
          delete this.myForm_health.value.heightinc
          //console.log(this.myForm_health)
          
        } else {
          this.myForm_health.get('weigthtUnit')?.setValue("kg")
          this.myForm_health.get('heightUnit')?.setValue("cm")
          delete this.myForm_health.value.heightfeet
          delete this.myForm_health.value.heightinc
          //console.log(this.myForm_health)
        


        }
        let data = this.myForm_health.value
        data.medicalCondition = []
        data.allergy = []
        data.medication =[]
        data.insurance =[]
        data.familyHistory = []
        data.immunization = []
        data.addon = []
        //console.log(data)
        this.auth.create_profile_list(this.filternull(data)).subscribe((res:any)=>{
          if(res){
            //console.log(res)
            this.toast.success("Added Profile Successfully!!")

          }
        })
        
      } else {

        if(this.primary_country=="USA"){
          this.myForm_health.get('weigthtUnit')?.setValue("lbs")
          this.myForm_health.get('heightUnit')?.setValue("feet")
          let height = `${this.myForm_health.get('heightfeet')?.value}.${this.myForm_health.get('heightinc')?.value}`
          this.myForm_health.get('height')?.setValue(height)
          delete this.myForm_health.value.heightfeet
          delete this.myForm_health.value.heightinc
          //console.log(this.myForm_health)
          
        } else {
          this.myForm_health.get('weigthtUnit')?.setValue("kg")
          this.myForm_health.get('heightUnit')?.setValue("cm")
          delete this.myForm_health.value.heightfeet
          delete this.myForm_health.value.heightinc
          //console.log(this.myForm_health)
        


        }

        this.auth.update_profile_list(this.profile?.id,this.filternull(this.myForm_health.value)).subscribe((res:any)=>{
          if(res){
            //console.log(res)
           // this.toast.success("Updated Successfully!!")
          }
        })

      }
    

    }
    if(this.myForm_digicoffer.valid){
      this.auth.update_digicoffer_profile(this.filternull(this.myForm_digicoffer.value)).subscribe((res:any)=>{
        if(res.error==false){
          this.toast.success(res.msg)
        } else {
          this.toast.error(res.msg)
        }
      })
      
    }
 

  

  }




}


@Component({
  selector: 'add-country-dialog',
  templateUrl: './add-citizenship.html',
  styleUrls: ['./health-info.component.scss'],
  providers: [ DatePipe ]
})
export class DialogAddCountry {
  @BlockUI()
  blockUI!: NgBlockUI; 
  status:any;
  data_edit:any;
  list_countries:any;
  submitted=false;
  maxDate=new Date();
  countryName:any;
  data_end:any;
  aff_list:any[]=[]
citizen_countries:any[]=[]
existing_affiliation:any[]=[]
show_country = false
dis_btn = false;



  affiliation_type:any;
  constructor(private formBuilder:FormBuilder, private auth: AuthServiceService, public datepipe:DatePipe, public dialog: MatDialogRef<DialogAddCountry>, @Inject(MAT_DIALOG_DATA) public data: any , public toast:ToastrService , private _communication:CommunicationService , private conbox: ConfirmBoxService , private  save:SaveAlertService){
    dialog.disableClose = true;
    this.status = data[0];
    this.data_edit = data[1];
  }
  myForm = this.formBuilder.group({
    "country": ["", Validators.required],
    "affiliation_type": ['', Validators.required],
    "home_address": ['',Validators.required],
    "mobile_phone":['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    "work_address":[''],
    "work_phone":['',Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
    "alt_phone":['',Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]
  });  

  ngOnInit(): void{
  
    this.auth.get_digicoffer_profile().subscribe((res:any)=>{
      if(res.error==false){
        this.data_end = res
        
      }
    })
  
    this.auth.getCountries().subscribe((resp: any) => {
      this.list_countries = resp.data.countries;
      if(this.status=="add"){
        var citizen_countries = this.getCitizenCountries(this.data_end?.data.citizen)
				this.list_countries = this.list_countries.filter(countries => citizen_countries.indexOf(countries[0]) < 0);
      }
    })
 
  
    if(this.status=="edit"){
      this.auth.get_aff_type_con(this.data_edit.country).subscribe((res:any)=>{
        if(res.error==false){
          this.aff_list = res.data
        
            var existing_affiliation = this.getExistingAffiliation(this.data_end?.data.citizen, this.data_edit.index)
            this.aff_list = this.aff_list.filter(affiliation => existing_affiliation.indexOf(affiliation.aflType) < 0);
  
          
        }
      })
      
        this.myForm.controls['country'] = new FormControl({ value: this.data_edit['country'], disabled: true })
      
          if(this.data_edit.index == 'citizen_primary'){
            this.myForm.controls['mobile_phone'] = new FormControl({ value: this.data_edit['mobile_phone'], disabled: true })
          }
    } 
   
   
  }

  start_loading(){
    this.blockUI.start()
  }

  stop_loading(){
    this.blockUI.stop()
  }


 
  check_doctype_exp(event){
    // if(event =="India"){
    //   this.aff_type.push({
    //     "display":"Overseas Citizen of India",
    //     "value":"oci"
    //   })
    //   this.aff_type.push({
    //     "display":"Person of Indian Origin",
    //     "value":"pio"
    //   })
    //   this.aff_type= this.aff_type.filter(affiliation =>  affiliation.value!='dcitz');

    // } else {
       
    //     this.aff_type.push({ 'value': 'dcitz', 'display': 'Dual Citizenship' })
    //     this.aff_type = this.aff_type.filter(affiliation => ['oci', 'pio'].indexOf(affiliation.value) < 0);
      
    // }
    this.auth.get_aff_type_con(decodeURIComponent(event)).subscribe((res:any)=>{
      if(res.error==false){
        this.aff_list = res.data
        var existing_affiliation = this.getExistingAffiliation( this.data_end.data.citizen, "")
        this.aff_list = this.aff_list.filter(affiliation => existing_affiliation.indexOf(affiliation.aflType) < 0);
      }
    })
  }
  getCitizenCountries(citizens){
		// for(let item in citizens)
		// {
		// 	this.citizen_countries.push(citizens[item]['country'])
		// }
    citizens.forEach(element => {
      this.citizen_countries.push(element.country)
      
    });
		return this.citizen_countries

  }
  getExistingAffiliation(citizens, category)
	{ 
		for(let item in citizens)
		{
			if(category == "")
				this.existing_affiliation.push(citizens[item]['affiliation_type'])
			else
			{
				if(category != citizens[item]['index'])
					this.existing_affiliation.push(citizens[item]['affiliation_type'])
			}
		}
		return this.existing_affiliation		
	}
 
 
 
  get f(){
    return this.myForm.controls;
  }
  get_no(){
    if(this.data_edit.index=='citizen_primary'&& this.status=='edit'){
      return this.data_end?.data.mobile
    }
    return this.data_edit.mobile_phone
  }
  onSubmit(mob,work_add,work_no,alt_no){
    this.dis_btn = true
    this.start_loading()
   
    if(this.data_edit.index == 'citizen_primary'){
      this.myForm.get('affiliation_type')?.setValue(this.data_edit?.affiliation_type)
      if(mob){
        
        this.myForm.get('mobile_phone')?.setValue(mob)
        
      } else {
        this.myForm.get('mobile_phone')?.setValidators([])
        this.myForm.get('mobile_phone')?.setValue("")
      }
    
      

    } else {
      this.myForm.get('mobile_phone')?.setValue(mob)
    }
  
    this.myForm.get('work_address')?.setValue(work_add)
    this.myForm.get('work_phone')?.setValue(work_no)
    this.myForm.get('alt_phone')?.setValue(alt_no)
    // if(this.status=="edit"){
    //   this.myForm.get('country')?.setValue(this.data_edit['country'])
    // }
    
    this.submitted = true;
    if (this.myForm.invalid) {
      this.dis_btn = false
      this.stop_loading()
      return;
    
    }
    //console.log(this.myForm.value);
  
    if(this.status=="add"){
  
      this.auth.add_country(this.myForm.value).subscribe((res:any)=>{
        if(res.error==false){
          this.toast.success(res.msg)
          
          this.auth.get_digicoffer_profile().subscribe((res:any)=>{
            if(res.error == false){
              this._communication.emitChange(res?.data.citizen)
            }
          })
          this.close_dialog()
          this.stop_loading()
         
       
  
          
        }else if(res.msg.country){
          this.toast.error(res.msg.country)
          this.dis_btn = false
          this.stop_loading()
        } else{
          this.toast.error(res.msg)
          this.dis_btn = false
          this.stop_loading()
        }
      })

    } else if(this.status=="edit"){
      let form_data = this.myForm.value
    form_data['country'] = this.data_edit?.country
      this.auth.edit_country(this.data_edit.index,form_data).subscribe((res:any)=>{
        if(res.error==false){
          this.toast.success(res.msg)
          this.auth.get_digicoffer_profile().subscribe((res:any)=>{
            if(res.error == false){
              this._communication.emitChange(res?.data.citizen)
            }
          })
          this.close_dialog()
          this.stop_loading()
         
          
        }else if(res.error && res.affiliation_type){
          this.toast.error(res.msg.affiliation_type)
          this.dis_btn = false
          this.stop_loading()
        }  else if(res.msg.country){
          this.dis_btn = false
          this.toast.error(res.msg.country)
          this.stop_loading()
        } else {
          this.toast.error(res.msg)
          this.dis_btn = false
          this.stop_loading()

        }
      })
 
     }

    
  }

  add_country_affliation(countryName, affiliation_type, home_add, work_add,mob,work_no,alt_no){
  
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    if(this.myForm.controls['country'].status == 'VALID'){
    let data = {"country":countryName,"affiliation_type": affiliation_type,"alt_phone": alt_no,"home_address":home_add,"mobile_phone": mob,"work_address": work_add,"work_phone": work_no}
     this.auth.add_country(data).subscribe((res:any)=>{
       if(res.error==false){
         this.toast.success(res.msg)
         
       }else{
         this.toast.error(res.msg)
       }
     })

    }

    this.close_dialog()


  }

  edit_country_affliation(countryName, affiliation_type, home_add, work_add,mob,work_no,alt_no){
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    if(this.myForm.controls['country'].status == 'VALID'){
      let data = {"country":countryName,"affiliation_type": affiliation_type,"alt_phone": alt_no,"home_address":home_add,"mobile_phone": mob,"work_address": work_add,"work_phone": work_no}
    
     //console.log(data)
     this.auth.edit_country(this.data_edit.index,data).subscribe((res:any)=>{
       if(res.error==false){
         this.toast.success(res.msg)
         
       }else{
         this.toast.error(res.msg)
       }
     })

    }
    this.close_dialog()

  }

  delete_country_affliation(){
    this.conbox.confirm("Alert","Are you sure you want to delete country affiliation?").then((res)=>{
      if(res){
        this.auth.delete_country(this.data_edit.index).subscribe((res:any)=>{
          if(res.error==false){
            this.toast.success(res.msg)
            this.auth.get_digicoffer_profile().subscribe((res:any)=>{
              if(res.error==false){
            
                this._communication.emitChange(res?.data.citizen)
              } else {
                
                this.toast.error(res.msg)
              }
            
            
    
            })
            this.dialog.close()
    
          } else {
            this.toast.error(res.msg)
          }

        })

      }
    })
  

    
  }
  close_dialog(){
    if(this.submitted==false && this.myForm.dirty){
      this.save.confirm("Alert").then((res)=>{
        if(res){
          this.dialog.close()

        }
      })
    } else {
      this.dialog.close()
    }
    
  }
  
  

}

@Component({
  selector: 'edit-field-dialog',
  templateUrl: './edit-field.html',
  styleUrls: ['./health-info.component.scss'],
  providers: [ DatePipe ]
})

export class EditFieldDialog {
  field:any;
  edit_data:any;
  myform:any;
  submitted=false;
  password_status = "fa fa-eye-slash";
  password_status1 = "fa fa-eye-slash";
  password_status2 = "fa fa-eye-slash";
  hide = true;
  hide1 = true;
  hide2 = true;

  constructor(private formBuilder:FormBuilder, private auth: AuthServiceService, public datepipe:DatePipe, public dialog: MatDialogRef<EditFieldDialog>, @Inject(MAT_DIALOG_DATA) public data: any , public toast:ToastrService , public _communication:CommunicationService){
    this.field=data[0]
    this.edit_data=data[1]

  }



  ngOnInit(): void{
    //console.log(this.field)



   switch (this.field){

    case "password":
      this.myform = this.formBuilder.group({
        field:[''],
        old:['',Validators.required],
        new:['',Validators.required],
        con:['',Validators.required]
      },{validator:this.checkPasswords})
      break

    case "first_name":
      this.myform = this.formBuilder.group({
        field:[''],
        first_name:['']
      })
      break

    case "last_name":
      this.myform = this.formBuilder.group({
        field:[''],
        last_name:['']
      })
      break
      case "middle_name":
        this.myform = this.formBuilder.group({
          field:[''],
          middle_name:['']
        })
        break

        case "email":
          this.myform = this.formBuilder.group({
            field:[''],
            email:['',Validators.email]
          })
          break

          case "mobile":
            this.myform = this.formBuilder.group({
              field:[''],
              mobile:['',Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]
            })
            break

            case "dob":
              this.myform = this.formBuilder.group({
                field:[''],
                dob:['']
              })
              break

      
        



   }




  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.new.value;
    let confirmPass = group.controls.con.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  password_icon(){
    this.password_status = "fa fa-eye";
    this.hide = !this.hide;
    
    if(this.hide){
      this.password_status = "fa fa-eye-slash"
    } else {
      this.password_status = "fa fa-eye"
      this.hide = false;
    }
  }
  password_icon1(){
    this.password_status1 = "fa fa-eye";
    this.hide1 = !this.hide1;
    
    if(this.hide1){
      this.password_status1 = "fa fa-eye-slash"
    } else {
      this.password_status1 = "fa fa-eye"
      this.hide1 = false;
    }
  }
  password_icon2() {
    this.password_status2 = "fa fa-eye";
    this.hide2 = !this.hide2;
    
    if(this.hide2){
      this.password_status2 = "fa fa-eye-slash"
    } else {
      this.password_status2 = "fa fa-eye"
      this.hide2 = false;
    }
  }
  Onsubmit(){
    this.submitted = true
    //console.log(this.myform)
    if(this.myform.invalid){
      return
    }
    this.myform.get("field").setValue(this.field);
    delete this.myform.value.con;
    //console.log(this.myform)

    this.auth.update_password(this.myform.value).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg)
        this.close_dialog()
      } else {
        this.toast.error(res.msg)
      }

    })

  
  }
  

  close_dialog(){
    this.dialog.close();
  }
  

}

<div class="logo-page">
    <div class="section-1 row">
        <div class="left-column col-md-6">
            <div class="content">
                <img src="../../assets/images/vitagist (1).png" alt="">
                <!-- <h2>WELCOME!</h2>
                <p>Start managing your patients remotely with VitaGist</p> -->
            </div>
        </div>
        <div class="right-column col-md-6">
            <div class="content">
                <h2 class="login-label">Login</h2>
                <form [formGroup]="myForm">
                    <!-- <div class="signTitle">Sign In</div> -->
                    <div class="signForm-row " id="email_input">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope"></i></span>
                        <input type="email" #emailInput formControlName='email' name="email" class="form-control" id='id_email' placeholder="Email" aria-describedby="basic-addon1"/>
                      </div>
                      
                      <!-- <div class="text-danger" *ngIf="f['email'].errors && submitted">Email is required</div> -->
                      <div *ngIf="f['email'].errors && submitted" class="text-danger">
                        <div *ngIf="f['email'].errors.required && submitted">Email is required</div>
                        <div *ngIf="f['email'].errors.email">Invalid Email</div>
                        <div *ngIf="f['email'].errors.msg">{{f['email'].errors.msg}}</div>
                      </div>
                    </div><br>
                    <div class="password_section signForm-row ">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon2"><i class="fas fa-key"></i></span>
                        <input [type]="hide ? 'password' : 'text'" #pwInput formControlName="password" class="form-control" 
                        inputmode="passsword" id="id_password" placeholder="Password" aria-describedby="basic-addon2"/>
                      </div>
                      
                        <i (click)="password_icon()" class="show_hide {{password_status}}"></i>
                      <div *ngIf="f['password'].errors && submitted" class="text-danger">
                        <div *ngIf="f['password'].errors.required">Password is required</div>
                        <div *ngIf="f['password'].errors.msg">{{f['password'].errors.msg}}</div>
                      </div>
                    </div>
                    <div class="signForm-row">
                      <div class="remCheck" hidden>
                        <label><input type="checkbox" />Remember me</label>
                      </div><br>
                      <div  class="forgot-password-button"><a (click)="forgot_password()" routerLink="/forgot-password">Forgot password</a></div>
                    </div>
                    <div class="signForm-row">
                      <button (click)="onLoginButtonClicked(emailInput.value, pwInput.value)" class="sign-in-button">Login</button>
                    </div>
                    <p class="account-label">Don't have an account?</p>
                    <button (click)="go_to_register()" class="register-button"><a routerLink="/register">Register</a></button>
                  </form>
                <!-- <form class="example-form" [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <mat-form-field class="example-full-width">
                      <mat-label >Email</mat-label>
                      <input type="tel" matInput placeholder="Enter Email" >
                      <mat-icon class="input-field-icon" matPrefix>perm_identity</mat-icon>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-icon class="input-field-icon" matPrefix>password</mat-icon>
                        <mat-label >Password</mat-label>
                        <input type="password" matInput placeholder="Enter Password">
                    </mat-form-field>
                    <button class="forgot-password-button" mat-button>Forgot Password?</button>
                    <button class="sign-in-button">Sign in</button>
                    <p class="account-label">Don't have an account?</p>
                    <button class="register-button">Register</button>
                </form> -->
            </div>
            <div class="copyright">
                <p>VitaGist 2023. All rights reserved.</p>
                <!-- <p>v{{currentApplicationVersion}}</p> -->
            </div>
        </div>
    </div>
</div>

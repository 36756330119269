

  <div class="closeDialog"><i class="fa fa-times closeBtn" (click)="closeDialog()" aria-hidden="true"></i></div>
  <mat-dialog-content class="mailoption">
    <h2 class="subneed" mat-dialog-title>Subscription needed!!</h2>
    Your storage limit exceeded please pay now.
  </mat-dialog-content>

  <mat-dialog-actions>
    <div mat-dialog-actions class="dialogSave">
          <button type="cancel" class="btn btnCancel" (click)="closeDialog()">Cancel</button>
          <button type="submit" class="btn btnSave" (click)="payNow()">Pay Now</button>
    </div>
  </mat-dialog-actions>


<div class="container">

<div class="card-dialog row justify-content-center">
 
  <div class="right col-12">
    <div class="close-btn">
      <button (click)="close_dialog()">X</button>
    </div>
    <h1 mat-dialog-title>Share Document</h1>
    
    
    
       <mat-dialog-content>
        
          <div class="menu-list row row-cols-1 row-cols-md-3 g-3">
          
            <div class="col" *ngFor="let item of menu_list">
              <mat-card [ngClass]="selected_tag == item.tagName? 'selected-card' : 'not-selected-card'" (click)="OnClicked(item.tagName)" >
                <div class="count-btn">
                  <button>{{item.count}}</button>
              </div>
                
                <div class="edit-btn">
                  <h2 class="card-title">
                   
                    <span *ngIf="item.tagName=='Others'">
                      <i class="fas fa-file-alt"></i>
                    </span>
                    <span *ngIf="item.tagName=='Personal'">
                      <i class="fas fa-users"></i>
                    </span>
                    <span *ngIf="item.tagName=='LegalFinance'">
                      <i class="fas fa-file-alt"></i>
                    </span>
                    <span *ngIf="item.tagName=='Health'">
                      <i class="fas fa-briefcase-medical"></i>
                    </span>
                    <span *ngIf="item.tagName=='Identity'">
                      <i class="far fa-address-book"></i>
                    </span> <br/>{{item.tagName}}</h2>
                    
                </div>
            </mat-card>
            </div>
            
        </div>
        <div>
          <div class="user_column">
            <div class="upper">
              
            
               <div class="formfield">
                  <mat-form-field appearance="fill">
                    <mat-select [(value)]="selected">
                      <mat-option *ngFor="let food of countries" (click)="selectedn(food.index,food.country)"[value]="food.index">
                        {{food.country}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
            </div>
            <div class="searchbar-e">
              <input  type="text" placeholder="Search"  [(ngModel)] = searchText  #filterValue>
              <i class="fas fa-search"></i>
           </div>
           <p *ngIf="list?.length == []" class="no-docs">No Records Found!!</p>
           <div *ngIf="clickedname=='Identity'" >
             <mat-card class="list-docs" *ngFor="let option of list | filterAll:searchText; let i = index">
               <div class="check-box-group" class="form-dialog" >
                <mat-checkbox #save class="example-radio-button"  (change)="selected_chart(option.id,option.doctype,i , $event.checked)" [value]="option.doctype">{{option.doctype | doctype}}</mat-checkbox>
               </div>
               <p class="sub-head">{{option.created}}-{{option.filesize}} MB-{{option.expiration_date}}</p>
              

             </mat-card>
             
             
           
          </div>
          <div *ngIf="clickedname!='Identity'" class="check-box-group">
            <mat-card class="list-docs" *ngFor="let option of list | search: 'name':filterValue.value; let i = index" >
              <div class="check-box-group"  class="form-dialog"  >
               <mat-checkbox  class="example-radio-button"  (change)="selected_chart(option.id,option.doctype,i , $event.checked)" [value]="option.doctype">{{option.name}}</mat-checkbox>
              </div>
              <p class="sub-head">{{option.created}}-{{option.filesize}}-{{option.expiration_date}}</p>

             

            </mat-card>
           
          </div>
          <div *ngIf="datai.length<0 && submitted" class="text-danger">
            <div>This field is required</div>
          </div>
          
        </div>
        </div>
       </mat-dialog-content>

      <div class="save-button">
        <button type="submit" (click)= "share()">Share</button> 
      </div>
  </div>
        
     
</div>
</div>
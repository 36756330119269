<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()" mat-dialog-close>X</button>
        </div>
        <div *ngIf="status=='with'">
          <h2>Shared With Me</h2>
          <mat-card class="list-docs" *ngFor="let option of list">
            <div class="content-share">
              <p>{{option.docname}}</p>
              <div class="btn-grp">
                <button *ngIf="option.doctype =='personal'"  (click)="view_doc('personal',option.country_affiliation,option.docid)"><mat-icon>remove_red_eye</mat-icon></button>
                <button *ngIf="option.doctype =='identity'"  (click)="view_doc('Credentials',option.country_affiliation,option.docname)"><mat-icon>remove_red_eye</mat-icon></button>
                <button *ngIf="option.doctype =='personal'" (click)="download_personal(option.docid,option.country_affiliation)"><mat-icon>file_download</mat-icon></button>
                <button *ngIf="option.doctype =='identity'"(click)="download_credential(option.docname , option.country_affiliation)"><mat-icon>file_download</mat-icon></button>
              
                
              </div>
            </div>
            
           
  
          </mat-card>
          <p *ngIf="list?.length == 0">No Records Found!!</p>

        </div>
        <div *ngIf="status=='by'">
          <h2 >Shared By Me</h2>
          <mat-card class="list-docs" *ngFor="let option of list">
            <div class="content-share">
              <p>{{option.docname}}</p>
              <div class="btn-grp">
                <button *ngIf="option.doctype =='personal'"  (click)="view_doc('personal',option.country_affiliation,option.docid)"><mat-icon>remove_red_eye</mat-icon></button>
                <button *ngIf="option.doctype =='identity'"  (click)="view_doc('Credentials',option.country_affiliation,option.docname)"><mat-icon>remove_red_eye</mat-icon></button>
                <button *ngIf="option.doctype =='personal'" (click)="download_personal(option.docid,option.country_affiliation)"><mat-icon>file_download</mat-icon></button>
                <button *ngIf="option.doctype =='identity'"(click)="download_credential(option.docname , option.country_affiliation)"><mat-icon>file_download</mat-icon></button>
              </div>
            </div>
            
           
  
          </mat-card>
          <p *ngIf="list?.length == 0">No Records Found!!</p>
        </div>
       
      
       
        </div>
    </div>
</div>
<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    
        <div>
            <div class="card-list row row-cols-1 row-cols-md-6 g-3">
              
              <div class="col" *ngFor="let item of menu_list">
                <mat-card  [ngClass]="selected_tag == item.tagName? 'selected-card' : 'not-selected-card'" (click)="OnClicked(item.tagName)" *ngIf="item.tagName!='LegalFinance'">
                  <div class="count-btn">
                    <button>{{item.count}}</button>
                </div>
                  
                  <div class="edit-btn">
                    <h2 class="card-title" *ngIf="item.tagName=='Key_contacts'">
                      <span>
                        <i class="fas fa-user-friends"></i>
                      </span>
                      <br/>Key Contacts</h2>

                    
                    <h2 class="card-title" *ngIf="item.tagName=='Lauditor'">
                      <span >
                        <i class="fas fa-suitcase"></i>
                      </span>
                       <br/>Legal & Finance </h2>
                       <h2 class="card-title" *ngIf="item.tagName=='Personal'">
                        <span >
                          <i class="fas fa-users"></i>
                        </span>
                         <br/>Family & Friends</h2>
                         <h2 class="card-title" *ngIf="item.tagName=='ContentCoffer'">
                          <span >
                            <i class="far fa-folder"></i>
                          </span>
                           <br/>Others</h2>
                           <h2 class="card-title" *ngIf="item.tagName=='Health'">
                            <span >
                              <i class="fas fa-briefcase-medical"></i>
                            </span>
                             <br/>Health</h2>
                             

                         
                      
                  </div>
              </mat-card>
              </div>
              
          </div>
           </div>
           <div class="">
             <mat-card class="user_column">
              <div class="upper">
                <div class="heading">
                  <p>Connections</p>

                </div>
              
              
              </div>
              <div class="searchbar-e">
                <input  type="text" placeholder="Search"  [(ngModel)] = searchText  #filter>
                <i class="fas fa-search"></i>
             </div>
 
            <div *ngIf="selected_tag!='Key_contacts'" class="relation-list row row-cols-1 row-cols-md-3 g-3" >
               <p *ngIf="list?.length == []" class="no-docs">No Records Found!!</p>
              <div class="col" *ngFor="let item of list | filterAll:searchText" >
                  <mat-card >
                    <div *ngIf ="item.isaccepted==true" class="upper-icons">
                      <button  aria-label="chat" title="Chat" class="chat-btn" [routerLink]="['/menu/chat', item['id'] ]"><i class='far fa-comment-alt'></i></button>
                      
                  </div>
                      <div class="card-cnt">
                          <img  *ngIf="(!item.profileUrl)" src="../../assets/images/owner.png" alt="">
                          <img *ngIf="(item.profileUrl)" src={{item.profileUrl}} alt="">
                          <!-- <img *ngIf="( imgurl)" src={{imgurl}} alt=""> -->
                          <div class="name-staus">
                            <p class="card-title">{{item.business_name}}</p>
                            <p *ngIf="item.isaccepted">Accepted</p>
                            <p *ngIf="!item.canAccept && !item.isaccepted">Pending</p>

                          </div>
                         

                          
                      </div>
                      <!-- <div class="below-btn" *ngIf ="item.isaccepted==true">
                        
                        <button (click)="Sharewith(item.id,'with')">Shared With Me</button>
                        <button (click)="Sharewith(item.id,'by')">Shared By Me</button>
  
                      </div> -->
                      <mat-card-footer class="share-grp" *ngIf ="item.isaccepted==true">
                        <button  mat-button title="Share Documents" class="share-btn" (click)="ShareDialog(item.id)"><i class="fas fa-file-upload"></i>Share</button>
                        <button mat-button title="Items Shared With Me" class="with-btn" (click)="share_doc_list('with',item.id)" data-toggle="modal" data-target="#modalshare"><i class="far fa-file-alt"></i>Shared With Me</button>
                        <button mat-button title="Items Shared By Me" class="by-btn" (click)="share_doc_list('by',item.id)" data-toggle="modal" data-target="#modalshare"><i class="far fa-file-alt"></i>Shared By Me</button>
                      </mat-card-footer>
                      <mat-card-footer class="status-btn" *ngIf ="item.isaccepted==false">
                    
                        <button  title="accept request" *ngIf="item.canAccept && !item.isaccepted " (click)="acceptRequest(item.id,item.biztype)">
                            Accept
                        </button>
                        <div *ngIf="!item.canAccept && !item.isaccepted" class="accept-button">
                          Pending
                        </div>
                        
                      </mat-card-footer>
                      <!-- <div class="e-btn">
                          
                        <button class="rgt-share"><i class='fas fa-share-square'></i></button>
                          
                      </div> -->
                     
                     
                         
                    </mat-card>
                   
                    <!-- <mat-accordion multi *ngIf ="item.isaccepted==true">
                        
                      <mat-expansion-panel >
                        <mat-expansion-panel-header>
                            <div class="rgt-share" (click)="$event.stopPropagation();">
                              <button (click)="ShareDialog(item.id)"><i class="fas fa-file-upload"></i></button>
                            </div>
                            
                          
                          
                        </mat-expansion-panel-header>
                          <div class="share-btn">
                            <button (click)="Sharewith(item.id,'with')">Shared With Me</button>
                            <button (click)="Sharewith(item.id,'by')">Shared By Me</button>
                          </div>
    
                      
                      </mat-expansion-panel>
                      
                    </mat-accordion> -->
                  
                    
                    
                  
            </div>
           
              
              
          </div>
          <div *ngIf="selected_tag=='Key_contacts'" class="key-list row row-cols-1 row-cols-md-3 g-3">
            <p *ngIf="entity_list?.length == []" class="no-docs">No Records Found!!</p>
            <div class="col" *ngFor="let item of entity_list | filterAll:searchText">
                <mat-card>
                  <!-- <div class="upper-icons">
                    <div  title="Account Id" class="acc-id-btn">{{item.account_id}}</div>
                    <div title="Entity Identification Number" class="ent-id-btn">{{item.biz_identification_no}}</div>
                    
                </div> -->

                    <div class="details-cnt">
                        <h4>{{item.firm_name}}</h4>
                        <h5>{{item.firm_address}}</h5>
                    </div>
                    <div class="act-btn">
                      <button  title="Edit"  class="read-btn" (click)="openDialog('edit',item)"><mat-icon>edit</mat-icon></button>
                      <button title="Delete"  class="del"  (click)="delete_entity(item.id)"><mat-icon>delete</mat-icon></button>

                    </div>
                   
                    <!-- <mat-card-actions align="end" class="action-grp">
                      <button  title="edit btn"  class="edit-btn" (click)="openDialog('edit',item)"><mat-icon>edit</mat-icon></button>
                      <button title="delete btn"  class="delete-btn"(click)="delete_entity(item.id)"><mat-icon>delete</mat-icon></button>
                
                    </mat-card-actions> -->
                    <!-- <mat-card-footer>
                      <button mat-button title="edit btn" class="edit-mat" (click)="openDialog('edit',item)"><mat-icon>edit</mat-icon></button>
                      <button mat-button title="delete btn" (click)="delete_entity(item.id)"><mat-icon>delete</mat-icon></button>
                    </mat-card-footer> -->
                </mat-card>
            </div>
            
        </div>



             </mat-card>
           </div>
        
           <div class="example-button-container">
            
                <button (click)="open_bottom_dialog($event)" title="add entity" mat-raised-button><mat-icon>add</mat-icon><span class="add-txt">Add</span></button>
            <!-- <button (click)="openDialog()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
              <mat-icon>add</mat-icon>
            </button> -->
          </div>
          <div *ngIf="isClassExpand" class="bottom_dialog" (click)="$event.stopPropagation()" >
            <div>
                <button (click)="openRelationshipCareDialog()"><i class="fas fa-user-alt"></i>Family & Friends</button>
                <!-- <button (click)="openBizCareDialog()"><i class="fas fa-user-friends"></i>Business & Professionals</button> -->
                <button (click)="openDialog('add','')"><i class="fas fa-user-plus"></i>Key Contacts</button>
                
            </div>
          </div>

        <div class="modal fade" id="modalshare" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            
            <!--Content-->
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel" *ngIf="status=='with'">Shared With Me</h1>
                <h1 class="modal-title" id="exampleModalLabel" *ngIf="status=='by'">Shared By Me</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">x
                </button>
                <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
              </div>
          
        
              <!--Body-->
              <div class="modal-body">
        
                <div *ngIf="status=='with'">
                  
                  <mat-card class="list-docs" *ngFor="let option of doc_list">
                    <div class="content-share">
                      <p>{{option.docname | doctype}}</p>
                      <div class="btn-grp">
                        <button title="View" (click)="shared_with_me_view(option.docid,'view',option.content_type.split('/',1) , option.url,option)" data-toggle="modal" data-target="#modalYT"><mat-icon>remove_red_eye</mat-icon></button>
                        <!-- <button *ngIf="selected_tag !='Personal'" title="Copy"  (click)="shared_with_me(option.docid,'copy',option.content_type.split('/',1) , option.url)"  ><mat-icon>file_copy</mat-icon></button> -->
                        <button  *ngIf="selected_tag =='Personal'" title="Download" (click)="shared_with_me(option.docid,'download',option.content_type.split('/',1) , option.url)"><mat-icon>file_download</mat-icon></button>
                     
                      
                        
                      </div>
                    </div>
                    
                   
          
                  </mat-card>
                  <p *ngIf="doc_list?.length == 0">No Records Found!!</p>
        
                </div>
                <div *ngIf="status=='by'">
                  <mat-card class="list-docs" *ngFor="let option of doc_list">
                    <div class="content-share">
                      <p>{{option.docname | doctype}}</p>
                      <div class="btn-grp">
                        <button titel="View" *ngIf="option.doctype =='personal'"  (click)="view_doc_personal(option.url,option.content_type.split('/',1),option)" data-toggle="modal" data-target="#modalYT"><mat-icon>remove_red_eye</mat-icon></button>
                        <button  title="View" *ngIf="option.doctype =='identity'"  (click)="view_doc_identity(option.url,option.content_type.split('/',1),option)" data-toggle="modal" data-target="#modalYT"><mat-icon>remove_red_eye</mat-icon></button>
                        <button title="Download" *ngIf="option.doctype =='personal'" (click)="download_personal(option.docid,option.country_affiliation)"><mat-icon>file_download</mat-icon></button>
                        <button  title="Download" *ngIf="option.doctype =='identity'"(click)="download_credential(option.docname , option.country_affiliation)"><mat-icon>file_download</mat-icon></button>
                      </div>
                    </div>
                    
                   
          
                  </mat-card>
                  <p *ngIf="doc_list?.length == 0">No Records Found!!</p>
                </div>
        
              </div>
        
              <!--Footer-->
             
        
            </div>
            <!--/.Content-->
        
          </div>
        </div>

        <div class="modal fade" id="modalYT" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            
            <!--Content-->
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title" id="exampleModalLabel">View Document</h1>
                <button type="button" class="close" data-dismiss="modal" (click)="clear_url()" aria-label="Close">x
                </button>
                <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
              </div>
          
        
              <!--Body-->
              <div class="modal-body">
        
                <div *ngIf="type_con!='image'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                  <iframe class="embed-responsive-item" [src] ="url | safeurl" height="500" width="100%"  allowfullscreen></iframe>
                </div>
                <div *ngIf="type_con=='image'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                  <img class="embed-responsive-item" [src] ="url | safeurl"  width="100%"  />
                </div>
        
              </div>
        
              <!--Footer-->
             
        
            </div>
            <!--/.Content-->
        
          </div>
        </div>
    
</div>
<block-ui></block-ui>
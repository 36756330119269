<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
            
        <div class="close-btn">
          <button (click)="close_dialog('false')" >X</button>
        </div>
        <h1 mat-dialog-title>Document Upload</h1>
         
            <div>
                <form *ngIf="title == 'Personal'" class="form-dialog" [formGroup]="myForm" class="">
                   
                  
                   
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Tags</mat-label>
                            <mat-select required [(ngModel)]="name_tag" multiple formControlName ="tags" >
                              <mat-option *ngFor="let topping of tag_list" [value]="topping">{{topping}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.tags.errors && submitted">This field is required</mat-error>
                          </mat-form-field>
                          <!-- <div *ngIf="f.tags.errors && submitted" class="text-danger">
                            <div>This field is required</div>
                          </div> -->
                          

                    
                
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Name</mat-label>
                        <input matInput #name required formControlName='name' name="name" placeholder="Enter Document Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" >
                        <mat-error *ngIf="f.name.errors && submitted">This field is required
                        </mat-error>
                       
                    </mat-form-field>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Description</mat-label>
                        <textarea matInput #description required formControlName='description' name="description" placeholder="Enter description"[ngClass]="{ 'is-invalid': submitted && f.description.errors }" ></textarea>
                       <mat-error *ngIf="f.description.errors && submitted">This field is required</mat-error>
                    </mat-form-field>
                    
                    
                    
                    
                      
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Expiration date</mat-label>
                        <input readonly formControlName='target' #target matInput [min]="maxDate" [matDatepicker]="dp3" >
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                      </mat-form-field>
                     
                      <br>
                      <div>
                      <label>Select file<font color="red">*</font></label>
                      <input type="file"  formControlName='file' class="file-upload" (change)="onFileChanged($event)" required id="docpicker"
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,image/jpg,image/jpeg,image/gif,image/bmp,image/png,text/plain,application/rtf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" > 
                      <mat-error *ngIf="f.file.errors && submitted">This field is required</mat-error>
                      <mat-error *ngIf="allowed_exe_status">Please upload PDF, Images, Word or Spreadsheet Documents</mat-error>
                     

                      
                      

                      </div>
                      
                      <div class="save-button">
                        <button (click)="add_personal_document(name.value,description.value,target.value,tags)">Save</button> 
                    </div>

                </form>
                <form *ngIf="title == 'Identity'" class="form-dialog" [formGroup]="myForm" class="">
                   
                    
                        
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Tags</mat-label>
                            <mat-select  required [ngModel]="name_tag"  multiple formControlName ="tags" >
                              <mat-option *ngFor="let topping of tag_list_id" [value]="topping">{{topping}}</mat-option>
                            </mat-select>
                            <mat-error  *ngIf="f.tags.errors && submitted">This field is required</mat-error>
                          </mat-form-field>
                         
                         
                        

                    
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Document Id</mat-label>
                        <input matInput #id required formControlName='id' name="id" placeholder="Enter the id" [ngClass]="{ 'is-invalid': submitted && f.id.errors }" >
                        <mat-error *ngIf="f.id.errors && submitted">This field is required</mat-error>
                    </mat-form-field>
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Document Type</mat-label>
                        <mat-select required formControlName='type' #doctype_selected (selectionChange)="check_doctype_exp($event)">
                            <mat-option *ngFor="let food of doclist | keyvalue" [value]="food.key">
                              {{food.value}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="f.type.errors?.required && submitted">This field is required</mat-error>
                    </mat-form-field>
                    
                    
                    
                      
                    
                    <!-- *ngIf="doctype_selected=='driver_license'||doctype_selected=='voterid'||doctype_selected=='pancard'||doctype_selected=='passport'" -->
                    <mat-form-field class="example-full-width" appearance="fill" *ngIf="chkexp" >
                        <mat-label>Expiration Date</mat-label>
                        <input readonly required formControlName='target' #target matInput [min]="tomorrow" [matDatepicker]="dp3"  >
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                        <mat-error *ngIf="f.target.errors?.required && submitted">This field is required</mat-error>
                      </mat-form-field>
                     
                      
                      <br>
                      <label>Select file<font color="red">*</font></label>
                      <input type="file" required formControlName='file' class="file-upload" (change)="onFileChanged($event)" accept="image/jpg,image/jpeg,image/gif,image/bmp,image/png,application/pdf"  > 
                      <mat-error *ngIf="f.file.errors && submitted">This field is required</mat-error>
                    
                      
                      <div class="save-button">
                        <button (click)="add_credential_document(id.value,doctype_selected.value , tags)">Save</button> 
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>
<block-ui></block-ui>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardsComponent, DialogOpenCard ,GraphOpenDialog , AddDietReading , AddExerciseReading} from './cards/cards.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { ThreeDataCardComponent, DialogOpen } from './three-data-card/three-data-card.component';
import { UserDetailsComponent ,DietDetailsDialog,ExerciseDetailsDialog} from './user-details/user-details.component';
import { ClinicDataComponent } from './clinic-data/clinic-data.component';
import { CareProgramComponent } from './care-program/care-program.component';
import { PatientsColumnComponent, FilterDialog, RelationshipDialog, SendRequest , CareRelationshipDialog ,CareSendRequest} from './patients-column/patients-column.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatChipsModule} from '@angular/material/chips';
import { MatSelectModule} from '@angular/material/select';
import { MatIconModule} from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CarePlanComponent } from './care-plan/care-plan.component';
import { ActivityFeedComponent } from './activity-feed/activity-feed.component';
import { MatStepperModule} from '@angular/material/stepper';
import { MatInputModule} from '@angular/material/input';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MatTabsModule} from '@angular/material/tabs';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ChartsModule } from 'angular-bootstrap-md';
import { GraphRowComponent } from './graph-row/graph-row.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {MatDialogModule} from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { PatientProfilePageComponent, AddDataReading } from './patient-profile-page/patient-profile-page.component';
import { FilterPipe, FilterPipeSecond } from './filter.pipe';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {CommonModule, DatePipe} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { SettingsComponent } from './settings/settings.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MailVerificationComponent } from './mail-verification/mail-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MenuCompComponent } from './menu-comp/menu-comp.component';
import { MenuComponent } from './menu/menu.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { AddDeviceComponent, AddDeviceDialog} from './add-device/add-device.component';
import { HealthInfoComponent , DialogAddCountry,EditFieldDialog} from './health-info/health-info.component';
import {MatTableModule} from '@angular/material/table';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { ProfileComponent , MedicalConditionDialog} from './profile/profile.component';

import {MatCheckboxModule} from '@angular/material/checkbox';

import { CareTeamComponent , RelationshipCareDialog , SendCareRequest} from './care-team/care-team.component';
import { ServiceProviderComponent , FilterMenuDialog , SendMenuRequest ,RelationshipMenuDialog} from './service-provider/service-provider.component';
import { CalenderComponent } from './calender/calender.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import dayGridPlugin from '@fullcalendar/daygrid';
import { AddPlanComponent } from './add-plan/add-plan.component';
import { CreateNewPlanComponent, FormDialog } from './create-new-plan/create-new-plan.component'; // a plugin!
import {A11yModule} from '@angular/cdk/a11y';
import { EditCarePlanComponent , FormEditDialog } from './edit-care-plan/edit-care-plan.component';
import { ChatComponent } from './chat/chat.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';


import { ViewCarePlanComponent , ExerciseDialog } from './view-care-plan/view-care-plan.component';
import { AddOnsComponent } from './add-ons/add-ons.component'

import { SurveyComponent } from './survey/survey.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import { CarePlanDashComponent } from './care-plan-dash/care-plan-dash.component';
import { BmiComponent } from './bmi/bmi.component';
import {MatBadgeModule} from '@angular/material/badge';
import { AuthInterceptor } from './AppHttpInterceptor';
import { HomepageComponent, ConfirmationDialogComponent } from './homepage/homepage.component';
import { DocumentsComponent , DialogAddDocument , DialogViewDocument ,DialogEditDocument , DragDrop} from './documents/documents.component';
import { ConnectionsComponent , AddEntity , ShareDocument,SharedWithDocument , DialogViewConnections}  from './connections/connections.component';
import { ConnectionComponent } from './connection/connection.component';
import { ActivityDashComponent } from './activity-dash/activity-dash.component';
import { RemindersComponent , DialogAddReminder} from './reminders/reminders.component';
import { NotificationComponent } from './notification/notification.component';
import { SearchPipe } from './search.pipe';
import {MatMenuModule} from '@angular/material/menu';
import { DoctypePipe } from './doctype.pipe';
import { SafeurlPipe } from './safeurl.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';

import {OverlayModule} from '@angular/cdk/overlay';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ConfirmBoxComponent } from './confirm-box/confirm-box.component';
import { ConfirmBoxService } from './confirm-box/confirm-box.service';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { timeout } from 'rxjs/operators';
import { PulseTransPipe } from './pulse-trans.pipe';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';
import { SaveAlertComponent } from './save-alert/save-alert.component';
import { SaveAlertService } from './save-alert/save-alert.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { UaenamePipe } from './uaename.pipe';
import { ProgressComponent } from './progress/progress.component';
import { DndDirective } from './dnd.directive';
import { FilterAllPipe } from './filter-all.pipe';
import { YearPickComponent } from './year-pick/year-pick.component';











FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin
]);



@NgModule({
  declarations: [
    AppComponent,
    FormDialog,
    CardsComponent,
    ThreeDataCardComponent,
    UserDetailsComponent,
    ClinicDataComponent,
    CareProgramComponent,
    PatientsColumnComponent,
    CarePlanComponent,
    ActivityFeedComponent,
    LoginComponent,
    RegisterComponent,
    GraphRowComponent,
    PatientProfilePageComponent,
    AddDataReading,
    ConfirmationDialogComponent,
    FilterPipe,
    FilterPipeSecond,
    SettingsComponent,
    SignUpComponent,
    MailVerificationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    FilterDialog,
    RelationshipDialog,
    SendRequest, 
    DialogOpen,
    DialogOpenCard,
    MenuCompComponent,
    MenuComponent,
    LogoutPageComponent,
    AddDeviceComponent,
    AddDeviceDialog,
    HealthInfoComponent,
    ProfileComponent,
    MedicalConditionDialog,
    CareTeamComponent,
    ServiceProviderComponent,
    CalenderComponent,
    AddPlanComponent,
    CreateNewPlanComponent,
    FormDialog,
    EditCarePlanComponent,
    FormEditDialog,
    ChatComponent,
    ViewCarePlanComponent,
    AddOnsComponent,
    FilterMenuDialog,
    RelationshipMenuDialog,
    SendMenuRequest,
    RelationshipCareDialog,
    SendCareRequest,
    SurveyComponent,
    CarePlanDashComponent,
    BmiComponent , 
    CareRelationshipDialog,
    CareSendRequest ,
    HomepageComponent,
    DocumentsComponent,
    ConnectionsComponent,
    DialogAddReminder,
    ConnectionComponent,
    ActivityDashComponent,
    RemindersComponent,
    NotificationComponent,
    DialogAddDocument,
    DialogViewDocument,
    SearchPipe,
    DialogEditDocument,
    AddEntity,
    ShareDocument,
    SharedWithDocument, 
    DialogViewConnections, DoctypePipe,
    DialogAddCountry,
    EditFieldDialog,
    GraphOpenDialog,
    SafeurlPipe,
    ExerciseDialog,
    AddDietReading,
    AddExerciseReading,
    DietDetailsDialog,
    ExerciseDetailsDialog,
    ConfirmBoxComponent,
    PulseTransPipe,
    AuditTrailComponent,
    SaveAlertComponent,
    UaenamePipe,
    ProgressComponent,
    DndDirective,
    DragDrop,
    FilterAllPipe,
    YearPickComponent,
    ConfirmationDialogComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatButtonModule,
    MatAutocompleteModule, 
    MatChipsModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    MatStepperModule,
    MatInputModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
     ChartsModule,
    // WavesModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    ReactiveFormsModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatListModule,
    MatDividerModule,
    NgxPaginationModule,

    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass:'toast-bottom-center',
      maxOpened:4,
      onActivateTick:true,
      preventDuplicates:true
    }),
  
    // ToastrModule.forRoot({
    //   timeOut: 3000,
    //   positionClass: 'toast-bottom-center',
    //   maxOpened: 4,
    //   onActivateTick: true,
    //   preventDuplicates: true
    // }),
    BlockUIModule.forRoot(),
    MatToolbarModule,
    MatFormFieldModule,
    MatTableModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    FullCalendarModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    
    A11yModule,
    MatProgressBarModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    OverlayModule,
    MatProgressSpinnerModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  
  
    // ServiceWorkerModul.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),

  ],
  entryComponents: [
    ConfirmationDialogComponent,DialogAddDocument,DialogViewDocument,DialogEditDocument,
      FormDialog,
      CardsComponent,
      ThreeDataCardComponent,
      UserDetailsComponent,
      ClinicDataComponent,
      CareProgramComponent,
      PatientsColumnComponent,
      CarePlanComponent,
      ActivityFeedComponent,
      LoginComponent,
      RegisterComponent,
      GraphRowComponent,
      PatientProfilePageComponent,
      AddDataReading,
      ConfirmationDialogComponent,
      SettingsComponent,
      SignUpComponent,
      MailVerificationComponent,
      ForgotPasswordComponent,
      ResetPasswordComponent,
      RelationshipDialog,
      SendRequest,
      DialogOpen,
      DialogOpenCard,
      MenuCompComponent,
      MenuComponent,
      LogoutPageComponent,
      AddDeviceComponent,
      AddDeviceDialog,
      HealthInfoComponent,
      ProfileComponent,
      MedicalConditionDialog,
      CareTeamComponent,
      ServiceProviderComponent,
      CalenderComponent,
      AddPlanComponent,
      CreateNewPlanComponent,
      FormDialog,
      EditCarePlanComponent,
      FormEditDialog,
      ChatComponent,
      ViewCarePlanComponent,
      AddOnsComponent,
      FilterMenuDialog,
      RelationshipMenuDialog,
      SendMenuRequest,
      RelationshipCareDialog,
      SendCareRequest,
      SurveyComponent,
      CarePlanDashComponent,
      BmiComponent,
      CareRelationshipDialog,
      CareSendRequest,
      HomepageComponent,
      DocumentsComponent,
      ConnectionsComponent,
      DialogAddReminder,
      ConnectionComponent,
      ActivityDashComponent,
      RemindersComponent,
      NotificationComponent,
      DialogAddDocument,
      DialogViewDocument,
      DialogEditDocument,
      AddEntity,
      ShareDocument,
      SharedWithDocument,
      DialogViewConnections,
      DialogAddCountry,
      EditFieldDialog,
      GraphOpenDialog,
      ExerciseDialog,
      AddDietReading,
      AddExerciseReading,
      DietDetailsDialog,
      ExerciseDetailsDialog,
      ConfirmBoxComponent,
      AuditTrailComponent,
      SaveAlertComponent,
      ProgressComponent,
      DragDrop,
      YearPickComponent,
      ConfirmationDialogComponent
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ ConfirmBoxService, DatePipe,SaveAlertService,
    {provide: HTTP_INTERCEPTORS, useClass :AuthInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
 
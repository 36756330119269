<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
            
        <div class="close-btn">
          <button (click)="close_dialog()" mat-dialog-close>X</button>
        </div>
        <h1 mat-dialog-title>Exercise Readings</h1>
        <div  mat-dialog-content>
          <div *ngFor="let item of grouped">
            <div class="ent">
              <mat-card>{{item.name}}</mat-card>
          </div>
          <div class="med-condition" *ngFor="let i of item.groupItem">
            <mat-card *ngIf="i.distance!=0" >{{i.distance | number: '1.0-0'}}&nbsp;{{i.distanceUnit}}
              <div class="right-icon">
                <button (click)="delete_reading(i.id)" >
                <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-card>
            <mat-card *ngIf="i.distance==0" >{{i.weight | number: '1.0-0'}}&nbsp;{{i.weightUnit}}&nbsp;{{i.numberOfStep}}&nbsp;Sets&nbsp;{{i.repetition}}&nbsp;Reps
              <div class="right-icon">
                <button  (click)="delete_reading(i.id)">
                <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-card>
          </div>
          
          </div>

        </div>
       
        <div  *ngIf="exe_list?.length == 0" class="Alert">
          No Readings found!!

        </div>
         
          

        </div>
    </div>
</div>

<div class="">
    <app-menu-comp></app-menu-comp>
    
        <div class="card-css main_container">
            <p class="heading">Activity Tracking</p>
            <div class="activity-plan-cnt cnt">
                
                <div class="">
                    <div class="">
                        <mat-card class="activity-feed-card">
                            
                            <div class="cntr">
                                <div class="activity-feed">
                                    <p class="update-time">Last Update: {{last?.activityDateTime}}</p>
                                </div>
                                <div class="activity-tracking">
                                    <!-- <h3>Activity Tracking</h3> -->
                                    <div  *ngFor="let item of groupArr">
                                        <div class="ent">
                                            <mat-card>{{item?.activityDateTime.split(",")[0]}}</mat-card>
                                        </div>
                                
                                        <mat-vertical-stepper  class="activity-filter" #stepper>
                                            
                                            <mat-step *ngFor="let value of item.groupItem" state="edit" #step>
                                                <ng-template matStepperIcon="edit" let-index="index">
                                                    <i class="fa fa-exclamation" style="color:red" *ngIf="(item.groupItem[index].status !='COMPLETED') && item.groupItem[index].canChangeStatus== true"></i>
                                                    <mat-icon *ngIf="item.groupItem[index].canChangeStatus == false || item.groupItem[index].status == 'COMPLETED'">done</mat-icon>
                                                </ng-template>
                                                
                                                
                                                <ng-template matStepLabel>{{value?.activityDateTime.split(",")[1]}}&nbsp;<p>{{value.description}}</p></ng-template>
                                                <div>
                                                    
                                                    <div *ngIf= "(value.status !='COMPLETED') && value.canChangeStatus== true" class="update-buttons">
                                                        
                                                        
                                                         <div class="date-time">
                                                            <div class="input-group">
                                                            
                                                                <mat-form-field class="example-full-width" appearance="fill">
                                                                <mat-label>Manual Entry</mat-label>

                                                                <input readonly matInput #datetime [min]="maxDate" [owlDateTime]="dt2" placeholder="Manual Entry" [owlDateTimeTrigger]="dt2"/>
                                                                
                                                                <owl-date-time #dt2></owl-date-time> 
                                                                </mat-form-field>
    
                                                            
                                                            </div>
                                                            
                                                         </div>
                                                        
                                                         
                                                        <button (click)='OnClickDone(value.id, datetime.value )'class="tick-mark"  mat-mini-fab matStepperNext><mat-icon>done</mat-icon></button>
                                                    </div> 
                                                
                                                </div>
                                                
                                            </mat-step>    
                                            
                                                
                                                
                                                    
                                        </mat-vertical-stepper>
                                    </div>
                                    
                                </div>
                            
                               
                            </div>
                            
                        </mat-card>
                       
                    </div>
                </div>
            </div>
            
    </div>

</div>
<block-ui></block-ui>
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { HttpResponse } from '@angular/common/http';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  name = new FormControl('');
  submitted = false;

  message: string = '';
  subscription: any;

  constructor(private toastr: ToastrService, private data: DataService , private formBuilder: FormBuilder, private router: Router, private authService: AuthServiceService) { }

  myForm = this.formBuilder.group({
		email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]]
	})

  get f() { return this.myForm.controls; }

  ngOnInit(): void {
    this.subscription = this.data.cofferIdMessage.subscribe(message => {
      this.message = message;
    });
    // //console.log("here is the msg on forgot password page - ", this.message);
  }

  onSubmit(email: string){
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    
    this.authService.forgot_password(email).subscribe((res: HttpResponse<any>) => {
      if(!res.body.error){
        this.router.navigate(['/reset-password']);
        this.data.changeCofferId(res.body.coffer_id);
        this.toastr.success(res.body.msg, 'Success!');
      } else {
        this.toastr.error(res.body.msg, 'Error!');
      }
      
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

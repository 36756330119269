import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { CalendarOptions } from '@fullcalendar/angular';
import { Utils } from './utils';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // isLoggedIn = false;
  // animal: any;
  // name: any;
 

  constructor() {}

  

  ngOnInit(){
    
    //this.isLoggedIn = Utils.isLoggedIn;

  }

  
  title = 'vitacape-consumer-web-component';

  
  
}


 
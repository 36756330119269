<div class="modal-content">
  <div class="modal-header">
    <h1 class="modal-title" id="exampleModalLabel">{{ title }}</h1>
    <button type="button" class="close" (click)="dismiss()" aria-label="Close">x
    </button>
   
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
  </div>
</div>
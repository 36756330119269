<div class="container">
    <div class=" card-dialog row justify-content-center">
        <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()" >X</button>
        </div>
        <h1 mat-dialog-title>Add Reminder</h1>
            <div>
                <form class="form-dialog" [formGroup]="myForm" class="">
            
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Message</mat-label>
                        <textarea matInput #message required formControlName='message' name="message" placeholder="Enter your message"[ngClass]="{ 'is-invalid': submitted && f.message.errors }" ></textarea>
                        <mat-error *ngIf="submitted && f.message.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    <!-- <div *ngIf="submitted && f.message.errors " class="text-danger">
                        <div *ngIf="f.message.errors.required">This field is required</div>
                    </div> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Target Date</mat-label>
                        <input required readonly formControlName='target' #target matInput [min]="maxDate" [matDatepicker]="dp3"  [ngClass]="{ 'is-invalid': submitted && f.target.errors }">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                        <mat-error *ngIf="f.target.errors?.required && submitted">This field is required
                        </mat-error>

                      </mat-form-field>
                      
                      <div class="save-button">
                        <button (click)=" add_reminder(message.value , target.value  )">Save</button> 
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<block-ui></block-ui>
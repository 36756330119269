
 
    <div class="whole-card" title="BMI Status">
        <mat-card style="background-color: #28293d" class="card-css example-card">
            <!-- <div class="plus-icon">
                <button (click)="openDialog()" (click)="$event.stopPropagation()" title="Add Reading">
                    <i class="fas fa-plus"></i>
                </button> 
            </div> -->
            <div class="container">
                <div class=" row">
                  <div  class="col-6">
                    <div class="heart-img">
                        <!-- <img src="{{card_image}}"  alt=""> -->
                        
                        <i style="color:rgb(247, 91, 91)" class='fas fa-walking'></i>
                        <i class="{{card_image}}" [style]="style"></i>
                    </div>
                    <p>BMI</p>
                    <h2>{{bmi}}</h2>
                    <p class="time">Healthy Range: 18.5 to 24.9</p>
                    <!-- <p class="time">Date: {{ card_details.readingDateTime }}</p> -->
                  </div>
                  <div class="col-6">
                      <p>Weight</p>
                      <h2>{{ card_details?.weight }} <span>{{ card_details?.weigthtUnit }}</span></h2>
                      <p class="time">{{card_details?.medium_value1}}  {{card_details?.high_value1}}</p>
                      <hr class="solid">
                      <p>Height</p>
                      <h2 *ngIf="card_details?.heightUnit!='feet'">{{ card_details?.height }} <span>{{ card_details?.heightUnit }}</span></h2>
                      <h2 *ngIf="card_details?.heightUnit=='feet'">{{ card_details?.height.split('.',1)[0] }}' {{ card_details?.height.split('.')[1] }}"</h2>
                      <p class="time">{{card_details?.medium_value2}}  {{card_details?.high_value2}}</p>
                  </div>
                  <!-- <div class="w-100"></div> -->
                </div>
            </div>
        </mat-card>
    
     </div>
       

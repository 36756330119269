import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-data',
  templateUrl: './clinic-data.component.html',
  styleUrls: ['./clinic-data.component.scss']
})
export class ClinicDataComponent implements OnInit {

  @Input() clinical_data_details: any;

  

  constructor() { }

  ngOnInit(): void {
  }

}

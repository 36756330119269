
  <div class="container cnt-dl" [ngClass]="{'cnt-dl': device_option.systemId == '0x1810'}">
    <div class=" card-dialog row justify-content-center">
      <div class="left col-4">
        <p [ngClass]="{'card-selected' : item.systemId == device_option.systemId}" *ngFor="let item of dv">{{item.title | pulseTrans}}</p>
      </div>
      <div class="right col-8">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
        <form [formGroup]="myForm" >
          <div class="first-row inp">
            <div class="device-input">
              <p>{{device_option.unit.title[0] | pulseTrans}}<span> . {{device_option.unit.unit[0]}}</span></p>
              <div class="signForm-row " id="first_reading">
                <input pattern="^[0-9]*$" #firstReading formControlName='firstReading' name="firstReading" class="form-control" id='id_first_reading' type="text" cdkFocusInitial  maxlength="3"/>
                <div *ngIf="f['firstReading'].errors" class="text-danger">
                  <div *ngIf="f['firstReading'].errors.required && (f['firstReading'].touched || submitted) ">This field is required</div>
                  <div *ngIf="f['firstReading'].errors.min">Enter value greater than 10</div>
                  <div *ngIf="f['firstReading'].errors.pattern">Please enter numeric value</div>
                </div>
              </div>
            </div>
          </div>    
          <div class="second-row">
            <div [hidden]="!(device_option && device_option.data_type == 'three')" class="device-input">
            <p >{{device_option.unit.title[1]}}<span> . {{device_option.unit.unit[1]}}</span></p> 
            <div class="signForm-row " id="second_reading">
              <input pattern="^[0-9]*$" #secondReading formControlName='secondReading' name="secondReading" class="form-control" id='id_second_reading' type="text"   maxlength="3"/>
              <div *ngIf="f['secondReading'].errors" class="text-danger">
                <div *ngIf="f['secondReading'].errors.required && (f['secondReading'].touched || submitted)">This field is required</div>
                <div *ngIf="f['secondReading'].errors.min">Enter value greater than 10</div>
                <div *ngIf="f['secondReading'].errors.pattern">Please enter numeric value</div>
              </div>
            </div>
          </div>
          <div [hidden]="!(device_option && device_option.data_type == 'three')" class="device-input">
            <p >{{device_option.unit.title[2]}}<span> .  {{device_option.unit.unit[2]}}</span></p>
            <div class="signForm-row " id="third_reading">
              <input pattern="^[0-9]*$" #thirdReading formControlName='thirdReading' name="thirdReading" class="form-control" id='id_third_reading' type="text"   maxlength="3"/>
              <div *ngIf="f['thirdReading'].errors" class="text-danger">
                <div *ngIf="f['thirdReading'].errors.required && (f['thirdReading'].touched || submitted)">This field is required</div>
                <div *ngIf="f['thirdReading'].errors.min">Enter value greater than 10</div>
                <div *ngIf="f['thirdReading'].errors.pattern">Please enter numeric value</div>
              </div>
            </div>
          </div>
          
        </div>
        <mat-chip-list *ngIf="tag_list != null" class="tag-section">
          <mat-chip (click)="tag_btn(tag.id);tmpindex=i;"  [selected]="i===tmpindex" *ngFor="let tag of tag_list; index as i" >{{tag.description}}</mat-chip>
          <!-- <div *ngIf="tag_id == 0" class="text-danger">
            <div>Please Select Tag</div>
          </div> -->
        </mat-chip-list>
        
        


          <!-- <div *ngIf="tag_list != null" class="tag-section">
            <button (click)="tag_btn(tag.id)" *ngFor="let tag of tag_list">{{tag.description}}</button>
          </div> -->
          <div >
            <div class="date-time-select">
              <mat-form-field class="calendar" appearance="fill">
                <mat-label>Choose a date</mat-label>
                <!-- <input readonly [formControl]="dateSelected"  matInput [matDatepicker]="dp3" > -->
                <input [max]="maxDate" required readonly #dateSelected [value]="date1.value"  matInput [matDatepicker]="dp3" >
                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                <mat-datepicker #dp3 ></mat-datepicker>
              </mat-form-field>
            </div>
  
            <div class="time-select">
              <mat-form-field class="set-time" appearance="fill">
                <mat-label>HH</mat-label>
                <mat-select required [(value)]="hour_value">
                  <mat-option *ngFor="let item of counter(13);let i= index" value="{{i}}">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="set-time" appearance="fill">
                <mat-label>MM</mat-label>
                <mat-select required [(value)]="minute_value">
                  <mat-option *ngFor="let item of counter(60); index as i" value="{{i}}">{{i}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="set-time" appearance="fill">
                <mat-label>AM/PM</mat-label>
                <mat-select required [(value)]="am_pm">
                  <mat-option *ngFor="let item of ampm_list" value="{{item}}">{{item}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="update">
            <p>{{last_updated}}</p>
          </div>
          <div class="save-button">
            <button  [disabled]="dis_btn" (click)="save_data(firstReading.value, secondReading.value, thirdReading.value, dateSelected.value, hour_value, minute_value, am_pm, device_option.unit.unit[0], device_option.unit.unit[1], device_option.unit.unit[2])">Save</button>
          </div>
        </form>
      </div>
      
    </div>  
  </div>
  <block-ui></block-ui>
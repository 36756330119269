import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import { MatAccordion } from '@angular/material/expansion';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogData } from '../app.component';
import { RelationshipCareDialog } from '../care-team/care-team.component';
import { RelationshipMenuDialog } from '../service-provider/service-provider.component';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { SaveAlertService } from '../save-alert/save-alert.service';





@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI; 
  @ViewChild(MatAccordion)
  accordion!: MatAccordion;
  menu_list:any[]=[];
  selected_tag="Key_contacts";
  list:any
  isClassExpand = false;
  expandclick = false;
  searchText='';
  searchTextSecond: any;
  
  searchbar = 'searchbar';
  relationship_list_data: any;
  entity_list: any;
  doc_list:any;
  type_con="";
 url ="";
 status:any;
share_id:any;
allowedFileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','application/rtf','text/csv','text/rtf'];

  constructor(public auth:AuthServiceService , public toastr:ToastrService,public _communicationService:CommunicationService , public dialog:MatDialog , public toast:ToastrService,public sanitizer:DomSanitizer , private box :ConfirmBoxService) { }
   
  openDialog(status,index) {
    
    this.dialog.open(AddEntity, {
      data:[status,index]
      
    });
  }
  openAddDialog(){
    this.dialog.open(AddEntity, {
      
    });

  }



  ShareDialog(id){
    this.dialog.open(ShareDocument, {
      data:id
      
    });

  }
  Sharewith(id,status){
    this.dialog.open(SharedWithDocument,{
      data:[id,status]
    
    })
  }
  ngOnInit(): void {
    this.start_loading()
    this.auth.service_provider_list().subscribe((res: any) => {
      if(res.data?.relationships.length == 0){
        this.openRelationshipCareDialog()

      }

     
       
     })
    this.auth.get_entity_dummy().subscribe((res:any)=>{
      
      this.entity_list=res.relationships;


    })
    this.update_tags()
  
  
    this.auth.get_relationship_bytag(this.selected_tag).subscribe((res:any)=>{
      if(res.error==false){
        this.list=res.data.relationships
       
     

      }

    })

   this.auth.relationship_list().subscribe((res:any)=>{
    this.relationship_list_data = res
   })
   this.stop_loading()

   this._communicationService.changeEmitted$.subscribe(data=>{
     if(data){
       this.entity_list = data.relationships
       this.update_tags()
     }

   })
  }
  update_tags(){
    this.start_loading()
    this.menu_list.splice(0,this.menu_list.length)
    this.menu_list = [
     {
         "tagName": "Key_contacts",
         "count": 0
     },
     {
         "tagName": "Personal",
         "count": 0
     },
     {
         "tagName": "Lauditor",
         "count": 0
     },
     {
         "tagName": "ContentCoffer",
         "count": 0
     }
 ]
 this.auth.get_relationship_tag_count().subscribe((res:any)=>{
  if(res.error==false){


  let count = res.counts

  this.auth.get_entity_dummy().subscribe((res:any)=>{
    if(res.error==false){
      this.menu_list.forEach(item=>{
        if(item.tagName=="Key_contacts"){
          item.count = res.relationships?.length;
        }
      })
    

    }
    
  })

  this.menu_list.forEach(item=>{
    res.counts.forEach(element=>{
      if(item.tagName == element.tagName){
        item.count = element.count
      } 
    })
  })
 
  
  
}
})
   
    // this.auth.get_entity_dummy().subscribe((res:any)=>{
      
    //   this.entity_list=res.relationships;
     

    // })
    // this.menu_list.splice(0,this.menu_list.length)
    // this.auth.get_relationship_tag_count().subscribe((res:any)=>{
    //   if(res.error==false){
      
  
    //     this.menu_list.unshift({"tagName": "Key_contacts","count": this.entity_list?.length})
    //     this.menu_list.splice(1,0,{"tagName": "Personal","count": '0'})
    //     this.menu_list.splice(2,0,{"tagName": "Lauditor","count": '0'})
    //     this.menu_list.splice(3,0,{"tagName": "ContentCoffer","count": '0'})
        
      
      
    //     res.counts.forEach(element => {
    //       if(element.tagName=="Personal"){
  
    //         this.menu_list[1].count = element.count
  
    //       } else if(element.tagName=="Lauditor"){
    //         this.menu_list[2].count = element.count
    //       } else if(element.tagName=="ContentCoffer"){
    //         this.menu_list[3].count = element.count
            
    //       } 
    //     });
        
       
     
    

      
    //   }
    // })
   this.stop_loading()

     
  }

  openRelationshipCareDialog() {
    this.dialog.open(RelationshipCareDialog, {
      data: [Object.assign({}, this.relationship_list_data), Object.assign({}, this.relationship_list_data)]
    }).afterClosed().subscribe(res=>{
      this.auth.get_relationship_bytag(this.selected_tag).subscribe((res:any)=>{
        if(res.error==false){
          this.list=res.data.relationships
          this.update_tags()
  
        }
      })
    });
    
  }
  openRelationshipCareUserDialog() {
    this.dialog.open(RelationshipCareDialog, {
      data: [Object.assign({}, this.relationship_list_data), Object.assign({}, this.relationship_list_data)]
    })
    
  }
  openBizCareDialog() {
    this.dialog.open(RelationshipMenuDialog, {
      data: [Object.assign({}, this.relationship_list_data), Object.assign({}, this.relationship_list_data)]
    });
    
  }
  
  
  open_bottom_dialog($event){
    // this.dialog.open(BottomDialog);
    $event.stopPropagation();
    this.isClassExpand = !this.isClassExpand;
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isClassExpand= false;
  }

  @HostListener('document:click', ['$event'])

  clickout() {
   this.isClassExpand = false;
  }
  expand() {
   this.expandclick = true
  }
  start_loading(){
    this.blockUI.start()
  }
  stop_loading(){
    this.blockUI.stop()
  }
  OnClicked(name){
   
    this.selected_tag = name
    this.start_loading()
   

    this.auth.get_relationship_bytag(name).subscribe((res:any)=>{
      if(res.error==false){
        this.list=res.data.relationships
        

      }
    })
    this.stop_loading()
  
   

  }
  delete_entity(id){
    this.box.confirm("alert","Are you sure you want to delete this?").then((res)=>{
      if(res){
        this.auth.delete_entity_dummy(id).subscribe((res:any)=>{
          if(res.error==false){
            this.toastr.success(res.msg)
            this.auth.get_entity_dummy().subscribe((res:any)=>{
              if(res.error==false){
                this._communicationService.emitChange(res)
    

              }
              
            })
    
          } 

        })

      }
    })

 
  }
  getFilterValue(item) {
    var name = item.business_name
    return name.toLowerCase()
  }


  share_doc_list(status,id){
    this.status = status
    this.share_id = id
    if(status=='with'){
      this.auth.shared_with_me(id).subscribe((res:any)=>{
        if(res.error==false){
          this.doc_list = res.data
        } else {
          this.toast.error(res.msg)
  
        }
      })
  
    } else if(status=='by'){
      this.auth.shared_by_me(id).subscribe((res:any)=>{
        if(res.error==false){
          this.doc_list = res.data
        } else {
          this.toast.error(res.msg)
  
        }
      })
    }

  }
  download_credential(type , index_country){
    this.auth.download_credentials(index_country,type).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.data.url, '_blank');
      } else {
        this.toast.error(res.msg)
      }
      
    })
  }

  clear_url()
  {
    this.url = ""
    this.type_con = ""
  }

  download_personal(id , index_country){
    this.auth.download_personal(id,index_country).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.data.url,'_blank');
      } else {
        this.toast.error(res.msg)
      }

    })
  }
  view_doc_identity(url , type,data){
    // this.url = url
    this.type_con = type
    console.log(this.type_con)
    if(this.type_con=='image'){
      this.url = url
    } else {
      this.doctopdfconvertor(url,data.content_type)
    }
   
    // this.auth.view_docs(index_country,type).subscribe((res:any)=>{
    //   if(res.error==false){
    //     this.url = res.data.url
    //   }
    // })
  }

  view_doc_personal(url , type,data){
    // this.url = url
    this.type_con = type
    if(this.type_con=='image'){
      this.url = url
      console.log(this.url)
    } else {
      this.doctopdfconvertor(url,data.content_type)
    }  
    // this.auth.view_personal_doc(index_country,id).subscribe((res:any)=>{
    //   if(res.error==false){
    //     this.url = res.data.url
    //   }
    // })

  }
  view_doc_gen(id){
  
    this.auth.view_general_doc(this.share_id,id).subscribe((res:any)=>{
      if(res.error==false){
        this.url = res.url
      }
    })


  }
  copy_element(url){
    
  }
  shared_with_me(docid,action,type ,url,data?){
    
      
      this.type_con = type
      // this.url = url
     if(action=="copy"){

    
    //  navigator.clipboard.writeText(url).then(()=>{
    //    this.toast.info("Document copied successfully")
    //  }).catch(()=>{
    //    this.toast.error("ERROR")
    //  })
    
    this.auth.share_with_me_action(this.share_id,docid,action).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.info(res.msg)
      } else {
        this.toast.error(res.msg)
      }
    })
    

    } else if(action=="download"){
      window.open(url,'_blank');
    }
  

  }

  shared_with_me_view(docid,action,type ,url,data?){
    this.type_con = type
      
      // this.url = url
      if(data.doctype=='general'&& data?.added_encryption ){
        // body.append('uid',this.profileInfo.uid)
        
          this.auth.decrypt(docid).subscribe((res:any)=>{
            const blob = new Blob([res], { type: data.content_type });
            if(this.allowedFileTypes.includes(data.content_type)){
                let fdata = new FormData();
                fdata.append('file', blob);
                this.auth.doc2pdf(fdata).subscribe((ans:any)=>{
                    const ansBlob = new Blob([ans], { type: 'application/pdf' });
                    const ansUrl = URL.createObjectURL(ansBlob);
                    this.url = ansUrl
                   
                })

            } 
            const urlobj = URL.createObjectURL(blob);
            this.url = urlobj
          
        })
          
      }else {

          this.doctopdfconvertor(url,data.content_type)
      
      } 

          // this.auth.share_with_me_action(this.share_id,docid,action).subscribe((res:any)=>{
          //   if(res.error==false){
          //     this.doctopdfconvertor(res.url,data.content_type)
          //   }
          // })
    
  }


  doctopdfconvertor(url,content_type){

    if(this.allowedFileTypes.includes(content_type)){
      let fdata = {'url':url}
      this.auth.doc2pdfurl(fdata).subscribe((ans:any)=>{
          const ansBlob = new Blob([ans], { type: 'application/pdf' });
          const ansUrl = URL.createObjectURL(ansBlob);
          this.url = ansUrl
         
      })
  }  else{
    this.url = url
  }


  }
  download_doc_gen(id){
    
    this.auth.download_general_doc(this.share_id,id).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.url,'_blank');
      } else {
        this.toast.error(res.error.msg)
      }
    })

  }



  acceptRequest(id , biztype){
    if(biztype=="consumer"){
      this.auth.care_team_accept_request(id).subscribe((res:any)=>{
    
        if(res.error && typeof(res.msg) == "string"){
          this.toastr.info(res.msg);
  
        } else if(!res.error) {
          this.toastr.success(res.msg);
          this.auth.get_relationship_bytag(this.selected_tag).subscribe((res:any)=>{
            if(res.error==false){
              this.list=res.data.relationships
              
      
            }
          })
         
          
          // this.auth.service_provider_list().subscribe((res:any)=>{
          //   this.list = res.data.relationships
           
          // })
   
        }
        else if(res.msg.description) {
          this.toastr.info(res.msg.description);
        }
         
      })

    } else if(biztype=="prof"){
      this.auth.service_provider_accept(id).subscribe((res:any)=>{
       
        if(res.error && typeof(res.msg) == "string"){
          this.toastr.info(res.msg);
  
        } else if(!res.error) {
          this.toastr.success(res.msg);
          this.auth.get_relationship_bytag(this.selected_tag).subscribe((res:any)=>{
            if(res.error==false){
              this.list=res.data.relationships
              
      
            }
          })
         

          
          
   
        }
        else if(res.msg.description) {
          this.toastr.info(res.msg.description);
        }
         
      })

    }

   

  }

}

@Component({
  selector: 'entity-connections',
  templateUrl: './add-entity.html',
  styleUrls: ['./connections.component.scss']
})

export class AddEntity  {
  @BlockUI()
  blockUI!: NgBlockUI; 
  submitted = false
  status:any;
  items:any;


  constructor(
    public dialogRef: MatDialogRef<AddEntity>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService ,  public _communicationService:CommunicationService , private save:SaveAlertService
  ) {
    dialogRef.disableClose = true
    this.status = data[0];
    this.items = data[1];
  }
  
  myForm = this.formBuilder.group({
  })


  ngOnInit(){
    this.myForm = this.formBuilder.group({
      name:['',Validators.required],
      address:['',Validators.required],
      id:['',Validators.required],
      id_no:[''],
      others:['']
     });
  }


   get f() {
     return this.myForm.controls;
   }
 start_loading(){
  this.blockUI.start()
 }
 stop_loading(){
  this.blockUI.stop()
 }

  add_entity(name,address,id,id_no,others){
  
    this.submitted = true
    if(this.myForm.invalid){
      return
    }
    this.start_loading()
 
   let payload = {"firm_name": name, "firm_address": address, "account_id": id, "biz_identification_no": id_no, "others": others}
    this.auth.add_entity_dummy(payload).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg)
        this.auth.get_entity_dummy().subscribe((res:any)=>{
          if(res.error==false){
            this.stop_loading()
            this._communicationService.emitChange(res)
            this.close_dialog()
          }
        })
      } else {
        this.stop_loading()
        this.toast.error(res.msg)
      }
    })
   
  }

  edit_entity(name,address,id,id_no,others){
 
    this.submitted = true
    if(this.myForm.invalid){
      return
    }
    this.start_loading()
   let payload = {"firm_name": name, "firm_address": address, "account_id": id, "biz_identification_no": id_no, "others": others}
    this.auth.edit_entity_dummy(this.items.id ,payload).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg)
        this.auth.get_entity_dummy().subscribe((res:any)=>{
          if(res.error==false){
            this.stop_loading()
            this._communicationService.emitChange(res)
            this.close_dialog()
          }
        })
      } else {
        this.stop_loading()
        this.toast.error(res.msg)
      }
    })
   
  }

  close_dialog(){
    if(this.submitted==false && this.myForm.dirty){
      this.save.confirm("Alert").then((res)=>{
        if(res){
          this.dialogRef.close()
        }
      })
      

    } else {
      this.dialogRef.close()
    }
    
  }

}

@Component({
  selector: 'app-connections',
  templateUrl: './share-files-dialog.html',
  styleUrls: ['./connections.component.scss']
})

export class ShareDocument {

  relation_id:any;
  menu_list = [
    {
        "tagName": "Identity",
        "count": 0
    },
    {
        "tagName": "Health",
        "count": 0
    },
    {
        "tagName": "LegalFinance",
        "count": 0
    },
    {
        "tagName": "Personal",
        "count": 0
    },
    {
        "tagName": "Others",
        "count": 0
    }
]
  selected:any;
  countries:any;
  selected_country:any;
  searchbar = 'searchbar';
  clickedname="Identity";
  index_country = "citizen_primary";
  list: any;
  credential_len: any;
  option:any;
  datai:any[]=[];
  pay_load:any;
  searchText='';
  selected_tag="Identity";
  submitted = false;
  
  constructor(
    public dialogRef: MatDialogRef<ShareDocument>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService ,  public _communication:CommunicationService
  ) {dialogRef.disableClose = true
    this.relation_id = data
  }
  myForm = this.formBuilder.group({})

  ngOnInit(): void {
    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
        this.countries = res.data
        for (let i = 0; i < this.countries.length; i++) {

          if(this.countries[i].index == "citizen_primary"){
            this.selected = this.countries[i].index
            this.selected_country = this.countries[i].country


          }
        }
       

        
     

        
        
      }

    })
    if(this.clickedname=="Identity"){

      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        if(res.error==false){
          this.list = res.data
          //this.menu_list.unshift( {"tagName": "Credentials","count": res.data.length })
          this.credential_len = res.data.length
          
        }
        
      })

    } 
    this.auth.get_tag_counts(this.index_country).subscribe((res:any)=>{
      if(res.error==false){
  
  
    
    
      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        if(res.error==false){
          this.menu_list.forEach(item=>{
            if(item.tagName=="Identity"){
              item.count = res.data.length
            }
          })
        
      //     this.menu_list.unshift({"tagName": "Identity","count": crede_len})
      // this.menu_list.splice(1,0,{"tagName": "Health","count": '0'})
      // this.menu_list.splice(2,0,{"tagName": "LegalFinance","count": '0'})
      // this.menu_list.splice(3,0,{"tagName": "Personal","count": '0'})
      // this.menu_list.splice(4,0,{"tagName": "Others","count": '0'})
      // //console.log(this.menu_list)
        }
        
      })

      this.menu_list.forEach(item=>{
        res.counts.forEach(element=>{
          if(item.tagName == element.tagName){
            item.count = element.count
          }
        })
      })
     
      
    
    
      // res.counts.forEach(element => {
      //   if(element.tagName=="Health"){

      //     this.menu_list[1].count = element.count

      //   } else if(element.tagName=="LegalFinance"){
      //     this.menu_list[2].count = element.count
      //   }  else if(element.tagName=="Personal"){
      //     this.menu_list[3].count = element.count
          
      //   } else if(element.tagName=="Others"){
      //     this.menu_list[4].count = element.count
          
      //   } 
      // });
      
    }
    })
    
    
 

  }

  update_tags(){
  
   this.menu_list.splice(0,this.menu_list.length)
   this.menu_list = [
    {
        "tagName": "Identity",
        "count": 0
    },
    {
        "tagName": "Health",
        "count": 0
    },
    {
        "tagName": "LegalFinance",
        "count": 0
    },
    {
        "tagName": "Personal",
        "count": 0
    },
    {
        "tagName": "Others",
        "count": 0
    }
]
    // this.auth.get_tag_counts(this.index_country).subscribe((res:any)=>{
    //   if(res.error==false){
  
      
    //     this.count = res.counts
    //     //console.log(this.count)
    //     this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
    //       // if(res.error==false){
    //       //   var Identity_len = res.data.length
    //       //   this.menu_list.unshift({"tagName": "Identity","count": Identity_len})
    //       //   this.menu_list.splice(1,0,{"tagName": "Health","count": '0'})
    //       //   this.menu_list.splice(2,0,{"tagName": "LegalFinance","count": '0'})
    //       //   this.menu_list.splice(3,0,{"tagName": "Personal","count": '0'})
    //       //   this.menu_list.splice(4,0,{"tagName": "Others","count": '0'})
    //       // }
    //       debugger
    //       if(res.error==false){
    //         this.menu_list.forEach(item=>{
    //           if(item.tagName=="Identity"){
    //             item.count = res.data.length
    //           }
    //         })
    //       }
    //     })
    //     this.menu_list.forEach(item=>{
    //       res.counts.forEach(element=>{
    //         if(item.tagName == element.tagName){
    //           item.count = element.count
    //         }
    //       })
    //     })
    
      
      
    //     // this.count.forEach(element => {
    //     //   if(element.tagName=="Health"){
  
    //     //     this.menu_list[1].count = element.count
  
    //     //   } else if(element.tagName=="LegalFinance"){
    //     //     this.menu_list[2].count = element.count
    //     //   }  else if(element.tagName=="Personal"){
    //     //     this.menu_list[3].count = element.count
            
    //     //   } else if(element.tagName=="Others"){
    //     //     this.menu_list[4].count = element.count
            
    //     //   } 
    //     // });
        
    //   }
    // })
    // //console.log(this.menu_list)
    this.auth.get_tag_counts(this.index_country).subscribe((res:any)=>{
      if(res.error==false){
  
    
      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        if(res.error==false){
          this.menu_list.forEach(item=>{
            if(item.tagName=="Identity"){
              item.count = res.data.length
            }
          })
        
      //     this.menu_list.unshift({"tagName": "Identity","count": crede_len})
      // this.menu_list.splice(1,0,{"tagName": "Health","count": '0'})
      // this.menu_list.splice(2,0,{"tagName": "LegalFinance","count": '0'})
      // this.menu_list.splice(3,0,{"tagName": "Personal","count": '0'})
      // this.menu_list.splice(4,0,{"tagName": "Others","count": '0'})
      // //console.log(this.menu_list)
        }
        
      })

      this.menu_list.forEach(item=>{
        res.counts.forEach(element=>{
          if(item.tagName == element.tagName){
            item.count = element.count
          } 
        })
      })
    
      
      
    }
    })

     
  }
  selectedn(index , country){
    this.selected = index
    this.selected_country = country
    
    this.index_country = index
   
    
    if(this.clickedname=="Identity"){
      
      this.auth.get_crediential_docs(index).subscribe((res:any)=>{
        
          this.list = res.data
          this.credential_len = res.data.length
       
        
        
      })
  
 
    } else  {
      
    
      this.auth.get_personal_docs_tag(this.selected_tag,index).subscribe((res:any)=>{
      
          this.list = res.data
      
        
      })
    }

    this.update_tags()
   
    //this._tagcomms.emitChange(this.menu_list)
   
    


  }
  OnClicked(name){
    this.selected_tag = name
    
     if(name=="Identity"){
      
      this.auth.get_crediential_docs(this.index_country).subscribe((res:any)=>{
        
          this.list = res.data
          
        
        
      })
      this.clickedname="Identity";
 
    } else  {
      
      this.clickedname="Personal";
      this.auth.get_personal_docs_tag(name,this.index_country).subscribe((res:any)=>{
      
          this.list = res.data
         
        
      })
    }
    
   

  }
  selected_chart(id,doctype,index , event){
    
   
    if(this.clickedname=="Identity"){
      var value_identity = { "doctype" : "identity", "docid" : id }
      if(event){
        this.datai.push(value_identity)
      

      } else if(!event){
        this.datai.splice(this.datai.indexOf(value_identity),1)
      
      }
      

    } else {

      var value_personal = { "doctype" : "personal", "docid" : id }

      if(event){
       
        this.datai.push(value_personal)
      
      } else if(!event){
        this.datai.splice(this.datai.indexOf(value_personal),1)
      }

     
    }
    
   
    //  this.q.forEach(element => {
    //    var value = element.option[index]
    //    if(element.id == id && event){
    //      this.sav.push(value)
    //    }
    //    else if(element.id == id && !event){

    //      this.sav.splice(this.sav.indexOf(value),1)

    //    }
       
    //  });
    //  //console.log(this.sav)
    //  this.repchoice = {"question": id,"choice": this.sav}

  }
  get f(){
     return this.myForm.controls;
  }

  share(){
    this.submitted = true
    if(this.datai.length<=0){
      this.toast.error("Choose Atlest one File!")
      return
    }
  
     this.pay_load = {"add":
       this.datai
     }
    
    
    this.auth.share_realtionship_document(this.relation_id,this.pay_load).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg)

      } else {
        this.toast.error(res.msg)
      }
    })
    this.close_dialog()

  }
  getFilterValuePersonal(item){
    var name = item.name
    return name.toLowerCase()

  }

  getFilterValue(item){
    var name = item.doctype
    return name.toLowerCase()
  }



  

  close_dialog(){
    this.dialogRef.close()
  }



}

@Component({
  selector: 'app-connections',
  templateUrl: './share-dialog.html',
  styleUrls: ['./connections.component.scss']
})

export class SharedWithDocument {

  id:any;
  list:any;
  status:any;
  url:any;

  
  constructor(
    public dialogRef: MatDialogRef<SharedWithDocument>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService ,  public _communication:CommunicationService,public sanitizer:DomSanitizer , public dialog:MatDialog
  ) {
     this.id=data[0]
     this.status=data[1]

  }

  ngOnInit(): void{
    if(this.status=='with'){
      this.auth.shared_with_me(this.id).subscribe((res:any)=>{
        if(res.error==false){
          this.list = res.data
        } else {
          this.toast.error(res.msg)

        }
      })

    } else if(this.status=='by'){
      this.auth.shared_by_me(this.id).subscribe((res:any)=>{
        if(res.error==false){
          this.list = res.data
        } else {
          this.toast.error(res.msg)

        }
      })
    }
   
  }

  close_dialog(){
    this.dialogRef.close()
  }

  
  download_credential(type , index_country){

  
    this.auth.download_credentials(index_country,type).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.data.url, '_blank');
      } else {
        this.toast.error(res.msg)
      }
      
    })
  }

  download_personal(id , index_country){
    this.auth.download_personal(id,index_country).subscribe((res:any)=>{
      if(res.error==false){
        window.open(res.data.url,'_blank');
      }

    })
  }
   view_doc(title,index_country,id){
    this.dialog.open( DialogViewConnections , {
      data:[title,index_country,id]
      
    });

   }
 



}

@Component({
  selector: 'dialog-view-connections',
  templateUrl: 'view-dialog.html',
  styleUrls: ['./connections.component.scss']
})

export class DialogViewConnections {

  url:any;
  name:any;
  category:any;
  title:any;
  constructor(
    public dialogRef: MatDialogRef<DialogViewConnections>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService , public datepipe:DatePipe , public _communication:CommunicationService,private sanitizer: DomSanitizer
  ) {
    this.title=data[0]
    this.category=data[1]
    this.name=data[2]
  }
  ngOnInit(){
   
    if(this.title=="Identity"){
      this.auth.view_docs(this.category,this.name).subscribe((res:any)=>{
      
        if(res.error==false){
          this.url= this.sanitizer.bypassSecurityTrustResourceUrl(res.data.url)
          
        } else {
          this.toast.error(res.msg)
        }
      })

    } else {
      this.auth.view_personal_doc(this.category,this.name).subscribe((res:any)=>{
        if(res.error==false){
          this.url= this.sanitizer.bypassSecurityTrustResourceUrl(res.data.url)
          
        }else {
          this.toast.error(res.msg)
        }
        
      })
    }
   
  }

  close_dialog(){
    this.dialogRef.close();
  }

 
   


}

<mat-card class="user_column">
    <div class="ud container">
        <div class="user-icon">
            <img *ngIf="(! imgurl)" src="../../assets/images/user.png" alt="">
            <img *ngIf="( imgurl)" src={{imgurl}} alt="">
            <p>{{profile_detail?.first_name}}&nbsp;{{ profile_detail?.last_name}}<br>
                <span>{{user_details?.gender}}</span></p>
            <!-- <div class="task-column">
                <div  class="task-box">
                    <div [style]="item" *ngFor="let item of user_details.recent_tasks" class="each-box"></div>
                </div>
                <span>Recent Tasks</span>
            </div> -->
            <div class="rght-box pull-right">
                <div routerLink="/menu/activity-tracking" title="Activity Tracking" class="view-activity">
                    <i class="{{view_activity.icon}}"></i>
                    {{view_activity.title}}
                </div>
                <div routerLink="/update-profile" title="Update Profile" class="update-profile">
                    <i class="{{update_profile.icon}}"></i>
                    {{update_profile.title}}
                </div>  

            </div>
           
            
        </div>
        <div *ngIf="profile_detail" class="details-box">
            <div class="data-box" (click)="openDiet()">
                <p title ="Diet Status"><i style="color:rgb(91, 236, 38);font-size: 1.5rem;" class='fa fa-leaf'></i> <i [title]="status_diet" class="{{card_image_diet}}" [style]="style_diet"></i></p>
            </div>
            <div class="data-box" (click)="openExecrise()">
                <p title ="Exercise Status"><i style="color:rgb(247, 91, 91);font-size: 1.5rem;" class='fas fa-walking'></i> <i [title]="status_exe" class="{{card_image_exe}}" [style]="style_exe"></i></p>
            </div>
           
           
        </div>
    </div>
</mat-card>
<div>
    <div class="section-1 row">
        <div class="left-column col-md-6">
          <div class="content">
            <img src="../../assets/images/vitagist (1).png" alt="">
            <!-- <h2>WELCOME!</h2>
            <p>Start managing your patients remotely with VitaCape</p> -->
          </div>
        </div>
        <div class="right-column col-md-6">
            <div class="content">
                <h2 class="label">Reset Password</h2>
                <!-- <p>Please select you field of</p> -->
                <form [formGroup]="myForm" >
                    
                    <div class="signForm-row " >
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-unlock-alt"></i></span>
                        <input #token formControlName='token' name="token" class="form-control" id='id_token' type="text" placeholder="Token" aria-describedby="basic-addon1"/>
                      </div>
                      
                        <div *ngIf="f['token'].errors && submitted" class="text-danger">
                          <div *ngIf="f['token'].errors.required">This field is required</div>
                          <div *ngIf="f['token'].errors.msg">{{f['token'].errors.msg}}</div>
                        </div>
                    </div><br>

                    <button (click)="resend_token()" class="resend-button">Resend Token</button>

                    <div class="password_section signForm-row ">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon2"><i class="fas fa-key"></i></span>
                        <input [type]="hide ? 'password' : 'text'" #password formControlName="password" class="form-control" inputmode="passsword" id="id_password" placeholder="Password" aria-describedby="basic-addon2"/>
                      </div>
                      
                        <i (click)="password_icon()" class="show_hide {{password_status}}"></i>
                        <mat-error *ngIf="myForm.hasError('required', 'password') && submitted">
                          Please enter your new password
                        </mat-error>
                        <div *ngIf="f['password'].errors && submitted" class="text-danger">
                          <div *ngIf="f['password'].errors.minlength">Password must be at least 8 characters</div>
                        </div>
                    </div><br>

                    <div class="password_section signForm-row ">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon3"><i class="fas fa-key"></i></span>
                        <input [type]="confirm_hide ? 'password' : 'text'" #ConfirmPassword formControlName="ConfirmPassword" class="form-control" inputmode="passsword" id="id_confirm_password" placeholder="Confirm Password" aria-describedby="basic-addon3"/>
                      </div>
                      
                        <i (click)="confirm_password_icon()" class="show_hide {{confirm_password_status}}"></i>
                        <mat-error *ngIf="myForm.hasError('notSame') && submitted">
                          Passwords do not match
                        </mat-error>
                    </div><br>
                    
                   
                    <div class="signForm-row">
                      <button (click)="onSubmit(token.value, password.value, ConfirmPassword.value)" class="submit-button">Submit</button>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</div>
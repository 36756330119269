import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import * as uuid from 'uuid';
import { CommunicationService } from '../communication.service';
import { DatePipe } from '@angular/common';
import { Utils } from '../utils';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AddDataReading } from '../patient-profile-page/patient-profile-page.component';
import { ShareUpdateService } from '../share-update.service';
import { debug } from 'console';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceComponent implements OnInit {
  device_names = [
    {title: 'Blood Glucose',data_type: 'single', unit: {title: ['Blood Glucose'], unit: ['mg/dl']}, device_type: 'BGDEVICE', systemId: '0x1808' , image: ['fas fa-tint', 'color: #f75b5b'], category: 2, flag: false}, 
    {title: 'Pulse', data_type: 'single', unit: {title: ['Pulse'], unit: ['SpO2%']}, device_type: 'PULSEDEVICE', systemId: '0x1822',  image: ['fas fa-lungs', 'color: #4c7dfe'], category: 4, flag: false}, 
    {title: 'Blood Pressure', data_type: 'three', unit: {title: ['Systolic', 'Diastolic', 'Pulse'], unit: ['mm Hg','mm Hg', 'bpm']}, device_type: 'BPDEVICE', systemId: '0x1810',  image: ['fas fa-heartbeat', 'color: #ed3b3b'], category: 3, flag: false}, 
    {title:  'Weight', data_type: 'single', unit: {title: ['Weight'], unit: ['Kg']}, device_type: 'WEIGHSCALEDEVICE', systemId: '0x181D', image: ['fas fa-weight', 'color: #52b8c4'], category: 6, flag: false}, 
    {title: 'Temperature', data_type: 'single', unit: {title: ['Temperature'], unit: ['Fahrenheit']}, device_type: 'THERMODEVICE', systemId: '0x1809', image: ['fa fa-thermometer-half', 'color: #f9f44f'], category: 5, flag: false}
  ]
  device_list: any;
  test_dev: any;
  final_list: string[] = [];
  update_dr: any;
  card_details: any;
  device_reading: any;
  isClassExpand = false;
  device_selected: any;
  dv: any;
  constructor(private router: Router, private authservice: AuthServiceService, public dialog: MatDialog,  private _communicationService: CommunicationService , private con:ConfirmBoxService , private change_stats:ShareUpdateService) { }

  ngOnInit() {

  
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
  
   

    this.authservice.device_list().subscribe((res: any) => {
      this.device_list = res;
      this.update_dr = this.device_list;
      ////console.log(this.device_list)
      this.update_dr.forEach(element => {
        this.device_names.forEach(item => {
          if(item.systemId == element.systemId){
            element['image'] = item.image;
            // //console.log(element['image'])
          }
        });
      });
      
    })

    this._communicationService.changeEmitted$.subscribe(data => {
      if(data){
        this.update_dr = data;
      } else {
        this.update_dr = this.device_list;
      }
      
      ////console.log("data ====> ",data)
    })

  
    
    
    
  }
  

  ngDoCheck(){
    if(this.test_dev){
      // //console.log(this.test_dev[1])
      
      
    }
    // //console.log(this.final_list)
  }

  openDialog() {
    this.dialog.open(AddDeviceDialog, {
      data: [this.device_names]
    }).afterClosed().subscribe(dataFromChild => {
      this.test_dev = dataFromChild;
    })
  }

  openReadingDialog(systemId) {
    this.device_list.forEach(item => {
      if(item.systemId == systemId){
        this.device_selected = item;
      }
    })
    this.device_names.forEach(item => {
      if(item.systemId == systemId){
        this.dv = item;
        ////console.log(item.systemId, systemId)
      }
    })
    this.dialog.open(AddDataReading, {
    data: [this.dv, this.device_selected,this.device_names, this.isClassExpand]
    })
    
   
  }

  OnDeleteDevice(deviceid){
  
    this.con.confirm("Alert","Are you sure you want to Delete this Device?").then((res)=>{
      if(res){
          this.authservice.delete_device(deviceid).subscribe((res:any)=>{
          
            this.authservice.device_list().subscribe((res:any)=>{
              
              this._communicationService.emitChange(res);
              res.forEach(element => {
                this.device_names.forEach( item => {
                  if(item.systemId == element.systemId){
                       element['image'] = item.image;
                     // //console.log(element['image'])
                      }
                  
    
                });
                
              });
              //  res.forEach(element => {
              //   this.device_names.forEach(item => {
              //     if(item.systemId == element.systemId){
              //       element['image'] = item.image;
              //       // //console.log(element['image'])
              //     }
              //   });
    
            })
            
          })
        
          
        

      }
    })

  }

}


@Component({
  selector: 'add-device-dialog',
  templateUrl: 'add-device-dialog.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceDialog {
  @BlockUI()
  blockUI!: NgBlockUI; 
  submitted = false;
  device_names : any;
  selected_device: any;
  final_list: string[] = [];
  registered_device_list: any;
  disabled = false;
 

  // toppings: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<AddDeviceDialog>, private formBuilder: FormBuilder, private toastr: ToastrService, private authservice: AuthServiceService, private _communicationService: CommunicationService){
    dialog.disableClose = true;
    this.device_names = data[0]
    
  }

  // toppings = this.fb.group({
  //   pepperoni: false,
  //   extracheese: false,
  //   mushroom: false
  // });

  myForm = this.formBuilder.group({
   'Blood Glucose': new FormControl({value: false, disabled: false}, Validators.required), 
    Pulse: new FormControl({value: false, disabled: false}, Validators.required),
   'Blood Pressure': new FormControl({value: false, disabled: false}, Validators.required),
    Weight: new FormControl({value: false, disabled: false}, Validators.required),
    Temperature: new FormControl({value: false, disabled: false}, Validators.required)
  });

  ngOnInit (){
    
    // this.myForm['Blood Pressure'].disable({onlySelf: true});
    this.authservice.device_list().subscribe((res: any) => {
      this.registered_device_list = res;
      this.registered_device_list.forEach(element => {
        // if(Object.keys(this.myForm.value).includes(element.category.name)){
        //   //console.log(element.category.name)
        // }
        this.device_names.forEach(item => {
          if(item.systemId == element.systemId){
            // //console.log(element.systemId, element.category.name)
            this.myForm.controls[item.title].disable();
            item.flag = true;
          } else {
            
          }
          // //console.log(this.disabled)
          
        });
      });
    })

    

  }
  start_loading(){
    this.blockUI.start()
  }
  stop_loading(){
    this.blockUI.stop()
  }

  // get f() { return this.myForm.controls; }

  on_selection(index){
    // //console.log(index)
    this.selected_device = this.device_names[index];
  }

  close_dialog(){
    // //console.log(this.myForm.value)
    this.dialog.close();
  }

  save_data(){
    this.submitted = true;
    let count=0
    this.start_loading()
  
    
    Object.keys(this.myForm.value).forEach((element: string) => {
      if(this.myForm.value[element]){
        this.final_list.push(element);
        
        // var id = uuid.v4();
        // //console.log(id);
        let name = uuid.v4();
        let manufacturer = uuid.v4();
        let category = 0;
        let serviceUid = uuid.v4();
        let systemId = ''
        let modelNumber =1234;
        let serialNumber = 1234;
        let isBLE = true;
        let paired = false;
        let status = "OFFLINE";

        this.device_names.forEach(item => {
          if(item.title == element){
            category = item.category;
            systemId = item.systemId;            
          }
        });

        this.authservice.add_new_device(name, category, serviceUid, manufacturer, systemId, modelNumber, serialNumber, isBLE, paired, status).subscribe((res: any) => {
          this.toastr.success('New Device Added');
          this.authservice.device_list().subscribe((res: any) => {
            this._communicationService.emitChange(res);
            res.forEach(element => {
              this.device_names.forEach(item => {
                if(item.systemId == element.systemId){
                  element['image'] = item.image;
               
                }
              });
            });
          })
          
          this.dialog.close(this.final_list);
          this.stop_loading()
        })


      } else {
        count++
       
      } 
      
    });
    if(count==5){
      this.stop_loading()
      this.dialog.close()
    }
    if(this.myForm.untouched &&(count!=5)){
      
      this.stop_loading()
      this.toastr.error("Choose Atlest One Device")
      this.submitted = false
      return
    }

    
  }

  on_device_select(index){
    // //console.log(this.device_names[index])
  }

}



// @Component({
//   selector: 'dialog-add-reading',
//   templateUrl: './dialog-add-reading.html',
//   styleUrls: ['./add-device.component.scss'],
//   providers: [DatePipe]
// })

// export class DialogAddReading{

//   current_card: any;
//   tag_list: any;
//   tmpindex: number = 0;

//   tag_id = 0;
//   device_names: any;
//   system_id_list: any;
//   device_id: any;
//   device_option: any;
//   submitted = false;
//   device_reading: any;
//   maxDate = new Date();
//   date1 = new FormControl(new Date());
//   // dateSelected = new FormControl(new Date());
//   ampm_list = ["AM", "PM"];
//   hour_value = Utils._12_hour_format();
//   minute_value = new Date().getMinutes().toString();
//   am_pm =  new Date().getHours()>12? "PM": "AM";

//   last_updated: any;
//   dv: any;
//   dialog_status: any;
  
//   counter = Array;
//   // device_selected : any;

//   constructor(@Inject(MAT_DIALOG_DATA) public data: any, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, public dialog: MatDialogRef<DialogAddReading>, private _communicationService: CommunicationService , public datePipe :DatePipe){
//     dialog.disableClose = true;
//     // this.device_id = data[0];
//     this.device_option = data[0];
//     this.device_names = data[1];
//     this.dv = data[2];
//     this.dialog_status = data[3];
//   }

//   myForm = this.formBuilder.group({
//     firstReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
//     secondReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
//     thirdReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]]
//   });

  


//   ngOnInit(){
//     this.authservice.data_last_reading().subscribe((res: any) => {
//       res.forEach(element => {
//         ////console.log(res)
//         if(element.device__systemId == this.device_names.systemId){
//          // //console.log(element.device__systemId, this.dv.systemId)
//           if(element.daySince > 0){
//             this.last_updated = `Last Reading: ${element.daySince} days ago`
//           } else if(element.daySince == 0) {
//               this.last_updated = `Last Reading: Today`
//           }
//         } 
//       });
//     })
//     if(this.device_option.systemId=="0x181D"){
//       this.authservice.profile_details().subscribe((res1:any)=>{
      
        
//         if(res1.length!= 0){
//           this.device_option.unit.unit[0] = res1[0]?.weigthtUnit
       
          
//         } else {
//              this.authservice.get_citizenship().subscribe((res:any)=>{
//             if(res.error==false){
//               for (let i = 0; i < res.data.length; i++) {

//                 if(res.data[i].index == "citizen_primary" &&  (res.data[i].country =="USA")){
//                   this.device_option.unit.unit[0] = "lbs"
      
      
//                 } else {
//                   this.device_option.unit.unit[0] = "kg"
//                 }
//               }
//             }
//           })
//         }
//       })

//     }

//     this.authservice.get_tag_list(this.device_names.category.id).subscribe((res: any) => {
//       if(!(res==[])){
//         this.tag_list = res;
//         this.tag_id = res[0]?.id;

//       }
      
//     })  


   
//   }


//   close_dialog(){

//     this.dialog.close();
//   }

//   get f() { return this.myForm.controls; }

//   tag_btn(tag_id: number){
//     this.tag_id = tag_id
   
//   }
  
  

//   save_data(value1: string, value2: string, value3: string, date: string, hour: string, minute: string, am_pm: string, valueUnit1: string, valueUnit2: string, valueUnit3: string){
//     this.submitted = true;
//     let category = this.device_names.id; 
//     let tagId = this.tag_id
//     let sequence = 0;
    
    
  
//     if(minute.length == 1){
//       minute = `0${minute}`
//     }

//     if(!value2 && !value3 && !valueUnit2 && !valueUnit3){
//       value2 = '0';
//       value3 = '0';
//       valueUnit2 = "";
//       valueUnit3 = "";
//     }
//     var readingDateTime;
    
    
//     if(date == ""){
//       date = Utils.formatDate();
//       readingDateTime = `${date}`;
//     } else {
//       let DateTime = `${date}, ${hour}:${minute} ${am_pm}`
//       readingDateTime = this.datePipe.transform(DateTime , "MM-dd-yyyy hh:mm a")
//     }

    

    


 
//     if(this.device_option.data_type == 'three'){
//       if(value1 && value2 && value3 && value1 >= '10' && value2 >= '10' && value3 >= '10'){
//         this.authservice.add_device_reading(category, sequence, readingDateTime, Number(value1), Number(value2), Number(value3), valueUnit1, valueUnit2, valueUnit3, tagId).subscribe((res: any) => {
         
//           this.toastr.success('Reading created successfully.')
          
//           this.dialog.close()
          
//         })
//       } else {
//         // this.toastr.error('Please fill the required field.');
//       }
      
//     } else if(this.device_option.data_type == 'single'){
//       if(value1 >= '10'){
//         this.authservice.add_device_reading(category, sequence, readingDateTime, Number(value1), Number(value2), Number(value3), valueUnit1, valueUnit2, valueUnit3, tagId).subscribe((res: any) => {
          
//           this.toastr.success('Reading created successfully.')
//           // this.authservice.lastest_device_reading().subscribe((res: any) => {
//           //   //console.log(res)
//           //   this._communicationService.emitChange(res);
//           // })
          
//           this.dialog.close()
//         })
//       } else {
//         // this.toastr.error('Please fill the required field.');
//       }
//     } 
    
    
//   }

  
// }


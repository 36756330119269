import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';

import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import moment  from 'moment-timezone';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications:any;
  maxDate= new Date();
  list:any;
  new15: any;
  todaydate:any;
  searchText:any;
  

  constructor(public auth:AuthServiceService , public toast:ToastrService ,private _communication: CommunicationService , public datePipe:DatePipe , private box : ConfirmBoxService) { }

  ngOnInit(): void {
 

    this.auth.get_notifications().subscribe((res:any)=>{
      this.notifications=res.data.notifications
    })
    this._communication.changeEmitted$.subscribe(data =>{
      if(data){
        this.notifications =  data.data.notifications
      
      }
    })
  }
  displayStyle = "none";
  
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }

  read_notification(id){
    this.auth.read_notification(id).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg)
        this.auth.get_notifications().subscribe((res:any)=>{
          this._communication.emitChange(res)

        })

      }

    })
   

  

  }
  getFilterValue(item) {
    var name = item.message
    return name.toLowerCase()
  }
  delete_notification(id){
     this.box.confirm("ALert","Are you sure you want to delete Notification?").then((res)=>{
       if(res){
        this.auth.delete_notifications(id).subscribe((res:any)=>{
          if(res.error==false){
            this.toast.success(res.msg)
            this.auth.get_notifications().subscribe((res:any)=>{
              this._communication.emitChange(res)
    
            })
    
          }

        })

       }
     })
  
  }

}

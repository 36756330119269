<div class=" container">
    <div class=" card-dialog row justify-content-center">
      <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
        <div>
          <h1 mat-dialog-title> Manual Devices</h1>
            <!-- <p class="dialog-title">Manual Devices</p> -->
            <!-- <div  class="select-list device-list">
              <button  (click)="on_device_select(i)" class="list-tag" *ngFor="let item of device_names; index as i"><mat-card  >{{item.title}}<button *ngIf="item.image"><i class="{{item.image[0]}}"></i></button></mat-card></button>
            </div> -->
            <section class="add-device-dialog-list example-section" [formGroup]="myForm">
              <p [ngClass]="{'chck': item.flag == true}" *ngFor="let item of device_names; index as i"><mat-checkbox formControlName="{{item.title}}" [disabled]="disabled">{{item.title | pulseTrans}}</mat-checkbox><button><i class="{{item.image[0]}}"></i></button></p>
            </section>
            <!-- <section class="example-section" [formGroup]="myForm">
              <h4>You chose:</h4>
              {{myForm.value | json}}
            </section> -->
        
            <div class="save-button">
                <button [disabled]="submitted" (click)="save_data()">Save</button> 
            </div>
          </div>
      </div>
    </div>  
</div>
<block-ui></block-ui>

<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">Reminders</p>
    <!-- <div class="hi-right header-icons">
        <button (click)="openDialog()">
            <i class="fas fa-plus"></i>
        </button>
    </div> -->
    <div class="searchbar-e">
        <input  type="text" placeholder="Search"  [(ngModel)] = searchText #filterValue>
        <i class="fas fa-search"></i>
    </div>
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of reminders  | filterAll:searchText">
            <mat-card>
                <div class="card-cnt">
                    <h4>{{item.message}}</h4>
                </div>
                <div class="edit-btn">
                    <h5>{{item.target}}</h5><button title="Delete" (click)="delete_reminder(item.id)"><mat-icon>delete</mat-icon></button>
                    
                </div>
            </mat-card>
        </div>
        
    </div>
    <div class="example-button-container">
        <button 
        (click)="openDialog()" title="add reminders" mat-raised-button  >
            <mat-icon>add</mat-icon><span>Add</span>
        </button>
    </div>
</div>




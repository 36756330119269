import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { DataService } from '../data.service';
import { EditCarePlanComponent } from '../edit-care-plan/edit-care-plan.component';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent implements OnInit {
  care_plan: any;
  isClassExpand = false;
  @Input() id:any;
  searchText:any;
  searchTextSecond: any;
  
  searchbar = 'searchbar';

  constructor(private authService: AuthServiceService, private route: ActivatedRoute, private router: Router ) { }
 

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.authService.get_care_plan().subscribe((res: any) => {
      this.care_plan = res;
   
    })

  
  }
  open_bottom_dialog($event){
    // this.dialog.open(BottomDialog);
    $event.stopPropagation();
    this.isClassExpand = !this.isClassExpand;
  }
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isClassExpand= false;
  }

  @HostListener('document:click', ['$event'])

  clickout() {
   this.isClassExpand = false;
  }
  openDialog(){
    
  }

  getFilterValue(item) {
    var name = item.title
    return name.toLowerCase()
  }
}

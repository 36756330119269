
<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">Audit Trails</p>
    <!-- <div class="hi-right header-icons">
        <button (click)="openDialog()">
            <i class="fas fa-plus"></i>
        </button>
    </div> -->
    <div class="searchbar-e">
        <input  type="text" placeholder="Search"  [(ngModel)] = searchText #filterValue>
        <i class="fas fa-search"></i>
    </div>
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of audit | filterAll:searchText"  >
            <mat-card>
                <p class="head-title"><b>{{item.name}}</b></p>
                <div class="card-cnt">
                    
                    <h4>{{item.message}}</h4>
                </div>
                <div class="edit-btn">
                    <h5>{{item.timestamp}}</h5>
                    
                </div>
            </mat-card>
        </div>
        
    </div>
  
  
</div>






<div class="">
    <app-menu-comp></app-menu-comp>
    <div class="card-css main_container ">
        <p>Plan Information</p>
        <form class="example-form" [formGroup]="plan_info_form" >
            
            <div cdkTrapFocus [cdkTrapFocusAutoCapture]="true" >
                <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Medical Condition</mat-label>
                <input type="text" cdkFocusInitial matInput #medCon placeholder="Medical Condition" formControlName="medicalCondition" [matAutocomplete]="auto"/>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option>Select Condition</mat-option>
                    <mat-option *ngFor="let item of medcon_list" value= "{{item.title}}">
                        {{item.title}}
                    </mat-option>
                </mat-autocomplete>
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Title</mat-label>
                    <input matInput #titleInput formControlName='title' name="title" placeholder="Title" />
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label> Description</mat-label>
                    <input matInput #descriptionInput formControlName='description' name="description" placeholder="description" />
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Category</mat-label>
                <mat-select formControlName="category" [(value)]="categoryvalue">
                    <mat-option>Select Condition</mat-option>
                    <mat-option *ngFor="let item of category_list" value= "{{item.id}}">
                        {{item.name}}
                    </mat-option>
                </mat-select>
                </mat-form-field>
            </div>

            <div style="display: flex;">
                <mat-form-field class=" num-sel example-full-width" appearance="fill">
                    <mat-label>Select Duration</mat-label>
                    <input matInput type="number" id="duration" #duration formControlName='duration' name="num" placeholder="Number" />
                </mat-form-field>
                <mat-form-field class="sel" appearance="fill">
                    <mat-label>Select Type</mat-label>
                    <mat-select formControlName="durationType" [(value)]="durationType">
                        <mat-option>Select Condition</mat-option>
                        <mat-option value="DAY">Day</mat-option>
                        <mat-option value="YEAR">Year</mat-option>
                        <mat-option value="MONTH">Month</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Desired Outcome</mat-label>
                    <input matInput #desOut formControlName='desiredOutcome' name="desiredOutcome" placeholder="Desired Outcome" />
                </mat-form-field>
            </div>

            
        
            
                <!-- <div *ngIf = "isShow"> -->
                <div *ngIf = "isShow">
                    
                    <div class="top-space ent">
                        <mat-card>Desired Outcome<button (click)="openDialog('desiredOutcome','create')">+</button></mat-card>
                    </div>
                    <div class="med-condition">
                        <mat-card  *ngFor="let item of titledesire">{{item}}
                            <!-- <div class="right-icon">
                                <button (click)="openDialogEdit('desiredOutcome','edit')" >
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </button>
                            </div> -->
                        </mat-card>
                    </div>
            
                    <div class="ent">
                        <mat-card>Activities<button (click)="openDialog('activities','create')">+</button></mat-card>
                    </div>
                    <div class="med-condition">
                        <mat-card *ngFor="let item of titleschdule">{{item}}
                            <!-- <div class="right-icon"> 
                            <button (click)="openDialogEdit('activities','edit')">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                            </div> -->
                        </mat-card>
                    </div>

                    <div class="ent">
                        <mat-card>Medications<button (click)="openDialog('medication','create')">+</button></mat-card>
                    </div>
                    <div class="med-condition">
                        <mat-card *ngFor="let item of titlemedication">{{item}}
                            <!-- <div class="right-icon">
                            <button (click)="openDialogEdit('medication','edit')">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                            </div> -->
                        </mat-card>
                    </div>  
                </div>  
               



                <div class="example-button-row">
                    <button class="cancel-btn" (click)="clickMethod()" mat-raised-button >Cancel</button>
                    <button mat-raised-button (click)="onSaveClicked(medCon.value, titleInput.value,descriptionInput.value, desOut.value , categoryvalue, duration.value, durationType)" (click)="toggleDisplay()">Save</button>
                </div>
        </form>
    </div>
</div>
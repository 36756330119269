<div>
    <div class="section-1 row">
        <div class="left-column col-md-6">
          <div class="content">
            <img src="../../assets/images/vitagist (1).png" alt="">
            <!-- <h2>WELCOME!</h2>
            <p>Start managing your patients remotely with VitaCape</p> -->
          </div>
        </div>
        <div class="right-column col-md-6">
            <div class="content">
                <h2 class="label">Register</h2>
                <!-- <p>Please select you field of</p> -->
                <form [formGroup]="myForm" >
                    <!-- <div class="signTitle">Sign In</div> -->
                    <div class="signForm-row " id="first_name_input">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><i class="fa fa-user fa-lg"></i></span>
                        <input required #firstNameInput formControlName='firstName' name="firstName" class="form-control" id='id_first_name' type="text" placeholder="First Name" />
                      </div>
                      
                      <div *ngIf="f['firstName'].errors && submitted" class="text-danger">
                        <div *ngIf="f['firstName'].errors.required">This field is required</div>
                        <div *ngIf="f['firstName'].errors.msg">{{f['firstName'].errors.msg}}</div>
                      </div>
                    </div><br>

                    <div class="signForm-row " id="last_name_input">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon2"><i class="fa fa-user fa-lg"></i></span>
                        <input required #lastNameInput formControlName='lastName' name="lastName" class="form-control" id='id_last_name' type="text" placeholder="Last Name" aria-describedby="basic-addon2"/>
                      </div>
                      
                      <div *ngIf="f['lastName'].errors && submitted" class="text-danger">
                        <div *ngIf="f['lastName'].errors.required">This field is required</div>
                        <div *ngIf="f['lastName'].errors.msg">{{f['lastName'].errors.msg}}</div>
                      </div>
                    </div><br>

                    <div class="signForm-row " id="con_sel">
                      <div class="input-group">
                        <span class="input-group-text" id="add-con"><i class="fa fa-globe headericon"></i></span>
                        <select required placeholder="Country" formControlName="country"  [value]="countryName"  class="form-control" aria-describedby="add-con">
                          <option value="" disabled selected>Select Country</option>
                          <option *ngFor="let country of countries" value="{{country[0]}}">{{country[0] | uaename}}</option>
                        </select>
                      </div>
                      
                      <div *ngIf="f['country'].errors && submitted" class="text-danger">
                        <div *ngIf="f['country'].errors.required">This field is required</div>
                      </div>
                    </div><br>

                    <!-- <div>

                      <mat-form-field class="dropdown" appearance="fill" floatLabel="always">
                        <mat-label>Select Country</mat-label>
                        <mat-select  required formControlName="country"  [(value)]="countryName">
                          
                          <mat-option *ngFor="let country of countries" value="{{country[0]}}">
                            {{country[0]}}
                          </mat-option>
                        </mat-select>
                        <span matPrefix class=""><i class="fa fa-globe headericon"></i></span>
                      </mat-form-field>
                      <mat-error *ngIf="myForm.hasError('required', 'country') && submitted">You must make a selection</mat-error>
                    </div> -->
                    
                    <div class="signForm-row " id="email_input">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon3"><i class="fas fa-envelope"></i></span>
                        <input #emailInput required formControlName='email' name="email" class="form-control" id='id_email' type="text" placeholder="Email" aria-describedby="basic-addon3"/>
                      </div>
                      
                      <div *ngIf="f['email'].errors && submitted" class="text-danger">
                        <div *ngIf="f['email'].errors.required">This field is required</div>
                        <div *ngIf="f['email'].errors.email">Invalid Email</div>
                        <div *ngIf="f['email'].errors.msg">{{f['email'].errors.msg}}</div>
                      </div>
                    </div><br>

                    <div class=" password_section signForm-row ">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon4"><i class="fas fa-key"></i></span>
                        <input  #password formControlName="password" class="form-control" 
                      [type]="hide ? 'password' : 'text'" id="id_password" placeholder="Password" aria-describedby="basic-addon4"/>
                      </div>
                      
                        <i (click)="password_icon()" class="show_hide {{password_status}}"></i>
                        <mat-error *ngIf="myForm.hasError('required', 'password') && submitted">
                          Please enter your new password
                        </mat-error>
                        <div *ngIf="f['password'].errors && submitted" class="text-danger">
                          <div *ngIf="f['password'].errors.minlength">Password must be at least 8 characters</div>
                        </div>

                      <!-- <div *ngIf="f['password'].errors && myForm.touched" class="text-danger">
                        <div *ngIf="f['password'].errors.required">This field is required</div>
                        <div *ngIf="f['password'].errors.msg">{{f['password'].errors.msg}}</div>
                      </div> -->
                    </div><br>

                    <div class="password_section signForm-row ">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon5"><i class="fas fa-key"></i></span>
                        <input [type]="confirm_hide ? 'password' : 'text'" #ConfirmPassword formControlName="ConfirmPassword" class="form-control" 
                        inputmode="passsword" id="id_confirm_password" placeholder="Confirm Password" aria-describedby="basic-addon5"/>
                      </div>
                      
                        <i (click)="confirm_password_icon()" class="show_hide {{confirm_password_status}}"></i>
                        <div *ngIf="myForm.hasError('notSame') && submitted" class="text-danger">
                          Passwords do not match
                        </div>
                      <!-- <div *ngIf="f['ConfirmPassword'].errors && myForm.touched" class="text-danger">
                        <div *ngIf="f['ConfirmPassword'].errors.required">This field is required</div>
                        <div *ngIf="f['ConfirmPassword'].errors.msg">{{f['ConfirmPassword'].errors.msg}}</div>
                      </div> -->
                    </div><br>

                    <div>
                      <p>By sign up, you agree to our <a href="https://digicoffer.com/digicoffer_terms.pdf">Terms</a> & <a href="https://digicoffer.com/privacy.html"> Privacy Policy</a></p>
                    </div>
                   
                    <div class="signForm-row">
                      <button (click)="onSignUpButtonClicked( firstNameInput.value, lastNameInput.value, countryName , emailInput.value, password.value, ConfirmPassword.value)" class="sign-in-button">Sign Up</button>
                    </div>

                    <div>
                      <p>Already have an account? <a routerLink="/login">Sign In</a> </p>
                    </div>
                    
                </form>
            </div>
            <!-- <div class="copyright">
              <p>VitaGist 2020. All rights reserved.</p>
            </div> -->
        </div>
    </div>
</div>
<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()" mat-dialog-close>X</button>
        </div>
        <form *ngIf="field=='first_name'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
            <h2>Edit First Name</h2>
            <div mat-dialog-content>
 
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label> First Name</mat-label>
                        <input matInput #firstName placeholder="First Name" formControlName ="first_name" [ngModel]="edit_data"  />
                    </mat-form-field>
                  </div>
 
                <div class="save-button">
                    <button type="submit">Save</button>
                    <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                  </div>
 
               
            </div>
 
        </form>
        <form *ngIf="field=='middle_name'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
          <h2>Edit Middle Name</h2>
          <div mat-dialog-content>
 
            <div>
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Middle Name</mat-label>
                  <input matInput #midName placeholder="Middle Name" formControlName ="middle_name" [ngModel]="edit_data"  />
              </mat-form-field>
            </div>
 
              <div class="save-button">
                  <button type="submit">Save</button>
                  <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                </div>
 
             
          </div>
 
        </form>
        <form *ngIf="field=='last_name'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
        <h2>Edit Last Name</h2>
        <div mat-dialog-content>
 
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Last Name</mat-label>
                    <input matInput #lastName placeholder="Last Name" formControlName ="last_name" [ngModel]="edit_data"  />
                </mat-form-field>
              </div>
 
            <div class="save-button">
                <button type="submit">Save</button>
                <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
              </div>
 
           
        </div>
 
        </form>
        <form *ngIf="field=='email'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
            <h2>Edit Email ID</h2>
            <div mat-dialog-content>
        
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Email Id</mat-label>
                        <input matInput #firstName placeholder="Email Id" formControlName ="email" [ngModel]="edit_data"  />
                    </mat-form-field>
                    <mat-error *ngIf="myform.controls['email'].invalid" >
                      Enter Correct Pattern
                    </mat-error>
                    </div>
        
                <div class="save-button">
                    <button type="submit">Save</button>
                    <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                    </div>
        
                
            </div>
 
        </form>
        <form *ngIf="field=='mobile'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
            <h2>Edit Mobile Number</h2>
            <div mat-dialog-content>
        
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Mobile Number</mat-label>
                        <input matInput
                        #firstName placeholder="Mobile Number" formControlName ="mobile" [ngModel]="edit_data"  />
                    </mat-form-field>
                </div>
        
                <div class="save-button">
                    <button type="submit">Save</button>
                    <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                </div>
        
            
            </div>
 
        </form>
        <form *ngIf="field=='dob'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
            <h2>Edit Date Of Birth</h2>
            <div mat-dialog-content>
            
                <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Date Of Birth</mat-label>
                        <input matInput #firstName placeholder="Date of Birth" formControlName ="dob" [ngModel]="edit_data"  />
                    </mat-form-field>
                    </div>
            
                <div class="save-button">
                    <button type="submit">Save</button>
                    <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                    </div>
            
                
            </div>
 
        </form>
        <form *ngIf="field=='password'" class="form-dialog" [formGroup]="myform" (ngSubmit)="Onsubmit()">
          <h2>Edit Password</h2>
          <div mat-dialog-content>
          
              <div>
                  <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Old Password</mat-label>
                      <input matInput #old placeholder="Old Password" formControlName ="old" [type]="hide ? 'password' : 'text' "/>
                      <i (click)="password_icon()" class="show_hide {{password_status}}" matSuffix></i>
                  </mat-form-field>
                  <mat-error  *ngIf="myform.controls['old'].invalid && submitted">This Field is required</mat-error>
                  </div>
                  <div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>New Password</mat-label>
                        <input matInput #new placeholder="New Password" formControlName ="new" [type]="hide1 ? 'password' : 'text' "/>
                        <i (click)="password_icon1()" class="show_hide {{password_status1}}" matSuffix></i>
                    </mat-form-field>
                    <mat-error  *ngIf="myform.controls['new'].invalid && submitted">This Field is required</mat-error>
                    </div>
                    <div>
                      <mat-form-field class="example-full-width" appearance="fill">
                          <mat-label>Confirm Password</mat-label>
                          <input matInput #con placeholder="Confirm Password" formControlName ="con" [type]="hide2 ? 'password' : 'text' " />
                          <i (click)="password_icon2()" class="show_hide {{password_status2}}" matSuffix></i>
                      </mat-form-field>
                      </div>
                      <mat-error *ngIf="myform.hasError('notSame') && submitted">
                        Passwords do not match
                      </mat-error>
          
              <div class="save-button">
                  <button type="submit">Save</button>
                  <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                  </div>
          
              
          </div>

      </form>
        
 
     
        </div>
    </div>
</div>


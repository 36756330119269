<div class="container">
    <div class=" card-dialog row justify-content-center">
      <div class="left col-4">
        <!-- <p class="card-selected">Glucose</p> -->
        <p [ngClass]="{'card-selected' : item == 'Diet'}" *ngFor="let item of left_list">{{item}}</p>
      </div>
  
      <div class="right col-8">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
        
      
        <div>
        
            <div class="diet-list" *ngFor="let item of exe_list">
              <p>{{ item.title }}<span>&nbsp;{{item.qty | number: '1.0-0'}}&nbsp;{{item.unit}}</span></p>
              <input pattern="^[0-9]*$" type="number" min="0" max="999999" #first class="form-control" [formControl]="valueform" (keydown)="onKeydown($event)" (change)="get_value(first.value,item.title,item.unit,first.pattern)" >
              <mat-error *ngIf="first.value.length > 6">Enter a valid number</mat-error>
              <div *ngIf="(first.value=='')&& submitted" class="text-danger">
                <div>Value is required</div>

                
                
              </div>



              


             
  
            </div>
            <div >
                <mat-chip-list *ngIf="tag_list != null" class="tag-section">
                    <mat-chip (click)="tag_btn(tag.id);tmpindex=i;"  [selected]="i===tmpindex" *ngFor="let tag of tag_list; index as i" >{{tag.description}}</mat-chip>
                    <!-- <div *ngIf="tag_id == 0" class="text-danger">
                      <div>Please Select Tag</div>
                    </div> -->
                  </mat-chip-list>
              <div class="date-time-select">
                <mat-form-field class="calendar" appearance="fill">
                  <mat-label>Choose a date</mat-label>
                  <!-- <input readonly [formControl] ="dateSelected" matInput [matDatepicker]="dp3" > -->
                  <input [max]="maxDate" required readonly #dateSelected [value]="date1.value"  matInput [matDatepicker]="dp3" >
                  <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                  <mat-datepicker #dp3 ></mat-datepicker>
                </mat-form-field>
              </div>
    
              <div class="time-select">
                <mat-form-field class="set-time" appearance="fill">
                  <mat-label>HH</mat-label>
                  <mat-select required [(value)]="hour_value">
                    
                    <mat-option *ngFor="let item of counter(13);let i= index" value="{{i}}">{{i}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="set-time" appearance="fill">
                  <mat-label>MM</mat-label>
                  <mat-select required [(value)]="minute_value">
                    
                    <mat-option *ngFor="let item of counter(60); index as i" value="{{i}}">{{i}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="set-time" appearance="fill">
                  <mat-label>AM/PM</mat-label>
                  <mat-select required [(value)]="am_pm">
                   
                    <mat-option *ngFor="let item of ampm_list" value="{{item}}">{{item}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            
        
         
             
    
            
           
            
          

        </div>
        <div class="save-button">
          <button (click)="save_data(dateSelected.value,hour_value,minute_value,am_pm)">Save</button> 
        </div>
        
      </div>
      
    </div>   
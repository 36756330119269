<div class="">
    <app-menu-comp></app-menu-comp>
    <div class="card-css main_container ">
        <p>Devices</p>
        <div class=" add-device container">
          
            <div class=" cnt">
                <mat-card><button (click)="openDialog()"class="add-btn" title="Add Device">+</button></mat-card>
            </div>
            <p class="sub-heading">Manual Devices</p>
            <!-- <div *ngIf="update_dr" style="color: black; font-size: 17px;"><h5>No Device</h5></div> -->
            <div *ngIf="update_dr?.length==0" class="no-dev">No Devices Found</div>
            <div class="device-list">
                <mat-card *ngFor="let item of update_dr"><button class="read-btn" (click)="openReadingDialog(item.systemId)" *ngIf="item.image"><i class="{{item.image[0]}}"></i>
                </button>{{item.category?.name | pulseTrans}}<button class="del-btn"
                    (click)="OnDeleteDevice(item.id)" ><i class="fa fa-trash"></i></button
                    ></mat-card>
                    

            </div>
            <p class="sub-heading">BlueTooth Devices</p>
            <!-- <div *ngIf="update_dr == undefined" style="color: black; font-size: 17px;"><h5>No Device</h5></div> -->
            <!-- <div class="test_dev" >
                <p *ngFor="let item of test_dev">{{item}}</p>
            </div> -->
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uaename'
})
export class UaenamePipe implements PipeTransform {

  transform(str): unknown {
    if(str =='UnitedArabEmirates'){
      return 'United Arab Emirates'
    }
    return str
  }

}

import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { SaveAlertService } from '../save-alert/save-alert.service';
import { checkvalid } from '../exercisevalid';

@Component({
  selector: 'app-view-care-plan',
  templateUrl: './view-care-plan.component.html',
  styleUrls: ['./view-care-plan.component.scss']
})

@Injectable()
export class ViewCarePlanComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI; 

  goal:any;
  activity:any;
  med:any;
  id:any;
  careplan:any;
  parentid:any;
  q:any;
  diet:any;
  exercise:any;

  constructor(public auth: AuthServiceService  , private route: ActivatedRoute , public router:Router,public dialog:MatDialog , public _comm:CommunicationService) { }


  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
  this.blockUI.start()
    this.id = this.route.snapshot.paramMap.get("id");
    //console.log(this.id)

    this.auth.get_care_plan_id(this.id).subscribe((res:any)=>{
      this.parentid = res.parentPlan;
      //console.log(res ,this.parentid)
      if(this.parentid){
        this.auth.get_questions(this.parentid).subscribe((res:any)=>{
          this.q = res.length
        })

      }

    })
    this.auth.get_careplangoal(this.id).subscribe((res:any)=>{
      this.goal = res
      //console.log(this.goal,this.goal.length)
    })
    this.auth.get_careplandiet(this.id).subscribe((res:any)=>{
      this.diet = res
    })
    this.auth.get_careplabexerice(this.id).subscribe((res:any)=>{
      this.exercise = res
    })
    
    this.auth.profile_details().subscribe((res:any)=>{
      this.med = res[0].medication
    })

    
    //  this.auth.get_careplanmed(this.id).subscribe((res:any)=>{
    //    this.med = res.length
    //  })
   this.blockUI.stop()

   this._comm.changeEmitted$.subscribe(data=>{
     if(data){
       this.exercise = data
     }
   })
  
  
  }
  onclick(){
    this.dialog.open(ExerciseDialog,{ 
      maxHeight: '450px',
      data:[this.id]
    })
  }

  onEdit(index){
    this.dialog.open(ExerciseDialog,{ 
      maxHeight: '450px',
      data:[this.id,index]
    })

  }

}


@Component({
  selector: 'exercise-dialog',
  templateUrl: './exercise-dialog.html',
  styleUrls: ['./view-care-plan.component.scss']
})

export class ExerciseDialog {
  @BlockUI()
  blockUI!: NgBlockUI; 

  data_edit:any;
  care_id:any;
  distanceUnit_list=["steps"]
  distanceValid:any;
  submitted=false;
  myForm:any;
  repss=0;
  btn_on= false;
  repitation_list=[
  
    {
      "title":"Once",
      "value":1
    },
    {
      "title":"twice / Couple of times",
      "value":2
    },
    {
      "title":"thrice / Three Times",
      "value":3
    },
    {
      "title":"Four Times",
      "value":4
    },{
      "title":"Five Times",
      "value":5
    },
    {
      "title":"Six Times",
      "value":6
    },
    {
      "title":"Seven Times",
      "value":7
    },
    {
      "title":"Eight Times",
      "value":8
    },{
      "title":"Nine Times",
      "value":9
    },{
      "title":"Ten Times",
      "value":10
    }
  ]
  error_dis:any;


  constructor(public auth: AuthServiceService  , private route: ActivatedRoute , public router:Router, public formbuilder:FormBuilder,public dialog: MatDialogRef<ExerciseDialog>, @Inject(MAT_DIALOG_DATA) public data: any , public toast:ToastrService , public _communication:CommunicationService , private cbox:ConfirmBoxService , private save:SaveAlertService)
   { 
     dialog.disableClose=true
     this.care_id = data[0]
     this.data_edit = data[1]
    }

    
    ngOnInit(){
      this.myForm = this.formbuilder.group({
        carePlan:[0,Validators.required],
        title:['',Validators.required],
        category:[0,Validators.required],
        distance:[0,[Validators.min(0)]],
        distanceUnit:[""],
        weight:[0,[Validators.min(0)]],
        weightUnit:[''],
        numberOfStep:[0,[Validators.min(0), Validators.max(99)]],
        repetition:[0],
        sequence:[0]
    
        
    
      },{validator:this.checkPasswords}) 
      //console.log(this.data_edit)
      this.auth.get_category("EXERCISE").subscribe((res:any)=>{
        let id = res[0].id
        //console.log(res,id)
        this.myForm.get('category')?.setValue(id)

      })
      this.auth.get_citizenship().subscribe((res:any)=>{
        if(res.error==false){
          for (let i = 0; i < res.data.length; i++) {  
            if(res.data[i].index == "citizen_primary" && res.data[i].country=="USA"){
              this.distanceUnit_list.push("mile")
              this.myForm.get('weightUnit')?.setValue("lbs")
  
            } else if(res.data[i].index == "citizen_primary" && res.data[i].country!="USA"){

              this.distanceUnit_list.push("km")
              this.myForm.get('weightUnit')?.setValue("kg")
            }
          }
         
          
  
    
       
  
          
          
        }
      })

      this.repss = this.data_edit?.repetition

 }
 start_loading(){
  this.blockUI.start()
 }
 stop_loading(){
  this.blockUI.stop()
 }
 

 checkvalid(group:FormGroup){
   
   let distance = group.controls.distance.value
   let weight = group.controls.weight.value
   
   return ((distance > 0 && weight == 0) ||(weight > 0 && distance == 0)) ? null : { notSame: true }

 }
 checkPasswords(group: FormGroup) { // here we have the 'passwords' group
  let pass = group.controls.distance.value;
  let confirmPass = group.controls.weight.value;

  return ((pass>0) && (confirmPass==0))||((confirmPass > 0) && pass==0) ? null : { notSame: true }
}

get f(){
  return this.myForm.controls;
}
 get_reps(reps){
   return {
    "title":"Ten Times",
    "value":reps
  }
 }


  onSubmit(rep,set){
    this.btn_on = true
    this.submitted = true
    
    this.myForm.get('carePlan')?.setValue(Number(this.care_id))
    this.myForm.get('sequence')?.setValue(1)
    this.myForm.get('numberOfStep').setValue(Number(set))
    if(this.myForm.get('distance').value==0){
      this.myForm.get('distanceUnit').setValue("steps")
      this.myForm.get('repetition').setValue(Number(rep))
      
      
    }  else if(this.myForm.get('weight').value==0){
      this.myForm.get('repetition').setValue(0)
      this.myForm.get('numberOfStep').setValue(0)


    }
    

    //console.log(this.myForm)
    
    if(this.myForm.invalid){
      this.btn_on = false
      return
    }
   
    this.start_loading()
    if(this.myForm.valid){
      this.auth.add_careplan_exercise(this.myForm.value).subscribe((res:any)=>{
        this.toast.success("Added Exercise")
        this.auth.get_careplabexerice(this.care_id).subscribe((res:any)=>{
          this._communication.emitChange(res)
          this.close_dialog()
          this.stop_loading()
         
        })
        
      })
    }
    this.stop_loading()
  }

  onEdit(){
    this.submitted = true
    
    this.myForm.get('carePlan')?.setValue(this.care_id)
    this.myForm.get('sequence')?.setValue(1)
    if(this.myForm.get('distance').value==0){
      this.myForm.get('distanceUnit').setValue("steps")
    } 

    //console.log(this.myForm,this.data_edit?.id)
    this.start_loading()
    if(this.myForm.valid){
      this.auth.update_careplan_exercise(this.myForm.value,this.data_edit?.id).subscribe((res:any)=>{
        this.toast.success("Edited Exercise")
        this.auth.get_careplabexerice(this.care_id).subscribe((res:any)=>{
          this._communication.emitChange(res)
          this.close_dialog()
        })
      })
    }
  this.stop_loading()
  }

  onDelete(){
    this.cbox.confirm("Alert","Are you sure you want to delete this Exercise?").then((res:any)=>{
      if(res){
        this.auth.delete_careplan_exercise(this.data_edit.id).subscribe((res:any)=>{
          this.toast.success("Deleted Sucessfully!!")
          this.auth.get_careplabexerice(this.care_id).subscribe((res:any)=>{
            this._communication.emitChange(res)
            this.close_dialog()
          })
          
        })

      }
    })

 

  }

  close_dialog(){
    if(this.submitted==false && this.myForm.dirty){
      this.save.confirm("Alert").then((res)=>{
        if(res){
          this.dialog.close()
        }
      })
      

    } else {
      this.dialog.close()
    }

  }

}
 <div (click)="openGraph()"  title="Show Historic Data" class="whole-card">
    <mat-card style="background-color: #28293d" class="card-css example-card">
        <!-- <div class="plus-icon">
            <button (click)="openDialog()" (click)="$event.stopPropagation()" title="Add Reading">
                <i class="fas fa-plus"></i>
            </button> 
        </div> -->
        <div class="container">
            <div class=" row">
              <div  class="col-6">
                <div class="heart-img">
                    <!-- <img src="{{card_image}}"  alt=""> -->
                    <i class="{{card_image[0]}}" [style]="card_image[1]"></i>
                </div>
                <p>{{card_details.device.category.name}}</p>
                <h2>{{card_details.value3}} <span>{{card_details.valueUnit3}}</span></h2>
                <p class="time">Healthy Range: {{medium_value3}} to {{high_value3}}</p>
                <p class="time">{{ card_details.readingDateTime }}</p>
              </div>
              <div class="col-6">
                  <p>Systolic</p>
                  <h2>{{ card_details.value1 }} <span>{{ card_details.valueUnit1 }}</span></h2>
                  <p class="time">Healthy Range: {{medium_value1}} to {{high_value1}}</p>
                  <hr class="solid">
                  <p>Diastolic</p>
                  <h2>{{ card_details.value2 }} <span>{{ card_details.valueUnit2 }}</span></h2>
                  <p class="time">Healthy Range: {{medium_value2}} to {{high_value2}}</p>
              </div>
              <div class="w-100"></div>
            </div>
        </div>
    </mat-card>

 </div>
   


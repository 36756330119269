import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-mail-verification',
  templateUrl: './mail-verification.component.html',
  styleUrls: ['./mail-verification.component.scss']
})
export class MailVerificationComponent implements OnInit {
  name = new FormControl('');
  email: any;
  submitted = false;

  message: string = '';
  subscription: any;

  constructor(private toastr: ToastrService, private data: DataService ,private http: HttpClient, private formBuilder: FormBuilder, private router: Router, private authService: AuthServiceService) { }

  myForm = this.formBuilder.group({
    token: ['', Validators.required],
  })
 
  ngOnInit(): void {

    this.subscription = this.data.currentMessage.subscribe(message => {
      this.message = message;
    });
    // //console.log("here is the mail", this.message);

    if(this.message == "" || this.message == "default message") {
      this.router.navigate(['/register']);
    }
  }

  get f() { return this.myForm.controls; }

  resend_token(){
    this.authService.resend_token(this.message).subscribe((res: HttpResponse<any>) => {
        this.toastr.info(res.body.msg);
    })
    // //console.log(this.message);
  }

  onSubmit(token: string){
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    
    this.authService.mail_verification(token, this.message).subscribe((res: HttpResponse<any>) => {
      if(!res.body.error) {
        this.router.navigate(['/login']);
        this.toastr.success(res.body.msg, "Success!");
      } else {
        this.toastr.error(res.body.msg);
      }
    })
    // //console.log(this.message, token);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

import { Component, Inject, OnInit,ChangeDetectorRef,NgZone, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddDeviceDialog } from '../add-device/add-device.component';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import {FocusOrigin} from '@angular/cdk/a11y';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';






@Component({
  selector: 'app-create-new-plan',
  templateUrl: './create-new-plan.component.html',
  styleUrls: ['./create-new-plan.component.scss']
})
export class CreateNewPlanComponent implements OnInit {
  elementOrigin = this.formatOrigin(null);
  subtreeOrigin = this.formatOrigin(null);
  medcon_list: any;
  activities: any;
  categoryvalue :any;
  durationType = 0;
  careplan: any;
  isShow = false;
  category_list:any;

  current_con = '';
  titledesire : string[] = [];
  titlemedication:string[] = [];
  titleschdule:string[] = [];



  



  constructor(private formBuilder: FormBuilder, private auth: AuthServiceService, private router: Router, public dialog: MatDialog , public toast: ToastrService,private _ngZone: NgZone, private _cdr: ChangeDetectorRef) { }

  plan_info_form = this.formBuilder.group({
    medicalCondition: ['', Validators.required],
    description: [''],
    title: ['' , Validators.required],
    category: ['',Validators.required],
    duration: [''],
    durationType: [''],
    desiredOutcome: ['',Validators.required]
  })

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  ngOnInit(): void {
    this.auth.get_medical_condition().subscribe((res:any) =>{
      this.medcon_list = res;
    })
    this.auth.get_category("CAREPLAN").subscribe((res:any)=>{
      this.category_list = res;
     
   })

    this.auth.get_activities().subscribe((res: any) => {
      this.activities = res;
    })
  }

  
 

  onSaveClicked(medCondition, title, description, goal, category, duration, durationType){
    // api call for plan information
    
    //console.log(medCondition,title ,description,goal, category , duration , durationType)
    if(!(this.careplan)){
      this.auth.create_new_careplan(medCondition,title,description ,goal, category , duration , durationType).subscribe((res:any)=>{
        //console.log(res.id)
        this.careplan = res.id;
        if(res){
          this.toast.success("Create Care Plan Added Successfully!!")
        }
        
    })

    }
    
  
  }


 
  // openDialogDesire(title: string, status: any){
  //   this.dialog.open(FormDialog, {
  //     maxHeight: '450px',
  //     data: [title , this.careplan , this.activities , this.medcon_list]
  //   }).afterClosed().subscribe((res) =>{
  //     //console.log(res)
  //     this.titledesire.push(res)
      
  //   })

  // }
  // openDialogActivity(title: string, status: any){
  //   this.dialog.open(FormDialog, {
  //     maxHeight: '450px',
  //     data: [title , this.careplan , this.activities , this.medcon_list]
  //   }).afterClosed().subscribe((res:any)=>{
  //     this.titleschdule.push(res)
      
  //   })

  // }
  // openDialogMedication(title: string, status: any){
  //   this.dialog.open(FormDialog, {
  //     maxHeight: '450px',
  //     data: [title , this.careplan , this.activities , this.medcon_list]
  //   }).afterClosed().subscribe((res:any)=>{
  //     this.titlemedication.push(res)
      
  //   })

  // }
  openDialog(title: string, status: any){

    this.dialog.open(FormDialog, {
      maxHeight: '450px',
      data: [title , this.careplan , this.activities , this.medcon_list ,  status]
    }).afterClosed().subscribe((res:any)=>{
      if(res){

      
      if(title == "desiredOutcome")

      {  this.titledesire.push(res) 
       
      } else if(title == "activities")
      {
        
        this.titleschdule.push(res)

      }
      else if(title == "medication"){
        this.titlemedication.push(res)
      }
    }
      
    })

  }
  
 
  formatOrigin(origin: FocusOrigin): string {
    return origin ? origin + ' focused' : 'blurred';
  }

  // Workaround for the fact that (cdkFocusChange) emits outside NgZone.
  markForCheck() {
    this._ngZone.run(() => this._cdr.markForCheck());
  }
 

  clickMethod() {
    if(confirm("Are you sure you want to go back ?")) {
      this.router.navigate(['/menu/care-plan']);
      
    } 
  }

  

} 

@Component({
  selector: 'form-dialog',
  templateUrl: './form-dialog.html',
  styleUrls: ['./create-new-plan.component.scss']
})
export class FormDialog implements OnInit {
  title: any;
  category_list:any;
  careplan: any;
  categoryvalue:any;
  activites:any;
  activityvalue:any;
  priorityvalue:any;
  medcon_list:any;
  freqvalue:any;
  statusvalue:any;
  formattime:any;
  submitted = false;
  time : string[] = [];
  status_list = [
    {value: 'not-started', viewValue: 'Not Started'},
    {value: 'scheduled', viewValue: 'Scheduled'},
    {value: 'in-progress', viewValue: 'In progress'},
    {value: 'on-hold', viewValue: 'On Hold'},
    {value: 'completed', viewValue: 'Completed'},
    {value: 'cancelled', viewValue: 'Cancelled'},
    {value: 'stopped', viewValue: 'Stopped'},
    {value: 'unknown', viewValue: 'Unknown'},
    {value: 'entered-in-error', viewValue: 'Entered in error'},
  ];
  unitvalue:any;
  unit_list=[ "g", "IU", "mcg", "mcg/hr" , "mcg/ml" , "mEq", "mg", "mg/cm2", "mg/g" , "mg/ml", "mL", "%"];

  exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 12 };

  onChangeHour(event) {
    //console.log('event', event);
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<AddDeviceDialog>, private formBuilder: FormBuilder, private toastr: ToastrService, private authservice: AuthServiceService, private _communicationService: CommunicationService , public router:Router){
    this.title = data[0]
    this.careplan = data[1]
    this.activites = data[2]
    this.medcon_list = data[3]
  }

  myForm = this.formBuilder.group({

  })

  ngOnInit() {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    //console.log(this.title)
    this.authservice.get_category("CAREPLAN").subscribe((res:any)=>{
       this.category_list = res;
       //console.log(this.category_list)
    })
   
  

    switch (this.title) {
      case 'desiredOutcome':
        this.myForm = this.formBuilder.group({
          goalType: ['', Validators.required],
          presentText: [''],
          targetText: [''],
          presentValue: ['', Validators.required],
          targetValue: ['', Validators.required],
          targetDate: ['', Validators.required],
          category: ['', Validators.required],
          carePlan: [''],
          isPublic: [''],
          sequence: ['']
        })
        break;
      case 'activities': 
        this.myForm = this.formBuilder.group({
          title: ['', Validators.required],
          description: ['', Validators.required],
          carePlan: [''],
          category: ['', Validators.required],
          activity: ['', Validators.required],
          priority: [''],
          status: [''],
          dueDate: [''],
          owner: ['']
        })
        break;
      case 'medication':
        this.myForm = this.formBuilder.group({
          medicineName: ['', Validators.required],
          medicalCondition: ['', Validators.required],
          description: [''],
          carePlan: [''],
          category: ['', Validators.required],
          strength: ['', Validators.required],
          unit: ['', Validators.required],
          frequency: ['', Validators.required],
          timeFrequency: [''],
          numberOfTimes: ['', Validators.required],
          startDate: [''],
          endDate: [''],
          instruction: [''],
          isPublic: [''],
          sequence: ['']
        })
        break;
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.myForm.controls;
  }

  save_desired_outcome(goalType,presentValue , targetValue , presentText , targetText , targetDate , category ){
    this.submitted = true
    if (this.myForm.invalid) {
      //console.log("invalid")
      return
       }   
    //console.log(goalType ,presentValue , targetValue , presentText , targetText , targetDate , category , this.careplan)
    if(this.myForm.controls['goalType'].status == 'VALID'){
      this.authservice.add_caregoal(goalType , presentText , targetText , presentValue , targetValue , category , this.careplan , false,this.careplan).subscribe((res:any)=>{
          //console.log(res)
          if(res){
            this.toastr.success("added desired outcomes!!")
          }
      })

  
    }
   
    this.dialog.close(goalType)

  }
  save_activities(title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue){
    this.submitted = true
    if (this.myForm.invalid) {
      return
       }   
    //console.log(title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue)
    this.authservice.add_careplan_schedule(title ,description,categoryvalue,this.careplan,activityvalue,priorityvalue ,statusvalue,dueDate,"doctor").subscribe((res:any)=>{
      //console.log(res)
      if(res){
        this.toastr.success("added care plan activites!!")
      }
    })
    this.dialog.close(title)

  }
 
  save_medication(medicineName ,medCon,categoryvalue,strength,unitvalue,freq ,startDate , endDate? , numberOfTimes?){
    this.submitted = true
    if (this.myForm.invalid) {
      return
       }     
    //console.log(medicineName ,medCon,categoryvalue,strength,unitvalue ,freq , startDate , endDate , numberOfTimes , this.time)
    this.authservice.add_careplan_med(medicineName ,medCon,categoryvalue,this.careplan,strength,unitvalue,freq,this.time,numberOfTimes,startDate,endDate,false).subscribe((res:any)=>{
      //console.log(res)
      if(res){
        this.toastr.success("added care plan medications!!")
      }
    })
    this.dialog.close(medicineName)


  }
   calculateFrequency(freq) 
   {
     var step = 12
     var startTime = 8
    var maxTime = 24
    if(freq <= 2) {

        maxTime = 24

    } else if(freq<= 4) {

        maxTime = 12
        //console.log(maxTime)

    } else if(freq < 6) {

        maxTime = 16

    } else if(freq == 6) {

        maxTime = 14

    } else if(freq == 7 ){

        maxTime = 16

    } else if(freq == 8) {

        maxTime = 18

    } else if(freq == 9) {

        maxTime = 20

    } else if(freq == 10) {

        maxTime = 22

    } else if(freq == 11) {

        maxTime = 23

    } else  {

        maxTime = 24

    }

    if(freq >= 2) {

        if(freq <= 6) {

            step = (Math.ceil(maxTime / freq))
            //console.log(step)

        } else {

            step = (Math.ceil(maxTime / freq))

        }

        var index = 1
         for(let i=2; i < maxTime ;step) {

            // Do something

            if(startTime < 12) {
                
               this.formattime = (((startTime == 0 ? 12 : startTime).toString()).padStart(2,'0')).concat(":00 AM") 
                //time.append((String((startTime == 0 ? 12 : startTime)).leftPadding(toLength: 2, withPad: "0")):00 AM)
                //console.log(this.formattime)
                this.time.push(this.formattime)
                
      
            } else {

                if((startTime-12) < 12) {
                    
                  this.formattime =  (((startTime - 12 == 0 ? 12 : startTime - 12).toString()).padStart(2,'0')).concat(":00 PM") 
              
                  //console.log(this.formattime)
                  this.time.push(this.formattime)
                    

                } else {
                  this.formattime =  (((startTime - 24 == 0 ? 12 : startTime - 24).toString()).padStart(2,'0')).concat(":00 AM") 
                  //this.formattime = (((startTime - 24 == 0 ? 12 : startTime - 24).toString()).padStart(2,'0')).concat(":00 AM") 
                  //console.log(this.formattime)
                  this.time.push(this.formattime)
 
                }

            }

            startTime += step

            index += 1

            if(index > freq) {

                break

            }

        }

    }
    //console.log(this.time)

    return this.time

}

 

  close_dialog(){
    this.dialog.close();
  }
  
}






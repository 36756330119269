<div class="container">
  <div class="card-dialog row justify-content-center">
      <div class="right col-12">
          
      <div class="close-btn">
        <button (click)="close_dialog()" mat-dialog-close>X</button>
      </div>
      <h1 mat-dialog-title>View Document</h1>
       
          <div mat-dialog-content>
            
              <iframe [src]="url" allowfullscreen="" height="500" width="100%"></iframe>
          

             
             
          </div>

      </div>
  </div>
</div>
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { WebRequestService } from './web-request.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, throwError } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';
import { environment } from 'src/environments/environment';
import { MatTabBody } from '@angular/material/tabs';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  @BlockUI() blockUI: any;
  api: any;
  AuthServiceService: any;

  constructor(private connectionService: ConnectionService, private toastr: ToastrService, private webService: WebRequestService, private router: Router, private http: HttpClient) { }

  login(email: string, password: string, logintype: string, action: string) {
    logintype: 'email';
    action: 'login'
    return this.webService.login(email, password, logintype, action).pipe(
      shareReplay(),
      tap((res: HttpResponse<any>) => {
        // the auth tokens will be in the header of this response
		
		if(res.body.token){
			this.setAccessToken(res.body.token, res.body.data.pk, res.body.data.first_name, res.body.data.last_name, res.body.data.uid , res.body.data.email )
        	////console.log(res.body.token);
		}
		
        // //console.log(res);
      })
    )
  }

  signUp(first_name: string, last_name: string, country: string, email: string, password: string){
	return this.webService.signUp(first_name, last_name, country, email, password).pipe(
		shareReplay(),
		tap((res: HttpResponse<any>) => {
			if (typeof(res.body.msg) == "object"){
				//alert("This field is required");
			} else {
				//alert(res.body.msg);
			}
		})
	)
  }  

  create_user_vitacape_consumer(email: string, password: string, name: string, token: string){
	  return this.http.post(`${environment.vitacapeRestUrl}user/create/`, {
		  email, password, name, token
	  }).pipe(
		  map((res: any) => {

			if(res.status==201){ 
				this.router.navigate(['/home'])

			}
			
		  })
		  ,
		  catchError((err: any) => {
			if(err.status==400){
				this.router.navigate(['/home'])
			}
			return EMPTY
		  })
	  )
  }
  login12(data){
	return this.http.post(`${environment.vitacapeRestUrl}/login`,data)

  }
   isAuthenticated(): boolean {
    // get the token
    let token = localStorage.getItem('TOKEN')!
	const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    
  }

  getCountries(){
	  return this.webService.getCountries().pipe(
		shareReplay(),
		  tap((res: HttpResponse<any>) => {
			////console.log(res.body)
		  })
	  )
  }

  

  mail_verification(token: string, email: string){
	return this.webService.mail_verification(token, email).pipe(
		shareReplay(),
		tap((res: HttpResponse<any>) => {
			// if (typeof(res.body.msg) == "object"){
			// 	alert("This field is required");
			// } else {
			// 	alert(res.body.msg);
			// }
		})
	)
  }

  resend_token(email: string){
	  return this.webService.resend_token(email).pipe(
		  shareReplay(),
		  tap((res: HttpResponse<any>) => {
			//alert(res.body.msg);
		  })
	  )
  }

  forgot_password(email: string){
    return this.webService.forgot_password(email).pipe(
		shareReplay(),
		tap((res: HttpResponse<any>) => {
		  //alert(res.body.msg);
		})
	)
  }

  resend_password_token(coffer_id: string){
    return this.webService.resend_password_token(coffer_id).pipe(
		shareReplay(),
		tap((res: HttpResponse<any>) => {
		  //alert(res.body.msg);
		})
	)
  }

  submit_password(coffer_id: string, password: string, token: string){
    return this.webService.submit_password(coffer_id, password, token).pipe(
		shareReplay(),
		tap((res: HttpResponse<any>) => {
		  //alert(res.body.msg);
		})
	)
  }

  service_provider_list() {
    return this.http.get(`${environment.cofferRestUrl}consumer/relationships`, {

		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')
		})
    })
	.pipe(
		 catchError(err => {
			if(err.status == 401 || err.status == 403){
			  ////console.log("error => ",err.status)
			  this.router.navigate(['/login']);
			}
			return "error"
		  })
	)
  }

  notify_user_list(relId) {
    return this.http.get(`${environment.cofferRestUrl}consumer/relationships/${relId}/users/notify`, {

		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')
		})
    })
	.pipe(
		 catchError(err => {
			if(err.status == 401 || err.status == 403){
			 // //console.log("error => ",err.status)
			  this.router.navigate(['/login']);
			}
			return "error"
		  })
	)
  }

  device_list(){
	return this.http.get(`${environment.vitacapeRestUrl}consumer/device/`, {

		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	})
  }

  update_digi_profile(field_name,data){
	return this.http.put(`${environment.cofferRestUrl}consumer/profile/${field_name}/update`,data, {
		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	}) 

  }
  update_password(data){
	return this.http.put(`${environment.cofferRestUrl}consumer/changepassword/update`,data, {
		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	}) 

  }

  get_activity_feed(){
	return this.http.get(`${environment.vitacapeRestUrl}consumer/activitylog/`, {

		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	})
  }
  get_activity_feed_tilldate(tilldate){
	return this.http.get(`${environment.vitacapeRestUrl}consumer/activitylog/?tilldate=${tilldate}`, {

		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	})

  }
  add_new_device(name: string, category: number, serviceUid: string, manufacturer: string, systemId: string, modelNumber: number, serialNumber: number, isBLE: boolean, paired: boolean, status: string){
	return this.http.post(`${environment.vitacapeRestUrl}consumer/device/`, {name, category, serviceUid, manufacturer, systemId, modelNumber, serialNumber, isBLE, paired, status}, {

		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	})
  }

  update_profile_list(id: number, profile: any){
	  return this.http.patch(`${environment.vitacapeRestUrl}consumer/profile/${id}/`, profile ,{
		  headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			"Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
		  })
	  })
  }
 
	create_profile_list(profile: any){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/profile/`, profile ,{
			headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			"Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
			})
		})
	}
	get_all_personal_doc(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/profile/`,{
			headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			
			})
		})
	}




 share_with_me_action(id,docid,action){
	return this.http.get(`${environment.cofferRestUrl}consumer/v2/relationships/${id}/${docid}/${action}`, {
		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	}) 

 }
 get_aff_type_con(con){
	return this.http.get(`${environment.cofferRestUrl}consumer/citizenship/${con}/affiliations`, {
		headers: new HttpHeaders({
			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
		})
	}) 

 }

  
	add_device_reading(device: number, sequence: number, readingDateTime: string, value1: number, value2: number, value3: number, valueUnit1: string, valueUnit2: string, valueUnit3: string, tag: number){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/devicereading/`, {device, sequence, readingDateTime, value1, value2, value3, valueUnit1, valueUnit2, valueUnit3, tag}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
				"Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
			})
	
		})
	}

	relationship_list(){
		return this.http.get(`${environment.cofferRestUrl}consumer/sprelationship/search/entity`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			}) 
		})
	}

  

	send_request(description: string, entityId: string){
		return this.http.post(`${environment.cofferRestUrl}consumer/sprelationship/request/entity`, {description, entityId}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})
	}

	service_provider_accept(entityId){
		return this.http.put(`${environment.cofferRestUrl}consumer/relationships/${entityId}/confirm `, {field: "isaccepted", isaccepted: true}
		, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})
	}
   
	view_general_doc(rel_id,doc_id){

		return this.http.get(`${environment.cofferRestUrl}consumer/v2/relationships/${rel_id}/${doc_id}/view`
		, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})
	}
	download_general_doc(rel_id,doc_id){
		return this.http.get(`${environment.cofferRestUrl}consumer/v2/relationships/${rel_id}/${doc_id}/download`
		, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}
	get_audit_trails(){
		return this.http.get(`${environment.cofferRestUrl}consumer/audit`
		, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}

	device_reading(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/devicereading/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN') + " " + localStorage.getItem('PK')
			})
		})
	}

  	get_tag_list(a: Number){
		return this.http.get(`${environment.vitacapeRestUrl}setting/tags/?catid=${a}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	get_vitacape_devices(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/categories/?type=DEVICE`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	profile_details(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/profile/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	get_ethnicity(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/ethnicity/` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
				
			})

		})
	}

	get_bloodgrps(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/bloodgroup/` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
				
			})

		})

	}
	get_allergic_reaction(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/allergicreaction/` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
				
			})

		})

	}

	get_medical_condition(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/medicalcondition/` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
				
			})

		})

	}

	get_vaccine_list(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/vaccine/` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
				
			})

		})

	}

	data_last_reading(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/devicelastreading/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	healthy_range(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/deviceType/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	get_care_plan(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanlist/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	add_plan(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/careplans/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}
    

	update_activity_log(id:number, status: any , completedDateTime: any){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/activitylog/${id}/`,
		{ status, completedDateTime },{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			  "Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
			})
		})
	}

	update_general_profile(id:number, firstName: any , lastName: any , dob : any){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/profile/${id}/`,
		{ firstName , lastName , dob },{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			  "Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
			})
		})
		
	}

	get_activities(){
		return this.http.get(`${environment.vitacapeRestUrl}setting/activities/`,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			  })
		})
	}

	get_category(type){
		return this.http.get(`${environment.vitacapeRestUrl}setting/categories/?type=${type}`,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			  })
		})

	}

	subscribe_careplan(id: string){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplanlist/`, {id}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}





	create_new_careplan(medCondition: string, title: string, description: string , goal: string, category: number, duration: number, durationType: string){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplanlist/ `, {medCondition, title , goal,description, category, duration, durationType}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}


	add_caregoal( goalType: string, presentText: string,targetText: string,presentValue: number,targetValue: number,category: number,carePlan: number,isPublic: boolean,sequence: number){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplangoal/`, {goalType, presentText , targetText, presentValue,targetValue, category , carePlan , isPublic , sequence}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	add_careplan_schedule( title: string, description: string,category: number,carePlan: number,activity: number , priority :any,status:any,dueDate:Date ,owner:any){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplanschedule/`, {title,description,category,carePlan,activity,priority,status,dueDate,owner}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	add_careplan_med( medicineName: string,medicalCondition:string,category: number,carePlan: number,strength: number , unit :any,frequency:any,timeFrequency:any , numberOfTimes:any , startDate :any , endDate :any , isPublic:boolean ){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplanmedication/`, {medicineName,medicalCondition  ,category, carePlan,strength,unit,frequency,timeFrequency,numberOfTimes,startDate, endDate , isPublic }, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}
	add_careplan_exercise(data){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplanexercise/`, data , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
				
			})
		})

	}

	update_careplan_exercise(data,id){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/careplanexercise/${id}/`, data , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	delete_careplan_exercise(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/careplanexercise/${id}/` , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}
	get_care_plan_id(id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanlist/${id}/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}
    
	get_careplangoal(id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplangoal/?planid=${id}`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			  
			})
		})

	}
	get_careplandiet(id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplandiet/?planid=${id}`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			  
			})
		})

	}
	get_careplabexerice(id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanexercise/?planid=${id}`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
			  
			})
		})

	}

	get_careplanschedule(id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanschedule/?planid=${id}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	delete_device(deviceId){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/device/${deviceId}/`, { "isDeleted": true },{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	get_careplanmed(id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanmedication/?planid=${id}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	edit_careplangoal(id,goal){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/careplangoal/${id}/`, goal ,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}
	edit_careplanmed(id,med){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/careplanmedication/${id}/`, med , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}
	edit_careplan(id,care){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/careplanlist/${id}/`, care , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	edit_careplanschedule(id,activity){
		return this.http.patch(`${environment.vitacapeRestUrl}consumer/careplanschedule/${id}/ `, activity , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	subscribed_careplan(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplan/ `,  {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})

		})
	}
	subscribeto_careplan(plan){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/careplan/ `, {plan} ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})

		})
	}

	delete_careplangoal(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/careplangoal/${id}/` ,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}
	delete_careplanmed(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/careplanmedication/${id}/`,  {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}
	delete_careplan(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/careplanlist/${id}/` , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	delete_careplanschedule(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/careplanschedule/${id}/` , {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	get_careplangoalall(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplangoal/`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}
	get_all_docs(){
		return this.http.get(`${environment.cofferRestUrl}consumer/all/docs`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	get_careplanscheduleall(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanschedule/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	get_careplanmedall(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/careplanmedication/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	// accept_service_provier_requset(id){
	// 	return this.http.post(`${environment.cofferRestUrl})/consumer/sprelationship/request/entity`, {
	// 		headers: new HttpHeaders({
	// 			"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
	// 		})
	// 	})
		
	// }
    
	care_team_list_all(){
		return this.http.get(`${environment.cofferRestUrl}consumer/sprelationship/search/consumer`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}

	care_team_send_request(description , consumerId){
		return this.http.post(`${environment.cofferRestUrl}consumer/sprelationship/request/consumer`, {description , consumerId},{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}

	care_team_accept_request(id){
		return this.http.post(`${environment.cofferRestUrl}consumer/sprelationship/${id}/accept`, { "response": "accept" },{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}

	lastest_device_reading(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/devicelatestreading/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
		
	}
   
	upload_image(file){
		return this.http.post(`${environment.cofferRestUrl}consumer/profile/pic/upload`, file ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})

		}) 
	}

	decrypt(docid){
		return this.http.post(`${environment.cofferRestUrl}consumer/document/decrypt/${docid}`,{},{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			}), responseType:'arraybuffer'

		}) 
	}

	doc2pdf(data){
		return this.http.post(`${environment.DOC2FILE}`, data ,{
			headers: new HttpHeaders({
			}), responseType:'arraybuffer'

		}) 

	}

	doc2pdfurl(data){
		return this.http.post(`${environment.DOC2PDF}`, data ,{
			headers: new HttpHeaders({
			}), responseType:'arraybuffer'

		}) 

	}

	get_image(){
		return this.http.get(`${environment.cofferRestUrl}consumer/profile/pic`,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})

		}) 

	}

	post_quesres(res){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/questionresponse/`, res, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	update_quesres(res){
		return this.http.put(`${environment.vitacapeRestUrl}consumer/questionresponse/`, res, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})

	}

	get_quesres(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/questionresponse/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}

	delete_quesres(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/questionresponse/${id}   `, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		})
	}


	get_chartreading(device_id){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/devicereading/?devicetype=${device_id}&rows=10`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_activitylog_date(date){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/activitylog/?date=${date}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_medical_log(){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/medicationlog/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_reminders(){
		return this.http.get(`${environment.cofferRestUrl}consumer/reminders`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_diet_status(date){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/dietstatus/?date=${date}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_exercise_status(date){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/exercisestatus/?date=${date}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	add_diet_reading(data){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/dietreading/`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
				"Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
			})
		}) 

	}

	get_diet_graph(date){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/dietrequired/?date=${date}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	add_exercise_reading(data){
		return this.http.post(`${environment.vitacapeRestUrl}consumer/exercisereading/`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK'),
				"Accept-Language": Intl.DateTimeFormat().resolvedOptions().timeZone
			})
		}) 

	}

	get_diet_reading(date){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/dietreading/?date=${date}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_exercise_reading(date){
		return this.http.get(`${environment.vitacapeRestUrl}consumer/exercisereading/?date=${date}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	delete_exercise_reading(id){
		return this.http.delete(`${environment.vitacapeRestUrl}consumer/exercisereading/${id}/`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	add_reminders(message,target){
		return this.http.post(`${environment.cofferRestUrl}consumer/reminders/add`,{message,target}, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	delete_reminder(id){
		return this.http.delete(`${environment.cofferRestUrl}consumer/reminders/${id}/delete`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_notifications(){
		return this.http.get(`${environment.cofferRestUrl}consumer/notifications`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	delete_notifications(id){
		return this.http.request('delete',`${environment.cofferRestUrl}consumer/notifications/delete`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			}),
			body:[id]
		}) 

	}

	read_notification(id){
		return this.http.put(`${environment.cofferRestUrl}consumer/notifications/read`,[id], {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}


	get_primary_citizen_docs(){
		return this.http.get(`${environment.cofferRestUrl}consumer/identity/citizen_primary`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_second_citizen_docs(){
		return this.http.get(`${environment.cofferRestUrl}consumer/identity/citizen_second`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_third_citizen_docs(){
		return this.http.get(`${environment.cofferRestUrl}consumer/identity/citizen_third`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_crediential_docs(country){
		return this.http.get(`${environment.cofferRestUrl}consumer/identity/${country}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

		
	}

	add_crediential_docs(category,data){ 
		return this.http.post(`${environment.cofferRestUrl}consumer/identity/${category}/add`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}           
	update_credentails(category,type,data){
		return this.http.put(`${environment.cofferRestUrl}consumer/identity/${category}/${type}/update`,data ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	update_personal(category,id,data){
		return this.http.put(`${environment.cofferRestUrl}consumer/personal/${category}/${id}/update`,data ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	download_credentials(category,type){
		return this.http.get(`${environment.cofferRestUrl}consumer/identity/${category}/${type}/download` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	download_personal(id,category){
		return this.http.get(`${environment.cofferRestUrl}consumer/personal/${category}/${id}/download` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_personal_docs_tag(tag,country){
		return this.http.get(`${environment.cofferRestUrl}consumer/personal/tagged/${country}/${tag}` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	add_entity_dummy(data){
		return this.http.post(`${environment.cofferRestUrl}consumer/dummyrelation/create` ,data,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_entity_dummy(){
		return this.http.get(`${environment.cofferRestUrl}consumer/dummyrelation` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	edit_entity_dummy(id,data){
		return this.http.put(`${environment.cofferRestUrl}consumer/dummyrelation/update/${id}`,data ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	delete_entity_dummy(id){
		return this.http.delete(`${environment.cofferRestUrl}consumer/dummyrelation/delete/${id}` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	add_personal_doc(category,data){
		return this.http.post(`${environment.cofferRestUrl}consumer/personal/${category}/add`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 


	}
	delete_personal_doc(type,id){
		return this.http.delete(`${environment.cofferRestUrl}consumer/personal/${type}/${id}/delete`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 
		
	}

	get_tag_counts(type){
		return this.http.get(`${environment.cofferRestUrl}consumer/personal/counts/${type}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	delete_credentiall_docs(index,type){
		return this.http.delete(`${environment.cofferRestUrl}consumer/identity/${index}/${type}/delete`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_personal_docs(country){
		return this.http.get(`${environment.cofferRestUrl}consumer/personal/${country}` ,{
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	view_docs(category,name){
		return this.http.get(`${environment.cofferRestUrl}consumer/identity/${category}/${name}/view`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 
	}
	view_personal_doc(category,id){
		return this.http.get(`${environment.cofferRestUrl}consumer/personal/${category}/${id}/view`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
    get_relationship_bytag(tag){
		return this.http.get(`${environment.cofferRestUrl}consumer/relationships/bytag/${tag}`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
	get_relationship_tag_count(){
		return this.http.get(`${environment.cofferRestUrl}consumer/relationships/tagcount`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	share_documents(){
		return this.http.put(`${environment.cofferRestUrl}consumer/relationships/bytag`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	shared_with_me(id){
		return this.http.get(`${environment.cofferRestUrl}consumer/v2/relationships/${id}/docs/shared/withme`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}
    
	shared_by_me(id){
		return this.http.get(`${environment.cofferRestUrl}consumer/v2/relationships/${id}/docs/shared/byme`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 


	}

	

	get_doc_details(type,citizen,doc_id){
		return this.http.get(`${environment.cofferRestUrl}consumer/${type}/${citizen}/${doc_id}/details`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	unshare_realtionship_document(id,data){
		return this.http.post(`${environment.cofferRestUrl}consumer/v2/relationships/${id}/docs/unshare`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 


	}
	

	share_realtionship_document(id,data){
		return this.http.post(`${environment.cofferRestUrl}consumer/v2/relationships/${id}/docs/share`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 


	}

	add_country(data){
		return this.http.put(`${environment.cofferRestUrl}consumer/profile/addcountry`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	edit_country(index_country,data){
		return this.http.put(`${environment.cofferRestUrl}consumer/profile/citizenship/${index_country}/update`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	delete_country(index_country){
		return this.http.delete(`${environment.cofferRestUrl}consumer/profile/citizenship/${index_country}/delete`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_digicoffer_profile(){
		return this.http.get(`${environment.cofferRestUrl}consumer/profile`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	get_storage(){
		return this.http.get(`${environment.cofferRestUrl}consumer/storage`, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	update_digicoffer_profile(data){
		return this.http.put(`${environment.cofferRestUrl}consumer/profile/update`,data, {
			headers: new HttpHeaders({
				"Authorization": "Bearer " + localStorage.getItem('TOKEN')+ " " + localStorage.getItem('PK')
			})
		}) 

	}

	















  get(url: string) {
		this.blockUI.start('Loading...');
		return this.http.get(url, this.get_httpoptions()).pipe(map(
			(res: any) => {
				this.blockUI.stop();
				return res;
			}
		),
			catchError(err => {
				this.handleError(err)
				return throwError(err);
			})
		)
	}  

  post(url: string, data: any) {
		this.blockUI.start('Loading...');
		return this.http.post(this.api + url, data, this.get_httpoptions()).pipe(map(
			(res: any) => {
				this.blockUI.stop();
				return res
			}
		),
			catchError(err => {
				this.handleError(err)
				return throwError(err);
			})
		)
	}

  get_httpoptions() {
			const httpOptions = {
				headers: new HttpHeaders({
					"Authorization": "Bearer " + localStorage.getItem('TOKEN')
				})
			};
			return httpOptions
	}

	get_citizenship(){
		return this.http.get(`${environment.cofferRestUrl}consumer/all/citizenship`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}

	get_questions(id){
		return this.http.get(`${environment.vitacapeRestUrl}setting/careplanquestions/?planid=${id}`,{
			headers: new HttpHeaders({
			  "Authorization": "Bearer " + localStorage.getItem('TOKEN')
			})
		})

	}


  handleError(error: Response | any) {
		this.blockUI.stop();
		if (error.status == 401) {
			localStorage.clear()
			localStorage.setItem('error_alert_msg', "Session expired, Login to continue")
			this.router.navigate(['/login'])
		}
		else {
			if (this.check_internet()) {
				this.error_alert("Error, Please try again or contact support")
			}
			else {
				this.error_alert("Please check your internet connectivity")
			}
		}
	}

  error_alert(message) {
		this.toastr.error(message, 'Alert');
	}

  check_internet() {
		this.connectionService.monitor().subscribe(isConnected => {
			if (isConnected) {
				return true;
			}
			else {
				return false;
			}
		})
		return false;
	}

	

//   getAccessToken() {
//     return localStorage.getItem('TOKEN');
//   }

//   getRefreshToken() {
//     return localStorage.getItem('x-refresh-token');
//   }

  setAccessToken(accessToken: string, pk: string, fn: string, ln: string, jid: string , email:any) {
    localStorage.setItem('TOKEN', accessToken);
	localStorage.setItem("PK", pk);
	localStorage.setItem("first_name", fn);
	localStorage.setItem("last_name", ln)
	localStorage.setItem("jid", jid)
	localStorage.setItem("email",email)
	
  }

  

  removeAccessToken() {
    localStorage.removeItem('TOKEN');
	localStorage.removeItem('PK');
	localStorage.removeItem("first_name");
	localStorage.removeItem("last_name")
	localStorage.removeItem('jid')
	localStorage.removeItem("email")
	localStorage.removeItem("dob")

  }


	
  
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';

@Component({
  selector: 'app-activity-dash',
  templateUrl: './activity-dash.component.html',
  styleUrls: ['./activity-dash.component.scss']
})
export class ActivityDashComponent implements OnInit {

  card_image: any;
  date = new Date();
  data:any;
  status:any;
  activityDateTime: any;
  maxDate = new Date();
  today_date = this.dpipe.transform(this.date,'YYYYMMdd');
  toogle = new FormControl(true, []);
  toggle_status = true;
  no_data=false;

  constructor(public auth:AuthServiceService , public dpipe:DatePipe , public toast:ToastrService , public _communication:CommunicationService) { }

  ngOnInit(): void {

    this.toogle.valueChanges.subscribe(newToogleValue=> {
     
      this.toggle_status = newToogleValue
   });
    
    
    this.auth.get_activitylog_date(this.today_date).subscribe((res:any)=>{
      this.data = res.filter(item=>{
        return (item.status !='COMPLETED') && (item.canChangeStatus== true)
      })
     
  
    })

  }
  
   onChange(event){
   this.toggle_status = event.checked
     if(event.checked == true){
       this.data = this.data.filter(item=>{
         return (item.status !='COMPLETED') && (item.canChangeStatus== true)
       })
       if(this.data){
         this.no_data = true

       }
     } else if(event.checked == false){
      this.auth.get_activitylog_date(this.today_date).subscribe((res:any)=>{
        this.data = res;
      
    
      })
     }

   }

  OnClickDone(id:any , inputValue:any ){

    this.status = "COMPLETED"


    if(inputValue == ""){
      this.activityDateTime = new Date();
    } else {
      this.activityDateTime = inputValue;
    }
  
    let formatDate = this.dpipe.transform(this.activityDateTime , "MM-dd-yyyy, hh:mm a")
     
   
    this.auth.update_activity_log(id , this.status , formatDate).subscribe((res:any) => {
     
      if(res){
        if(this.toggle_status){
             
      this.auth.get_activitylog_date(this.today_date).subscribe((res:any)=>{
        this.data = res.filter(item=>{
          return (item.status !='COMPLETED') && (item.canChangeStatus== true)
        })
      
    
      })

        } else {
          this.auth.get_activitylog_date(this.today_date).subscribe((res:any)=>{
            this.data = res
        
          })
        }
     

      }
      this.toast.success("Completed this task!!");
    })
  }

}

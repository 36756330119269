<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    
    <div class="menu-comp">
        <p class="heading">Home</p>
        <div class= "menu_list row row-cols-1 row-cols-md-3 g-3" >
            <div *ngFor="let item of menu_list" class="col">
                <a routerLink="{{item.link}}" class="card h-100"  title="Click to Navigate">
                    <div class="card-body">
                    <h2 class="card-title"><span><i class="{{item.icon}}"></i></span> <br/>{{item.name}}</h2>
                </div>

            </a>
           
            </div>
        </div> 
        
    </div>
    

    <div>
        
      
        <div *ngIf="data!=0">
            <app-activity-dash></app-activity-dash>
        </div>
        <div>
            <app-connection></app-connection>
        </div>
        <div>
            <app-care-plan-dash></app-care-plan-dash>
        </div>
    </div>
    <p *ngIf="reminders==0 && data== 0 && service_provider_lists==0" class="no-activity">You do not have any action items or activities today</p>
</div>
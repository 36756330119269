<div class="container cnt-dl">
  <div class=" card-dialog row justify-content-center">
    <div class="left col-4">
      <p>Glucose</p>
      <p >Pulse</p>
      <p class="card-selected">Blood Pressure</p>
      <p>Weight</p>
      <p>Temperature</p>
    </div>
    <div class="right col-8">
      <div class="close-btn">
        <button (click)="close_dialog()">X</button>
      </div>
      <form [formGroup]="myForm" >
        <div class="first-row inp">
          <p>Pulse<span> . {{current_card.valueUnit3}}</span></p>
          <div class="signForm-row " id="third_reading">
            <input pattern="^[0-9]*$" #thirdReading formControlName='thirdReading' name="thirdReading" class="form-control" id='id_third_reading' type="text" cdkFocusInitial  maxlength="3"/>
            <div *ngIf="f['thirdReading'].errors" class="text-danger">
              <div *ngIf="f['thirdReading'].errors.required && (f['thirdReading'].touched || submitted)">This field is required</div>
              <div *ngIf="f['thirdReading'].errors.pattern">Please enter numeric value</div>
              <div *ngIf="f['thirdReading'].errors.min">Please enter value greater than 10</div>
              <div *ngIf="f['thirdReading'].errors.msg">{{f['thirdReading'].errors.msg}}</div>
            </div>
          </div>
        </div>
        <div class="second-row">
          <div class="inp">
            <p>Systolic <span> . {{current_card.valueUnit1}}</span></p>
            <div class="signForm-row " id="first_reading">
              <input pattern="^[0-9]*$" #firstReading formControlName='firstReading' name="firstReading" class="form-control" id='id_first_reading' type="text" cdkFocusInitial  maxlength="3"/>
              <div *ngIf="f['firstReading'].errors" class="text-danger">
                <div *ngIf="f['firstReading'].errors.required && (f['firstReading'].touched || submitted)">This field is required</div>
                <div *ngIf="f['firstReading'].errors.pattern">Please enter numeric value</div>
                <div *ngIf="f['firstReading'].errors.min">Please enter value greater than 10</div>
                <div *ngIf="f['firstReading'].errors.msg">{{f['firstReading'].errors.msg}}</div>
              </div> 
            </div>
          </div>
          <div>
            <p>Diastolic <span> . {{current_card.valueUnit1}}</span></p>
            <div class="signForm-row " id="second_reading">
              <input pattern="^[0-9]*$" #secondReading formControlName='secondReading' name="secondReading" class="form-control" id='id_second_reading' type="text" cdkFocusInitial  maxlength="3"/>
              <div *ngIf="f['secondReading'].errors" class="text-danger">
                <div *ngIf="f['secondReading'].errors.required && (f['secondReading'].touched || submitted)">This field is required</div>
                <div *ngIf="f['secondReading'].errors.min">Please enter value greater than 10</div>
                <div *ngIf="f['secondReading'].errors.pattern">Please enter numeric value</div>
                <div *ngIf="f['secondReading'].errors.msg">{{f['secondReading'].errors.msg}}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="tag_list != null" class="tag-section">
          <button (click)="tag_btn(tag.id)" *ngFor="let tag of tag_list">{{tag.description}}</button>
        </div>
        <div >
          <div class="date-time-select">
            <mat-form-field class="calendar" appearance="fill">
              <mat-label>Choose a date</mat-label>

              

              <!-- <input readonly [formControl]="dateSelected"  matInput [matDatepicker]="dp3" > -->
              <input [max]="maxDate" readonly #dateSelected [value]="date1.value"  matInput [matDatepicker]="dp3" >

              <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
              <mat-datepicker #dp3 ></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="time-select">
            <mat-form-field class="set-time" appearance="fill">
              <mat-label>HH</mat-label>
              <mat-select [(value)]="hour_value">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let item of counter(13);let i= index" value="{{i}}">{{i}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="set-time" appearance="fill">
              <mat-label>MM</mat-label>
              <mat-select [(value)]="minute_value">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let item of counter(60); index as i" value="{{i}}">{{i}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="set-time" appearance="fill">
              <mat-label>AM/PM</mat-label>
              <mat-select [(value)]="am_pm">
                <mat-option>None</mat-option>
                <mat-option *ngFor="let item of ampm_list" value="{{item}}">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="update">
          <p>Last Updated: {{last_updated}}</p>
        </div>
        <div class="save-button">
          <button (click)="save_data(firstReading.value, secondReading.value, thirdReading.value, dateSelected.value, hour_value, minute_value, am_pm, current_card.valueUnit1, current_card.valueUnit2, current_card.valueUnit3)">Save</button>
        </div>
      </form>
    </div>
    
  </div>  
</div>
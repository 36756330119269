<div class="add-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">Care Plan</p>
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of add_plan; let i = index" >
            <mat-card>
                <!-- <div class="count-btn">
                    <button>3</button>
                </div> -->
                <div class="card-cnt">
                    <i class="far fa-file-alt fa-3x"></i>
                    <p class="card-title">{{item.title}}</p>
                    <p>{{item.description}} </p>
                </div>
                <div class="edit-btn" *ngIf="checksub(item.id , i)">
                    <button (click)= "onSelectCard(item)">Subscribe</button>
                </div>
            </mat-card>
        </div>
        
    </div>
</div>
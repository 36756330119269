


 <div class="care-plan">
    <app-menu-comp></app-menu-comp>
    
    
        
     
        
    <p class="heading">Goals</p>
        
            
                    <div class="goal-list row row-cols-1 row-cols-md-3  g-3">
                        <div class="col" *ngFor="let item of goal;let num=index;">
                            <mat-card>
                                <div class="content">
                                    <div class="count">
                                    <span>{{num+1}}</span>
                                    </div>
                                    <div class="card-cnt">
                                        <!-- <i class="fas fa-file-medical fa-3x"></i> -->
                                        <p class="card-title">{{item.goalType}}</p>
                                        <p>{{item.presentText}}</p>
                                    </div>

                                </div>
                                
                                
                                <!-- <div class="edit-btn">
                                    <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                                </div> -->
                            </mat-card>
                        </div>
                       
                        
                    </div>
                    
    
        
     
    <div>  
        <p class="head-2">Diet</p>
        
                
            <div class="card-list row row-cols-1 row-cols-md-3 g-3">
                <div class="col" *ngFor="let item of diet">
                    <mat-card>
                        <div class="content">
                            <div class="count">
                            <i class="fas fa-utensils" style="color: rgb(247, 91, 91);"></i>
                            </div>
                            <div class="card-cnt">
                                <!-- <i class="fas fa-file-medical fa-3x"></i> -->
                                <p class="card-title">{{item.title}}</p>
                                <p>{{item.qty | number: '1.0-0'}}&nbsp;{{item.unit}}</p>
                            </div>

                        </div>
                        
                        
                        <!-- <div class="edit-btn">
                            <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                        </div> -->
                    </mat-card>
                </div>
            
                
            </div>
    

        <div>
            <div class="head-btn">
            <p class="head-2">Exercise</p>
            <div class="example-button-container pull-right" title="Click to Add">
                <button mat-mini-fab color="primary" (click)="onclick()" aria-label="Example icon button with a menu icon">
                <mat-icon>add</mat-icon>
                </button>
            </div>
            </div>

    
    

    
            <div class="card-list row row-cols-1 row-cols-md-3 g-3">
                <div class="col" *ngFor="let item of exercise">
                    <mat-card>
                        <div class="content">
                            <div class="count">
                            <i class="fas fa-running" style="color: rgb(247, 91, 91);"></i>
                            </div>
                            <div class="card-cnt">
                                <!-- <i class="fas fa-file-medical fa-3x"></i> -->
                                <p class="card-title">{{item.title}}</p>
                                <p *ngIf="item.distance!=0">{{item.distance | number: '1.0-0'}}&nbsp;{{item.distanceUnit}}</p>
                                <p *ngIf="item.distance==0">{{item.weight | number: '1.0-0'}}&nbsp;{{item.weightUnit}}</p>
                            </div>

                        
                        

                        </div>
                        <div class="right-icon">
                            <button (click)="onEdit(item)" title="Click to Edit">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                    
                        
                        
                        <!-- <div class="edit-btn">
                            <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                        </div> -->
                    </mat-card>
            </div>
           
            
        </div>

    
    <p class="head-2">Medications</p>
    <div>
        <div class="card-list row row-cols-1 row-cols-md-3 g-3">
            <div class="col" *ngFor="let item of med">
                <mat-card>
                    <div class="content">
                        <div class="count">
                        <i class="fas fa-pills" style="color: rgb(91, 153, 247)"></i>
                        </div>
                        <div class="card-cnt">
                            <!-- <i class="fas fa-file-medical fa-3x"></i> -->
                            <p class="card-title">{{item.medicationName}}</p>
                            <p>{{item.strength}}&nbsp;{{item.unit}}&nbsp;{{item.numberOfTimes}}&nbsp;times a day</p>
                        </div>

                    </div>
                    
                    
                    <!-- <div class="edit-btn">
                        <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                    </div> -->
                </mat-card>
            </div>
           
            
        </div>

    </div>
       
        
    
   
   
</div>
<block-ui></block-ui>
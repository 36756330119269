import { Component, Input, OnInit } from '@angular/core';
import {MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
//import {VERSION} from '@angular/material';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  currentTabValue: any;
  tabChanged1: any;
  options = ['option 1', 'option 2', 'option 3'];
  genders = ['Male', 'Female', 'Others'];
  
  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('PK');
  }
  
  public demo1TabIndex = 0;
  public nextTab() {
    const tabCount = 4;
    this.demo1TabIndex = (this.demo1TabIndex + 1) % tabCount;
  }
  public prevTab(){
    const tabCount = 4;
    this.demo1TabIndex = (this.demo1TabIndex - 1) % tabCount;
  }
}

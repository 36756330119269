import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/angular';


@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    height: '77vh'
  };

  constructor(public router : Router) { }

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PatientProfilePageComponent } from './patient-profile-page/patient-profile-page.component';
import { SettingsComponent } from './settings/settings.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { MailVerificationComponent } from './mail-verification/mail-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MenuComponent } from './menu/menu.component';
import { LogoutPageComponent } from './logout-page/logout-page.component';
import { AddDeviceComponent } from './add-device/add-device.component';
import { HealthInfoComponent } from './health-info/health-info.component';
import { ProfileComponent } from './profile/profile.component';
import { ActivityFeedComponent } from './activity-feed/activity-feed.component';
import { CarePlanComponent } from './care-plan/care-plan.component';
import { CareTeamComponent } from './care-team/care-team.component';
import { ServiceProviderComponent } from './service-provider/service-provider.component';
import { CalenderComponent } from './calender/calender.component';
import { AddPlanComponent } from './add-plan/add-plan.component';
import { CreateNewPlanComponent } from './create-new-plan/create-new-plan.component';
import { EditCarePlanComponent } from './edit-care-plan/edit-care-plan.component';
import { ChatComponent } from './chat/chat.component';
import { ViewCarePlanComponent } from './view-care-plan/view-care-plan.component';
import { AddOnsComponent } from './add-ons/add-ons.component';
import { SurveyComponent } from './survey/survey.component';
import { HomepageComponent } from './homepage/homepage.component';
import { DocumentsComponent } from './documents/documents.component';
import { ConnectionsComponent } from './connections/connections.component';
import { ActivityDashComponent } from './activity-dash/activity-dash.component';
import { RemindersComponent } from './reminders/reminders.component';
import { NotificationComponent } from './notification/notification.component';
import { AuditTrailComponent } from './audit-trail/audit-trail.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: SignUpComponent },
  { path: 'dashboard', component: PatientProfilePageComponent },
  { path: 'home' , component: HomepageComponent},
  { path:'documents' , component:DocumentsComponent},
  { path:'connections' , component : ConnectionsComponent},
  { path: 'menu/settings', component: SettingsComponent },
  { path: 'mail-verification', component: MailVerificationComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'menu', component: MenuComponent },
  { path: 'menu/add-device', component: AddDeviceComponent },
  { path: 'logout', component: LogoutPageComponent },
  { path: 'update-profile', component: HealthInfoComponent },
  { path: 'menu/health-info', component: ProfileComponent },
  { path: 'menu/activity-tracking', component: ActivityFeedComponent },
  { path: 'menu/care-plan', component: CarePlanComponent },
  { path: 'menu/care-team', component: CareTeamComponent },
  { path: 'menu/service-provider', component: ServiceProviderComponent },
  { path: 'menu/calender', component: CalenderComponent },
  { path: 'menu/care-plan/add-plan', component: AddPlanComponent },
  { path: 'menu/care-plan/create-new-plan', component: CreateNewPlanComponent },
  { path: 'menu/care-plan/edit-care-plan/:id', component: EditCarePlanComponent },
  { path: 'menu/chat', component: ChatComponent },
  { path: 'menu/chat/:id', component: ChatComponent },
  { path: 'menu/care-plan/view-care-plan/:id', component: ViewCarePlanComponent },
  { path: 'menu/care-plan/view-care-plan/:id/:cat', component: AddOnsComponent },
  { path: 'menu/care-plan/survey/:id', component: SurveyComponent},
  { path:'menu/activity',component:ActivityDashComponent},
  { path:'reminders',component:RemindersComponent},
  { path:'notify',component: NotificationComponent},
  { path:'audit',component:AuditTrailComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

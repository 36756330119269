import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { observable } from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebRequestService {

  constructor(private http: HttpClient) { }

  login(email: string, password: string, logintype: string, action: string) {
    return this.http.post(`${environment.cofferRestUrl}consumer/login`, {
      email,
      password,
      logintype,
      action
    }, { observe: 'response'})
  }

  signUp(first_name: string, last_name: string, country: string, email: string, password: string){
    return this.http.post(`${environment.cofferRestUrl}consumer/register`, {
      first_name,
      last_name,
      country,
      email,
      password
    }, {observe: 'response'})
  }

  create_user_vitacape_consumer(email: string, password: string, name: string, token: string){
    return this.http.post(`${environment.vitacapeRestUrl}user/create/`, {
      email,
      password,
      name,
      token
    }, {observe: 'response'})
  }

  getCountries(){
    return this.http.get<any>(`${environment.cofferRestUrl}consumer/countries`)
  }

  mail_verification(token: string, email: string){
    return this.http.post(`${environment.cofferRestUrl}consumer/register/email/token/verify`, {
    token,
    email
    }, {observe: 'response'})
  }
  
  resend_token(email: string){
    return this.http.post(`${environment.cofferRestUrl}consumer/register/email/token/resend`, {
      email
    }, {observe: 'response'})
  }

  forgot_password(email: string){
    return this.http.post(`${environment.cofferRestUrl}consumer/forgot/email`, {
      email
    }, {observe: 'response'})
  }

  resend_password_token(coffer_id: string){
    return this.http.post(`${environment.cofferRestUrl}consumer/forgot/email/token/resend`, {
      coffer_id
    }, {observe: 'response'})
  }

  submit_password(coffer_id: string, password: string, token: string){
    return this.http.post(`${environment.cofferRestUrl}consumer/forgot/email/token/verify`, {
      coffer_id,
      password,
      token
    }, {observe: 'response'})
  }
  
  
}

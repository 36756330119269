import { Component, Injectable, OnInit } from '@angular/core';
import { AuthServiceService } from '../auth-service.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  care_team_list:any
  user_first_name:any;
  user_last_name:any;
  email:any;
  files: any;
  ethnicity_list: any;
  bloodgrp_list: any;
  imgurl:any;
  profile:any


  constructor(public auth: AuthServiceService,private http:HttpClient , public toast:ToastrService , public router:Router) { }
  
  onFileChanged(event: any) {
    this.files = event.target.files[0];
    //console.log(this.files.size)
    //console.log(this.files.type)
    if(!this.files.type.endsWith('jpeg') && !this.files.type.endsWith('png') && !this.files.type.endsWith('jpg') ) {
       this.toast.error("must be a image")
      return 
    }
    if(this.files.size > 10000000){

      alert("file limit exceded")
      return
    }
    this.onUpload()
  }
 
  ngOnInit(): void {
 
    this.auth.get_digicoffer_profile().subscribe((res:any)=>{
      if(res.error==false){
        //localStorage.setItem('dob',res.data.dob)
        this.profile = res.data
      }
    })
    
    this.auth.get_image().subscribe((res:any)=>{
      if(res){
        this.imgurl = res.imageUrl;
      } 
     

    })
    this.user_first_name = localStorage.getItem('first_name');
    this.user_last_name =  localStorage.getItem('last_name');
    this.email = localStorage.getItem('email')

    

  }
  onUpload() {
    //console.log(this.files)
    const formData = new FormData(); 

    formData.append("file", this.files);
    //console.log(formData)
    this.auth.upload_image(formData).subscribe((res:any)=>{
      if(res){
        this.toast.success(res.error)
        this.auth.get_image().subscribe((res:any)=>{
          if(res){
            this.imgurl = res.imageUrl;
          } 
         
    
        })

      }
    })
}

}

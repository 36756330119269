<div class="care-plan">
    <app-menu-comp></app-menu-comp>
     <div>
      <div class="card-list row row-cols-1 row-cols-md-6 g-3">
        
        <div class="col" *ngFor="let item of menu_list">
          <mat-card [ngClass]="selected_tag == item.tagName? 'selected-card' : 'not-selected-card'" (click)="OnClicked(item.tagName)" >
            <div class="count-btn">
              <button>{{item.count}}</button>
          </div>
            
            <div class="edit-btn">
              <h2 class="card-title" *ngIf="item.tagName!=='LegalFinance'">
                
                <span *ngIf="item.tagName=='Others'">
                  <i class="far fa-folder"></i>
                </span>
                <span *ngIf="item.tagName=='Personal'">
                  <i class="fas fa-users"></i>
                </span>
               
                <span *ngIf="item.tagName=='Health'">
                  <i class="fas fa-briefcase-medical"></i>
                </span>
                
                <span *ngIf="item.tagName=='Identity'">
                  <i class="far fa-address-book"></i>
                </span> <br/>{{item.tagName}}</h2>
                <h2 class="card-title" *ngIf="item.tagName=='LegalFinance'">
                  <span >
                    <i class="fas fa-suitcase"></i>
                  </span> <br/>Legal & Finance</h2>
                
            </div>
        </mat-card>
        </div>
        
    </div>
     </div>
    
    
    
    <div class="">
        
        
        
            <!-- <div *ngFor = "let iso of ccode">
                <button>
                    <span class="flag-icon flag-icon-{{iso | lowercase}}"></span>
                </button>
                
            </div> -->
            
            <mat-card class="user_column">
              <div class="upper">
                <div class="heading">
                  <p>Documents</p>

                </div>
                 
                 <div class="formfield">
                 
                    <mat-form-field appearance="fill">
                      <mat-select [(value)]="index_country">
                        <mat-option *ngFor="let food of countries" (click)="selectedn(food.index,food.country)"[value]="food.index">
                          <!-- <img class="flag-icon" src="/assets/flags/{{food.country}}.svg" alt="{{food.country}}"> -->
                          {{food.country | uaename}}
                        </mat-option>
                        
                      </mat-select>
                    </mat-form-field>
                    <div class="icon-list">
                   
                      <button  mat-mini-fab (click)="change_view()" >
                        <mat-icon title="Click to get Image view" *ngIf="view_icon=='list'">image</mat-icon>
                        <mat-icon title="Click to get List view" *ngIf="view_icon=='image'">list</mat-icon>
                      </button>
              
                     </div>
                  </div>
              </div>
              <div class="searchbar-e">
                <input  type="text" placeholder="Search" [(ngModel)] = searchText  #filter>
                <i class="fas fa-search"></i>
             </div>
             <div class="mat-tab" *ngIf="clickedname=='Identity'  && view_icon=='list'">
              <table  mat-table [dataSource]="list  | filterAll:searchText" class="mat-elevation-z0" >
                  
                <ng-container matColumnDef="doctype">
                  <th mat-header-cell *matHeaderCellDef>Document Name </th>
                  <td mat-cell class="title-doc" *matCellDef="let transaction"><a title="click to view document"  (click)="view_doc_identity(transaction.url,transaction.content_type)" data-toggle="modal" data-target="#modalYT" >{{transaction.doctype | doctype}}</a>  </td>
                  
                </ng-container>
              
                
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef>Uploaded On</th>
                  <td mat-cell *matCellDef="let transaction"> {{transaction.created}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="docid">
                  <th mat-header-cell *matHeaderCellDef>Doc Id</th>
                  <td mat-cell *matCellDef="let transaction"> {{transaction.docid}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="expiration_date">
                  <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
                  <td mat-cell *matCellDef="let transaction"> {{transaction.expiration_date}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row" >
                    <!-- <button (click)="EditDialog(row,'Identity')"class="edit"><i class="fa fa-edit"></i></button>
                    <button (click)="delete_credential(row.doctype)"class="trash"><i class="fa fa-trash"></i></button>
                    <button (click)="view_doc_identity(row.url,row.content_type.split('/',1))" class="eye"><i class="fa fa-eye"></i></button>
                    <button (click)="download_credential(row.doctype)"class="download"><i class="fa fa-download"></i></button> -->
                    <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" title="Action Buttons">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                    
                    <mat-menu #menu="matMenu">
                      <button (click)="share_view_doc(row,'identity')" data-toggle="modal" data-target="#modalview" mat-menu-item>
                        <mat-icon>share</mat-icon>
                        <span>Share</span>
                      </button>
                      <button (click)="EditDialog(row,'Identity')" mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button (click)="delete_credential(row.doctype)" mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                      <button (click)="view_doc_identity(row.url,row.content_type)" data-toggle="modal" data-target="#modalYT" mat-menu-item>
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                      </button>
                      <button (click)="download_credential(row.doctype)" mat-menu-item>
                        <mat-icon>file_download</mat-icon>
                        <span>Download</span>
                      </button>
                    </mat-menu>
                    
                  </td>
        
                  
                </ng-container>
              
              
              
                <tr mat-header-row class="example-first-header-row" *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row class="header-row" *matRowDef="let row; columns: displayedColumns;"></tr>
                
              </table>
              <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->

             </div>
             <div class="mat-tab" *ngIf="clickedname!='Identity' && view_icon=='list'">
              <table  mat-table [dataSource]="list | filterAll:searchText" class="mat-elevation-z0" >
                
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Document Name </th>
                  <td mat-cell class="title-doc" *matCellDef="let transaction"><a title="click to view document"(click)="view_doc_personal(transaction.url,transaction.content_type)" data-toggle="modal" data-target="#modalYT"> {{transaction.name}}</a> </td>
                  
                </ng-container>
              
              
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let transaction"> {{transaction.description}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="created">
                  <th mat-header-cell *matHeaderCellDef>Uploaded On</th>
                  <td mat-cell *matCellDef="let transaction"> {{transaction.created}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="expiration_date">
                  <th mat-header-cell *matHeaderCellDef>Expiration Date</th>
                  <td mat-cell *matCellDef="let transaction"> {{transaction.expiration_date}} </td>
                  
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef>Actions</th>
                  <td mat-cell *matCellDef="let row" >
                    <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" title="Action Buttons" class="menu-btn">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                    
                    <mat-menu #menu="matMenu">
                      <button (click)="share_view_doc(row,'personal')" data-toggle="modal" data-target="#modalview" mat-menu-item>
                        <mat-icon>share</mat-icon>
                        <span>Share</span>
                      </button>
                      <button (click)="EditDialog(row,'Personal')" mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>Edit</span>
                      </button>
                      <button (click)="delete_personal(row.id)" mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Delete</span>
                      </button>
                      <button (click)="view_doc_personal(row.url,row.content_type)" data-toggle="modal" data-target="#modalYT" mat-menu-item>
                        <mat-icon>visibility</mat-icon>
                        <span>View</span>
                      </button>
                      <button (click)="download_personal(row.id)" mat-menu-item>
                        <mat-icon>file_download</mat-icon>
                        <span>Download</span>
                      </button>
                    </mat-menu>
                   </td>
        
                  
                </ng-container>
                
              
              
              
                <tr mat-header-row class="example-first-header-row" *matHeaderRowDef="displayedColumns1"></tr>
                <tr mat-row class="header-row" *matRowDef="let row; columns: displayedColumns1;"></tr>
                
              </table>
             
             </div>
                
              
              
             
             <p *ngIf="list?.length == []" class="no-docs">No Records Found!!</p>
              <div *ngIf="clickedname=='Identity' && view_icon=='image'"  class="relation-list row row-cols-1 row-cols-md-4 g-4">
            
                <div class="col" *ngFor="let item of list | filterAll:searchText">
                  <mat-card>
                    <div class="header-card">
                      
                      <p class="card-title-main">{{item.doctype | doctype}}</p>
                      <div  style="flex-grow: 1"></div>
                      <div class="header-btn">
                        <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" title="Action Buttons">
                          <mat-icon>more_horiz</mat-icon>
                        </button>
                        
                        <mat-menu #menu="matMenu">
                          <button (click)="share_view_doc(item,'identity')" data-toggle="modal" data-target="#modalview" mat-menu-item>
                            <mat-icon>share</mat-icon>
                            <span>Share</span>
                          </button>
                          
                          <button (click)="EditDialog(item,'Identity')" mat-menu-item>
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                          </button>
                          <button (click)="delete_credential(item.doctype)" mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                          </button>
                          <button (click)="view_doc_identity(item.url,item.content_type)" data-toggle="modal" data-target="#modalYT" mat-menu-item>
                            <mat-icon>visibility</mat-icon>
                            <span>View</span>
                          </button>
                          <button (click)="download_credential(item.doctype)" mat-menu-item>
                            <mat-icon>file_download</mat-icon>
                            <span>Download</span>
                          </button>
                        </mat-menu>
                        
                      </div>
                     
                    </div>
                    <div class="img-clk" (click)="view_doc_identity(item.url,item.content_type)" data-toggle="modal" data-target="#modalYT">
                      <img class="doc-img" *ngIf="item.content_type.split('/',1) =='image'" mat-card-image src="{{item.url}}" alt="Photo of a document" >
                      <img class="doc-img" *ngIf="item.content_type =='application/msword'" mat-card-image src="../../assets/images/doc.png" title="Document" alt="Photo of a document">
                      <img class="doc-img" *ngIf="item.content_type =='application/pdf'" mat-card-image src="../../assets/images/pdf.png" alt="Photo of a document" >
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" mat-card-image src="../../assets/images/docx.png" alt="Photo of a document" >
                      <img class="doc-img" *ngIf="item.content_type =='text/plain'" mat-card-image src="../../assets/images/txt.png" alt="Photo of a document" loading="eager">
                      <img class="doc-img" *ngIf="item.content_type =='application/rtf'" mat-card-image src="../../assets/images/rtf.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.ms-excel'" mat-card-image src="../../assets/images/xls.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" mat-card-image src="../../assets/images/xlsx.png" alt="Photo of a document" >
                    </div>
                    
                  </mat-card>


                </div>
                <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                

              </div>
              <div *ngIf="clickedname!='Identity' && view_icon=='image'"  class="relation-list row row-cols-1 row-cols-md-4 g-4">
                <div class="col" *ngFor="let item of list  | filterAll:searchText">
                  <mat-card >
                    <!-- <div class="header-card">
                      <div class="card-title-main" >
                        <p title="Doc Name">{{item.name}}</p>
                      </div>
                      
                      <div class="header-btn">
                        <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" title="action buttons" class="menu-btn">
                          <mat-icon>more_horiz</mat-icon>
                        </button>
                        
                        <mat-menu #menu="matMenu">
                          <button (click)="EditDialog(item,'Personal')" mat-menu-item>
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                          </button>
                          <button (click)="delete_personal(item.id)" mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                          </button>
                          <button (click)="view_doc_personal(item.url,item.content_type.split('/',1))" data-toggle="modal" data-target="#modalYT" mat-menu-item>
                            <mat-icon>visibility</mat-icon>
                            <span>View</span>
                          </button>
                          <button (click)="download_personal(item.id)" mat-menu-item>
                            <mat-icon>file_download</mat-icon>
                            <span>Download</span>
                          </button>
                        </mat-menu>
                        
                      </div>
                     
                    </div> -->
                    <div class="header-card">
                      <div class="card-title-main">
                        <p>{{item.name}}</p>
                      </div>
                      
                      <div  style="flex-grow: 1"></div>
                      <div class="header-btn">
                        <button mat-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" title="Action Buttons" class="menu-btn">
                          <mat-icon>more_horiz</mat-icon>
                        </button>
                        
                        <mat-menu #menu="matMenu">
                          <button (click)="share_view_doc(item,'personal')" data-toggle="modal" data-target="#modalview" mat-menu-item>
                            <mat-icon>share</mat-icon>
                            <span>Share</span>
                          </button>
                          <button (click)="EditDialog(item,'Personal')" mat-menu-item>
                            <mat-icon>edit</mat-icon>
                            <span>Edit</span>
                          </button>
                          <button (click)="delete_personal(item.id)" mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            <span>Delete</span>
                          </button>
                          <button (click)="view_doc_personal(item.url,item.content_type)" data-toggle="modal" data-target="#modalYT" mat-menu-item>
                            <mat-icon>visibility</mat-icon>
                            <span>View</span>
                          </button>
                          <button (click)="download_personal(item.id)" mat-menu-item>
                            <mat-icon>file_download</mat-icon>
                            <span>Download</span>
                          </button>
                        </mat-menu>
                        
                      </div>
                    </div>
                    <div class="img-clk" (click)="view_doc_personal(item.url,item.content_type)" data-toggle="modal" data-target="#modalYT">
                      <img class="doc-img" *ngIf="item.content_type.split('/',1) =='image'" mat-card-image src="{{item.url}}" title="Document" alt="Photo of a document">
                      <img class="doc-img" *ngIf="item.content_type =='application/pdf'" mat-card-image src="../../assets/images/pdf.png" title="Document" alt="Photo of a document">
                      <img class="doc-img" *ngIf="item.content_type =='application/msword'" mat-card-image src="../../assets/images/doc.png" title="Document" alt="Photo of a document">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" mat-card-image src="../../assets/images/docx.png" alt="Photo of a document" >
                      <img class="doc-img" *ngIf="item.content_type =='text/plain'" mat-card-image src="../../assets/images/txt.png" alt="Photo of a document" loading="eager">
                      <img class="doc-img" *ngIf="item.content_type =='application/rtf'" mat-card-image src="../../assets/images/rtf.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.ms-excel'" mat-card-image src="../../assets/images/xls.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" mat-card-image src="../../assets/images/xlsx.png" alt="Photo of a document" >
                     
                    </div>
                   
                    <!-- <mat-card-subtitle title="Description">{{item.description}}</mat-card-subtitle> -->
                    <!-- <div title="Description">{{item.description}}</div> -->
                    
                  </mat-card>
                   

                </div>
                <!-- <pagination-controls (pageChange)="p = $event"></pagination-controls> -->
                
              </div>
              
             
            </mat-card>
            
         
        
        <!-- <div class="right-btn">
          <button (click)="openDialog()">
            <i class="fas fa-plus"></i>
        </button>

        </div> -->
        

      
      

      
        
        
        
    </div>
    <div *ngIf="clickedname=='Identity'" class="btn-bottom">
      <div class="example-button-container">
        <button (click)="openDialog(clickedname)" title="add documents" mat-raised-button aria-label="Example icon button with a menu icon" >
          <mat-icon>add</mat-icon><span class="add-txt">Add</span>
        </button>
        <!-- <button (click)="open_bottom_dialog($event)"  title="add documents" mat-raised-button><mat-icon>add</mat-icon><span class="add-txt">Add</span></button> -->
      </div>

    </div>
    <div *ngIf="clickedname!='Identity'" class="btn-bottom">
      <div class="example-button-container">
        <!-- <button (click)="openDialog(clickedname)" title="add documents" mat-raised-button color="primary" aria-label="Example icon button with a menu icon" >
          <mat-icon>add</mat-icon>Add
        </button> -->
        <button (click)="open_bottom_dialog($event)"  title="add documents" mat-raised-button><mat-icon>add</mat-icon><span class="add-txt">Add</span></button>
      </div>
      <div *ngIf="isClassExpand" class="bottom_dialog" (click)="$event.stopPropagation()" >
        <div>
            <button (click)="openDialog(clickedname)"><i class="fas fa-user-alt"></i>Upload a Single File</button>
            <!-- <button (click)="openBizCareDialog()"><i class="fas fa-user-friends"></i>Business & Professionals</button> -->
           
            <button (click)="drag()"><i class="fas fa-user-plus"></i>Upload Multiple Files</button>
            
        </div>
      </div>

    </div>
 
   

    
    
    <!--Modal: Name-->
   
    <!--Modal: Name-->
    
    <!--Modal: Name-->
    <div class="modal fade" id="modalYT" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title" id="exampleModalLabel">View Document</h1>
            <button type="button" class="close" data-dismiss="modal" (click)="clear_url()" aria-label="Close">x
            </button>
            <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
          </div>
      
    
          <!--Body-->
          <div class="modal-body">
             
            <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
              <iframe  *ngIf="type_con?.split('/',1)!='image'"  class="embed-responsive-item" [src] ="safeurl | safeurl" height="500" width="100%"  allowfullscreen></iframe>
              <img *ngIf="type_con?.split('/',1)=='image'" class="embed-responsive-item img-view" [src] ="safeurl | safeurl"  />
            </div>
            <!-- <div *ngIf="type_con!='application/pdf'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
              <img *ngIf="type_con =='image/png' || type_con =='image/jpg'||type_con =='image/jpeg'|| type_con =='image/gif' || type_con =='image/bmp'" class="embed-responsive-item img-view" [src] ="safeurl | safeurl"  />
              <img  *ngIf="type_con =='application/msword'" src="../../assets/images/doc.png"  class="embed-responsive-item img-view" title="Document" alt="Photo of a document">
                      
                      <img *ngIf="type_con =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'"  src="../../assets/images/docx.png"  class="embed-responsive-item img-view" alt="Photo of a document" >
                      <img  *ngIf="type_con =='text/plain'" src="../../assets/images/txt.png"  class="embed-responsive-item img-view" alt="Photo of a document" >
                      <img  *ngIf="type_con =='application/rtf'" src="../../assets/images/rtf.png" alt="Photo of a document"  class="embed-responsive-item img-view" >
                      <img  *ngIf="type_con =='application/vnd.ms-excel'"  src="../../assets/images/xls.png" alt="Photo of a document"  class="embed-responsive-item img-view" >
                      <img  *ngIf="type_con =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"  src="../../assets/images/xlsx.png" alt="Photo of a document"  class="embed-responsive-item img-view">

            </div> -->


    
          </div>
    
          <!--Footer-->
         
    
        </div>
        <!--/.Content-->
    
      </div>
    </div>

    <div class="modal fade" id="modalview" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        
        <!--Content-->
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title" id="exampleModalLabel">Share Document</h1>
            <button type="button" class="close" data-dismiss="modal" (click)="clear_url()" aria-label="Close">x
            </button>
            <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
          </div>
      
    
          <!--Body-->
          <div class="modal-body row ">
             
            <div class="col-md-8 ">
                <div class="first-doc">
                  <div class="">
                    <img *ngIf="doc_detials?.contentType.split('/',1)=='image'"  class="img-fluid" alt="Responsive image"  [src]="doc_detials?.url" >
                    
                      <!-- <img class="thunmb-img" *ngIf="doc_detials?.contentType =='application/msword'" src="../../assets/images/doc.png" title="Document" alt="Photo of a document">
                      
                      <img class="thunmb-img" *ngIf="doc_detials?.contentType =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'"  src="../../assets/images/docx.png" alt="Photo of a document" >
                      <img class="thunmb-img" *ngIf="doc_detials?.contentType =='text/plain'" src="../../assets/images/txt.png" alt="Photo of a document" loading="eager">
                      <img class="thunmb-img" *ngIf="doc_detials?.contentType =='application/rtf'" src="../../assets/images/rtf.png" alt="Photo of a document" loading="eager ">
                      <img class="thunmb-img" *ngIf="doc_detials?.contentType =='application/vnd.ms-excel'"  src="../../assets/images/xls.png" alt="Photo of a document" loading="eager ">
                      <img class="thunmb-img" *ngIf="doc_detials?.contentType =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"  src="../../assets/images/xlsx.png" alt="Photo of a document" > -->
                    <div *ngIf="doc_detials?.contentType.split('/',1) !='image'" >
                      <!-- <img class="img-fluid" alt="Responsive image" *ngIf="doc_detials?.contentType=='application/msword'" src="../../assets/images/doc.png">
                      <img class="img-fluid" alt="Responsive image" *ngIf="doc_detials?.contentType=='application/pdf'" src="../../assets/images/pdf.png"> -->
                    
                        <iframe  [src] ="safeurl | safeurl" height="400" width="100%"></iframe>
                      
                      
                     
                    </div>
                 
                    

                  </div>
                  <div class="doc-info">
                    <h3><strong>{{doc_detials?.name | doctype}}</strong></h3>
                  <mat-card>
                   
                    <table class="new-tab" style="width:100%">
                      <tr>
                        <th  *ngIf="doc_detials?.description">Description:</th>
                        <td  *ngIf="doc_detials?.description">{{doc_detials?.description}}</td>
                      </tr>
                      <tr>
                        <th *ngIf="doc_detials?.expiringOn">Expiration Date:</th>
                        <td *ngIf="doc_detials?.expiringOn">{{doc_detials?.expiringOn}}</td>
                      
                      </tr>
                      <tr>
                        <th>Uploaded On:</th>  
                        <td>{{doc_detials?.uploadedOn}}</td>
                      </tr>
                      <tr>
                        <th>Size:</th>
                        <td>{{doc_detials?.size}}</td>
                      </tr>
                      <tr>
                        <th>Tags</th>
                        <td>{{doc_detials?.tags}}</td>
                      </tr>
                    </table>
                  
                    <!-- <div class="grid-container">
                      <div class="grid">
                        <li *ngIf="doc_detials?.description">Description:</li>
                        <li *ngIf="doc_detials?.expiringOn">Experation Date:</li>
                        <li>Uploaded On:</li>
                        <li>Size:</li>
                        <li>Tags:</li>

                      </div>
                      <div class="grid">
                        <li *ngIf="doc_detials?.description"><span class="list-d">{{doc_detials?.description}}</span></li>
                        <li *ngIf="doc_detials?.expiringOn"><span class="list-d">{{doc_detials?.expiringOn}}</span></li>
                        <li><span class="list-d">{{doc_detials?.uploadedOn}}</span></li>
                        <li><span class="list-d">{{doc_detials?.size}}</span></li>
                        <li><span class="list-d">{{doc_detials?.tags}}</span></li>

                      </div>

                    </div> -->

                  </mat-card>
        
                  </div>
                 
                  
                 
  
                </div>

                

                </div>


                

                
              
            
            <div class="col-md-4 doc-details">
              
              
                <h3><strong>Document Sharing</strong></h3>
                  <div class="user-list">
                    <mat-card class="list-docs" *ngFor="let item of doc_detials?.sharedWith; let i = index">
                       
                        <mat-checkbox  [checked]="item.isShared" (change)="onChange_per($event, item,i)">{{item?.name}}</mat-checkbox>
                        <p class="sub-head">{{item.sharedOn}}</p>

                      
                      
                    
                   
                    <!-- <li>
                      <div class="profile">
                        <mat-slide-toggle [ngModel]="item.isShared" (change)="onChange_per($event, item)">
                        </mat-slide-toggle>
                        <img class="profile-pc" src="../../assets/images/owner.png" alt="">
                        <div>
                          
                          {{item.name}}<br>
                          <span class="date-sha">{{item.sharedOn}}</span>
                         

                        </div>
                      </div>
                      

                      

                    </li> -->
                    
                  </mat-card>
                

              </div>
            </div>
            
    
          </div>
    
          <!--Footer-->
         
    
        </div>
        <!--/.Content-->
    
      </div>
    </div>
   
    
    
</div>
<block-ui></block-ui>

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';

@Component({
  selector: 'app-care-plan-dash',
  templateUrl: './care-plan-dash.component.html',
  styleUrls: ['./care-plan-dash.component.scss']
})
export class CarePlanDashComponent implements OnInit {
  // @Input() card_details: any;
  // careplan :any;
  // progress:any;
  // len:any;
  reminders:any;
  maxDate= new Date();
  list:any;
  new15: any;
  todaydate:any;

  constructor(public auth:AuthServiceService , public toast:ToastrService ,private _communicationREM: CommunicationService , public datePipe:DatePipe , public dialog:MatDialog , private box:ConfirmBoxService) { }
   
 

  ngOnInit(): void {
    // this.auth.get_care_plan().subscribe((res:any)=>{
    //   this.careplan = res;
    //   //console.log(this.careplan)
    //   if(res){
    //     this.progress = (this.careplan.eplased/this.careplan.duration)*100;
      
    //   }
     

    // })
  
    
    this.new15 = this.datePipe.transform(moment().add(15, 'days').calendar(),'MMM d, y');
    this.todaydate = this.datePipe.transform(new Date(),'MMM d, y')
    
    this.auth.get_reminders().subscribe((res:any)=>{
  
      this.reminders = res.data.reminders?.filter((element=>{
       
        return (moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"))
      }))
      
    
    })
    // this._communicationREM.changeEmitted$.subscribe(data =>{
    //   if(data){
    //     this.reminders =  data.data.reminders.filter((element=>{
    //       return (moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"))
    //     }))
    //   }
    // })
  }

  

  delete_reminder(id){
    this.auth.delete_reminder(id).subscribe((res:any)=>{
      this.box.confirm("Alert", 'Are you sure you want to delete this?').then((res)=>{
        if(res){
          this.toast.success("Reminder deleted successfully.")
          this.auth.get_reminders().subscribe((res:any)=>{
            if(res.error==false){
              this.reminders = res.data.reminders?.filter((element=>{
       
                return (moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"))
              }))
      
            } 
  
          })

        }
      })



     

    })
  }

}


import { Component, Inject, Input, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthServiceService } from '../auth-service.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommunicationService } from '../communication.service';
import { Utils } from '../utils';
import { DatePipe } from '@angular/common';
import { ShareUpdateService } from '../share-update.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';


@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  providers: [ DatePipe ]
})
export class CardsComponent implements OnInit {
  @Input() card_details: any;
  @Input() device_list: any;
  @Input() device_names: any;
  card_image: any;
  medium_value: any;
  high_value: any;
  values:any[]=[]
  valuesx:any[]=[];
  constructor(public dialog: MatDialog, private authservice: AuthServiceService , public datePipe :DatePipe) { }

  openDialog() {
    
    this.dialog.open(DialogOpenCard, {
      data: [this.card_details, this.device_list]
    });
  }
  opengraphdialog(){
    this.dialog.open(GraphOpenDialog,{
      data:[this.card_details,this.values,this.valuesx]

    })

  }

  ngOnInit() {

    this.authservice.get_chartreading(this.card_details.device.systemId).subscribe((res:any)=>{

      this.values = res.map(res=>res.value1)
      this.valuesx = res.map(res=>res.readingDateTime)
      
      

    })
  
    this.device_names.forEach(element => {
      if(element.systemId == this.card_details.device.systemId){
        this.card_image = element.image;
      }
    });

    this.authservice.healthy_range().subscribe((res: any) => {
      res.forEach(element => {
        if(element.systemId == this.card_details.device.systemId){
          this.medium_value = element.dataWatch.data1.medium;
          this.high_value = element.dataWatch.data1.high
        }
      });
      
    })
  }

}

@Component({
  selector: 'dialog-open-card',
  templateUrl: 'dialog-open-card.html',
  styleUrls: ['./cards.component.scss'],
  providers: [ DatePipe ]
})
export class DialogOpenCard {
  @BlockUI()
  blockUI!: NgBlockUI; 
  current_card: any;
  tag_list: any;
  device_list_from_parent : any;
  selected_device: object[] = [];
  tag_id = 0;
  device_id: any;
  // selected = "Fasting";

  tmpindex: number = 0;
  
  submitted = false;

  maxDate = new Date();  // dateSelected = new FormControl(new Date());

  date1 = new FormControl(new Date());
  ampm_list = ["AM", "PM"];
  hour_value =  Utils._12_hour_format();
  minute_value = new Date().getMinutes().toString();
  am_pm =  new Date().getHours()>12? "PM": "AM";
  status:any;
  unit:any;
  


  last_updated: any;

  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  
  
  counter = Array;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DialogOpenCard>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, private _communicationService: CommunicationService , public datePipe:DatePipe , public share:ShareUpdateService) {
    dialog.disableClose = true;
    this.current_card = data[0]
    this.device_list_from_parent = data[1]
  }

  myForm = this.formBuilder.group({
    firstReading: ['', [Validators.required, Validators.maxLength(3), Validators.pattern("^[0-9]*$"), Validators.min(10)]],
    buttonTag: [ Validators.required],
    dateSelected : [ Validators.required]
  });
  start_loading()
  {
  this.blockUI.start()
 }
 stop_loading(){
  this.blockUI.stop()
 }
  ngOnChanges(){
    // if(this.dateSelected){
    //   //console.log(this.dateSelected)
    // }
  }

  ngOnInit(){
    
    this.authservice.get_tag_list(this.current_card.device.category.id).subscribe((res: any) => {
     
      this.tag_list = res
      this.tag_id = res[0]?.id;
    })
   

    this.selected_device = this.device_list_from_parent.filter(element => {
      if(element.systemId == this.current_card.device.systemId){
        this.device_id = element.id;
        return true
      } else {
        return false
      }
    });

    this.authservice.data_last_reading().subscribe((res: any) => {
      res.forEach(element => {
        if(element.device__systemId == this.current_card.device.systemId){
          if(element.daySince > 0){
            this.last_updated = `${element.daySince} days ago`
          } else if(element.daySince == 0) {
              this.last_updated = `Today`
          }
        }
      });
    })

   
    if(this.current_card.device.systemId=="0x181D"){
      this.authservice.profile_details().subscribe((res1:any)=>{
        if(res1==[]){
          this.authservice.get_citizenship().subscribe((res:any)=>{
            if(res.error==false){
              for (let i = 0; i < res.data.length; i++) {

                if(res.data[i].index == "citizen_primary" &&  (res.data[i].country =="USA")){
                  this.current_card.valueUnit1 = "lbs"
      
      
                } else {
                  this.current_card.valueUnit1 = "kg"
                }
              }
            }
          })
        } else {
          this.current_card.valueUnit1 = res1[0]?.weigthtUnit
        }
      })

    }

    this.share.status.subscribe((res:any)=>{
      this.status = res
    })

  }

  get f() { return this.myForm.controls; }

  tag_btn(tag_id: number){

    this.tag_id = tag_id
   
  }

  close_dialog(){
    this.dialog.close();
  }

  save_data(value1: string, date: string, hour: string, minute: string, am_pm: string, valueUnit1: string){
    this.submitted = true;
    this.start_loading()
    let device = this.device_id;
    let tagId = this.tag_id
    let sequence = 0;
    let value2 = 0;
    let value3 = 0;
    let valueUnit2 = "";
    let valueUnit3 = "";
    
   
    if(minute.length == 1){
      minute = `0${minute}`
    }

    var readingDateTime;

    if(date == ""){
      date = Utils.formatDate();
      readingDateTime = `${date}`;
    } else {
      
     
      let DateTime = `${date}, ${hour}:${minute} ${am_pm}`
      readingDateTime = this.datePipe.transform(DateTime , "MM-dd-yyyy hh:mm a")
      
    }


    
      if(value1 && value1 >= '10'){
        this.authservice.add_device_reading(device, sequence, readingDateTime, Number(value1), value2, value3, valueUnit1, valueUnit2, valueUnit3, tagId).subscribe((res: any) => {
        
          this.toastr.success('Reading created successfully.')
          this.authservice.lastest_device_reading().subscribe((res: any) => {
           
           this._communicationService.emitChange(res);
           this.share.change_status("true")
          })
          this.dialog.close()
          this.stop_loading()
        })
      } else {
        // this.toastr.error('Please fill the required field.')
        this.stop_loading()
      }
    
  }
}


@Component({
  selector: 'graph-open-dialog',
  templateUrl: 'graph-dialog.html',
  styleUrls: ['./cards.component.scss'],
  providers: [ DatePipe ]
})

export class GraphOpenDialog{

 device_details:any
 values:any;
 xais:any;
 vdia:any;
 xbp:any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<GraphOpenDialog>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, private _communicationService: CommunicationService , public datePipe:DatePipe) {
    dialog.disableClose = true;
    this.device_details = data[0]
    this.values=data[1];
    this.xais=data[2];
    this.vdia=data[3];
    this.xbp= data[4];
  }

  ngOnInit(){
    
  
  }
  close_dialog(){
    this.dialog.close()
  }

}

@Component({
  selector: 'add-exercise-dialog',
  templateUrl: 'add-exercise-dialog.html',
  styleUrls: ['./cards.component.scss'],
  providers: [ DatePipe ]

})

export class AddExerciseReading {

  care_id:any;
  exe_list;
  submitted=false;
  maxDate = new Date();  // dateSelected = new FormControl(new Date());

  date1 = new FormControl(new Date());
  ampm_list = ["AM", "PM"];
  hour_value =  Utils._12_hour_format();
  minute_value = new Date().getMinutes().toString();
  am_pm =  new Date().getHours()>12? "PM": "AM";
care_lists:any;
list:any;
left_list =['Exercise','Diet']
date = new FormControl(new Date());
serializedDate = new FormControl((new Date()).toISOString());
list_data:any[]=[]
index_data:any

   distanceControl = new FormControl('0', [Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]*$"), Validators.min(1)]);
   weightcontrol = new FormControl(0,[Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]*$"), Validators.min(1)])
   repsctrl = new FormControl(0,[Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]*$"), Validators.min(1)])
   sepsctrl = new FormControl(0,[Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]*$"), Validators.min(1)])
  counter = Array;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<AddExerciseReading>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, private _communicationService: CommunicationService , public datePipe:DatePipe , public share:ShareUpdateService) {
    dialog.disableClose = true;
    this.care_lists = data[0]
    this.list = data[1]
    
  }


 


  ngOnInit(){

    this.authservice.get_care_plan().subscribe((res:any)=>{
      if(res){
        this.care_id = res[0].id
        
        this.authservice.get_careplabexerice(this.care_id).subscribe((res:any)=>{
          if(res){
            this.exe_list = res
          }
    
    
        })


      }
    })
 
   
    
  
  }

  
  add_distance(value ,title, index){
  
    let number  = this.list_data.findIndex(item=>item.name==title)
    if(number==-1){
     
      this.index_data = 
        {
          "name": title,
          "distance": value,
          "distanceUnit": this.exe_list[index].distanceUnit,
          "weight": 0,
          "weightUnit": "",
          "numberOfStep": 0,
          "repetition": 0,
          "sequence": 1
        }
      
      this.list_data.push(this.index_data)

    } else {
   
      this.index_data.distance = value
      //this.list_data.push(this.index_data)
    }
   
  }
  add_wt(wt , reps , sets , title , index){
 
    if(wt.length > 6 || reps.length > 6 || sets.length > 6){
      return
    }
    let number  = this.list_data.findIndex(item=>item.name==title)
    if(number==-1){
      this.index_data = 
      {
        "name": title,
        "distance": "0",
        "distanceUnit": "",
        "weight": Number(wt),
        "weightUnit": this.exe_list[index].weightUnit,
        "numberOfStep": sets,
        "repetition": reps,
        "sequence": 1
      }
    
    this.list_data.push(this.index_data)

    } else {
      this.index_data.weight = Number(wt)
      this.index_data.numberOfStep = sets
      this.index_data.repetition = reps

    }
  }
  
    onKeydown(event: KeyboardEvent) {

      if(event.keyCode === 189){
        return false
      } else {
        return true
      }
      
    }
  
  save_data(date, hour, minute, am_pm ){
  
    this.submitted = true
    if(this.list_data.length <=0){
    
      return
    }
    if(this.distanceControl.invalid && this.weightcontrol.invalid && this.repsctrl.invalid && this.sepsctrl.invalid){
      return
    }

  
   
    if(minute.length == 1){
      minute = `0${minute}`
    }

    var readingDateTime;

    if(date == ""){
      date = Utils.formatDate();
      readingDateTime = `${date}`;
    } else {
      
     
      let DateTime = `${date}, ${hour}:${minute} ${am_pm}`
      readingDateTime = this.datePipe.transform(DateTime, "MM-dd-yyyy hh:mm a")
      
    }
   
    this.list_data.map(item=>{
      item.readingDateTime = readingDateTime
    })
 
    this.authservice.add_exercise_reading(this.list_data).subscribe((res:any)=>{
      if(res){
        this.toastr.success("Added Exercise Reading Successfully!!!")
        this.share.change_status("true")
      }
    })
    this.close_dialog()

  }
  close_dialog(){
    this.dialog.close()
  }

}


@Component({
  selector: 'add-diet-dialog',
  templateUrl: 'add-diet-dialog.html',
  styleUrls: ['./cards.component.scss'],
  providers: [ DatePipe ]

})

export class AddDietReading {

  myForm:any;
  care_id:any;
  exe_list;
  submitted=false;
  maxDate = new Date();  // dateSelected = new FormControl(new Date());

  date1 = new FormControl(new Date());
  ampm_list = ["AM", "PM"];
  hour_value =  Utils._12_hour_format();
  minute_value = new Date().getMinutes().toString();
  am_pm =  new Date().getHours()>12? "PM": "AM";
care_lists:any;
list:any;
left_list =['Exercise','Diet']
counter = Array;
tag_list:any;
tag_id = 4;
device_id: any;
maxVal:any;
// selected = "Fasting";

tmpindex: number = 0;
list_data:any[]=[]

constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<AddDietReading>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService, private _communicationService: CommunicationService , public datePipe:DatePipe , public share:ShareUpdateService) {
  dialog.disableClose = true;
  this.care_lists = data[0]
  this.list = data[1]
  


}

valueform = new FormControl(0,[Validators.required, Validators.maxLength(6), Validators.pattern("^[0-9]*$"), Validators.min(0)])
   


  ngOnInit(){

    
    this.authservice.get_care_plan().subscribe((res:any)=>{
      if(res){
        this.care_id = res[0].id
    
        this.authservice.get_careplandiet(this.care_id).subscribe((res:any)=>{
          if(res){
            this.exe_list = res
          }
    
    
        })


      }
    })

    this.authservice.get_category("NUTRITION").subscribe((res:any)=>{
      if(res){
        let id = res[0].id
        this.authservice.get_tag_list(id).subscribe((res:any)=>{
          if(res){
            this.tag_list = res

          }
        })
      }
    })
 
    this.myForm = this.formBuilder.group({
      name:['',Validators.required],
      readingDateTime:['',Validators.required],
     value:[''],
     valueUnit:['',Validators.required],
      tag:['',Validators.required],
      sequence:['']
  
      
  
    })
    
  
  }
  get f() { return this.myForm.controls; }

  tag_btn(tag_id: number){

    this.tag_id = tag_id
  
  }

  onKeydown(event: KeyboardEvent) {

    if(event.keyCode === 189){
      return false
    } else {
      return true
    }
    
  }



  get_value(f,name , unit,ee){
    console.log(ee)
    if(f>999999 || f.length > 6){
      this.maxVal = true
      return

    }
    
    let index = this.list_data.findIndex(item=>item.name==name)

    if(index==-1){
      this.list_data.push({name:name,value:f,valueUnit:unit})
    } else {
      this.list_data[index].value = f
    }
  
     
  
    
   

  }
  save_data(date, hour, minute, am_pm){
    this.submitted = true
    if(this.list_data.length <= 0){
      
      return
    }
    if(this.valueform.invalid){
      
      return
    }
    
    
    if(minute.length == 1){
      minute = `0${minute}`
    }

    var readingDateTime;

    if(date == ""){
      date = Utils.formatDate();
      readingDateTime = `${date}`;
    } else {
      
     
      let DateTime = `${date}, ${hour}:${minute} ${am_pm}`
  
      readingDateTime = this.datePipe.transform(DateTime , "MM-dd-yyyy hh:mm a")
      
    }
  
    this.list_data.map(item=>{
      item.tag = this.tag_id
      item.sequence = 1
      item.readingDateTime = readingDateTime
      
    })
   
    this.authservice.add_diet_reading(this.list_data).subscribe((res:any)=>{
      if(res){
        this.toastr.success("Added Diet Reading Succesfully!!")
        this.share.change_status("true")
      }

    })
    this.close_dialog()

  }
  close_dialog(){
    this.dialog.close()
  }


}
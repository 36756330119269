<div class="container">
    <div class=" card-dialog row justify-content-center">
        <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
            <div>
                <h1 mat-dialog-title>Add Entity</h1>
                <form class="form-dialog" [formGroup]="myForm" class="">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Entity Name</mat-label>
                        <input matInput #entity_name required formControlName='name' name="name" [ngModel]="items.firm_name"/>
                        <mat-error *ngIf="submitted && f.name.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Entity Address</mat-label>
                        <textarea matInput #entity_add required formControlName='address' name="address" [ngModel]="items.firm_address" placeholder="Enter the address" ></textarea>
                        <mat-error *ngIf="submitted && f.address.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Relationship/Account Id</mat-label>
                        <input matInput #entity_id required formControlName='id'  [ngModel]="items.account_id" >
                        <mat-error *ngIf="submitted && f.id.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Entity Identification Number</mat-label>
                        <input matInput #id_no formControlName='id_no'  [ngModel]="items.biz_identification_no" >
                       
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Others</mat-label>
                        <textarea matInput #others formControlName='others'  placeholder="Others" [ngModel]="items.others"  ></textarea>
                    </mat-form-field>
                   

                      <div class="save-button">
                        <button type="submit" (click)=" add_entity(entity_name.value ,entity_add.value, entity_id.value , id_no.value,others.value)">Save</button> 
                        <button *ngIf="status=='edit'" (click)="edit_entity(entity_name.value ,entity_add.value, entity_id.value , id_no.value,others.value)" >Save</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<block-ui></block-ui>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pulseTrans'
})
export class PulseTransPipe implements PipeTransform {

  transform(str): unknown {
    if(str =='Pulse'){
      return 'Blood Oxygen'
    }
    return str
  }

}

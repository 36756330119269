import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AuthServiceService } from '../auth-service.service';



@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI;
  careid:any;
  q:any;
  numofq:any;
  sel:any
  rep:any;
  index_ques=0;
  ques_item:any;
  words = '';
  selbutton:any;
  status = "able";
  answers :any[] =[];
  sav: string[] = [];
  option: any;
  res:any[]=[];
  repchoice:any;
  submitted= false;
  success=false;

  constructor(public auth:AuthServiceService  ,  private route: ActivatedRoute , public router:Router , public toast:ToastrService , public fb: FormBuilder) { 
    
  }
  myForm = this.fb.group({
    radio: ['',Validators.required],
    button: ['',Validators.required],
    text:['',Validators.required],
    checkbox:['',Validators.required],
    bloodgroup:[''],
    lastName: ['']
  });
  

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.blockUI.start()
    this.careid = this.route.snapshot.paramMap.get("id");
    this.auth.get_questions(this.careid).subscribe((res:any)=>{
      this.q = res;
      this.option = res.option;
      this.numofq = res.length;
      this.ques_item = this.q[this.index_ques]
      //console.log(res)

    })
    this.auth.get_quesres().subscribe((res:any)=>{
      //console.log("this",res)
    })
    this.blockUI.stop()
  
  }

  // loadQuestion(){
  //   this.ques = this.questionList[this.currentQuestion-1];
  // }

  // previousQuestion(){
  //   if(this.currentQuestion > 1){
  //     --this.currentQuestion;
  //     this.loadQuestion()
  //   }
  // }


  //  nextQuestion(){
  //   if(this.currentQuestion < this.questionList.length){
  //     ++this.currentQuestion;
  //     this.loadQuestion()
  //   }
  // }


  prev_ques(){
    this.index_ques= this.index_ques-1
    this.ques_item = this.q[this.index_ques]

  }

  next_ques(){
    
    this.index_ques= this.index_ques+1
    this.ques_item = this.q[this.index_ques]


   

  }
  get f() {
    return this.myForm.controls;
  }
  selected_chart(id,index , event){
    //console.log(id,index , event)
    
    
     this.q.forEach(element => {
       var value = element.option[index]
       if(element.id == id && event){
         this.sav.push(value)
       }
       else if(element.id == id && !event){

         this.sav.splice(this.sav.indexOf(value),1)

       }
       
     });
     //console.log(this.sav)
     this.repchoice = {"question": id,"choice": this.sav}

  }
   
  save_res( id , saved , status){
    //console.log(id , saved , status)
    this.submitted = true
    if(status == 'yes'){
      this.rep = this.repchoice
 
    } else {
      this.rep = {"question": id,"choice": [saved] }
     }
     //console.log(this.rep)

     

  
    
    this.answers[id] = this.rep
    //this.answers.push(this.rep)
    //console.log(this.answers)
    // this.auth.post_quesres(this.rep).subscribe((res:any)=>{
    //   //console.log(res)
    //     this.toast.success("Response Recoreded")
      
    // })
    //console.log(this.index_ques+1 , this.numofq)
    

    
  }

  submit(){
    //console.log(this.answers)
    //console.log(this.index_ques+1 , this.numofq)
    if((this.index_ques+1)==this.numofq){
      this.status = "disable"
    }
    this.answers.forEach(element => {
      this.auth.post_quesres(element).subscribe((res:any)=>{
        
        //console.log(res)
      
        
      }, (err:any)=>{
        this.success = true
        this.toast.error(err)
      })

      
    });

    if(this.success == false){
      this.toast.success("Responses Recoreded")
      this.router.navigate(['/menu/care-plan']);

    }

    // this.auth.post_quesres(this.answers).subscribe((res:any)=>{
    //   //console.log(res)
    //     this.toast.success("Response Recoreded")
      
    // })
    // if(this.status=="disable"){
    //   this.router.navigate(['/menu/care-plan']);

    // }
 
 

  }
  keyup(event){
    //console.log(this.words)
  }


  last_ques(){
    if((this.index_ques+1)==this.numofq){

     
      return true
    } else {
      return false
    }

  }
 
    
}

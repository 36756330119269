import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';

@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {
  add_plan: any
  id:any
  subscribed:any;
  

  constructor(private authService: AuthServiceService , public toast : ToastrService , public router :Router) { }
  selected = false;
 

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.authService.add_plan().subscribe((res: any) => {
      this.add_plan = res
    
    })
    this.authService.subscribed_careplan().subscribe((res:any)=>{
     
      this.subscribed = res

    })
    
  }
  onSelectCard(id) {
  
    this.selected = !this.selected;
    if(this.selected){
    
      this.authService.subscribeto_careplan(id.id).subscribe((res:any)=>{
      
        if(res){
          this.toast.success("Subscribed to this care plan!!")
        }
  
       
    },(error)=>{
      if(error){
   
        this.toast.info("Care Plan Aleardy Subscribed!!")
      } 

    })
      
      
     

    }
  }

  checksub(id , index){
    var  isSubscribed = false
    for(let item of this.subscribed ){
      isSubscribed = false
      if(item.plan.id == id){
        isSubscribed = true;
        break
      }
    }
    if(isSubscribed){
      return false
    } else {
      return true
    }
   
      
  
    

    

    

}

}

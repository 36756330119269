<div class="">
  <app-menu-comp></app-menu-comp>
 
 
    <div class="card-css main_container">
       
      
      
        <div class="example-form">
          <p class="heading">Settings</p>
          <div class="user_column">
            <mat-card >
                <div class="user-icon">
                  <label class="hoverable" for="fileInput">
                    <img *ngIf="(!imgurl)" src= "../../assets/images/user.png" alt=""> 
                    <img *ngIf="( imgurl)" src={{imgurl}} alt="">
                    <div class="hover-text" onchange="console.log(event.target.files)">Choose file</div>
                    <div class="background"></div>
                  </label>
                  <input id="fileInput" type='file' (change)="onFileChanged($event)">
                  <p>{{profile?.first_name}}&nbsp;{{profile?.last_name}}<br>
                  {{profile?.email}}</p>
                </div>
                

            </mat-card>
         </div>
       
        <div class="med-condition">
          <mat-card>Edit Profile
            <div class="right-icon"> 
              <button  routerLink="/update-profile">
              <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
          <mat-card>Logout
            <div class="right-icon"> 
              <button  routerLink="/logout">
              <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
        <!-- <div class="ent">
          <mat-card>App Settings</mat-card>
        </div>
        <div class="med-condition">
          <mat-card>Theme
            <div class="right-icon"> 
              <button>
              <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
         -->
        
        
      
      </div>
  </div>

</div>

      
 
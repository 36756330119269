import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-year-pick',
  templateUrl: './year-pick.component.html',
  styleUrls: ['./year-pick.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearPickComponent implements OnInit {
  date = new FormControl(moment());
  maxDate = new Date()
  @Input() sel_date: any;
  @Output() year_sel = new EventEmitter<number>();
  sel:any;

  constructor() { }

  ngOnInit(): void {
    if(this.sel_date){
    
    }
    console.log(this.sel_date)

  }
 
 

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>,dat) {
    // const ctrlValue = this.date.value;
    // ctrlValue.year(normalizedYear.year());
    // this.date.setValue(ctrlValue);
    console.log(dat)
    datepicker.close();
    this.send_data()
  }
  send_data(){
    this.year_sel.emit(moment(this.date.value).year())
  }
  get_date_value(date){
    let d = moment(this.sel_date,"YYYY").format('YYYY');
    console.log(d)
    return d
    

  }

}

<div (click)="opengraphdialog()" title="Show Historic Data" class="whole-card">
  <mat-card  style="background-color: #28293d" class="card-css2 example-card">
    <!-- <div class="plus-icon">
      <button (click)="openDialog()" (click)="$event.stopPropagation()"title="Add Reading" >
        <i class="fas fa-plus"></i>
      </button>
    </div> -->
      <div class="container">
          <!-- <div class="row"> -->
            <div class="col">
              <div class="heart-img">
                  <!-- <img src="{{card_image}}" alt=""> -->
                  <i class="{{card_image[0]}}" [style]="card_image[1]"></i>
              </div>
              <p>{{ card_details.device.category.name | pulseTrans }}</p>
              <h2>{{ card_details.value1 }} <span>{{ card_details.valueUnit1 }}</span></h2>
              <p *ngIf="medium_value" class="time">Healthy Range: {{medium_value}} to {{high_value}}</p>
              <p class="time">{{ card_details.readingDateTime }}</p>
            </div>
            
            <div class="w-100"></div>
          <!-- </div> -->
      </div>
  </mat-card>
  

</div>
    
    


 
<div class="care-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">{{title}}</p>
    
    <div *ngIf = "title == 'Activity'">
        <div class="card-list row row-cols-1 row-cols-md-3 g-3">
            <div class="col" *ngFor="let item of activity">
                <mat-card>
                    
                    <div class="card-cnt">
                        <i class="fa fa-calendar fa-3x"></i>
                        <p class="card-title">{{item?.title}}</p>
                        
                    </div>
                    <div class="edit-btn">
                        <button>{{item?.dueDate}}</button>
                    </div>
                </mat-card>
            </div>
            
        </div>
    </div>
    <div *ngIf = "title == 'Goal'">
        <div class="card-list row row-cols-1 row-cols-md-3 g-3">
            <div class="col" *ngFor="let item of goal">
                <mat-card>
                    
                    <div class="card-cnt">
                        <i class="fas fa-file-medical fa-3x"></i>
                        <p class="card-title">{{item?.goalType}}</p>
                        <p>{{item?.presentValue}}</p>
                    </div>
                    <!-- <div class="edit-btn">
                        <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                    </div> -->
                </mat-card>
            </div>
            
        </div>
    </div>
    <div *ngIf = "title == 'Medications'">
        <div class="card-list row row-cols-1 row-cols-md-3 g-3">
            <div class="col" *ngFor="let item of med">
                <mat-card>
                    
                    <div class="card-cnt">
                        <i class="fas fa-file-medical fa-3x"></i>
                        <p class="card-title">{{item?.medicineName}}</p>
                        <p>{{item?.strength}}<span>{{item?.unit}}</span></p>
                    </div>
                    <!-- <div class="edit-btn">
                        <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                    </div> -->
                </mat-card>
            </div>
            
        </div>
    </div>
   
</div>


<div class="">
  <app-menu-comp></app-menu-comp>
  
      <div class="card-css main_container">
          <p class="heading">Assessment</p>
          <div class="activity-plan-cnt cnt">
              
              <div class="cnt">
                  <div class="survey">
                      <mat-card class="activity-feed-card" >
                          
                          <div class="cntr"  [formGroup]="myForm">
                              <div class="activity-feed">{{index_ques+1}} of {{numofq}} questions
                                <mat-progress-bar class="progress" mode="determinate" [value] = "((index_ques+1)/numofq)*100"></mat-progress-bar>
                              </div>
                              <div class="activity-tracking">
                                <p>{{index_ques+1}}) {{ques_item?.question}}?</p>
                                
                                  <mat-radio-group
                                        aria-labelledby="example-radio-group-label"
                                        class="example-radio-group" [(ngModel)]="sel" formControlName="radio">
                                        <mat-radio-button class="example-radio-button" *ngFor="let option of ques_item.option" [value]="option">
                                          {{option}}
                                        </mat-radio-button>
                                        
                                      </mat-radio-group>
                                      <div *ngIf="(ques_item?.optionType =='RADIOBUTTON'&& ques_item?.choiceType=='CHOICE' && (ques_item?.multiChoice==false))">
                                        <div *ngIf="submitted && f.radio.errors " class="text-danger">
                                          <div *ngIf="f.radio.errors.required">This field is required</div>
                                        </div>
                                      
                                    
                      
                                      <div class="example-button-row">
                      
                                        
                                      <button class="cancel-btn" mat-raised-button>Cancel</button>
                                      
                                      <button class="save-btn" *ngIf="(last_ques()==false)" mat-raised-button (click)="save_res(ques_item.id , sel , 'no');next_ques()">Next</button>
                                      <button class="save-btn" *ngIf="(last_ques()== true)" mat-raised-button (click)="save_res(ques_item.id , sel  ,'no');submit()">Submit</button>
                                        
                                      </div>
                                      
                                    
                                    
                                    
                                  
                                  </div>
                                  
                                  <div *ngIf="(ques_item?.optionType=='BUTTON'&& ques_item?.choiceType=='CHOICE')">
                                    
                                    
                                    <mat-button-toggle-group class="example-radio-group" [(ngModel)]="selbutton" formControlName="button">
                                      <mat-button-toggle class="example-radio-button"  *ngFor="let option of ques_item.option" [value]="option">{{option}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                    <div class="example-button-row">
                      
                                      
                                      <button class="cancel-btn" mat-raised-button>Cancel</button>
                                      
                                        <button class="save-btn" *ngIf="(last_ques()==false)" mat-raised-button (click)="save_res(ques_item.id , selbutton , 'no');next_ques()">Next</button>
                                        <button class="save-btn" *ngIf="(last_ques()== true)" mat-raised-button (click)="save_res(ques_item.id ,selbutton , 'no');submit()">Submit</button>
                                      
                                    </div>
                                    <div *ngIf="submitted && f.button.errors " class="text-danger">
                                      <div *ngIf="f.button.errors.required">This field is required</div>
                                    </div>
                                    
                                    
                                  </div>
                                  <div *ngIf="(ques_item?.choiceType=='ENTRY')">
                                    <div class="input-box">
                                      <div class="example-full-width" >
                                        <textarea name="input-box" #words formControlName="text"></textarea>
                                      </div>
                                    </div>
                                   
                                    <div class="example-button-row">
                      
                                      <button class="cancel-btn" mat-raised-button>Cancel</button>
                                      
                                        <button class="save-btn" *ngIf="(last_ques()==false)" mat-raised-button (click)="save_res(ques_item.id , words.value , 'no');next_ques()">Next</button>
                                        <button class="save-btn" *ngIf="(last_ques()== true)" mat-raised-button (click)="save_res(ques_item.id , words.value , 'no');submit()">Submit</button>
                                      
                                    </div>
                                    <div *ngIf="submitted && f.text.errors " class="text-danger">
                                      <div *ngIf="f.text.errors.required">This field is required</div>
                                    </div>
                                    
                                  </div>

                                  
                                    <div *ngIf="(ques_item?.optionType =='RADIOBUTTON'&& ques_item?.choiceType=='CHOICE' && ques_item?.multiChoice==true)">
                                      <div class="check-box-group">
                                        <mat-checkbox  class="example-radio-button" formControlName="checkbox" *ngFor="let option of ques_item.option; let i = index" (change)="selected_chart(ques_item.id,i , $event.checked)" [value]="option">{{option}}</mat-checkbox>
                                      </div>
                                        <div class="example-button-row" >

                      
                                        <button class="cancel-btn" mat-raised-button>Cancel</button>
                                        
                                          <button class="save-btn" *ngIf="(last_ques()==false)" mat-raised-button (click)="save_res(ques_item.id ,index_ques,'yes');next_ques()">Next</button>
                                          <button class="save-btn" *ngIf="(last_ques()== true)" mat-raised-button (click)="save_res(ques_item.id ,index_ques,'yes');submit()">Submit</button>
                                        
                                      </div>
                                      <div *ngIf="submitted && f.checkbox.errors " class="text-danger">
                                        <div *ngIf="f.checkbox.errors.required">This field is required</div>
                                      </div>

                                    </div>
                                  
                                    
                                    <button class="prev_btn" *ngIf="(index_ques!==0 && ((index_ques <= numofq)))" mat-raised-button (click)="prev_ques()">Previous</button>
                                

                                  
                                  


                                
                                
                                  
                              </div>
                          
                             
                          </div>
                          
                      </mat-card>
                     
                  </div>
              </div>
        </div>
          
  </div>


</div>
<block-ui></block-ui>
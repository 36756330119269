
  <div class="">
    <app-menu-comp></app-menu-comp>
    <div class="card-css main_container ">
      <p>My Health Profile</p>
      <form class="example-form" [formGroup]="myForm" >
        <div>
            <!-- <div class="ent">
              <mat-card>General Info</mat-card>
            </div> -->
          <br>
          <div>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Gender</mat-label>
              <mat-select required formControlName="gender" #gender [ngModel]="profile_detail?.gender">
                <mat-option>Select Gender</mat-option>
                <mat-option *ngFor="let item of gender_list" value= "{{item}}">
                  {{item}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.gender.errors?.required&& submitted">This Field is Required</mat-error>
            </mat-form-field>

          <!-- <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Gender</mat-label>
              <input readonly type="text" matInput #gender placeholder="Gender" formControlName="gender" [ngModel]="profile_detail?.gender" [matAutocomplete]="auto"/>
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option>Select Gender</mat-option>
              <mat-option *ngFor="let item of gender_list" value= "{{item}}">
                {{item}}
              </mat-option>
              </mat-autocomplete>
          </mat-form-field> -->
        </div>
          
        <div>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Ethnicity</mat-label>
            <mat-select required formControlName ="ethnicity" #ethnicity [ngModel]="profile_detail?.ethnicity">
              <mat-option>Select Ethnicity</mat-option>
              <mat-option *ngFor="let item of ethnicity_list" value = "{{item.title}}">
                {{item.title}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.ethnicity.errors?.required && submitted">This Field is Required</mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="country!='USA'" >
          <mat-form-field class="example-full-width" appearance="fill"   >
            <mat-label>Height</mat-label>
            <input required  type="number" maxlength="3" min="0" matInput #heightInput (input)="get_heightcm(heightInput.value)" formControlName='height' name="height" placeholder="height" [ngModel]="profile_detail?.height"/>
            <span matSuffix>cm</span>
            <mat-error *ngIf="f.height.errors?.required && submitted">This Field is Required</mat-error>
            <mat-error *ngIf="f.height.errors?.min || f.height.errors?.max || f.height.errors?.pattern">Enter a valid value</mat-error>
            

          </mat-form-field>
        </div>
        <div *ngIf="country=='USA'"  class="height-us" >
          <mat-form-field class="half-width" appearance="fill">
            <mat-label>Feet</mat-label>
            
            <input required matInput type="number" maxlength="2" min="0" max="10" #fett formControlName="heightfeet" [ngModel]="get_ftheight(profile_detail?.height)" name="height" ><span matSuffix>ft</span>
            <mat-error *ngIf="f.heightfeet.errors?.required && submitted">This Field is Required</mat-error>
            <mat-error *ngIf="f.heightfeet.errors?.min ||f.heightfeet.errors?.max || f.heightfeet.errors?.pattern">Enter a valid value</mat-error>

            
          </mat-form-field>
          <mat-form-field class="half-width" appearance="fill" >
            <mat-label>Inches</mat-label>
            <input matInput required type="number" maxlength="2" min="0" max="11" #inc [ngModel]="get_incheight(profile_detail?.height)" formControlName="heightinc" name="height"/><span matSuffix>inch</span>
            <mat-error *ngIf="f.heightinc.errors?.required && submitted">This Field is Required</mat-error>
            <mat-error *ngIf="f.heightinc.errors?.min ||f.heightinc.errors?.max || f.heightinc.errors?.pattern">Enter a valid value</mat-error>
            
          </mat-form-field>


        </div>
        
        <div>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label> Weight</mat-label>
            <input type="number" required maxlength="3" min="0"  matInput #weightInput formControlName='weight' name="weight" placeholder="Weight" [ngModel]="profile_detail?.weight" />
            <span *ngIf="country!='USA'" matSuffix>kg</span>
            <span  *ngIf="country=='USA'"  matSuffix>lbs</span>
            <mat-error *ngIf="f.weight.errors?.required && submitted">This Field is Required</mat-error>
            <mat-error *ngIf="f.weight.errors?.min || f.weight.errors?.pattern">Enter a valid value</mat-error>
            <mat-error *ngIf="f.weight.errors?.max">Enter a valid value</mat-error>

          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Date Of Birth</mat-label>
            <input required readonly [max]="maxDate" #dob matInput [value]="get_date_value(profile_detail?.dob)" [matDatepicker]="dp3" >
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker #dp3 ></mat-datepicker>
           

          </mat-form-field>
          <div *ngIf="!dob.value && submitted" class="text-danger">
            This Field is Required
          </div>
        </div>
      
        <div>
  
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Blood Group</mat-label>
            <mat-select  required formControlName ="bloodGroup" #bloodgroup [ngModel]="profile_detail?.bloodGroup">
              <mat-option>Select Blood Group</mat-option>
              <mat-option *ngFor="let item of bloodgrp_list" value= "{{item.title}}">
                {{item.title}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.bloodGroup.errors?.required && submitted">This Field is Required</mat-error>
            </mat-form-field>

        </div>
      
        <div class="ent">
            <mat-card>Medical Condition<button (click)="openDialog('medicalCondition', 'create')">+</button></mat-card>
        </div>
        <div class="med-condition">
          <mat-card *ngFor="let item of profile_detail?.medicalCondition let i = index">{{item.conditionName}}
            <div class="right-icon">
              <button (click)="openDialogEdit('medicalCondition', i, 'edit')" >
              <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div> 
          </mat-card>
        </div>

        <div class="ent">
          <mat-card>Allergy<button (click)="openDialog('allergy', 'create')">+</button></mat-card>
        </div>
        <div class="med-condition">
          <mat-card *ngFor="let item of profile_detail?.allergy let i = index">{{item.allergicTo}}
            <div class="right-icon"> 
              <button (click)="openDialogEdit('allergy', i, 'edit')">
              <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>

        <div class="ent">
          <mat-card>Medications<button (click)="openDialog('medication', 'create')">+</button></mat-card>
        </div>
        <div class="med-condition">
          <mat-card *ngFor="let item of profile_detail?.medication let i = index">{{item.medicationName}}
            <div class="right-icon">
              <button (click)="openDialogEdit('medication', i, 'edit')">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
        
        <div class="ent">
            <mat-card>Family Medical History<button (click)="openDialog('familyHistory', 'create')">+</button></mat-card>
        </div>
        <div class = "med-condition">
          <mat-card *ngFor="let item of profile_detail?.familyHistory let i = index">{{item.conditionName}}<br><span class="stext">{{item.relationship}}</span>
            <div class="right-icon">
              <button (click)="openDialogEdit('familyHistory', i, 'edit')">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
        
        <div class="ent">
            <mat-card>Insurance<button (click)="openDialog('insurance', 'create')">+</button></mat-card>
        </div>
        <div class = "med-condition">
          <mat-card *ngFor="let item of profile_detail?.insurance let i = index">{{item.insuranceCompany}}
            <div class="right-icon">
              <button (click)="openDialogEdit('insurance', i, 'edit')">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
        
        <div class="ent">
          <mat-card>Immunization<button (click)="openDialog('immunization', 'create')">+</button></mat-card>
        </div>
        <div class = "med-condition">
          <mat-card *ngFor="let item of profile_detail?.immunization let i = index">{{item.vaccineName}}
            <div class="right-icon">
              <button (click)="openDialogEdit('immunization', i, 'edit')">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
        <div class="example-button-row">
          <button class="cancel-btn" mat-raised-button routerLink="/dashboard">Cancel</button>
          <button mat-raised-button  [disabled]="isOpen"  (click)="onSaveClicked(weightInput.value , gender.value, ethnicity.value, bloodgroup.value , dob.value)">Save</button>
        </div>

       

        
      </div>
      </form>
    </div>
</div>
<block-ui></block-ui>






<div class="">
    <div class="card-css main_container">
        <!-- <p>Dashboard</p> -->
        <div class="row row-cols-1 row-cols-md-3 g-3" >
            <div *ngFor="let item of menu_list" class="col">
            <a routerLink="{{item.link}}" class="card h-100" title="Click to Navigate" >
                <div class="card-body">
                    <h2 class="card-title"><span><i class="{{item.icon}}"></i></span> <br/>{{item.name}}</h2>
                </div>
            </a>
            </div>
        </div> 
    </div>
</div>

<div class="container">
  <div class=" card-dialog row justify-content-center">
      <div class="right col-12">
      <div class="close-btn">
        <button (click)="close_dialog()">X</button>
      </div>
      <h1 mat-dialog-title>Add Exercise</h1>
          <div>
            <form class="" [formGroup]="myForm">
                    
              
              
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Title</mat-label>
                  <input matInput required formControlName="title" placeholder="Title" [ngModel]="data_edit?.title" />
                  <mat-error  *ngIf="f['title'].errors?.required  && submitted">This field is required</mat-error>
                </mat-form-field>
                
                
                
                <!-- <div ngIf="f['title'].errors" class="text-danger">
                  <div *ngIf="f['title'].errors.required && (submitted)">This field is required</div>
                </div>
                 -->
        
                
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Distance</mat-label>
                    <input matInput required type="number" #distance formControlName='distance' placeholder="Enter Distance" ngModel="{{data_edit?.distance}}" />
                    
                  </mat-form-field>
                  <mat-error *ngIf="myForm.hasError('notSame') && submitted">
                      Please enter either Distance or Weight Value, Do not enter both at same time
                    </mat-error>
                  
                  <!-- <div *ngIf="f['distance'].errors && submitted" class="text-danger">
                    <div  *ngIf="f['distance'].errors.min && submitted">Please enter a positive value</div>
                  </div> -->
                  
                
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Distance Unit</mat-label>
                  <mat-select formControlName ="distanceUnit"   placeholder="Enter Distance Unit" [ngModel]="data_edit?.distanceUnit" >
                    
                    <mat-option *ngFor="let item of distanceUnit_list" value = "{{item}}">
                      {{item}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>
                    
                  
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Weight</mat-label>
                        <input type="number"  pattern="^[0-9]*$"  min="0" required  matInput #weight formControlName='weight' placeholder="Enter weight" [ngModel]="data_edit?.weight" />
                        <mat-error  *ngIf="f['weight'].errors?.min && submitted">Please enter a positive value</mat-error>
                       </mat-form-field>
                   
                     
                   
                    <!-- <mat-error *ngIf="myForm.hasError('notSame') && submitted">
                      Please enter either Distance or Weight Value, Do not enter both at same time
                    </mat-error> -->
                      
                    
                        <mat-form-field class="example-full-width" appearance="fill">
                          <mat-label>Number Of Steps</mat-label>
                          <input type="number"  pattern="^[0-9]*$" min="0" max="99" matInput #setps formControlName='numberOfStep'  placeholder="Enter Number Of Steps" [ngModel]="data_edit?.numberOfStep" />
                          <mat-error  *ngIf="f['numberOfStep'].errors?.min && submitted">Please enter a positive value</mat-error>
                          <mat-error  *ngIf="f['numberOfStep'].errors?.max && submitted">Please enter a value less or equal to 99</mat-error>
                      </mat-form-field>
                    
                       
                        
                      
                    
                        
                        
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Repetition</mat-label>
                            <mat-select  formControlName ="repetition" #reps placeholder="Enter repetition" ngModel ="{{data_edit?.repetition}}" >
                              <mat-option value = 0>None</mat-option>
                              <mat-option *ngFor="let item of repitation_list" value = "{{item.value}}">
                                {{item.title}}
                              </mat-option>
                            </mat-select>
                            
                          </mat-form-field>

               
                          
                       
        
                            
                            

            
                
             

            
              
                <div class="save-button">
                  <button type="submit" [disabled]="btn_on" *ngIf="data_edit == undefined" (click)="onSubmit(reps.value,setps.value)">Save</button>
                  <button type="submit"  *ngIf = "data_edit" (click)= "onEdit()">Save</button> 
                </div>
                <div *ngIf = "data_edit" class="delete-button">
                  <button (click)= "onDelete()">Delete</button>
                </div>

          </form>
          </div>
      </div>
  </div>
</div>
<block-ui></block-ui>
<app-menu-comp></app-menu-comp>
<div class="chat_cnt">
    <div id="coffer-container">
        <aside id="coffer-menu">
            <header>
                <div class="coffer-profile">
                    <img *ngIf="(!imgurl)" src="../../assets/images/owner.png" alt="">
                    <img *ngIf="( imgurl)" src={{imgurl}} alt="">
                    <div>
                        <h1>{{ownerName}}</h1>
                        <!-- <p>Gillete India</p> -->
                    </div>

                </div><br><br>
                <div class="coffer-search-icon">
                    <i class="fa fa-search " aria-hidden="true"></i>
                    <input type="text" #filterValue placeholder="Search">
                </div>

            </header>
            <ul>
                <mat-accordion>
                    <li *ngFor="let item of contactList"
                        [hidden]="getFilterValue(item).indexOf(filterValue.value.toLowerCase()) == -1">
                        <ng-container *ngIf="item['isaccepted'] && item['biztype'] == 'consumer'">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                disabled style="margin-bottom: 1px; background: transparent;">
                                <mat-expansion-panel-header
                                    (click)="chatUsers(item['guid'], item['business_name'], true, '', 'Admin', 'consumer')">
                                    <mat-panel-title>
                                        <img class="userImg" *ngIf="(!item['profileUrl'])" src="../../assets/images/user.png" alt="">
                                        <img class="userImg" *ngIf="(item['profileUrl'])" [src]="item['profileUrl']" alt="">
                                        <div>
                                            <h1 class=" coffer-nav-header-title">{{item['business_name']}}</h1>
                                            <!-- <div class="status green"></div> -->
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                            </mat-expansion-panel>
                        </ng-container>
                        <ng-container *ngIf="item['isaccepted'] && item['biztype'] != 'consumer'">
                            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                                style="margin-bottom: 1px; background: transparent;" [expanded]="action == item['id']" >
                                <!-- expanded="true" -->
                                <mat-expansion-panel-header (click)="loadFirmUsers(panelOpenState, item)">
                                    <mat-panel-title>
                                        <img class="userImg" *ngIf="(!item['profileUrl'])" src="../../assets/images/user.png" alt="">
                                        <img class="userImg" *ngIf="(item['profileUrl'])" [src]="item['profileUrl']" alt="">
                                        <div>
                                            <h1 class=" coffer-nav-header-title">{{item['business_name']}}</h1>
                                            <!-- <div class="status green"></div> -->
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- <ul class="list-group"> -->
                                <p (click)="chatUsers(selectedFirmJid, selectedFirm, true, '', 'Admin', '')"
                                    class="list-group-item sub-users">
                                    <i class="fa fa-user-circle fa-lg" style="margin-right: 5px; color: #078f8f;"
                                        aria-hidden="true"></i>{{selectedFirm}}
                                </p>
                                <p (click)="chatUsers(selectedFirmJid, user['name'], false, user['id'], selectedFirm, '')"
                                    class="list-group-item sub-users" *ngFor="let user of firmUsersList"><i
                                        class="fa fa-user-circle fa-lg" style="margin-right: 5px; color: #078f8f;"
                                        aria-hidden="true"></i>{{user['name']}}</p>
                                <!-- </ul> -->
                            </mat-expansion-panel>
                        </ng-container>

                    </li>
                </mat-accordion>
            </ul>
        </aside>
        <main>
            <header class="head">
                <img src="../../assets/images/user.png" alt="">
                <div class="coffer-header-content">
                    <p>{{toName}}</p>
                </div>
                <i class="fa fa-video-camera chatclse" aria-hidden="true" (click)="StartVideo()"></i>
                    <!-- <div class="coffer-current-status"></div> -->
            </header>
            <div id="coffer-chat" class="scrollbar-deep bordered-deep thin" #scrollMe [scrollTop]="scrollMe.scrollHeight">
                <ul>
                    <!-- <ng-container > -->
                    <li *ngFor="let msg of messages" [class]="msg['action'] == 'SENT' ? 'coffer-me t' : 'coffer-you t'">
                        <div class="coffer-chat-list">
                            <img src="../../assets/images/user.png" alt="">
                            <div class="coffer-message">{{msg['text']}}
                            </div>
                            <p [class]="msg['action'] == 'SENT' ? 'coffer-time-me' : 'coffer-time-you'">
                                {{msg['timestamp']}}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <footer>
                <input type="text" id='messege_input' #messageinput placeholder="Write your message...">
                <i class="fa fa-paper-plane fa-1x" aria-hidden="true" (click)="sendMessage(messageinput.value)"></i>
            </footer>
        </main>
    </div>
</div>
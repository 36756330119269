import { DatePipe } from '@angular/common';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { AuthServiceService } from '../auth-service.service';
declare let Strophe: any;
declare let $msg: any;
declare let $pres: any;
declare let $iq: any;
declare let $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('scrollMe') myScrollContainer: ElementRef | undefined;
  @ViewChildren('item')
  itemElements!: QueryList<any>;

  action: String = ""

  domain = environment.xmppDomain
  panelOpenState = false;
  toJID = ""
  toName = "Select Contact"
  ownerName = ""
  contactList = []
  messages = []
  contactSelected = false
  USERNAME = "" //prof_ravitejachakkadigicoffercom@devchat.digicoffer.com
  PASSWORD
  ownerJID = localStorage.getItem("jid")
  // URL = "https://" + this.domain + "/http-bind";
  URL = "wss://" + this.domain + ":5443/ws";
  imgurl:any;



  // Temporary
  firmUsersList = []
  selectedFirm = ""
  selectedFirmJid = ""
  existingData = { bizType: "" }
  chatUserName:any;

  constructor(private router: Router, private toastr: ToastrService, private authservice: AuthServiceService, private _Activatedroute: ActivatedRoute) {
    Strophe.ui = this
    this._Activatedroute.paramMap.subscribe(params => {
      // this.realtionshipRequest()
      var value = params.get('id')
      if (value)
        this.action = value
      this.loadUsersData()
    });

    // this.scrollFrame = 
  }
  ngOnDestroy(): void {
    //console.log("disconnecting")
    Strophe.ui.conn.disconnect()
  }
  ngOnInit(): void {

    this.authservice.get_image().subscribe((res:any)=>{
      if(res.error==false){
        this.imgurl = res.imageUrl;
      }
    })

    if (localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.ownerName = localStorage.getItem('first_name') + " " + localStorage.getItem('last_name');
    this.PASSWORD = localStorage.getItem("TOKEN")
    this.USERNAME = this.ownerJID + "@" + this.domain
    var connection = new Strophe.Connection(this.URL);
    connection.connect(this.USERNAME, this.PASSWORD, this.onConnect)

    Strophe.ui.conn = connection
    this.scrollToBottom()

    // below is the code which works for dropdown icon. on click, down arrow will become up arrow

    // $('i.coffer-collapse-icon').click(function () {
    //   $('i.coffer-collapse-icon').parent().parent().children('ul.coffer-nav-list').toggle(300);


    //   if ($('i.coffer-collapse-icon').hasClass("fa-chevron-up")) {
    //     $('i.coffer-collapse-icon').addClass("fa-chevron-down");
    //     $('i.coffer-collapse-icon').removeClass("fa-chevron-up");
    //     //console.log("true")
    //   } else {
    //     $('i.coffer-collapse-icon').removeClass("fa-chevron-down");
    //     $('i.coffer-collapse-icon').addClass("fa-chevron-up");
    //   }

    // });



  }

  StartVideo(){
    let jid = this.ownerJID + "@" + this.domain
    console.log("jid",jid)
    let token = localStorage.getItem('TOKEN')
    let firstName = localStorage.getItem('first_name') || '';
    let lastName = localStorage.getItem('last_name') || '';
    let name = `${firstName} ${lastName}`.trim(); // Trim extra spaces
    const link =  `${environment.AVChat}?logintype=ind&token=${token}&jid=${jid}&name=${name}`;
    window.location.href = link;
  }
  scrollToBottom(): void {
    try {
      if (this.myScrollContainer)
        this.myScrollContainer.nativeElement.scroll({
          top: this.myScrollContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        })
    } catch (err) { }
  }

  ngAfterViewInit() {
    this.scrollToBottom()
  }

  ngAfterViewChecked() {
    // $('.t').last().addClass('active-li').focus();

  }

  loadUsersData() {
    this.authservice.service_provider_list().subscribe((res: any) => {
      this.contactList = res['data']['relationships'];
      for (let item of this.contactList) {
        if (this.action == item['id']) {
          this.existingData['bizType'] = item['bizType']
          if (item['biztype'] != 'consumer')
            this.loadFirmUsers(true, item)
          else {
            this.existingData['guid'] = item['guid']
            this.existingData['name'] = item['business_name']
          }
        }
      }
    })
  }

  stanzaHandler(msg) {
    // Strophe.ui.conn.addHandler(Strophe.ui.stanzaHandler, null, "message")
    Strophe.ui.xmlParser(msg)
    // Strophe.ui.conn.addHandler(Strophe.ui.stanzaHandler, null, "message");
    return true
  }

  async xmlParser(msg) {
    var timeStamp
    var action = "RECEIVE"
    var from = ""
    var messaageBody = $(msg).find('forwarded')[0]
    var body = ""
    if (messaageBody != undefined) {
      var messaage = $(messaageBody).find('message')[0]
      // //console.log(messaage)
      var to = $(messaage).attr('to')
      from = $(messaage).attr('from')
      timeStamp = $(messaageBody).find('delay').attr("stamp")
      timeStamp = new DatePipe('en-US').transform(timeStamp, 'MMM dd yyyy HH:mm')
      // //console.log(to)
    }
    else {
      var to = $(msg).attr('to')
      from = $(msg).attr('from')
      timeStamp = new DatePipe('en-US').transform(new Date(), 'MMM dd yyyy HH:mm')
    }

    if (from.indexOf("/") != -1)
      from = from.split("/")[0]

    var bodies = $(msg).find('body')
    body = Strophe.xmlunescape(Strophe.getText(bodies[0]))
    if (to == (this.toJID + "@" + this.domain))
      action = "SENT"
    else if (from != (this.toJID + "@" + this.domain))
      action = "NONE"
    if (action != "NONE")
      Strophe.ui.messages.push({ text: body, action: action, timestamp: timeStamp })
    this.scrollToBottom()
  }

  onConnect(status) {
    if (status == Strophe.Status.CONNECTING) {
      console.log('Strophe is connecting.');
    } else if (status == Strophe.Status.CONNFAIL) {
      console.log('Strophe failed to connect.');
    } else if (status == Strophe.Status.DISCONNECTING) {
      console.log('Strophe is disconnecting.');
    } else if (status == Strophe.Status.DISCONNECTED) {
      console.log('Strophe is disconnected.');
    } else if (status == Strophe.Status.CONNECTED) {
      console.log('Strophe is connected.');
      Strophe.ui.toastr.success("Connection Established")
      Strophe.ui.conn.addHandler(Strophe.ui.stanzaHandler, null, "message");
      // Strophe.ui.conn.addHandler(Strophe.ui.onOwnMessage, null, 'iq', 'set', null, null);
      // Strophe.ui.conn.addHandler(Strophe.ui.on_presence, null, 'presence', null, null, null);
      Strophe.ui.conn.send($pres().tree());
      if (Strophe.ui.action != '') {
        if (Strophe.ui.existingData['bizType'] == 'consumer')
          Strophe.ui.chatUsers(Strophe.ui.selectedFirmJid, Strophe.ui.selectedFirm, true, '', 'Admin', Strophe.ui.existingData['bizType'])
        else
          Strophe.ui.chatUsers(Strophe.ui.existingData['guid'], Strophe.ui.existingData['name'], true, '', 'Admin', Strophe.ui.existingData['bizType'])
      }
    }
  }

  restoreMessages() {
    //     ``` <iq type='set' id='juliet1'>
    //   <query xmlns='urn:xmpp:mam:2'>
    //     <x xmlns='jabber:x:data' type='submit'>
    //       <field var='FORM_TYPE' type='hidden'>
    //         <value>urn:xmpp:mam:2</value>
    //       </field>
    //       <field var='with'>
    //         <value>juliet@capulet.lit</value>
    //       </field>
    //     </x>
    //   </query>
    // </iq>```

    this.messages = []

    var query = $iq({ type: 'set', id: this.toJID })
      .c('query', { xmlns: 'urn:xmpp:mam:2' })
      .c('x', { xmlns: 'jabber:x:data', type: 'submit' })
      .c('field', { var: 'FORM_TYPE', type: 'hidden' })
      .c('value', {}).t('urn:xmpp:mam:2').up().up()
      .c('field', { var: 'with' })
      .c('value', {}).t(`${this.toJID}@${this.domain}`).up().up().up()
      .c('set', { xmlns: 'http://jabber.org/protocol/rsm' })
      .c('max', {}).t('500').up()
      .c('before')
    Strophe.ui.conn.send(query)

  }

  selectUser(value) {
    this.toName = value
    this.toJID = 'prof_rajendrasaidigicoffercom'
    // if (groupName.toLowerCase() == 'admin')
    //   this.toName = name + " - " + groupName
    // else if (groupName.toLowerCase() == 'backupadmin')
    //   this.toName = name + " - " + groupName
    // else
    //   this.toName = groupName + " - " + name
    this.messages = []
    this.contactSelected = true
    this.restoreMessages()
  }

  sendMessage(message) {
    if (message.trim() == "")
      return
    else if (!this.contactSelected) {
      this.toastr.error("Select contact")
      return
    }
    var UserName = localStorage.getItem('first_name')+" "+localStorage.getItem('last_name')
    var subject = UserName + " ##" + this.ownerJID + "## #N#" + UserName + "#N#"
    var msgXML = $msg({ to: `${Strophe.ui.toJID}@${Strophe.ui.domain}`, type: "chat" }).c('subject').t(subject);
    msgXML.up().c('body').t(message);
    Strophe.ui.conn.send(msgXML);
    var timeStamp = new DatePipe('en-US').transform(new Date(), 'MMM dd yyyy HH:mm')
    Strophe.ui.messages.push({ text: message, action: 'SENT', timestamp: timeStamp })
    $("#messege_input").val("")
  }

  getTMFilterValue(value) {
    return value.toLowerCase()
  }

  loadFirmUsers(panelOpenState, item) {
    this.selectedFirm = ""
    this.selectedFirmJid = ""
    this.firmUsersList = []
    this.authservice.notify_user_list(item.id).subscribe(
      (resp) => {
        this.selectedFirm = item.business_name
        this.selectedFirmJid = resp['data']['uid']
        this.firmUsersList = resp['data']['users']

      }
    )
  }

  chatUsers(jid, name, isAdmin, userid, firm, biztype) {
    // (<HTMLInputElement>document.getElementById("btn_send")).disabled = false;
    this.toJID = jid
    if (!isAdmin)
      this.toJID += "_" + userid
    if (firm == 'Admin') {
      if (biztype == 'consumer')
        this.toName = name
      else
        this.toName = name + " - " + firm
    }
    else
      this.toName = firm + " - " + name
    this.messages = []
    this.contactSelected = true
    // //console.log(this.toJID)
    // const root: XmlElement = $build('presence', {
    //   // from: this.USERNAME, 
    //   from: this.USERNAME,
    //   to: this.toJID+"@"+this.domain,
    //   type: 'probe'
    // });
    this.restoreMessages()
  }

  getFilterValue(item) {
    var name = item.business_name
    return name.toLowerCase()
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../auth-service.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import _ from 'lodash';
import { BlockUI, NgBlockUI } from 'ng-block-ui';




@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: false,}
  } , DatePipe ]
})
export class ActivityFeedComponent implements OnInit {
  @BlockUI()
  blockUI!: NgBlockUI;
  activity_feed: any; 
  last:any;
  dateOrder:any;
  state = false;
  datematch:any;
  maxDate = new Date();

  isLinear = true;
  status: any;
  activityDateTime: any;
  id:any;
  formatDate: any;
  groupArr;
  date = new Date();
  today_date = this.datePipe.transform(this.date,'YYYYMMdd');
  activity_feed_tillDay:any;
  //@ViewChild(MatCalendar) _datePicker: MatCalendar<Date>

  state_icon="check";
  constructor(private authService: AuthServiceService , private router: Router , private datePipe: DatePipe , private toast : ToastrService) { }
 

  ngOnInit(): void {
    // debugger 
     if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.blockUI.start()
    this.authService.get_activity_feed_tilldate(this.today_date).subscribe(res => {
      if(res){
        this.activity_feed = res;
        
           
         this.last = this.activity_feed[this.activity_feed.length-1];
       
        }
      this.groupArr = this.sortData.reduce((r,{activityDateTime})=>{
        if(!r.some(o=>o.activityDateTime.split(",")[0]==activityDateTime.split(",")[0])){
          r.push({activityDateTime,groupItem:this.sortData.filter(v=>v.activityDateTime.split(",")[0]==activityDateTime.split(",")[0])});
    }
    return r;
    },[]);
     
      this.blockUI.stop()
      
   
      
    })


    // var groups = this.activity_feed.reduce(function(groups, game)  {
    //   const date = game.activityDateTime.split(',')[0];
    //   if (!groups[date]) {
    //     groups[date] = [];
    //   }
    //   groups[date].push(game);
      
    //   return groups;
    // }, {});

    

  // for(var i=0;i<this.activity_feed.length;i++)
  // {

  //   const current=this.activity_feed[i].activityDateTime.split(',')[0];
  //   if(this.datematch.indexOf(current) === -1) this.datematch.push(current);
  // }
  //   //console.log(this.datematch)
 
   
      
  
    
    
    
  

    
  }

change_icon(value){
  if(value.canChangeStatus==true && value.status!='COMPLETED'){
    this.state_icon = "wrong"


  } 
}
   

  get sortData() {
      return this.activity_feed?.sort((a, b) => {
        return <any>new Date(b.activityDateTime) - <any>new Date(a.activityDateTime);
    
      });
      
    }
    
  

 
 




    // isInArray(array, value) {
    //   this.datematch = (array.find(item => {return item == value}) || []).length > 0;
    //   //console.log(this.datematch)
    // }


 


  OnClickDone(id:any , inputValue:any ){
    this.status = "COMPLETED"


    if(inputValue == ""){
      this.activityDateTime = new Date();
    } else {
      this.activityDateTime = inputValue;
    }
  
    this.formatDate = this.datePipe.transform(this.activityDateTime , "MM-dd-yyyy, hh:mm a")
     
    
    this.authService.update_activity_log(id , this.status , this.formatDate).subscribe((res:any) => {
     
      this.toast.success("Completed this task!!");
    })
  }

   

  

}



import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare let $: any

@Component({
  selector: 'app-menu-comp',
  templateUrl: './menu-comp.component.html',
  styleUrls: ['./menu-comp.component.scss']
})
export class MenuCompComponent implements OnInit {

  menu_icon = "fa fa-bars";
  menubar_names_icons = [{name:'Home' , icon: 'fas fa-home', menu_link: '/home' },{name: 'Documents', icon: 'far fa-file-alt', menu_link: '/documents'},{name: 'Connections', icon: 'fas fa-users', menu_link: '/connections'},{name: 'Health', icon: 'far fa-heart', menu_link: '/dashboard'}];

  bottom_menu_icons = [{name: 'Notify', icon: 'far fa-bell', menu_link: '/notify'},{name: 'Reminders', icon: 'far fa-clock', menu_link: '/reminders'},{name: 'Audit Trails', icon: 'fa fa-history', menu_link: '/audit'},{name: 'Chat', icon: 'far fa-comment-dots', menu_link: '/menu/chat'}, {name: 'Settings & Profile', icon: 'fa fa-cog', menu_link: '/menu/settings'}, {name: 'Logout', icon: 'fas fa-sign-out-alt', menu_link: '/logout'}]
  

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  menu_open(){
    var x = window.matchMedia("(min-width: 320px)")
    var y = window.matchMedia("(max-width: 1023px)")
    if(this.menu_icon == "fa fa-bars"){
      this.menu_icon = "fa fa-list-ul";
      
      if(x.matches && y.matches){
        $(".sidebar").css({"height": "100%", "background-color": "#000000", "border-bottom-right-radius": "0px"})
        $(".main_container").css({"padding": "25px 0px 25px 100px"})
        if(x.matches && window.matchMedia("(max-width: 400px)").matches){
          $(".patient-list-column").css({"margin-left": "22%"})
        } else if (window.matchMedia("(min-width: 400px)").matches && window.matchMedia("(max-width: 767px)").matches){
          $(".patient-list-column").css({"margin-left": "22%"})
        }
      }  
    } else {
      this.menu_icon = "fa fa-bars";
      if(x.matches && y.matches){
        $(".sidebar").css({"height": "auto", "background-color": "#00000087", "border-bottom-right-radius": "10px"})
        $(".main_container").css({"padding": "82px 0px 25px 15px"})
        if(x.matches && window.matchMedia("(max-width: 400px)").matches){
          $(".patient-list-column").css({"margin-left": "9%"})
          
        } else if (window.matchMedia("(min-width: 400px)").matches && window.matchMedia("(max-width: 767px)").matches){
          $(".patient-list-column").css({"margin-left": "0%"})
        }
        // $(".patient-list-column").css({"margin-left": "9%"})
      } 
    }
    //console.log("menu is open")
  }

  redirect(){
    $("closeBtn").click();
}

}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';

@Component({
  selector: 'app-care-team',
  templateUrl: './care-team.component.html',
  styleUrls: ['./care-team.component.scss']
})
export class CareTeamComponent implements OnInit {
  searchbar = 'searchbar';
  display_value: any;
  
  @Input() display_status: any;
  @Input() chevron_left: any;
  @Input() call_message_buttons: any;
  @Input() service_provider_lists: any;
 
  @Input() patient_details: any;
  @Input() patient_selected: any;

  @Output() patientClicked = new EventEmitter();
  @Output() display = new EventEmitter();
 
  accept_text = "Accept";
  accept_class = "accept-button";

  removable = true;
  chips: string[] = [];
  searchText: any;
  searchTextSecond: any;
  bottomSheetRef: any;
  @Input() relationship_list_parent: any;
  relationship_list_data: any;
  care_team_list: any;
  service_provider_name: any;

  displayedColumns: string[] = ['business_name', 'accept_text'];
  dataSource : any;
  dataSource_care_team: any;
  imgurl:any;
  
  
  constructor(public dialog: MatDialog , public auth: AuthServiceService , public toastr: ToastrService , public router:Router) {  }

  loadData(){
    
    
    if(this.service_provider_lists){
      this.dataSource = this.service_provider_lists
      this.care_team_list = this.service_provider_lists.filter((item) => {
        return item.biztype == 'consumer';
      })
      this.dataSource_care_team = this.care_team_list;
 
      this.service_provider_name = this.service_provider_lists.filter((item) => {
        return item.biztype == 'prof';
      })
      // this.service_provider_name.forEach(element => {

      //   if(!element.isaccepted){
      //     if(Object.keys(element).includes('canAccept') && element.canAccept ){
      //       this.accept_text = "Accept"
      //     } else if (Object.keys(element).includes('canAccept') && !element.canAccept) {
      //       this.accept_text = "Pending"
      //     }
      //   }
        
      // });


      // if(this.relationship_list_parent){
      //   this.relationship_list_data = this.relationship_list_parent;
      //   //console.log("m==> " ,this.relationship_list_parent)
      // }
      
      
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.auth.service_provider_list().subscribe((res: any) => {
      this.service_provider_lists = res.data?.relationships;
      
      this.loadData();
    })

    this.auth.relationship_list().subscribe((res: any) => {
     
      
      this.relationship_list_data = res;
     
      
      
    })
    this.auth.get_image().subscribe((res:any)=>{
      if(res){
        this.imgurl = res.imageUrl;
      }
    })



    
  }

  toggle_patient_column(){
    this.display.emit("none");
  }


  remove(chip): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    } 
  }

  pickValue(a:any){
    // this.patientClicked.emit(a);
    // //console.log(a)
    // return a;
  }
  acceptRequest(id){
    this.auth.care_team_accept_request(id).subscribe((res:any)=>{
     
      if(res.error && typeof(res.msg) == "string"){
        this.toastr.info(res.msg);

      } else if(!res.error) {
        this.toastr.success(res.msg);
 
      }
      else if(res.msg.description) {
        this.toastr.info(res.msg.description);
      }
       
    })
   

  }

  openDialog() {
    this.dialog.open(RelationshipCareDialog, {
      data: [Object.assign({}, this.relationship_list_data), Object.assign({}, this.relationship_list_data)]
    });
    
  }


}

@Component ({
  selector: 'relationship-care-menu-dialog', 
  templateUrl: 'relationship-care-dialog.html',
  styleUrls: ['./care-team.component.scss']
})

export class RelationshipCareDialog {
  new_patient:any;
  loop_num = ["a", "b", "c"]
  removable = true;
  chips: string[] = [];
  searchText: any;
  relationship_list: any;
  bottomSheetRef: any;
  filtered_relationship_list: any;
  chips_data_from_child: string[] = [];
  filter_list: any;
  original_list_relationship: any;
  care_team_list:any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialog: MatDialogRef<RelationshipCareDialog> ,public filter_dialog: MatDialog , public auth: AuthServiceService,public send_menu_request_dialog: MatDialog) { 
    this.relationship_list = data[0];
    this.filtered_relationship_list = data[0];
    this.filter_list = Object.assign({}, data[0]);
    this.original_list_relationship = Object.assign({}, data[1]);
  }
  loaddata(){
    // var list = Object.assign([],this.relationship_list.entities);
    
    if(this.chips_data_from_child && (this.chips_data_from_child.length != 0 && this.relationship_list.entities)){
      this.filtered_relationship_list.entities = this.filter_list.entities.filter((element) => {
        if(element.country.toLowerCase() == this.chips_data_from_child[0] || this.isEmpty(element.address.city_town).toLowerCase() == this.chips_data_from_child[1] || this.isEmpty(element.address.state).toLowerCase() == this.chips_data_from_child[2] || this.isEmpty(element.address.zipcode) == this.chips_data_from_child[3]){
          return true 
        } else {
          return false;
        }
      })
      
     
    }  

    if(this,this.chips_data_from_child){
      if(this.chips_data_from_child.every((e) => {
        return e == ""
      })){
      
        if(this.filter_list.entities){
        this.filtered_relationship_list.entities = this.filter_list.entities;
        }
      } else {
       
      }
    }
    

  }

  ngOnInit(){    
    
    this.auth.care_team_list_all().subscribe((res:any)=>{
      this.care_team_list = res.consumers;
     

    })
    this.loaddata()

  }

  isEmpty(value: any){
    if(value == undefined){
      return "empty"
    }
    return value
  }


  closeDialog(){
    this.dialog.close();
  }

  remove(chip): void {
    const index = this.chips.indexOf(chip);
    if (index >= 0) {
      this.chips.splice(index, 1);
      // this.chips[index] = ""
    }
    const ind = this.chips_data_from_child.indexOf(chip)
    this.chips_data_from_child[ind] = "";

  }

  openSendRequestDialog(value){
    this.send_menu_request_dialog.open(SendCareRequest, {
      data: value
    })
  }

}

@Component ({
  selector: 'send-care-request',
  templateUrl: 'send-care-request.html',
  styleUrls: ['./care-team.component.scss']
})

export class SendCareRequest {
  name = new FormControl('');
  selected_entity: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<SendCareRequest>, private authservice: AuthServiceService, private formBuilder: FormBuilder, private toastr: ToastrService){
    this.selected_entity = data;
  }

  myForm = this.formBuilder.group({
    desc: ['Please accept the request']
  })

  get f() { return this.myForm.controls; }

  ngOnInit(){
   
  }


  cancel(){
    this.dialog.close();
  }

  confirm(desc){
    this.authservice.care_team_send_request(desc , this.selected_entity).subscribe((res: any) => {
    
      if(res.error && typeof(res.msg) == "string"){
        this.toastr.info(res.msg);
        this.dialog.close()
      } else if(!res.error) {
        this.toastr.success(res.msg);
        this.dialog.close();
      }
      else if(res.msg.description) {
        this.toastr.info(res.msg.description);
      }
    });

  }



}


import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  name = new FormControl('');
  email: any;
  submitted = false;

  message: string = '';
  subscription: any;

  password_status = "fa fa-eye-slash";
  hide = true;
  confirm_password_status = "fa fa-eye-slash";
  confirm_hide = true;


  constructor(private toastr: ToastrService, private data: DataService ,private http: HttpClient, private formBuilder: FormBuilder, private router: Router, private authService: AuthServiceService) { }

  myForm = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    token: ['', Validators.required],
    ConfirmPassword: ['', Validators.required]
  }, { validator: this.checkPasswords })

  get f() { return this.myForm.controls; }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let confirmPass = group.controls.ConfirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  ngOnInit(): void {
    
    this.subscription = this.data.cofferIdMessage.subscribe(message => {
      this.message = message;
    });
    // //console.log("here is the id - ", this.message);

    if(this.message == "empty" || this.message == ""){
      this.router.navigate(['/forgot-password']);
    }
  }

  password_icon(){
    this.password_status = "fa fa-eye";
    this.hide = !this.hide;
    
    if(this.hide){
      this.password_status = "fa fa-eye-slash"
    } else {
      this.password_status = "fa fa-eye"
      this.hide = false;
    }
  }

  confirm_password_icon(){
    this.confirm_password_status = "fa fa-eye";
    this.confirm_hide = !this.confirm_hide;
    
    if(this.confirm_hide){
      this.confirm_password_status = "fa fa-eye-slash"
    } else {
      this.confirm_password_status = "fa fa-eye"
      this.confirm_hide = false;
    }
  }


  onSubmit(token: string, password: string, confirmPassword: string){
    this.submitted = true;
    if (this.myForm.invalid) {
      return;
    }
    this.authService.submit_password(this.message, password, token).subscribe((res: HttpResponse<any>) => {
      if(!res.body.error){
        this.router.navigate(['/login']);
        this.toastr.success(res.body.msg, "Success!")
      } else {
        this.toastr.error(res.body.msg, "Error!")
      }
    });
  }

  resend_token(){
    this.authService.resend_password_token(this.message).subscribe((res: HttpResponse<any>) => {
      this.toastr.info(res.body.msg);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

<mat-card class="care-program-card">
    <div class="plus-icon">
        <i class="fas fa-plus"></i>
    </div>
    <div class="cntr container">
        <div class="care-program">
                <p class="heading">Care Program</p>
                <p class="update-time">Last Update: 3 Weeks ago on 08/10/2020</p>
        </div>
        <div class="cp-content">
            <div class="cp" *ngFor="let item of care_program_details">
                <div class="care-program-content ">
                    <div class="care-program-first-column ">
                        {{item.title}}
                        <div class="progress" >
                            <div class="progress-bar" [style.width]="item.right_column[1].value"></div>
                        </div>
                    </div>
                    <!-- <div *ngFor="let eachItem of item.right_column" class="care-program-second-column ">
                        <p class="cp-border">{{eachItem.title}} <br><span>{{eachItem.value}}</span></p>
                    </div> -->
                </div>
            
            </div> 
        </div>
        
    </div>
</mat-card>
<div class="send-request-section">
    <p class="headings">Relation Request</p>
    <p>Are you sure to send Relationship request with the selected Entity ?</p>
    <form [formGroup]="myForm" >
        <input placeholder="Description" #descriptionInput type="text" formControlName='desc' name="desc" class="form-control">
        <!-- <div *ngIf="f['desc'].errors" class="text-danger">
            <div *ngIf="f['desc'].errors.required">This field is required</div>
            <div *ngIf="f['desc'].errors.msg">{{f['desc'].errors.msg}}</div>
        </div>  -->
    </form>
</div> 
<div class="confirmation_buttons">
    <button class="cancel" (click)=cancel()>Cancel</button>
    <button class="confirm" (click)=confirm(descriptionInput.value)>OK</button>
</div>
<!-- 
<div class="col-md-12">
    <app-menu-comp></app-menu-comp>
    <div class="container">
        <div class="activity-plan-cnt cnt">
            <div class="row ">
                <div class="col-md-12">
                    <mat-card class="care-plan-card">
                        <div class="plus-icon">
                            <i class="fas fa-plus"></i>
                        </div>
                        <div class="cntr">
                            <div class="care-plan">
                                <p class="update-time">Care Plan</p>
                            </div>
                        
                            <h3>Pending</h3>
                            <div class="cp-content">
                                <div class="cp" >
                                    <div class="care-plan-content ">
                                        <div class="care-plan-first-column ">
                                            <h4>title <span>value</span></h4>
                                            <div class="progress" >
                                                <div class="progress-bar" [style.width]="60"></div>
                                            </div>
                                            <p>due_date</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="care-plan-buttons">
                            <button>View completed task</button>
                            <button>Assign a new task</button>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
        
    </div>
</div>
 -->


 <div class="care-plan">
    <app-menu-comp></app-menu-comp>
    <p class="heading">Care Plan</p>
    <div class="searchbar-e">
        <input  type="text" placeholder="Search" [(ngModel)] = searchText  #filterValue>
        <i class="fas fa-search"></i>
    </div>
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        <div class="col" *ngFor="let item of care_plan " [hidden]="getFilterValue(item).indexOf(filterValue.value.toLowerCase()) == -1">
            <mat-card  [routerLink]="['/menu/care-plan/view-care-plan', item.id]">
                <!-- <div class="count-btn">
                    <span matBadge="3" matBadgeSize="large"></span>
                </div> -->
                <div class="content">
                    <div class="icon">
                        <i class="fas fa-hand-holding-heart fa-3x"></i>
                    </div>
                    <div class="card-cnt">
                       
                        <p class="card-title">{{item?.title}}</p>
                        <p>{{item?.goal}} </p>
                        <div class="icon-list">
                            <div class="icon-small" *ngFor="let icon of item.parentTitle">
                                <i *ngIf="icon=='Fitness'" title="{{icon}}" class="fas fa-walking" style="color: rgb(247, 91, 91);font-size:1.5rem;"></i>
                                <i *ngIf="icon=='Hypertension'"  title="{{icon}}" class="fa-heartbeat fas" style="color: rgb(247, 91, 91);font-size:1.5rem;"></i>
                                <i *ngIf="icon=='Diabetes'"  title="{{icon}}" class="fa-tint fas" style="color: rgb(247, 91, 91);font-size:1.5rem;"></i>
                                
    
                            </div>

                        </div>
                      
                    </div>


                </div>
               
                <!-- <div class="edit-btn">
                    <button  [routerLink]="['/menu/care-plan/edit-care-plan', item.id]"><i class="fas fa-edit"></i></button>
                </div> -->
            </mat-card>
        </div>
        
    </div>
    <!-- <div class="example-button-container">
        <button (click)="open_bottom_dialog($event)" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
          <mat-icon>add</mat-icon>
        </button>
    </div>
    <div *ngIf="isClassExpand" class="bottom_dialog" (click)="$event.stopPropagation()" >
        <div>
            <button routerLink="/menu/care-plan/add-plan"><i class="fas fa-user-plus"></i>Add Plan</button>
            <button routerLink="/menu/care-plan/create-new-plan"><i class="fas fa-notes-medical"></i>Create New Plan</button>
            
        </div>
    </div> -->
</div>

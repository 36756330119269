
<!-- <mat-nav-list> -->
    <div class="close-button-section">
        <button (click)="close_sheet()" class="close-btn">X</button>
    </div>
    <div class="filter-dialog container">
        
        
        <div *ngFor="let item of filter_category; index as i" class="category">
            <p>{{item.title}}</p>
            <hr>
            <button [ngClass]="{'selectButton': eachItem.active}" (click)="btntext(eachItem, i, j)" *ngFor="let eachItem of filtered_list_array[i]; index as j" >{{eachItem.title | titlecase}}</button>
        </div> 
        <!-- <div>
            <input type="checkbox">
            Set these as default 
        </div> -->
        
    </div>
    <div class="reset-buttons">
        <button (click)="reset_filter()" class="reset">Reset</button>
        <button (click)="apply_filter()" class="apply">Apply</button>
    </div>
<!-- </mat-nav-list> -->
<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
            
        <div class="close-btn">
          <button (click)="close_dialog('false')" >X</button>
        </div>
        <h1 mat-dialog-title>Document Edit</h1>
         
            <div>
                <form *ngIf="title == 'Personal'" class="form-dialog" [formGroup]="myForm" class="">
                   
                    <div class="">
                   
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Tags</mat-label>
                            <mat-select required [ngModel]="tags" formControlName ="tags" multiple>
                              <mat-option *ngFor="let topping of tag_list" [value]="topping">{{topping}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.tags.errors && submitted">This field is required</mat-error>
                          </mat-form-field>
                          

                    </div>
                
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Name</mat-label>
                        <input matInput #name required formControlName='name' name="name" placeholder="Enter your name" [(ngModel)]="details.name"  >
                        <mat-error *ngIf="f.name.errors?.required && submitted" >This field is required</mat-error>
                    </mat-form-field>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Description</mat-label>
                        <textarea matInput #description required formControlName='description' name="description" placeholder="Enter description" [(ngModel)]="details.description" ></textarea>
                        <mat-error *ngIf="submitted && f.description.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    
                    
                    
                      
                    
                     
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Expiration date</mat-label>
                        <input readonly [value]="get_date_value(details?.expiration_date)" #target matInput [min]="tomorrow" [matDatepicker]="dp3" >
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                      </mat-form-field>
                      <!-- <div *ngIf="f.target.errors && submitted" class="text-danger">
                        <div>This field is required</div>
                      </div> -->
                      <br>
                      <!-- <label>Select file</label>
                      <input type="file" class="file-upload" (change)="onFileChanged($event)" required>  -->
                      
                      <div class="save-button">
                        <button type="submit" (click)="edit_personal_document(name.value,description.value,target.value,tags)">Save</button> 
                    </div>

                </form>
                <form *ngIf="title == 'Identity'" class="form-dialog" [formGroup]="myForm" class="">
                   
                    
                        <!-- <mat-button-toggle-group  class="tag-section">
                            <mat-button-toggle *ngFor="let tag of tag_list" [value]="tag"></mat-button-toggle>
                          </mat-button-toggle-group> -->
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Tags</mat-label>
                            <mat-select required [ngModel]="tag_list_id" formControlName ="tags" multiple>
                              <mat-option *ngFor="let topping of tag_list_id" [value]="topping">{{topping}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.tags.errors && submitted">This field is required</mat-error>
                          </mat-form-field>
                          

                    
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Document Id</mat-label>
                        <input matInput #id required formControlName='id' name="id" [(ngModel)]="details.docid" placeholder="Enter the id" >
                        <mat-error *ngIf="submitted && f.id.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    <!-- <div *ngIf="submitted && f.id.errors " class="text-danger">
                        <div *ngIf="f.id.errors.required">This field is required</div>
                    </div> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Document Type</mat-label>
                        <mat-select [disabled]="true" [ngModel]="details.doctype" formControlName ="type" >
                            <mat-option *ngFor="let food of doclist | keyvalue" [value]="food.key">
                              {{food.value}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="submitted && f.type.errors?.required">This field is required</mat-error>
                    </mat-form-field>
                    <!-- <div *ngIf="submitted && f.type.errors " class="text-danger">
                        <div *ngIf="f.type.errors.required">This field is required</div>
                    </div> -->
                    
                    
                      
                    
                     
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Expiration Date</mat-label>
                        <input readonly [value]="get_date_value(details?.expiration_date)" #target1 matInput [min]="tomorrow" [matDatepicker]="dp3">
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                      </mat-form-field>
                      <!-- <div *ngIf="f.target.errors && submitted" class="text-danger">
                        <div>This field is required</div>
                      </div> -->
                      <br>
                      <!-- <label>Select file</label>
                      <input type="file" class="file-upload" (change)="onFileChanged($event)" required>  -->
                      
                      <div class="save-button">
                        <button type="submit" (click)="edit_credential_document(id.value,tags,target1.value)">Save</button> 
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>
<block-ui></block-ui>
<div class="col-md-1 sidebar">
    <div class="sidebar_inner">
        <nav class="navv navigation-bar">
            <input type="checkbox" id="check">
            <label for="check"  class="checkbtn">
                <i class="fa fa-bars"></i>
            </label>
            <ul>
                <li >
                    <label id="closeBtn" for="check" class="checkbtn">
                      <i style="color: white; float: left; margin-right: 15px;" class="fa fa-times"></i>
                    </label>
                </li>
            <!-- </ul>
            <ul> -->
                
                <!-- <li class="menu" (click)="menu_open()">
                    <a>
                        <span class="icon">
                            <i class="{{menu_icon}}"></i>
                        </span>
                        <span class="text">
                            Menu
                        </span>
                    </a>
                </li> -->
                <li (click)="redirect()" [ngClass]="{'menu-status': menu_icon == 'fa fa-bars'}" *ngFor="let item of menubar_names_icons">
                    <a routerLink="{{item.menu_link}}">
                        <span class="icon">
                            <i  class="{{item.icon}}"></i>
                            <span class="tooltiptext">{{item.name}}</span>
                        </span>
                    </a>
                </li>
                <li class="resp" [ngClass]="{'menu-status': menu_icon == 'fa fa-bars'}" *ngFor="let item of bottom_menu_icons" >
                    <a routerLink="{{item.menu_link}}">
                        <span class="icon">
                            <i class="{{item.icon}}"></i>
                            <span class="tooltiptext">{{item.name}}</span>
                        </span>
                    </a>
                </li>
            </ul>
            <ul class="bottom-menu">
                <li  [ngClass]="{'menu-status': menu_icon == 'fa fa-bars'}" *ngFor="let item of bottom_menu_icons" >
                    <a routerLink="{{item.menu_link}}">
                        <span class="icon">
                            <i class="{{item.icon}}"></i>
                            <span class="tooltiptext">{{item.name}}</span>
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit {

  audit:any;
  searchText='';

  constructor(public auth:AuthServiceService , public toast:ToastrService) { }

  ngOnInit(): void {

    this.auth.get_audit_trails().subscribe((res:any)=>{
      if(res.error==false){
       let aud = res.data.events
       this.audit = aud.reverse();
      }
    })
  }
  getFilterValue(item) {
    var name = item.message
    return name.toLowerCase()
  }

  applyFilter(event: Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.audit = filterValue.trim().toLowerCase();

  }

}

<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
            
        <div class="close-btn">
          <button (click)="close_dialog()" mat-dialog-close>X</button>
        </div>
        <h1 mat-dialog-title>Diet Readings</h1>
        <div mat-dialog-content>
          <!-- <div class="menu-list row row-cols-1 row-cols-md-3 g-3">
            <div *ngFor="let item of new">
              <mat-progress-spinner
          mode="determinate"
          value="50" color="##078f8f"></mat-progress-spinner>
          <div style="position:relative; top: -4rem; left: 2rem;">{{item.name}}<br><p class="small-txt">{{item.value | number: '1.0-0'}}&nbsp;{{item.valueUnit}}</p></div>

            </div>
            


          </div> -->
         
          <div  *ngFor="let item of grouped  | keyvalue">
            <div class="ent">
              <mat-card>{{item.key}}</mat-card>
          </div>
          <div class="med-condition" *ngFor="let i of $any(item).value">
            <mat-card  >{{i.name}}
              <div class="right-icon">
                {{i.value | number: '1.0-0'}}&nbsp;{{i.valueUnit}}
              </div>
             
            </mat-card>
            
          </div>
          
          </div>

        </div>
        <div  *ngIf="diet?.length == 0" class="Alert">
          No Readings found!!

        </div>

        
         
          

        </div>
    </div>
</div>
<div class="container">

  <div class="card-dialog row justify-content-center">
      <div class="right col-12">
          
      <div class="col-btn">
        <button (click)="close_dialog()" mat-dialog-close>X</button>
      </div>
      <h1 mat-dialog-title>{{ device_details.device.category.name | pulseTrans}}</h1>
      
       
          <div class="graph-con">         
              <app-graph-row *ngIf = "device_details.device.systemId!='0x1810'" [selected]="device_details.device.systemId" [values]="values" [xvalue]="xais"></app-graph-row>
              
             <app-graph-row *ngIf = "device_details.device.systemId=='0x1810'"
              [selected]="device_details.device.systemId"
              [valuesp] = "values"
              [valuess]="xais"
              [valuesd]="vdia"
              [xvalue]="xbp"

             ></app-graph-row>
             
          </div>

      </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  private messageSource = new BehaviorSubject('default message');
  private cofferId = new BehaviorSubject('empty');

  currentMessage = this.messageSource.asObservable();
  cofferIdMessage = this.cofferId.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeCofferId(message: string){
    this.cofferId.next(message);
  }

  constructor() { }
}

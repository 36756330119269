import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { ShareUpdateService } from '../share-update.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  user_details: any;
  user_first_name: any;
  user_last_name: any;

  view_activity = {title: 'View Activity', icon: 'fas fa-chart-line'}
  update_profile = {title: 'Update Profile', icon: 'fas fa-address-card'}

  profile_detail: any;
  bmi: any;
  citizens: any;
  height: any;
  weight:any;
  imgurl :any;
  today=new Date()
  statusDiet:any;
  statusExe:any;
  card_image_diet = "fas fa-exclamation-circle"
  style_diet = 'color:green'
  card_image_exe = "fas fa-exclamation-circle"
  style_exe = 'color:green'
  id:any;
  status_exe:any;
  status_diet:any;
  

  constructor(private authservice: AuthServiceService, public dpipe:DatePipe ,public dialog:MatDialog , public change_detect:ShareUpdateService , private cbox: ConfirmBoxService) { }

  ngOnInit(): void {
  
    // //console.log(this.login.get_details())
 

    this.authservice.get_image().subscribe((res:any)=>{
      if(res){
        this.imgurl = res.imageUrl;
      } 
     

    })
    this.authservice.get_digicoffer_profile().subscribe((res:any)=>{
      if(res.error==false){
        this.profile_detail = res.data
      }
    })
    this.authservice.get_care_plan().subscribe((res:any)=>{
      if(res){
        this.id = res[0]?.id

      }
    })
    let date_new =  this.dpipe.transform(this.today,'YYYYMMdd');

    this.authservice.get_diet_status(date_new).subscribe((res:any)=>{
      if(res){
       this.statusDiet = res[0].status
       this.display_stats_diet(this.statusDiet)
      // this.display_stats_icon(this.statusDiet)

      }
    })
    this.authservice.profile_details().subscribe((res:any)=>{
      if(res){
        this.user_details = res[0]
      }
    })
    this.authservice.get_exercise_status(date_new).subscribe((res:any)=>{
      if(res){
        this.statusExe = res[0].status
        this.display_stats_exe(this.statusExe)
 
      }
    })
    this.change_detect.status.subscribe(res=>{
      if(res){
        this.authservice.get_exercise_status(date_new).subscribe((res:any)=>{
          if(res){
            this.authservice.get_exercise_status(date_new).subscribe((res:any)=>{
              if(res){
                this.statusExe = res[0].status
                this.display_stats_exe(this.statusExe)
               
              }
            })
            this.authservice.get_diet_status(date_new).subscribe((res:any)=>{
              if(res){
               this.statusDiet = res[0].status
               this.display_stats_diet(this.statusDiet)
            
        
              }
            })
           
          }
        })

      }
    })


  }

  openDiet(){
    this.dialog.open(DietDetailsDialog,{
      data:[this.id]
    })

  }

  openExecrise(){
    this.dialog.open(ExerciseDetailsDialog,{
      data:[this.id]
    })

  }
   
  display_stats_diet(status){
    if(status=="FULLY_COMPLETED"){
      this.card_image_diet = "fa fa-check-circle"
      this.style_diet = 'color:green;font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_diet= "Fully Completed"


    } else if(status=="NOT_RECORDED"){
      this.card_image_diet = "fas fa-exclamation-circle"
      this.style_diet = 'color:orange;font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_diet= "Not Recorded"

    } else if(status=="PARTIALLY_COMPLETED"){
      this.card_image_diet = "fas fa-exclamation-circle"
      this.style_diet = 'color:red;font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_diet= "Partially Completed"
    


    } else if(status=="IN_PROGRESS"){
      this.card_image_diet = "fas fa-exclamation-circle"
      this.style_diet = 'color:rgb(76, 125, 254);font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_diet = "In Progress"
    }
  }

  display_stats_exe(status){
    if(status=="FULLY_COMPLETED"){
      this.card_image_exe = "fa fa-check-circle"
      this.style_exe = 'color:green;font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_exe = "Fully Completed"

    } else if(status=="NOT_RECORDED"){
      this.card_image_exe = "fas fa-exclamation-circle"
      this.style_exe = 'color:orange;font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_exe = "Not Recorded"
 
    }  else if(status=="PARTIALLY_COMPLETED"){
      this.card_image_exe = "fas fa-exclamation-circle"
      this.style_exe = 'color:red;font-size:20px; position: absolute;margin-top: -1%;top:0;right:0;'
      this.status_exe = "Partially Completed"

    }

  }

}

@Component({
  selector: 'diet-details-dialog',
  templateUrl: './diet-details-dialog.html',
  styleUrls: ['./user-details.component.scss']
})

export class DietDetailsDialog {

  tday = new Date();
  diet :any
  id_care:any;
  s:any[]=[];
  grouped: any;
  new:any[]=[];
  total:any[]=[]
  constructor(private formBuilder:FormBuilder, private auth: AuthServiceService, public datepipe:DatePipe, public dialog: MatDialogRef<DietDetailsDialog>, @Inject(MAT_DIALOG_DATA) public data: any , public toast:ToastrService , public _communication:CommunicationService){
    this.id_care = data[0]
    
  }
  ngOnInit(){
    let date_new =  this.datepipe.transform(this.tday,'YYYYMMdd');
   
    this.auth.get_careplandiet(this.id_care).subscribe((res:any)=>{
      if(res){
      
        this.s = res
      
      }
    })
    this.auth.get_diet_graph(date_new).subscribe((res:any)=>{
      this.new = res
      
       this.new.forEach(item => {
         this.s.forEach(i => {
           if(item.name == i.title){
             this.total.push(item)
             
           }
         })
       })
    })
    //console.log(this.total)
    this.auth.get_diet_reading(date_new).subscribe((res:any)=>{
      if(res){
        this.diet = res
        this.grouped = this.group(this.diet ,'tag.description')
        //console.log(this.grouped)
   
      }
    })
  
  
    
    
  

  }
  group(data , element){
  //   this.grouped = data.reduce((r,{tag.id})=>{
  //     if(!r.some(o=>o.tag.id==tag.id)){
  //       r.push({tag.id,groupItem:this.diet.filter(v=>v.tag.id==tag.id)});
  // }
  // return r;
  // },[]);
 return  _.groupBy(data,element)

  }
  close_dialog(){
    this.dialog.close()
  }
}

@Component({
  selector: 'exercise-details-dialog',
  templateUrl: './exercise-details-dialog.html',
  styleUrls: ['./user-details.component.scss']
})

export class ExerciseDetailsDialog {

   tday= new Date()
   exe_list:any;
   grouped:any;
  constructor(private formBuilder:FormBuilder, private auth: AuthServiceService, public datepipe:DatePipe, public dialog: MatDialogRef<DietDetailsDialog>, @Inject(MAT_DIALOG_DATA) public data: any , public toast:ToastrService , public _communication:CommunicationService, private cbox : ConfirmBoxService){
    
  }
  ngOnInit(){
    let date_new =  this.datepipe.transform(this.tday,'YYYYMMdd');
    this.auth.get_exercise_reading(date_new).subscribe((res:any)=>{
      if(res){
        this.exe_list = res
        this.group(this.exe_list)
        //this.grouped = _.groupBy(this.exe_list,'name')
        
      
      //console.log(this.grouped);

      }
    })

  }
  group(data){
    this.grouped = data.reduce((r,{name})=>{
      if(!r.some(o=>o.name==name)){
        r.push({name,groupItem:this.exe_list.filter(v=>v.name==name)});
  }
  return r;
  },[]);

  }

  delete_reading(id){
    //console.log(id)
    this.cbox.confirm("Alert","Are you sure you want to delete this Reading?").then((res)=>{
      if(res){
        this.auth.delete_exercise_reading(id).subscribe((res:any)=>{
          this.toast.success("Deleted Reading Sucessufully!!")
          let date_new =  this.datepipe.transform(this.tday,'YYYYMMdd');
          this.auth.get_exercise_reading(date_new).subscribe((res:any)=>{
            if(res){
              this.exe_list = res
              this.group(this.exe_list)
              //this.grouped = _.groupBy(this.exe_list,'name')
              
            
            //console.log(this.grouped);
      
            }
          })
        })

      }

    })
  
 
   
  }

  close_dialog(){
    this.dialog.close()
  }


  
}


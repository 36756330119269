<app-menu-comp></app-menu-comp>
<div class="care-plan" *ngIf="reminders?.length!=0">

    <p class="heading">Reminders</p>
 
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of reminders">
            <mat-card >
                <div class="card-cnt">
                    <h4>{{item.message}}</h4>
                </div>
                <div class="edit-btn">
                    <h5>{{item.target}}</h5><button (click)="delete_reminder(item.id)"><mat-icon>delete</mat-icon></button>
                    
                </div>
            </mat-card>
        </div>
        
    </div>
</div>




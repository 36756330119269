import { Component, Input, OnInit } from '@angular/core';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import { ShareUpdateService } from '../share-update.service';

@Component({
  selector: 'app-bmi',
  templateUrl: './bmi.component.html',
  styleUrls: ['./bmi.component.scss']
})
export class BmiComponent implements OnInit {
  @Input() card_details:any;
  bmi :any;
  weightunit:any;
  heightunit:any;
  height:any;
  weight:any;
  country:any;
  info:any;
  bmi_status:any;
  card_image = "fas fa-exclamation-circle"
  style = 'font-size:10px;color:green'
  constructor(public auth: AuthServiceService , private _communicationService: CommunicationService , public change_stats:ShareUpdateService) { }

  ngOnInit(): void {
    this.auth.get_citizenship().subscribe((res:any)=>{
      if(res.error==false){
   
        for (let i = 0; i < res.data.length; i++) {

          if(res.data[i].index == "citizen_primary"){
            this.country = res.data[i].country


          }
        }
     

        
        
      }

    })
    this.auth.profile_details().subscribe((res:any)=>{
      if(res){
      this.card_details = res[0];
      this.weight = res[0].weight;
      this.height = res[0].height;
      this.weightunit = res[0].weigthtUnit;
      this.heightunit = res[0].heightUnit;
      this.bmi = res[0].bmi;
      this.bmi_status = res[0].bmiStatus;
  
      this.display_status_icon(this.bmi_status)
  
      //this.calculate_bmi(this.weight, this.height , this.weightunit , this.heightunit)

      }
       
    })
    this.change_stats.status.subscribe((res:any)=>{
      if(res=="true"){
        this.auth.profile_details().subscribe((res:any)=>{
          if(res){
          this.card_details = res[0];
          this.weight = res[0].weight;
          this.height = res[0].height;
          this.weightunit = res[0].weigthtUnit;
          this.heightunit = res[0].heightUnit;
          this.bmi = res[0].bmi;
          this.bmi_status = res[0].bmiStatus;
        
          this.display_status_icon(this.bmi_status)
      
          //this.calculate_bmi(this.weight, this.height , this.weightunit , this.heightunit)
    
          }
           
        })

      }
    })
  

  }
 display_status_icon(bmi_status){
   if(bmi_status){
     if(bmi_status=="NORMALWEIGHT"){
      this.card_image = "fa fa-check-circle"
      this.style = 'color:green;font-size:20px; position: absolute;'

     } else if(bmi_status=="OVERWEIGHT"){
      this.card_image = "fas fa-exclamation-circle"
       this.style = 'color:orange;font-size:20px; position: absolute;'

     } else if(bmi_status=="OBESE"){
      this.card_image = "fas fa-exclamation-circle"
       this.style = 'color:red;font-size:20px; position: absolute;'

     } else if(bmi_status=="UNDERWEIGHT"){
       this.card_image = "fas fa-exclamation-circle"
       this.style = 'color:red;font-size:20px; position: absolute;'

     }
   }

 }
  // calculate_bmi(weight , height , wunit , hunit)
  // {   
  
  //    if(wunit =="" || hunit == ""){
  //     if(this.country== "usa" || this.country == "united states of america"){
  //       wunit = "pound";
  //       this.card_details.weigthtUnit = "pound";
  //       hunit = "feet";
  //       this.card_details.heightUnit = "feet";

  //     } else {
  //       wunit = "kg";
  //       this.card_details.weigthtUnit = "kg";
  //       hunit = "m";
  //       this.card_details.heightUnit = "m";

  //     }
  //   }
    
  //    if(!(weight == 0) && !(height == 0)) {

  //       if(wunit == "kg") {
  //           if(hunit == "centimeter" || hunit == "cm") {
  //              var ht = height/ 100
  //             this.bmi = (weight  / (ht * ht)).toPrecision(2);
  //             //console.log(this.bmi)

  //           } else if (hunit == "meter"|| hunit == "m" ){

  //               this.bmi = (weight / (height * height )).toPrecision(2);

  //           }

  //       } else if (wunit =="pound") {

  //           if (hunit == "feet") {

  //               let inch = (hunit) * 12.0

  //               this.bmi = ((weight  / (inch * inch)) * 703).toPrecision(2);

  //           } else if (hunit =="meter"){

  //               let ft = height

  //               let inch = (ft * 12.0)

  //               this.bmi = ((weight  / (inch * inch)) * 703).toPrecision(2);

  //           }

  //       }
  //   }
  //   if(this.bmi){
      

  //     if (this.bmi > 0.0) {

  //         if (this.bmi < 18.5) {

  //             this.card_image = "fas fa-exclamation-circle"

  //             this.style = 'color:red;font-size:20px; position: absolute;'

  //         } else if(this.bmi >= 18.5 && this.bmi <= 24.9) {

  //             this.card_image = "fas fa-user-check"
  //             this.style = 'color:green;font-size:20px; position: absolute;'

  //         } else if(this.bmi >= 25.0 && this.bmi <= 29.9) {

  //             this.card_image = "fas fa-exclamation-circle"

  //             this.style = 'color:orange;font-size:20px; position: absolute;'

  //         } else {

  //             this.card_image = "fas fa-exclamation-circle"

  //             this.style = 'color:red;font-size:20px; position: absolute;'

  //         }
  //   }
  // }

   

  // }
 
  

}

<div >
    <!-- <div [ngClass]="{'on_extension_plc': chevron_left == 'fa fa-chevron-right'}" class="patient-list-column">
        <div class="header-details">
            <div class="hi-left header-icons">
                <button (click)="toggle_patient_column()">
                    <i class="{{chevron_left}}"></i>
                </button>
            </div>
            <div class="pateint-header">
                <p>Family & Friends<br></p>
            </div> 
            <div class="searchbar-e">
                <input  type="text" placeholder="Search" [(ngModel)] = searchTextSecond>
                <i class="fas fa-search"></i>
            </div>
        
        </div>
        <div class="searchbar">
            <input  type="text" placeholder="Search" [(ngModel)] = searchTextSecond>
            <i class="fas fa-search"></i>
        </div>
        
        <div class="table-section">
            <table mat-table [dataSource]="dataSource_care_team | FilterPipe: searchTextSecond" class="mat-elevation-z8">

                <ng-container matColumnDef="business_name">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td class="table-td" mat-cell *matCellDef="let element"> 
                        <div class="first-column">
                           
                        <img  *ngIf="(!element.profileUrl)" src="../../assets/images/owner.png" alt="">
                          <img *ngIf="(element.profileUrl)" src={{element.profileUrl}} alt="">
                            <p>{{element.business_name}}</p> 
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="accept_text">
                    <th mat-header-cell *matHeaderCellDef>  </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="accept-btn-section">
                           
                            <button *ngIf="element.canAccept && !element.isaccepted" class="accept-button"
                            (click)="acceptRequest(element.id)">
                            Accept
                        </button>
                        <div *ngIf="!element.canAccept && !element.isaccepted" class="accept-button">
                            Pending
                        </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        
    </div> -->


    
    <div [ngClass]="{'on_extension_plc': chevron_left == 'fa fa-chevron-right'}" class="patient-list-column">
        <div class="header-details">
            <div class="hi-left header-icons">
                <button (click)="toggle_patient_column()">
                    <i class="{{chevron_left}}"></i>
                </button>
            </div>
            <div class="pateint-header">
                <p>Family & Friends<br></p>
            </div> 
            <div class="searchbar-e">
                <input  type="text" placeholder="Search" [(ngModel)] =   searchTextSecond>
                <i class="fas fa-search"></i>
            </div>
            <div class="hi-right header-icons">
                <button (click)="openCareDialog()">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
        <div class="searchbar">
            <input  type="text" placeholder="Search" [(ngModel)] =  searchTextSecond>
            <i class="fas fa-search"></i>
        </div>


    <div class="table-section">
        <table mat-table [dataSource]="dataSource_care_team | FilterPipe:   searchTextSecond" class="mat-elevation-z8">

            <ng-container matColumnDef="business_name">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td class="table-td" mat-cell *matCellDef="let element"> 
                    <div class="first-column">
                        <!-- <img src="../../assets/images/1.jpg" alt=""> -->
                        <img  *ngIf="(!element.profileUrl)" src="../../assets/images/owner.png" alt="">
                          <img *ngIf="(element.profileUrl)" src={{element.profileUrl}} alt="">
                        <p>{{element.business_name}}</p> 
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="accept_text">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element">
                    <div class="accept-btn-section">
                        <button *ngIf="element.canAccept && !element.isaccepted " class="accept-button"
                        (click)="acceptCareRequest(element.id)">
                        Accept
                    </button>
                    <div *ngIf="!element.canAccept && !element.isaccepted" class="accept-button">
                        Pending
                    </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
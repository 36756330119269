
<div class="care-plan" *ngIf="lists?.length!=0">
    <p class="heading">Connection Request</p>
 
   
    <div class="card-list row row-cols-1 row-cols-md-3 g-3">
        
        <div class="col" *ngFor="let item of lists ">
            <mat-card F>
                <div class="card-cnt">
                    <img  *ngIf="(!item.profileUrl)" src="../../assets/images/owner.png" alt="">
                    <img *ngIf="(item.profileUrl)" src={{item.profileUrl}} alt="">
                    <!-- <img *ngIf="( imgurl)" src={{imgurl}} alt=""> -->
                    <p class="card-title">{{item.business_name}}</p>
                    
                </div>
                <div class="edit-btn">
                    
                    <button *ngIf="item.canAccept && !item.isaccepted " class="accept-button" (click)="acceptRequest(item.id,item.biztype)">
                        Accept
                    </button>
                    
                </div>
            </mat-card>
        </div>
        
    </div>
</div>




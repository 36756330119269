import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError, } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor (public route:Router,  public toast:ToastrService ){}
  public getToken(): string {
    let token = localStorage.getItem('TOKEN')!
      return token;

    
    
  }
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // request = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${this.getToken()}`
    //   }
    // });

    return next.handle(request).pipe(
     catchError(err => {
       if (err.status === 401|| err.status === 403) {
        // remove Bearer token and redirect to login page
        this.toast.error("Session expired, Login to continue")
         this.route.navigate(['/login']);
       }
       return throwError( err );
     }));
 }
}
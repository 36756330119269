<div class=" container">
    <div class=" card-dialog row justify-content-center">
      <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
        <div>
            <form *ngIf="title == 'desiredOutcome'" class="" [formGroup]="myForm" >
                <p class="dialog-title">Desired Outcome</p>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Goal Type</mat-label>
                    <input matInput #goalType formControlName='goalType' name="goalType" placeholder="Goal Type" [ngModel]="desiredout?.goalType"  [ngClass]="{ 'is-invalid': submitted && f.goalType.errors }"/>
                </mat-form-field>
                <div *ngIf="submitted && f.goalType.errors " class="text-danger">
                    <div *ngIf="f.goalType.errors.required">This field is required</div>
                  </div>
                  <!-- <div *ngIf="!myForm.goalType.$valid" class="text-danger">This field is required</div> -->
                
                    <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" [(value)]="categoryvalue" [ngModel]="desiredout?.category" [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                        <mat-option>Select Condition</mat-option>
                        <mat-option *ngFor="let item of category_list" value= "{{item.id}}">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                    <div *ngIf="f.category.errors && submitted" class="text-danger">
                        <div *ngIf="f.category.errors.required">This field is required</div>
                      </div>
                
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Present Value</mat-label>
                    <input type ="number" matInput #presentValue formControlName='presentValue' name="presentvalue" placeholder="Present Value(Number)" [ngModel]="desiredout?.presentValue" [ngClass]="{ 'is-invalid': submitted && f.presentValue.errors }" />
                </mat-form-field>
                <div *ngIf="f.presentValue.errors && submitted" class="text-danger">
                    <div *ngIf="f.presentValue.errors.required">This field is required</div>
                  </div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Target Value</mat-label>
                    <input type ="number" matInput #targetValue formControlName='targetValue' name="targetValue" placeholder="Target Value(Number)" [ngModel]="desiredout?.targetValue" />
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Present Value</mat-label>
                    <input matInput #presentText formControlName='presentText' name="presentText" placeholder="Present Value(Text)" [ngModel]="desiredout?.presentText"/>
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Target Value</mat-label>
                    <input matInput #targetText formControlName='targetText' name="targetText" placeholder="Target Value(Text)" [ngModel]="desiredout?.targetText"/>
                </mat-form-field>
                <mat-form-field class="calendar" appearance="fill">
                    <mat-label>Target Date</mat-label>
                    <input readonly formControlName='targetDate' #targetDate matInput [matDatepicker]="dp3"  [ngClass]="{ 'is-invalid': submitted && f.targetDate.errors }">
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 ></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="f.targetDate.errors && submitted" class="text-danger">
                    <div>This field is required</div>
                  </div>
                
  
                
                

                  
                
                <div class="save-button">
                    <button (click)="status == 'create' && save_desired_outcome(goalType.value,presentValue.value , targetValue.value , presentText.value , targetText.value , targetDate.value ,categoryvalue )" (click)="status == 'edit' && edit_desired_outcome(goalType.value,presentValue.value , targetValue.value , presentText.value , targetText.value , targetDate.value ,categoryvalue )">Save</button> 
                </div>
              
                  <div *ngIf = "status == 'edit'" class="delete-button">
                    <button (click)= "delete_desired_outcome()">Delete</button>
                  </div>
            </form>

            <form *ngIf="title == 'activities'" class="" [formGroup]="myForm" >
                <p class="dialog-title">Activities</p>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Title</mat-label>
                    <input matInput #title formControlName='title' name="title" placeholder="Title" [ngModel]="activity?.title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }"/>
                </mat-form-field>
                <div *ngIf="f.title.errors && submitted" class="text-danger">
                    <div *ngIf="f.title.errors.required">This field is required</div>
                  </div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" [(value)]="categoryvalue" [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                        <mat-option>Select Condition</mat-option>
                        <mat-option *ngFor="let item of category_list" value= "{{item.id}}">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                    <div *ngIf="f.category.errors && submitted" class="text-danger">
                        <div *ngIf="f.category.errors.required">This field is required</div>
                      </div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Activity</mat-label>
                        <mat-select formControlName="activity" [(value)]="activityvalue" [ngModel]="activity?.activity"  [ngClass]="{ 'is-invalid': submitted && f.activity.errors }">
                            <mat-option>Select Condition</mat-option>
                            <mat-option *ngFor="let item of activites" value= "{{item.id}}">
                                {{item.title}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="f.activity.errors && submitted" class="text-danger">
                        <div *ngIf="f.activity.errors.required">This field is required</div>
                      </div>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Priority</mat-label>
                        <mat-select formControlName ="priority" [(value)]="priorityvalue" [ngModel]="activity?.priority">
                        <mat-option>Select Condition</mat-option>
                        <mat-option value="low">Low</mat-option>
                        <mat-option value="medium">Medium</mat-option>
                        <mat-option value="high">High</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Description</mat-label>
                        <input matInput #description formControlName='description' name="description" placeholder="description" [ngModel]="activity?.description"  [ngClass]="{ 'is-invalid': submitted && f.description.errors }" />
                    </mat-form-field>
                    <div *ngIf="f.description.errors && submitted" class="text-danger">
                        <div *ngIf="f.description.errors.required">This field is required</div>
                      </div>
                    
                    <mat-form-field class="calendar" appearance="fill">
                        <mat-label>Due Date</mat-label>
                        <input readonly #dueDate matInput [matDatepicker]="dp3" >
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status" [(value)]="statusvalue" [ngModel]="activity?.status">
                            <mat-option>Select Status</mat-option>
                            <mat-option *ngFor="let item of status_list" value= "{{item.value}}">
                                {{item.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>




            
                <div class="save-button">
                    <button (click)="status == 'create' && save_activities(title.value ,categoryvalue,activityvalue,priorityvalue,description.value ,dueDate.value ,statusvalue )" (click)="status == 'edit' && edit_activities(title.value ,categoryvalue,activityvalue,priorityvalue,description.value ,dueDate.value ,statusvalue )">Save</button> 
                </div>
                <div *ngIf = "status == 'edit'" class="delete-button">
                    <button (click)= "delete_activities()">Delete</button>
                </div>
            </form>

            <form *ngIf="title == 'medication'" class="" [formGroup]="myForm" >
                <p class="dialog-title">Medication</p>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Medicine Name</mat-label>
                    <input matInput #medicineName formControlName='medicineName' name="medicineName" placeholder="medicineName" [ngModel]="medication?.medicineName" [ngClass]="{ 'is-invalid': submitted && f.medicineName.errors }" />
                </mat-form-field>
                <div *ngIf="f.medicineName.errors && submitted" class="text-danger">
                    <div *ngIf="f.medicineName.errors.required">This field is required</div>
                  </div>
                
                    <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Medical Condition</mat-label>
                    <input type="text" matInput #medCon placeholder="Medical Condition" formControlName="medicalCondition" [matAutocomplete]="auto" [ngModel]="medication?.medicalCondition" [ngClass]="{ 'is-invalid': submitted && f.medicalCondition.errors }" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option>Select Condition</mat-option>
                        <mat-option *ngFor="let item of medcon_list" value= "{{item.title}}">
                            {{item.title}}
                        </mat-option>
                    </mat-autocomplete>
                    </mat-form-field>
                    <div *ngIf="f.medicalCondition.errors && submitted" class="text-danger">
                        <div *ngIf="f.medicalCondition.errors.required">This field is required</div>
                      </div>
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="category" [(value)]="categoryvalue" [ngClass]="{ 'is-invalid': submitted && f.category.errors }" >
                            <mat-option>Select Condition</mat-option>
                            <mat-option *ngFor="let item of category_list" value= "{{item.id}}">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                        </mat-form-field>
                        <div *ngIf="f.category.errors && submitted" class="text-danger">
                            <div *ngIf="f.category.errors.required">This field is required</div>
                          </div>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Strength</mat-label>
                            <input matInput type = "number" #strength formControlName='strength' name="strength" placeholder="strength" [ngModel]="medication?.strength" [ngClass]="{ 'is-invalid': submitted && f.strength.errors }" />
                        </mat-form-field>
                        <div *ngIf="f.strength.errors && submitted" class="text-danger">
                            <div *ngIf="f.strength.errors.required">This field is required</div>
                          </div>
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Unit</mat-label>
                            <mat-select formControlName="unit" [(value)]="unitvalue" [ngModel]="medication?.unit" [ngClass]="{ 'is-invalid': submitted && f.unit.errors }" >
                                <mat-option>Select Unit</mat-option>
                                <mat-option *ngFor="let item of unit_list" value= "{{item}}">
                                    {{item}}
                                </mat-option>
                            </mat-select>
                            </mat-form-field>
                            <div *ngIf="f.unit.errors && submitted" class="text-danger">
                                <div *ngIf="f.unit.errors.required">This field is required</div>
                              </div>
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>Frequency</mat-label>
                                <mat-select formControlName="frequency" [(value)]="freqvalue" [ngModel]="medication?.frequency" [ngClass]="{ 'is-invalid': submitted && f.frequency.errors }" >
                                    <mat-option>Select Unit</mat-option>
                                    <mat-option value="as-needed">As Needed</mat-option>
                                    <mat-option value="everyday">Everyday</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="f.frequency.errors && submitted" class="text-danger">
                                <div *ngIf="f.frequency.errors.required">This field is required</div>
                              </div>
                            <mat-form-field class="calender" appearance="fill">
                                <mat-label>Start Date</mat-label>
                                <input readonly #startDate matInput [matDatepicker]="dp3" >
                                <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                <mat-datepicker #dp3 ></mat-datepicker>
                            </mat-form-field>
                                <mat-form-field class="calender" appearance="fill">
                                    <mat-label>End Date</mat-label>
                                    <input readonly #endDate matInput [matDatepicker]="dp31" >
                                    <mat-datepicker-toggle matSuffix [for]="dp31"></mat-datepicker-toggle>
                                    <mat-datepicker #dp31 ></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Number Of Times</mat-label>
                                    <input matInput #numberOfTimes formControlName='numberOfTimes'  placeholder="Number of times taken" (input)="calculateFrequency(numberOfTimes.value)" [ngModel]="medication?.numberOfTimes"/>
                                </mat-form-field>
                                <div *ngIf = '(freqvalue=="everyday") && (numberOfTimes!= null)'>
                                    <div class="ent">
                                        <mat-card>Time and Dosage</mat-card>
                                    </div>
                                    <div class="med-condition" *ngFor="let item of time">
                                        <mat-card>{{item}}</mat-card>
                                        <!-- <label>
                                            Time
                                            <input [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4">
                                            <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
                                        </label> -->

                                    </div>
                                    <div *ngIf = "medication.frequency=='everyday'" class="med-condition">
                                        <mat-card *ngFor="let item of medication?.timeFrequency">{{item}}
                                        </mat-card>
                                    </div>
                                    <div *ngIf = "medication.frequency=='as-needed'" class="med-condition">
                                        <mat-card *ngFor="let item of medication?.timeFrequency">{{item.time}}
                                        </mat-card>
                                    </div>
                                </div>
                            

                            
                                
                            

            
                <div class="save-button">
                    <button (click)="status == 'create' && save_medication(medicineName.value ,medCon.value,categoryvalue,strength.value,unitvalue , freqvalue , startDate.value , endDate?.value ,numberOfTimes.value )" (click)="status == 'edit' && save_medication(medicineName.value ,medCon.value,categoryvalue,strength.value,unitvalue , freqvalue , startDate.value , endDate?.value ,numberOfTimes.value )">Save</button>
                    
                   
                </div>
                <div *ngIf = "status == 'edit'" class="delete-button">
                    <button (click)= "delete_medication()">Delete</button>
                </div>
            </form>
        </div>
      </div>
    </div>  
</div>
<div>
    <div class="section-1 row">
        <div class="left-column col-md-6">
            <div class="content">
                <img src="../../assets/images/vitagist (1).png" alt="">
                <!-- <h2>WELCOME!</h2>
                <p>Start managing your patients remotely with VitaCape</p> -->
            </div>
        </div>
        <div class="right-column col-md-6">
            <div class="content">
                <h2 class="label">Register</h2>
                <div class="alert alert-info" role="alert">
                    A token has been sent to your registered email address. Please verify before you login.
                </div>
                
                <form [formGroup]="myForm" >
                    <!-- <div class="signTitle">Sign In</div> -->
                    <div class="signForm-row " >
                        <div class="input-group">
                            <span class="input-group-text" id="basic-addon2"><i class="fa fa-unlock-alt"></i></span>
                            <input #token formControlName='token' name="token" class="form-control" id='id_token' type="text" placeholder="Token" aria-describedby="basic-addon2"/>
                        </div>
                      
                      <div *ngIf="f['token'].errors && submitted" class="text-danger">
                        <div *ngIf="f['token'].errors.required">This field is required</div>
                        <div *ngIf="f['token'].errors.msg">{{f['token'].errors.msg}}</div>
                      </div>
                    </div><br>
                   
                    <div class="signForm-row">
                      <button (click)="resend_token()" class="resend-button">RESEND</button>
                    </div>

                    <div class="signForm-row">
                        <button (click)="onSubmit(token.value)" class="submit-button">SUBMIT</button>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { AuthServiceService } from '../auth-service.service';


@Component({
  selector: 'app-graph-row',
  templateUrl: './graph-row.component.html',
  styleUrls: ['./graph-row.component.scss']
})
export class GraphRowComponent implements OnInit {

  @Input() selected:any;
  @Input() values:any;
  @Input() valuesp:any;
  @Input() valuess:any;
  @Input() valuesd:any;
  @Input() xvalue:any;
  public chartType:string = 'bar';
  public chartType2:string = 'line';
  public chartLabels:Array<any> = [];
 
  public chartColors2:Array<any> = [
    {
      backgroundColor: ' rgba(0, 132, 121, 0.2)',
      borderColor: 'rgba(5, 252, 231, 0.7)',
      borderWidth: 2,
    },{
      backgroundColor: 'rgba(124, 1, 98, 0.2)',
      borderColor: 'rgba(213, 0, 241, 0.7)',
      borderWidth: 2,
    },{
      backgroundColor: 'rgb(252, 144, 3, 0.2)',
      borderColor: 'rgba(247, 188, 111, 0.7)',
      borderWidth: 2,
    }
];
public chartColors:Array<any> = [
  {
      backgroundColor: 'rgba(0, 132, 121, 0.2)',
      borderColor: 'rgba(5, 252, 231, 0.7)',
      borderWidth: 2,
     
  }
];
public chartDatasets:Array<any>=[];
public chartDatasets2:Array<any>=[];
public chartOptions: any = {
  responsive: true,
    scales: {
      xAxes: [{
    
        ticks: {
          minor: {
            fontSize: 10
         },
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45
      }
        }],
    
  }
};

 

public chartClicked(e: any): void { 
     
} 

public chartHovered(e: any): void {
     
}


ngOnInit(): void{
  this.chartDatasets = [
    {data: this.values}
];
this.chartLabels = this.xvalue

  this.chartDatasets2 = [
  { data: this.valuesp, label: 'Pulse' },//value1
  { data: this.valuess, label: 'Systolic' },//value2
  { data: this.valuesd, label: 'Diastolic' }//value3
]
//console.log(this.values,this.chartDatasets , this.chartLabels)
  
}



  



  
  
 
}

 
  

 

  
  
function ngOnInit() {
  throw new Error('Function not implemented.');
}


<div class="container">
    <div class=" card-dialog row justify-content-center">
      <div class="right col-12">
        <div class="close-btn">
          <button (click)="close()"  mat-dialog-close>X</button>
        </div>
        <div>
          <div>
            <div *ngIf="selected_dialog_title == 'medicalCondition'">
              <h1 mat-dialog-title>Medical Condition</h1>
              <form class="form-dialog" [formGroup]="myForm" > 
              
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Medical Condition</mat-label>
                  <input required  matInput type="text" #conditionName formControlName="conditionName" placeholder="Condition Name" [ngModel]="medCon?.conditionName" [matAutocomplete]="auto"  [ngClass]="{ 'is-invalid': submitted && f.conditionName.errors }"/>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option>Select Condition</mat-option>
                    <mat-option *ngFor="let item of medcon_list |  search: 'title':conditionName.value" value = "{{item.title}}">
                      {{item.title}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="f.conditionName.errors && submitted" class="text-danger">
                  <div *ngIf="f.conditionName.errors.required">This field is required</div>
                </div>
  
                <div class="date-time-select">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Year Of Diagnosis</mat-label>
                    <input required readonly [max]="maxDate" #yearOfDiagnosis [value]="date1.value"  matInput [matDatepicker]="dp3" >
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 ></mat-datepicker>
                    <mat-error *ngIf="!yearOfDiagnosis.value">This field is required</mat-error>
                  </mat-form-field>
                  <!-- <div *ngIf="!yearOfDiagnosis.value" class="text-danger">
                    <div>This field is required</div>
                  </div> -->
                </div>
                <!-- <div>
                  <app-year-pick [sel_date]="medCon?.yearOfDiagnosis" (year_sel)="year_emit($event)"></app-year-pick>
                </div> -->
  
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Comment</mat-label>
                  <input matInput #commentInput formControlName="comment" placeholder="Comment" [ngModel]="medCon?.comment" />
                </mat-form-field>
                
                <div class="save-button">
                  <button type="submit" [disabled]="save_Click" (click)= "dialog_status == 'create' && onSave_medical_condition(conditionName.value ,yearOfDiagnosis.value,commentInput.value)" (click)="dialog_status == 'edit' && edit_medical_condition(conditionName.value ,yearOfDiagnosis.value,commentInput.value)">Save</button> 
                </div>
                <div *ngIf = "dialog_status == 'edit'" class="delete-button">
                  <button (click)= "onDelete_medical_condition(conditionName.value ,yearOfDiagnosis.value,commentInput.value)">Remove</button>
                </div>
          
              </form>

            </div>
            <div *ngIf="selected_dialog_title == 'allergy'">
              <h1 mat-dialog-title>Allergy</h1>
              <form    class="" [formGroup]="myForm" > 
              
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Allergy</mat-label>
                  <input required matInput #allergicToInput formControlName="allergicTo" placeholder="Allergic To" [ngModel]="allergy?.allergicTo"/>
                  <mat-error *ngIf="f.allergicTo.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Allergic Reaction</mat-label>
                  <mat-select required formControlName ="allergicReaction"  [ngModel]="allergy?.allergicReaction" multiple>
                    <mat-option *ngFor="let item of allery_list " value = "{{item.tagName}}">
                      {{item.title}}
                    </mat-option>
                  </mat-select>
                  <mat-error  *ngIf="f.allergicReaction.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
  
                <!-- <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Allergic Reaction</mat-label>
                  <input matInput #allergicReactionInput formControlName="allergicReaction" placeholder="Allergic Reaction" [ngModel]="allergy?.allergicReaction"/>
                </mat-form-field> -->
                <!-- <div *ngIf="f.allergicReaction.errors && submitted" class="text-danger">
                  <div *ngIf="f.allergicReaction.errors.required">This field is required</div>
                </div>
   -->
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Severity</mat-label>
                  <mat-select required formControlName ="severity" [ngModel]="allergy?.severity ">
                    <mat-option>Select Condition</mat-option>
                    <mat-option value="Low">Low</mat-option>
                    <mat-option value="Medium">Medium</mat-option>
                    <mat-option value="High">High</mat-option>
                  </mat-select>
                  <mat-error  *ngIf="f.severity.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                <!-- <div *ngIf="f.severity.errors && submitted" class="text-danger">
                  <div *ngIf="f.severity.errors.required">This field is required</div>
                </div> -->
                
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Comment</mat-label>
                  <input matInput #commentInput formControlName="comment" placeholder="Comment" [ngModel]="allergy?.comment"/>
                </mat-form-field>
  
                <div class="save-button">
                  <button [disabled]="save_Click"  (click)= "dialog_status == 'create' && onSave_allergy(allergicToInput.value, commentInput.value)" (click)="dialog_status == 'edit' && edit_allergy(allergicToInput.value, commentInput.value)">Save</button> 
                </div>
                <div *ngIf = "dialog_status == 'edit'" class="delete-button">
                  <button (click)= "dialog_status == 'edit' && onDelete_Allergy()">Remove</button>
                </div>
                
              </form>
  

            </div>
            <div *ngIf="selected_dialog_title == 'medication'">
              <h1 mat-dialog-title>Medication</h1>
              <form class="" [formGroup]="myForm" > 
              
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Medical Condition</mat-label>
                  <input required matInput #medCondition formControlName="medCondition" placeholder="Medical Condition" [ngModel]="medication?.medCondition" [matAutocomplete]="auto"/>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option>Select Medication</mat-option>
                    <mat-option *ngFor="let item of medcon_list |  search: 'title':medCondition.value" value = "{{item.title}}">
                      {{item.title}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error  *ngIf="f.medCondition.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                
     
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Medication Name</mat-label>
                  <input required matInput #medicationNameInput formControlName="medicationName" placeholder="Medication Name" [ngModel]="medication?.medicationName" />
                  <mat-error *ngIf="f.medicationName.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
               
                
  
               
  
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Strength</mat-label>
                  <input required type="number" matInput #strength formControlName="strength" placeholder="Strength" [ngModel]="medication?.strength"/>
                  <mat-error *ngIf="f.strength.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Unit</mat-label>
                  <mat-select required #unit formControlName="unit" placeholder="unit" [ngModel]="medication?.unit">
                    <mat-option value="">Select Unit</mat-option>
                    <mat-option  *ngFor="let item of medUnits" [value]="item">{{item}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.unit.errors?.required && submitted">This field is required</mat-error>
                  
                </mat-form-field>
                <!-- <div *ngIf="f.unit.errors && submitted" class="text-danger">
                  <div *ngIf="f.unit.errors.required">This field is required</div>
                </div> -->
             
                <div class="date-time-select">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input readonly [max]="today"  required placeholder="Start date" #startDate  [value]="get_date_value(medication?.startDate)" matInput [matDatepicker]="dp3" >
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 ></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="!startDate.value && submitted" class="text-danger">
                    This Field is Required
                  </div>
                
                </div>
                <div class="date-time-select">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input readonly [min]="tomorrow"  placeholder="End date" #endDate  [value]="get_date_value(medication?.endDate)"  matInput [matDatepicker]="dp" >
                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </div>
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Frequency</mat-label>
                  <mat-select required formControlName ="frequency" #freq  [ngModel]="medication?.frequency">
                    <mat-option>Select Status</mat-option>
                    <mat-option value="asNeeded">As Needed</mat-option>
                    <mat-option value="everyDay">Daily</mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.frequency.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                <!-- <div *ngIf="f.frequency.errors && submitted" class="text-danger">
                  <div *ngIf="f.frequency.errors.required">This field is required</div>
                </div> -->
                <div *ngIf = "freq.value=='everyDay'">
                  <mat-form-field  class="example-full-width" appearance="fill">
                    <mat-label>Number Of Times</mat-label>
                    <input required type="number" max=24 min=1 matInput #Notimes formControlName="numberOfTimes" placeholder="Number of Times"  (ngModelChange)="calculateFrequency(Notimes.value ,freq.value)" [ngModel]="num_of_times"/>
                    <mat-error *ngIf="f['numberOfTimes'].errors?.min && f['numberOfTimes'].dirty">Enter a positive number less than or equal to 24</mat-error>
                    <mat-error *ngIf="f['numberOfTimes'].errors?.max && f['numberOfTimes'].dirty">Please enter a number less than or equal to 24</mat-error>
                    <mat-error *ngIf="f['numberOfTimes'].errors?.required && submitted" ></mat-error>
                  </mat-form-field>
                  <!-- <div *ngIf="f['numberOfTimes'].errors && submitted" class="text-danger">
                    <div *ngIf="f['numberOfTimes'].errors.required">This field is required</div>
                  </div> -->
  
                </div>
               
              
                
                <div *ngIf = "f.frequency.value=='everyDay' && f.numberOfTimes.value>=1">
                    
                  <div class="med-condition" *ngFor="let item of time_freq;let i = index">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Time</mat-label>
                      <input type="time" #time matInput [value]="get_time(item.time)" (input)="time_edit(time.value, i , f.numberOfTimes.value)">
                    </mat-form-field>
                    
  
                  </div>
                  <!-- <div  class="med-condition" *ngFor="let item of medication?.timeFrequency;let i = index">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Time</mat-label>
                      <input type="time" #time matInput [value]="get_time(item.time)" (input)="time_edit(time.value, i , f.numberOfTimes.value)">
                    </mat-form-field>
                    
                  </div> -->
  
                  
  
                </div>
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Comment</mat-label>
                  <input matInput #commentInput formControlName="comment" placeholder="comment" [ngModel]="medication?.comment"/>
                </mat-form-field>
                
  
                <div class="save-button">
                  <button  [disabled]="save_Click" (click)= "dialog_status == 'create'&& onSave_medication(medCondition.value ,medicationNameInput.value ,strength.value ,unit.value,startDate.value, endDate?.value , freq.value ,commentInput.value)"  (click)= "dialog_status == 'edit'&& edit_medication(medCondition.value ,medicationNameInput.value ,strength.value ,unit.value,startDate.value, endDate?.value , freq.value ,commentInput.value)">Save</button> 
                </div>
                <div *ngIf = "dialog_status == 'edit'" class="delete-button">
                  <button (click)= "dialog_status == 'edit' &&  onDelete_Medication()">Remove</button>
                </div>
                
              </form> 

            </div>
            <div *ngIf="selected_dialog_title == 'familyHistory'">
              <h1 mat-dialog-title>Family History</h1>
              <form    class="" [formGroup]="myForm" > 
             
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Condition Name</mat-label>
                  <input  required matInput #conditionName formControlName="conditionName" placeholder="Condition Name" [ngModel]="famhistory?.conditionName" [matAutocomplete]="auto"/>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option>Select Condition</mat-option>
                    <mat-option *ngFor="let item of medcon_list |  search: 'title':conditionName.value" value = "{{item.title}}">
                      {{item.title}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="f.conditionName.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                <!-- <div *ngIf="f.conditionName.errors && submitted" class="text-danger">
                  <div *ngIf="f.conditionName.errors.required">This field is required</div>
                </div> -->
  
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Relationship</mat-label>
                  <mat-select required formControlName ="relationship"  [ngModel]="famhistory?.relationship" multiple>
                    
                    <mat-option *ngFor="let item of fam_historty_list" value = "{{item}}">
                      {{item | titlecase }}
                    </mat-option>
                  </mat-select>
                  <mat-error  *ngIf="f.relationship.errors?.required && submitted">This field is required</mat-error>
                </mat-form-field>
                <!-- <div *ngIf="f.relationship.errors && submitted" class="text-danger">
                  <div *ngIf="f.relationship.errors.required">This field is required</div>
                </div> -->
                
                <mat-form-field  class="example-full-width" appearance="fill">
                  <mat-label>Comment</mat-label>
                  <input matInput #commentInput formControlName="comment" placeholder="Comment" [ngModel]="famhistory?.comment" />
                </mat-form-field>
                
                <div class="save-button">
                  <button [disabled]="save_Click"  (click)= "dialog_status == 'create' && onSave_family_history(conditionName.value  ,commentInput.value)" (click)="dialog_status == 'edit' && edit_family_history(conditionName.value ,commentInput.value)">Save</button> 
                </div>
                <div *ngIf = "dialog_status == 'edit'" class="delete-button">
                  <button (click)= "dialog_status == 'edit' &&   onDelete_Family_History(conditionName.value,commentInput.value)">Remove</button>
                </div>
                
                
              </form>

            </div>

           <div *ngIf="selected_dialog_title == 'insurance'">
            <div class="mat-header">
              <div> <h1 mat-dialog-title>Insurance</h1></div>
              <div class="file-up">
                <button *ngIf = "dialog_status == 'edit' && (insurance.id!=undefined)"  (click)="upload_doc()"  mat-mini-fab aria-label="Example icon button with a vertical three dot icon" title="Upload Documents">
                  <mat-icon>file_upload</mat-icon>
                </button>
              </div>
            </div>
           
            <form    class="" [formGroup]="myForm" >

              
              <div>
              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Company Name</mat-label>
                <input required matInput #insuranceCompanyInput formControlName="insuranceCompany" placeholder="Company Name" [ngModel]="insurance?.insuranceCompany" />
                <mat-error *ngIf="f.insuranceCompany.errors?.required && submitted">This field is required</mat-error>
              </mat-form-field>
              <!-- <div *ngIf="f.insuranceCompany.errors && submitted" class="text-danger">
                <div *ngIf="f.insuranceCompany.errors.required">This field is required</div>
              </div> -->
              

              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Policy Number</mat-label>
                <input required matInput #policyNumberInput formControlName="policyNumber" placeholder="Policy Number" [ngModel]="insurance?.policyNumber"/>
                <mat-error *ngIf="f.policyNumber.errors?.required && submitted">This field is required</mat-error>
              </mat-form-field>
              <!-- <div *ngIf="f.policyNumber.errors && submitted" class="text-danger">
                <div *ngIf="f.policyNumber.errors.required">This field is required</div>
              </div> -->

              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Member Id</mat-label>
                <input matInput #memberIdInput formControlName="memberId" placeholder="Member Id" [ngModel]="insurance?.memberId"/>
              </mat-form-field>

              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Group Number</mat-label>
                <input matInput #groupNumberInput formControlName="groupNumber" placeholder="Group Number" [ngModel]="insurance?.groupNumber"/>
              </mat-form-field>

              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Plan Name</mat-label>
                <input required matInput #planNameInput formControlName="planName" placeholder="Plan Name" [ngModel]="insurance?.planName"/>
                <mat-error *ngIf="f.planName.errors?.required && submitted">This field is required</mat-error>
              </mat-form-field>
              <!-- <div *ngIf="f.planName.errors && submitted" class="text-danger">
                <div *ngIf="f.planName.errors.required">This field is required</div>
              </div> -->
              <div class="date-time-select">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Start Date</mat-label>
                  <input readonly placeholder="Start date" #startDate  [value]="get_date_value(insurance?.startDate)" matInput [matDatepicker]="dp3" >
                  <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                  <mat-datepicker #dp3 ></mat-datepicker>
                </mat-form-field>
                
              
              </div>
              <div class="date-time-select">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>End Date</mat-label>
                  <input readonly [min]="end_min_value(startDate.value)"  placeholder="End date" #endDate  [value]="get_date_value(insurance?.endDate)"  matInput [matDatepicker]="dp" >
                  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
              </div>
             

              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Status</mat-label>
                <mat-select required formControlName ="status" [ngModel]="insurance?.status">
                  <mat-option>Select Status</mat-option>
                  <mat-option *ngFor="let item of insurance_status_list" value = "{{item}}">
                    {{item}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.status.errors?.required && submitted">This field is required</mat-error>
              </mat-form-field>
              <!-- <div *ngIf="f.status.errors && submitted" class="text-danger">
                <div *ngIf="f.status.errors.required">This field is required</div>
              </div> -->
              
              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Comment</mat-label>
                <input matInput #commentInput formControlName="comment" placeholder="comment" [ngModel]="insurance?.comment" />
              </mat-form-field>
            </div>
            <div *ngIf="dialog_status == 'edit'" class="relation-list row row-cols-1 row-cols-md-3 g-3">
              <ng-template #content let-modal>
                <div>
                  
                  <!--Content-->
                  <div class="">
                    <div class="modal-header">
                      <h1 class="modal-title" id="exampleModalLabel">View Document</h1>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">x
                      </button>
                      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
                    </div>
                
              
                    <!--Body-->
                    <div class="modal-body">
                       
                      <div *ngIf="type_con=='application/pdf'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                        <iframe class="embed-responsive-item" [src] ="safeurl | safeurl" height="500" width="100%"  allowfullscreen></iframe>
                      </div>
                      <div *ngIf="type_con!='application/pdf'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                        <img *ngIf="type_con =='image/png' || type_con =='image/jpg'||type_con =='image/jpeg'|| type_con =='image/gif' || type_con =='image/bmp'" class="embed-responsive-item img-view" [src] ="safeurl | safeurl"  />
                        <img  *ngIf="type_con =='application/msword'" src="../../assets/images/doc.png"  class="embed-responsive-item img-view" title="Document" alt="Photo of a document">
                                
                                <img *ngIf="type_con =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'"  src="../../assets/images/docx.png"  class="embed-responsive-item img-view" alt="Photo of a document" >
                                <img  *ngIf="type_con =='text/plain'" src="../../assets/images/txt.png"  class="embed-responsive-item img-view" alt="Photo of a document" >
                                <img  *ngIf="type_con =='application/rtf'" src="../../assets/images/rtf.png" alt="Photo of a document"  class="embed-responsive-item img-view" >
                                <img  *ngIf="type_con =='application/vnd.ms-excel'"  src="../../assets/images/xls.png" alt="Photo of a document"  class="embed-responsive-item img-view" >
                                <img  *ngIf="type_con =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"  src="../../assets/images/xlsx.png" alt="Photo of a document"  class="embed-responsive-item img-view">
              
                      </div>
              
              
              
                    </div>
              
                    <!--Footer-->
                   
              
                  </div>
                  <!--/.Content-->
              
                </div>
              
              </ng-template>
              <div class="col" *ngFor="let item of list_insurance">
                <mat-card  >
                  <div class="header-card">
                    
                    <p class="card-title-main">{{item.name}}</p>
                    <div  style="flex-grow: 1"></div>
                    <div class="header-btn" (click)="delete_doc(item)" title="Delete Document">
                      <mat-icon>close</mat-icon>

                    </div>
                    
                   
                  </div>
                  <div class="doc-list-card" title="View Document" (click)="view_doc_personal(item.url,item.content_type, content)">
                    <img class="doc-img" *ngIf="item.content_type.split('/',1) =='image'" mat-card-image src="{{item.url}}" alt="Photo of a document" loading="eager">
                  <img class="doc-img" *ngIf="item.content_type =='application/pdf'" mat-card-image src="../../assets/images/pdf.png" alt="Photo of a document" loading="eager">
                  <img class="doc-img" *ngIf="item.content_type =='application/msword'" mat-card-image src="../../assets/images/doc.png" alt="Photo of a document" loading="eager ">
                  <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" mat-card-image src="../../assets/images/docx.png" alt="Photo of a document" >
                      <img class="doc-img" *ngIf="item.content_type =='text/plain'" mat-card-image src="../../assets/images/txt.png" alt="Photo of a document" loading="eager">
                      <img class="doc-img" *ngIf="item.content_type =='application/rtf'" mat-card-image src="../../assets/images/rtf.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.ms-excel'" mat-card-image src="../../assets/images/xls.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" mat-card-image src="../../assets/images/xlsx.png" alt="Photo of a document" >

                  </div>
                  
                    

                  
                  
                </mat-card>


              </div>
              

            </div>


              <div class="save-button">
                <button [disabled]="save_Click" (click)= "dialog_status == 'create' && onSave_insurance(insuranceCompanyInput.value, policyNumberInput.value, memberIdInput.value, groupNumberInput.value, planNameInput.value, commentInput.value,startDate.value,endDate.value)" (click)="dialog_status == 'edit' && edit_insurance(insuranceCompanyInput.value, policyNumberInput.value, memberIdInput.value, groupNumberInput.value, planNameInput.value, commentInput.value,startDate.value,endDate.value)">Save</button> 
              </div>
              <!-- <div   class="delete-button">
                <button  (click)="upload_doc()">Upload Doc</button> 
  
              </div> -->
              <div *ngIf = "dialog_status == 'edit'" class="delete-button">
                <button (click)= "dialog_status == 'edit' && onDelete_insurance()">Remove</button>
              </div>
              
            </form> 

           </div>

           <div *ngIf="selected_dialog_title == 'immunization'">
            <div class="mat-header">
              <div> <h1 mat-dialog-title>Immunization</h1></div>
              <div class="file-up">
                <button *ngIf = "dialog_status == 'edit' && (immunization.id!= undefined)"  (click)="upload_doc()"  mat-mini-fab aria-label="Example icon button with a vertical three dot icon" title="Upload Documents">
                  <mat-icon>file_upload</mat-icon>
                </button>
              </div>
            </div>
            <form    class="" [formGroup]="myForm" > 
             
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Immunization</mat-label>
                <input required matInput #vaccineName formControlName="vaccineName" placeholder="Vaccine Name" [ngModel]="immunization?.vaccineName" [matAutocomplete]="auto"/>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option>Select Immunization</mat-option>
                  <mat-option *ngFor="let item of vacc_list |  search: 'title':vaccineName.value " value = "{{item.title}}">
                    {{item.title}}
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="f.vaccineName.errors?.required && submitted">This field is required</mat-error>
              </mat-form-field>
              <!-- <div *ngIf="f.vaccineName.errors && submitted" class="text-danger">
                <div *ngIf="f.vaccineName.errors.required">This field is required</div>
              </div> -->


              <div class="date-time-select">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Admin Date</mat-label>
                  <input required readonly [max]="today" #adminDate [value]="get_date_value(immunization?.adminDate)"  matInput [matDatepicker]="dp3" >
                  <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                  <mat-datepicker #dp3></mat-datepicker>
                </mat-form-field>
                
               
              </div>
              <div *ngIf="adminDate.value=='' && submitted" class="text-danger">
                <div>This field is required</div>
              </div>
              <div class="date-time-select">
                <mat-form-field class="example-full-width" appearance="fill">
                  <mat-label>Next Due Date</mat-label>
                  <input readonly [min]="tomorrow" #nextDueDate matInput [value]="get_date_value(immunization?.nextDueDate)" [matDatepicker]="dp5" >
                  <mat-datepicker-toggle matSuffix [for]="dp5"></mat-datepicker-toggle>
                  <mat-datepicker #dp5></mat-datepicker>
                </mat-form-field>
              </div>
              
              <mat-form-field  class="example-full-width" appearance="fill">
                <mat-label>Detail</mat-label>
                <input matInput #commentInput formControlName="detail" placeholder="Detail" [ngModel]="immunization?.detail" />
              </mat-form-field>
              <div *ngIf = "dialog_status == 'edit'" class="relation-list row row-cols-1 row-cols-md-3 g-3">
            
                <div class="col" *ngFor="let item of list_vaccine">
                  <ng-template #content let-modal>
                    <div>
                      
                      <!--Content-->
                      <div class="">
                        <div class="modal-header">
                          <h1 class="modal-title" id="exampleModalLabel">View Document</h1>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">x
                          </button>
                          <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
                        </div>
                    
                  
                        <!--Body-->
                        <div class="modal-body">
                           
                          <div *ngIf="type_con=='application/pdf'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                            <iframe class="embed-responsive-item" [src] ="safeurl | safeurl" height="500" width="100%"  allowfullscreen></iframe>
                          </div>
                          <div *ngIf="type_con!='application/pdf'"  class="embed-responsive embed-responsive-16by9 z-depth-1-half">
                            <img *ngIf="type_con =='image/png' || type_con =='image/jpg'||type_con =='image/jpeg'|| type_con =='image/gif' || type_con =='image/bmp'" class="embed-responsive-item img-view" [src] ="safeurl | safeurl"  />
                            <img  *ngIf="type_con =='application/msword'" src="../../assets/images/doc.png"  class="embed-responsive-item img-view" title="Document" alt="Photo of a document">
                                    
                                    <img *ngIf="type_con =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'"  src="../../assets/images/docx.png"  class="embed-responsive-item img-view" alt="Photo of a document" >
                                    <img  *ngIf="type_con =='text/plain'" src="../../assets/images/txt.png"  class="embed-responsive-item img-view" alt="Photo of a document" >
                                    <img  *ngIf="type_con =='application/rtf'" src="../../assets/images/rtf.png" alt="Photo of a document"  class="embed-responsive-item img-view" >
                                    <img  *ngIf="type_con =='application/vnd.ms-excel'"  src="../../assets/images/xls.png" alt="Photo of a document"  class="embed-responsive-item img-view" >
                                    <img  *ngIf="type_con =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"  src="../../assets/images/xlsx.png" alt="Photo of a document"  class="embed-responsive-item img-view">
                  
                          </div>
                  
                  
                  
                        </div>
                  
                        <!--Footer-->
                       
                  
                      </div>
                      <!--/.Content-->
                  
                    </div>
                  
                  </ng-template>
                  <mat-card  >
                    <div class="header-card">
                      
                      <p class="card-title-main">{{item.name}}</p>
                      <div  style="flex-grow: 1"></div>
                      <div class="header-btn" (click)="delete_doc(item)" title="Delete Document">
                        <mat-icon>close</mat-icon>
  
                      </div>
                     
                    </div>
                    <div class="doc-list-card" title="View Document" (click)="view_doc_personal(item.url,item.content_type, content)">
                      <img class="doc-img" *ngIf="item.content_type.split('/',1) =='image'" mat-card-image src="{{item.url}}" alt="Photo of a document" loading="eager">
                    <img class="doc-img" *ngIf="item.content_type =='application/pdf'" mat-card-image src="../../assets/images/pdf.png" alt="Photo of a document" loading="eager">
                    <img class="doc-img" *ngIf="item.content_type =='application/msword'" mat-card-image src="../../assets/images/doc.png" alt="Photo of a document" loading="eager ">
                    <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" mat-card-image src="../../assets/images/docx.png" alt="Photo of a document" >
                      <img class="doc-img" *ngIf="item.content_type =='text/plain'" mat-card-image src="../../assets/images/txt.png" alt="Photo of a document" loading="eager">
                      <img class="doc-img" *ngIf="item.content_type =='application/rtf'" mat-card-image src="../../assets/images/rtf.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.ms-excel'" mat-card-image src="../../assets/images/xls.png" alt="Photo of a document" loading="eager ">
                      <img class="doc-img" *ngIf="item.content_type =='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" mat-card-image src="../../assets/images/xlsx.png" alt="Photo of a document" >

                    </div>
                   
                    
                  </mat-card>


                </div>
                

              </div>

              <div class="save-button">
                <button [disabled]="save_Click"  (click)= "dialog_status == 'create' && onSave_immunization(vaccineName.value ,adminDate.value,nextDueDate.value ,commentInput.value)" (click)="dialog_status == 'edit' && edit_immunization(vaccineName.value ,adminDate.value,nextDueDate.value , commentInput.value)">Save</button> 
              </div>
              
              <div *ngIf = "dialog_status == 'edit'" class="delete-button">
                <button (click)= "dialog_status == 'edit' && onDelete_immunization(vaccineName.value ,adminDate.value,commentInput.value)">Remove</button>
              </div>

            </form>
             
           </div>
           

            

                       

            
            
          </div>
      </div>
    </div>  
</div>
<block-ui></block-ui>




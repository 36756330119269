import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  // menu_list = [{name: 'Health Info', link: '/menu/health-info', icon: 'fas fa-laptop-medical'}, {name: 'Devices', link: '/menu/add-device', icon: 'fas fa-cubes'}, {name: 'Activity Tracking', link: '/menu/activity-tracking', icon: 'fas fa-chart-line'}, {name: 'Calendar', link: '/menu/calender', icon: 'far fa-calendar-alt'}, {name: 'Care Team', link: '/menu/care-team', icon:'fas fa-users'}, {name: 'Care Plan', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'}, {name: 'Service Provider', link: '/menu/service-provider', icon: 'far fa-id-badge'}, {name: 'Settings', link: '/menu/settings', icon: 'fa fa-cog'}, {name: 'Chat', link: '/menu/chat', icon: 'fa fa-comment'}]
 @Input() menu_list:any;
 // menu_list = [{name: 'Health Info', link: '/menu/health-info', icon: 'fas fa-laptop-medical'}, {name: 'Devices', link: '/menu/add-device', icon: 'fas fa-cubes'},{name: 'Care Plan', link: '/menu/care-plan', icon: 'fas fa-hand-holding-heart'}]

  constructor(private router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
  }



}

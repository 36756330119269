<div class="relationship-dialog-close-btn">
    <button (click)=closeDialog()>X</button>
</div>
<div class="relationship-dialog-section">
    <div class="header-details">
        <div class="dialog-header">
            <p>Search Family & Friends <br>
                <!-- <span>45 Patients, 3 Alerts</span> -->
            </p>
        </div> 
        <div class="searchbar-e">
            <input  type="text" placeholder="Search" [(ngModel)] = searchText>
            <i class="fas fa-search"></i>
        </div>
        <!-- <div [ngClass]="{'disable-filter' : !relationship_list.entities}" class="header-icons"> 
            <button [disabled]="!relationship_list.entities" (click)="openDialog()">
                <i class="fas fa-filter"></i>
            </button>
        </div> -->
    </div>    
    <div class="searchbar">
        <input  type="text" placeholder="Search" [(ngModel)] = searchText>
        <i class="fas fa-search"></i>
    </div>
    <!-- <div class="chips-cnt">
        <mat-chip-list aria-label="Chip selection">
            <mat-chip *ngFor="let chip of chips"
            [removable]="removable" 
            (removed)="remove(chip)"
            >{{chip | titlecase}}<mat-icon matChipRemove *ngIf="removable" >cancel</mat-icon></mat-chip>
        </mat-chip-list> 
    </div> -->
    <div class="relationship-scroll-list">
        <div class="relationship-list" *ngFor="let item of care_team_list | FilterPipe: searchText ;">
            <img src="../../assets/images/owner.png" alt="">
            <div class="name">
                <p>{{item.firstName}}  {{item.lastName}}</p> 
            </div>
            <button (click)="openSendRequestDialog(item.id)" >Send Request</button>
        </div>
    </div>
    
</div>
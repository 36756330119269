import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SaveAlertComponent } from './save-alert.component';

@Injectable()
export class SaveAlertService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,

    btnOkText: string = 'Yes',
    btnCancelText: string = 'No',
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(SaveAlertComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '../auth-service.service';

@Component({
  selector: 'app-add-ons',
  templateUrl: './add-ons.component.html',
  styleUrls: ['./add-ons.component.scss']
})
export class AddOnsComponent implements OnInit {
  id:any
  goal:any;
  med:any;
  activity:any;
  title:any

  constructor( private router: Router , private route: ActivatedRoute , private auth: AuthServiceService) { }

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.title = this.route.snapshot.paramMap.get("cat");
   
    this.id = this.route.snapshot.paramMap.get("id")
   

    this.auth.get_careplangoal(this.id).subscribe((res:any)=>{
      this.goal = res;
     
    })

    this.auth.get_careplanschedule(this.id).subscribe((res:any)=>{
      this.activity = res;
     
    })

    this.auth.get_careplanmed(this.id).subscribe((res:any)=>{
      this.med = res;
     
    })
  }


}

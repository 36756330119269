<div  class="row wrapper hover_collapse"> 
    <div class="example-button-container">
        <button (click)="open_bottom_dialog($event)" mat-raised-button>
          <mat-icon>add</mat-icon><span>Add</span>
        </button>
    </div>
    <div *ngIf="isClassExpand" class="bottom_dialog" title="Add Device Readings" (click)="$event.stopPropagation()" >
        <div *ngFor="let item of device_list">
            <button (click)="openDialog(item.systemId,item.category.type,item.title)"><i class="{{item.image[0]}}"></i>{{item.category.name | pulseTrans}}</button>
        </div>
    </div> 
    <app-menu-comp></app-menu-comp>
    <div class="{{patient_details_column}} col-md-8 main_container">
        <div class="container">
            <div class="cnt">
                <app-user-details style="min-width: 100%;"
               
                ></app-user-details>
            </div> 
        </div>
        <!-- <div class=" device-add container">
            <div class=" cnt">
                <mat-card>Vitals<button (click)="openDialog()">+</button></mat-card>
            </div>
        </div> -->
        <div>
            <div class="container">
                <div class=" cnt">
                    <div *ngIf="height_len" >
                        <app-bmi  ></app-bmi>
                    </div>
                    <div *ngFor="let item of update_dr;">
                        <div  *ngIf="item.device.systemId === '0x1810'">
                            <app-three-data-card 
                            [card_details]="item"
                            [device_list]="device_list"
                            [device_names]="device_names"
                            >
                            </app-three-data-card>
                        </div>


                        <div *ngIf="item.device.systemId !== '0x1810' && item.device.systemId !== '0x181D'" class="small-cards">
                            <app-cards  
                            [card_details]="item"
                            [device_list]="device_list"
                            [device_names]="device_names"
                            >
                            </app-cards>
                        </div>
                    </div>
                    
                </div> 
            </div>
            <!-- <div>
                <div class="container">
                    <div class="clinical-cnt cnt"  *ngIf="records_len !== 0">
                        <app-graph-row></app-graph-row>
                    </div>
                </div>
            </div> -->
            <div >
                <div class="container">
                    <app-menu [menu_list]=" menu_list"></app-menu>
                
                </div>
            </div>
    
            <!-- <div class="container">
                <div class="clinical-cnt cnt">
                    <app-care-program
                    [care_program_details]="single_detail.care_program_details"
                    ></app-care-program>
                </div>
            </div> -->
            <!-- <div class="container">
                <div class="activity-plan-cnt cnt">
                    <div class="row ">
                        
                    </div>
                </div>
            </div> -->
        </div>

    </div>
    <div class="{{third_column}} {{column_expansion}}" [ngClass]="{'third-column-extension': extended_icon_status == 1}">
        <app-patients-column 
        [service_provider_lists]="service_provider_lists"
        [relationship_list_parent]="relationship_list" 
        (display)="display_toggle($event)"
        (patientClicked)="indexValue($event)"
        [patient_details]="patient_details"
        [patient_selected]="patient_selected"
        [chevron_left]="chevron_left"
        [display_status]="display_status"
        [call_message_buttons]="call_message_buttons"
        ></app-patients-column>
    </div>
    
</div>
<div class="container">
  <div class="card-dialog row justify-content-center">
    <div class="rightdrag col-12">
      <div class="close-btn">
        <button (click)="close_dialog()">X</button>
      </div>
      <div class="">
          

        <h2>Drop or Browse Documents</h2>
        <div *ngIf="pg_no==0" mat-dialog-content>
          <div class="container1" appDnd (fileDropped)="onFileDropped($event)">
              <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf ,image/jpg,image/jpeg,image/gif,image/bmp,image/png,text/plain,application/rtf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"  />
              <svg xmlns="http://www.w3.org/2000/svg" width="63" height="64" viewBox="0 0 63 64">
                  <g fill="#3B454F" fill-rule="nonzero">
                      <path
                          d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                      <path
                          d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                      <path
                          d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                  </g>
              </svg>
          
              <h3>Drag and drop file here</h3>
              <h3>or</h3>
              <label class="browse" for="fileDropRef">Browse for files</label>
          </div>
          <div class="files-list">
              <div class="single-file" *ngFor="let file of files; let i = index">
                  <div class="file-icon" style="width: 50px">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
  <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
  <g>
    <path style="fill:#CEC9AE;"
      d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
    <path style="fill:#CEC9AE;"
      d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
    <path style="fill:#CEC9AE;"
      d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
    <path style="fill:#CEC9AE;"
      d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
    <path style="fill:#CEC9AE;"
      d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
  </g>
  <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
</svg>
                      <!-- <i class="fa fa-file fa-lg color-1"></i> -->
                  </div>
                  <div class="info">
                      <h4 class="name">
                          {{ file?.name }}
                      </h4>
                      <p class="size">
                          {{ formatBytes(file?.size) }}
                      </p>
                      <app-progress [progress]="file?.progress"></app-progress>
                  </div>
          
                  <div class="delete" (click)="deleteFile(i)">
                      <mat-icon>delete</mat-icon>
                  </div>
              </div>
          </div>
          <mat-error *ngIf="allowed_exe_status">Please upload PDF, Images, Word or Spreadsheet Documents</mat-error>

        </div>
        <div *ngIf="pg_no==1" mat-dialog-content>
         
          
          <div class="user_column">
            <div class="upper-drag">
              <div class="sub-head-drag">
                <p class="sub-head-edit">Edit Documents</p>
  
              </div>
          
        
           <div class="form-drag">
              <mat-form-field appearance="fill">
                <mat-select [(value)]="selected">
                  <mat-option *ngFor="let food of countries" (click)="selectedn(food.index,food.country)"[value]="food.index">
                    {{food.country}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            </div>
            <div class="panel-list">
          
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div formArrayName="lessons">
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let lessonForm of lessons.controls; let i = index">
                      <mat-expansion-panel-header>
                     
                        <mat-panel-title>
                          <div [ngClass]="lessonForm.invalid && submitted? 'alert-panel' : 'text-nom'">{{files[i].name}}</div>
                        </mat-panel-title>
                        <mat-panel-description>
                          {{files[i].name.split('.')[1] | titlecase }}&nbsp;File
                          <mat-icon (click)="deleteFile(i)">delete</mat-icon>
                        </mat-panel-description>
                        
                      </mat-expansion-panel-header>
                        <div class="form-group" [formGroupName]="i">
                          
                          <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Tags</mat-label>
                            <mat-select required  multiple formControlName ="tags"  >
                              <mat-option *ngFor="let topping of tag_list" [value]="topping">{{topping}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="lessonForm.get('tags')?.errors && submitted">This field is required</mat-error>
        
                          </mat-form-field>
                      
                          

                    
                
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Name</mat-label>
                        <input matInput #name required formControlName='name' [ngModel]="files[i].name" name="name" placeholder="Enter Document Name"  >
                        <mat-error *ngIf="lessonForm.get('name')?.errors && submitted">This field is required
                        </mat-error>
                      
                    </mat-form-field>
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Description</mat-label>
                        <textarea matInput #description required formControlName='description' [ngModel]="files[i].name" name="description" placeholder="Enter description"></textarea>
                      <mat-error *ngIf="lessonForm.get('description')?.errors && submitted">This field is required</mat-error>
                    </mat-form-field>
                    
                    
                    
                    
                      
                    
                    
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Expiration date</mat-label>
                        <input readonly formControlName='target' #target matInput [min]="maxDate" [matDatepicker]="dp3" >
                        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                        <mat-datepicker #dp3 ></mat-datepicker>
                       
                      </mat-form-field>
                    
                  

                        </div>
                       

                  

                  </mat-expansion-panel>
                </mat-accordion>
              </div>

            </form>

            </div>

          </div>
            
          

        </div>
        <div class="action-btn">
          <button class="cancel-btn"  mat-raised-button mat-dialog-close>Cancel</button>
          <button  *ngIf="pg_no==0" class="next-btn"(click)="next_fn()"  mat-raised-button>Next</button>
          <button type="submit" *ngIf="pg_no==1" class="next-btn" (click)="onSubmit()" mat-raised-button>Finish</button>
        </div>
      

  </div>

    </div>
     
  </div>

</div>




<div class="container">
    <div class="card-dialog row justify-content-center">
        <div class="right col-12">
        <div class="close-btn">
          <button (click)="close_dialog()">X</button>
        </div>
        <h2 mat-dialog-title>Country Affliation</h2>
            <div mat-dialog-content>
                <form class="form-dialog" [formGroup]="myForm">
                    
                    <div *ngIf="status=='add'">
                        <mat-form-field class="example-full-width" appearance="fill">
                          <mat-label>Select Country</mat-label>
                          <mat-select required #con formControlName="country" [ngModel]="data_edit.country" (selectionChange)="check_doctype_exp($event.value)">
                            <mat-option *ngFor="let country of list_countries" [value]="country[0]">
                              {{country[0] | uaename}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="myForm.controls['country'].invalid && submitted">This Field is required</mat-error>
                        </mat-form-field>
                        <!-- <div *ngIf="myForm.controls['country'].invalid && submitted" class="text-danger">
                        This Field is required.
                      </div> -->
                      </div>
                      <div *ngIf="status=='edit'">
                        <mat-form-field *ngIf="data_edit.country!='UnitedArabEmirates'" class="example-full-width" appearance="fill">
                          <mat-label>Select Country</mat-label>
                          
                          <input matInput required formControlName="country" [ngModel]="data_edit.country">
                        </mat-form-field>
                        <mat-form-field *ngIf="data_edit.country=='UnitedArabEmirates'" class="example-full-width" appearance="fill">
                          <mat-label>Select Country</mat-label>
                          
                          <input matInput disabled="true" required value ="{{data_edit.country | uaename}}">
                        </mat-form-field>
                        <!-- <div *ngIf="myForm.controls['country'].invalid && submitted" class="text-danger">
                        This Field is required.
                      </div> -->
                      </div>
                      <div *ngIf=" data_edit.index != 'citizen_primary'">
                        <mat-form-field class="example-full-width" appearance="fill">
                          <mat-label>Affiliation Type</mat-label>
                          <mat-select required formControlName ="affiliation_type"  [ngModel]="data_edit.affiliation_type" [(value)]="affiliation_type">
                            <mat-option>Select Affiliation</mat-option>
                            <mat-option *ngFor="let item of aff_list" value = "{{item.aflType}}">
                              {{item.aflName}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="myForm.controls['affiliation_type'].errors && submitted">This Field is required</mat-error>
                        </mat-form-field>

                      </div>
                      
                      <!-- <div *ngIf="myForm.controls['affiliation_type'].errors && submitted" class="text-danger">
                        This Field is required.
                    </div> -->
                      
                      <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Home Address</mat-label>
                        <textarea matInput #home_add required formControlName='home_address' [ngModel]="data_edit.home_address"  placeholder="Enter your home address" ></textarea>
                        <mat-error *ngIf="myForm.controls['home_address'].invalid && submitted">This Field is required</mat-error>
                    </mat-form-field>
                    <!-- <div *ngIf="myForm.controls['home_address'].invalid && submitted" class="text-danger">
                      This Field is required.
                  </div> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Work Address</mat-label>
                        <textarea matInput #work_add  formControlName='work_address' name="work_address" placeholder="Enter your work address"  [ngModel]="data_edit.work_address"></textarea>
                    </mat-form-field>
                  
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Mobile Phone</mat-label>
                        <input type="number" matInput #mob formControlName='mobile_phone' name="mobile_phone"  placeholder="Enter your mobile number" required  [ngModel]="get_no()" >
                        <mat-error *ngIf="myForm.controls['mobile_phone'].invalid && submitted && data_edit.index != 'citizen_primary' ">
                          <mat-error *ngIf="myForm.controls['mobile_phone'].errors?.required">This field is required</mat-error>
                          <mat-error *ngIf="myForm.controls['mobile_phone'].errors?.pattern">Mobile number is not 10 digit</mat-error>

                        </mat-error>
                    </mat-form-field>
                    
                    <!-- <div *ngIf="myForm.controls['mobile_phone'].invalid && submitted " class="text-danger">
                        <div *ngIf="myForm.controls['mobile_phone'].errors?.required">This field is required</div>
                        <div *ngIf="myForm.controls['mobile_phone'].errors?.pattern">Mobile number is not 10 digit</div>
                    </div> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Work Phone</mat-label>
                        <input type="number" matInput #work_no formControlName='work_phone' name="work_number"  placeholder="Enter your work number"  [ngModel]="data_edit.work_phone" >
                         <mat-error *ngIf="myForm.controls['work_phone'].errors?.pattern">Mobile number is not 10 digit</mat-error>
                        
                    </mat-form-field>
                    <!-- <div *ngIf="myForm.controls['work_phone'].invalid && submitted" class="text-danger">
                        <div *ngIf="myForm.controls['work_phone'].errors?.pattern">Mobile number is not 10 digit</div>
                    </div> -->
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Alternative Phone</mat-label>
                        <input type="number" matInput #alt_no formControlName='alt_phone' placeholder="Enter your alterantive mobile number" [ngModel]="data_edit.alt_phone">
                        <mat-error *ngIf="myForm.controls['alt_phone'].errors?.pattern">Mobile number is not 10 digit</mat-error>

                    </mat-form-field>
                    <!-- <div *ngIf="submitted && myForm.controls['alt_phone'].invalid " class="text-danger">
                        <div *ngIf="myForm.controls['alt_phone'].errors?.pattern">Mobile number is not 10 digit</div>
                    </div> -->

                  
                      
                    <div class="save-button">
                        <button [disabled]="dis_btn" type="submit" (click)="onSubmit(mob.value, work_add.value,work_no.value,alt_no.value)">Save</button>
                        <!-- <button type="submit" (click)= "status == 'add' && add_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)" (click)="status == 'edit' && edit_country_affliation(countryName, affiliation_type, home_add.value, work_add.value,mob.value,work_no.value,alt_no.value)">Save</button>  -->
                      </div>
                      <div *ngIf = "status == 'edit' && data_edit.index != 'citizen_primary'" class="delete-button">
                        <button (click)= "status == 'edit' &&  delete_country_affliation()">Delete</button>
                      </div>

                </form>
            </div>
        </div>
    </div>
</div>
<block-ui></block-ui>
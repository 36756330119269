import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-care-program',
  templateUrl: './care-program.component.html',
  styleUrls: ['./care-program.component.scss']
})
export class CareProgramComponent implements OnInit {

  @Input() care_program_details: any;

  constructor() { }

  ngOnInit(): void {
  }

}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DialogData } from '../app.component';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import {TooltipPosition} from '@angular/material/tooltip';
import { ConfirmBoxService } from '../confirm-box/confirm-box.service';
import { SaveAlertService } from '../save-alert/save-alert.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss']
})
export class RemindersComponent implements OnInit {
  reminders:any;
  maxDate= new Date();
  list:any;
  new15: any;
  todaydate:any;
  searchText='';
  searchTextSecond: any;
  
  searchbar = 'searchbar';

  constructor(public auth:AuthServiceService , public toast:ToastrService ,private _communication: CommunicationService , public datePipe:DatePipe , public dialog:MatDialog , private con: ConfirmBoxService) { }
   
  openDialog() {
    
    this.dialog.open(DialogAddReminder, {
      
    });
  }



  ngOnInit(): void {
        
    // this.new15 = this.datePipe.transform(moment().add(15, 'days').calendar(),'MMM d, y');
    // this.todaydate = this.datePipe.transform(new Date(),'MMM d, y')
    
    this.auth.get_reminders().subscribe((res:any)=>{
      
      this.reminders = res.data?.reminders;

      // this.reminders = res.data.reminders.filter((element=>{
      //   (moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"),element.target )
      //   return (moment(element.target).isBetween(this.todaydate,this.new15,undefined,"[]"))
      // }))
      
    
    })
    this._communication.changeEmitted$.subscribe(data =>{
      if(data){
        this.reminders =  data.data?.reminders
      
      }
    })
  }
  delete_reminder(id){
    this.con.confirm("Alert","Are you sure you want to delete Reminder?").then((res)=>{
      if(res){
        this.auth.delete_reminder(id).subscribe((res:any)=>{
          if(res.error == false){
            this.auth.get_reminders().subscribe((res:any)=>{
              if(res.error==false){
                this.toast.success("Reminder deleted successfully.")
                this._communication.emitChange(res)
        
              } 
    
            })

          }
        })
       

      }
    })
 
  }
  getFilterValue(item) {
    var name = item.message
    return name.toLowerCase()
  }
  

  

}


@Component({
  selector: 'dialog-add-reminder',
  templateUrl: './add-reminder.html',
  styleUrls: ['./reminders.component.scss']
})

export class DialogAddReminder  {
  @BlockUI()
  blockUI!: NgBlockUI; 
  submitted= false
  reminder:any;
  maxDate = new Date();
  


  constructor(
    public dialogRef: MatDialogRef<DialogAddReminder>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public formBuilder:FormBuilder,public auth:AuthServiceService,public toast:ToastrService , public datepipe:DatePipe , public _communication:CommunicationService, private save: SaveAlertService
  ) {
    dialogRef.disableClose=true
  }
  myForm = this.formBuilder.group({
   message:['',Validators.required],
   target:['',Validators.required]
  });
  get f(): { [key: string]: AbstractControl } {
    return this.myForm.controls;
  }
start_loading(){
  this.blockUI.start()
}
stop_loading(){
  this.blockUI.stop()
}
  add_reminder(msg,targetdate){
  
    this.submitted = true
    if (this.myForm.invalid) {
      return
       }  
   this.start_loading()
    let date = this.datepipe.transform(targetdate,'dd-MM-YYYY')
    this.auth.add_reminders(msg,date).subscribe((res:any)=>{
      if(res.error==false){
        this.toast.success(res.msg) 
        this.auth.get_reminders().subscribe((res:any)=>{
          this.reminder = res.data.reminder
          this._communication.emitChange(res)
          this.stop_loading()
          this.close_dialog()
        
        })
      }
      else {
        this.stop_loading()
        this.toast.error(res.msg)
      }

    })
    

  }
  close_dialog(){
    if(this.submitted==false && this.myForm.dirty){
      this.save.confirm("Alert").then((res)=>{
        if(res){
          this.dialogRef.close()
        }
      })
      

    } else {
      this.dialogRef.close()
    }
  }
  



}

import { FocusOrigin } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, Inject, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServiceService } from '../auth-service.service';
import { CommunicationService } from '../communication.service';
import { FormDialog } from '../create-new-plan/create-new-plan.component'; 

@Component({
  selector: 'app-edit-care-plan',
  templateUrl: './edit-care-plan.component.html',
  styleUrls: ['./edit-care-plan.component.scss']
})
export class EditCarePlanComponent implements OnInit {

  elementOrigin = this.formatOrigin(null);
  subtreeOrigin = this.formatOrigin(null);
  medcon_list: any;
  activities: any;
  categoryvalue :any;
  durationType = 0;
  careplan: any;
  isShow = false;
  category_list:any;
  desgoal:any;
  activity:any;
  medication:any;
  dialog_status = '';
  current_con = '';
  title:any;
  id:any;
  detail_card:any;
  care: any;
  desiredoutcome:any;
  titledesire: string[] = [];
  titleschedule: string[] = [];
  titlemedication: string[] = [];
  exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 12 };
  date1 : any;
  submitted = false;
  category:any;

  onChangeHour(event) {
    //console.log('event', event);
  }

  


  



  constructor(private formBuilder: FormBuilder, private auth: AuthServiceService, private router: Router, public dialog: MatDialog , public toast: ToastrService,private _ngZone: NgZone, private _cdr: ChangeDetectorRef , 
    private route: ActivatedRoute) { }

  plan_info_form = this.formBuilder.group({
    medicalCondition: [''],
    description: [''],
    title: [''],
    category: [''],
    duration: [''],
    durationType: [''],
    desiredOutcome: ['']
  })

  toggleDisplay() { 
    this.isShow = !this.isShow;
  }

  ngOnInit(): void {
    if(localStorage.getItem('TOKEN') == null) {
      this.router.navigate(['/login']);
    }
    this.id = this.route.snapshot.paramMap.get("id");
    //console.log(this.id)
    this.auth.get_medical_condition().subscribe((res:any) =>{
      this.medcon_list = res;
    })
    this.auth.get_category("CAREPLAN").subscribe((res:any)=>{
      this.category_list = res;
      //console.log(this.category_list)
   })

    this.auth.get_activities().subscribe((res: any) => {
      
      this.activities = res;
      //console.log(this.activities)
    })

    this.auth.get_care_plan_id(this.id).subscribe((res:any) => {

      this.detail_card = res;
      this.category = res.category.id;
      //console.log(this.detail_card , this.category)

    })
    this.auth.get_careplangoal(this.id).subscribe((res:any)=>{
      this.desgoal = res;
      //console.log(res)
    
    })

    this.auth.get_careplanmed(this.id).subscribe((res:any)=>{
      this.medication = res;
      //console.log(res)
    })
    this.auth.get_careplanschedule(this.id).subscribe((res:any)=>{
      this.activity = res;
      //console.log(res)
    })
  }

 

  
 

  onSaveClicked(medCondition: any, title: any, description: any, goal: any, category: any, duration: any, durationType: any){
    // api call for plan information
    
    //console.log(medCondition,title ,description ,goal, category , duration , durationType)
    this.care = {medCondition,title ,description,goal, category , duration , durationType}
    //console.log(this.care)
    this.auth.edit_careplan(this.id ,this.care ).subscribe((res:any)=>{
      //console.log(res)
      if(res){
        this.toast.success("Updated Profile Successfully!!")
      }
    })
  
  }

  OnDelete(){
  
      if(confirm("Are you sure to delete?")) {
        this.auth.delete_careplan(this.id).subscribe((res:any)=>{
          if(!res){
            this.toast.success("Deleted Sucessfully!!")
            this.router.navigate(['/menu/care-plan']);
          }
           
          
        })
        
      } 
    
   
  }

 
  openDialog(title: string, status: any){
    this.dialog_status = status;
    this.dialog.open(FormEditDialog, {
      maxHeight: '450px',
      data: [title , this.id , this.activities , this.medcon_list ,  NaN , NaN , NaN ,NaN , this.dialog_status]
    }).afterClosed().subscribe((res:any)=>{
      if(res){

      
      if(title == "desiredOutcome")

      {  this.desgoal.push(res) 
       
      } else if(title == "activities")
      {
        
        this.activity.push(res)

      }
      else if(title == "medication"){
        this.medication.push(res)
      }
    }
      
    })

  }


  openDialogEdit(title: string,i:any, status: any){
    this.dialog_status = status;
    
    this.dialog.open(FormEditDialog, {
      maxHeight: '450px',
      data: [title , this.id , this.activities , this.medcon_list, this.desgoal[i] , this.activity[i] , this.medication[i] , i , this.dialog_status]
    }).afterClosed().subscribe((res:any)=>{
      //console.log("closed")
        
        if(title == "desiredOutcome")
        {
          if(res?.action== "edit"){
            this.desgoal[i] = res.data
          }
          else if(res?.action == "delete"){
            this.desgoal.splice(i,1)
          }
          
        } else if(title == "activities")
        {
          if(res?.action== "edit"){
            this.activity[i] = res.data
          }
          else if(res?.action == "delete"){
            this.activity.splice(i,1)
          }
  
        }
        else if(title == "medication"){
          if(res?.action== "edit"){
            this.medication[i] = res.data
          }
          else if(res?.action == "delete"){
            this.medication.splice(i,1)
          }
          
        }
  
      
    
    })
  }

  formatOrigin(origin: FocusOrigin): string {
    return origin ? origin + ' focused' : 'blurred';
  }

  // Workaround for the fact that (cdkFocusChange) emits outside NgZone.
  markForCheck() {
    this._ngZone.run(() => this._cdr.markForCheck());
  }



  clickMethod() {
    if(confirm("Are you sure you want to go back ?")) {
      this.router.navigate(['/menu/care-plan']);
      
    } 
  
  }


}

@Component({
  selector: 'formeditdialog',
  templateUrl: './formdialog.html',
  styleUrls: ['./edit-care-plan.component.scss']
})
export class FormEditDialog implements OnInit {
  title: any;
  category_list:any;
  id: any;
  categoryvalue:any;
  activites:any;
  activityvalue:any;
  priorityvalue:any;
  medcon_list:any;
  freqvalue:any;
  statusvalue:any;
  formattime:any;
  desiredout:any;
  activity:any;
  medication:any;
  careplangoal:any;
  careplanmed:any;
  careplanactivity:any;
  status:any;
  submitted = false;
  time : string[] = [];
  status_list = [
    {value: 'not-started', viewValue: 'Not Started'},
    {value: 'scheduled', viewValue: 'Scheduled'},
    {value: 'in-progress', viewValue: 'In progress'},
    {value: 'on-hold', viewValue: 'On Hold'},
    {value: 'completed', viewValue: 'Completed'},
    {value: 'cancelled', viewValue: 'Cancelled'},
    {value: 'stopped', viewValue: 'Stopped'},
    {value: 'unknown', viewValue: 'Unknown'},
    {value: 'entered-in-error', viewValue: 'Entered in error'},
  ];
  unitvalue:any;
  index:any;
  unit_list=[ "g", "IU", "mcg", "mcg/hr" , "mcg/ml" , "mEq", "mg", "mg/cm2", "mg/g" , "mg/ml", "mL", "%"];


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<FormEditDialog>, private formBuilder: FormBuilder, private toastr: ToastrService, private authservice: AuthServiceService, private _communicationService: CommunicationService ){
    this.title = data[0]
    this.id = data[1]
    this.activites = data[2]
    this.medcon_list = data[3]
    this.desiredout = data[4]
    this.activity = data[5]
    this.medication = data[6]
    this.index = data[7]
    this.status = data[8]

  }

  myForm = this.formBuilder.group({

  })

  ngOnInit() {
    //console.log(this.index)
    //console.log(this.desiredout , this.activity , this.medication)
    //console.log(this.title)
    this.authservice.get_category("CAREPLAN").subscribe((res:any)=>{
       this.category_list = res;
       //console.log(this.category_list)
    })
   
  

    switch (this.title) {
      case 'desiredOutcome':
        this.myForm = this.formBuilder.group({
          goalType: ['', Validators.required],
          presentText: [''],
          targetText: [''],
          presentValue: ['', Validators.required],
          targetValue: ['', Validators.required],
          targetDate: ['', Validators.required],
          category: ['', Validators.required],
          carePlan: [''],
          isPublic: [''],
          sequence: ['']
        })
        break;
      case 'activities': 
        this.myForm = this.formBuilder.group({
          title: ['', Validators.required],
          description: ['', Validators.required],
          carePlan: [''],
          category: ['', Validators.required],
          activity: ['', Validators.required],
          priority: [''],
          status: [''],
          dueDate: [''],
          owner: ['']
        })
        break;
      case 'medication':
        this.myForm = this.formBuilder.group({
          medicineName: ['', Validators.required],
          medicalCondition: ['', Validators.required],
          description: [''],
          carePlan: [''],
          category: ['', Validators.required],
          strength: ['', Validators.required],
          unit: ['', Validators.required],
          frequency: ['', Validators.required],
          timeFrequency: [''],
          numberOfTimes: ['', Validators.required],
          startDate: [''],
          endDate: [''],
          instruction: [''],
          isPublic: [''],
          sequence: ['']
        })
        break;
    }
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.myForm.controls;
  }

  save_desired_outcome(goalType: string,presentValue: any , targetValue: any , presentText: string , targetText: string , targetDate: any , category: number ){
     
    ////console.log(goalType ,presentValue , targetValue , presentText , targetText , targetDate , category )
    this.submitted = true;
    //console.log(this.submitted)
    if (this.myForm.invalid) {
      //console.log("invalid" , this.myForm.value)
      return
      
    }  
     
    if(this.myForm.controls['goalType'].status == 'VALID'){
    
      this.authservice.add_caregoal(goalType , presentText , targetText , presentValue , targetValue , category , this.id , false,this.id).subscribe((res:any)=>{
          //console.log(res)
          if(res){
            this.toastr.success("added desired outcomes!!")
          }
      })
      
       

    }
    this.dialog.close({goalType,presentValue , targetValue , presentText , targetText , targetDate , category})

  }
  save_activities(title,categoryvalue,activityvalue,priorityvalue,description,dueDate ,statusvalue){
    this.submitted = true;
    
    //console.log(this.submitted)
    if (this.myForm.invalid) {
      //console.log("invalid" , this.myForm.value)
      return
      
    }  
    //console.log(title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue)
  
    this.authservice.add_careplan_schedule(title ,description,categoryvalue,this.id,activityvalue,priorityvalue ,statusvalue,dueDate,"doctor").subscribe((res:any)=>{
      //console.log(res)
      if(res){
        this.toastr.success("added care plan activites!!")
      }
    })

     this.dialog.close({title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue})

  }
  save_medication(medicineName,medCon,categoryvalue: number,strength,unitvalue,freq ,startDate, endDate , numberOfTimes){
     
    this.submitted = true;
    
    //console.log(this.submitted)
    if (this.myForm.invalid) {
      //console.log("invalid" , this.myForm.value)
      return
      
    }  
    this.authservice.add_careplan_med(medicineName ,medCon,categoryvalue,this.id,strength,unitvalue,freq,this.time,numberOfTimes,startDate,endDate,false).subscribe((res:any)=>{
      //console.log(res)
      if(res){
        this.toastr.success("added care plan medications!!")
      }
    })
    
    this.dialog.close({medicineName ,medCon,categoryvalue,strength,unitvalue,freq ,startDate , endDate , numberOfTimes})


  }
exportTime = { hour: 7, minute: 15, meriden: 'PM', format: 12 };

  onChangeHour(event) {
    //console.log('event', event);
  }
   calculateFrequency(freq: any) 
   {
     var step = 12
     var startTime = 8
    var maxTime = 24
    if(freq <= 2) {

        maxTime = 24

    } else if(freq<= 4) {

        maxTime = 12
        //console.log(maxTime)

    } else if(freq < 6) {

        maxTime = 16

    } else if(freq == 6) {

        maxTime = 14

    } else if(freq == 7 ){

        maxTime = 16

    } else if(freq == 8) {

        maxTime = 18

    } else if(freq == 9) {

        maxTime = 20

    } else if(freq == 10) {

        maxTime = 22

    } else if(freq == 11) {

        maxTime = 23

    } else  {

        maxTime = 24

    }

    if(freq >= 2) {

        if(freq <= 6) {

            step = (Math.ceil(maxTime / freq))
            //console.log(step)

        } else {

            step = (Math.ceil(maxTime / freq))

        }

        var index = 1
         for(let i=2; i < maxTime ;step) {

            // Do something

            if(startTime < 12) {
                
               this.formattime = (((startTime == 0 ? 12 : startTime).toString()).padStart(2,'0')).concat(":00 AM") 
                //time.append((String((startTime == 0 ? 12 : startTime)).leftPadding(toLength: 2, withPad: "0")):00 AM)
                //console.log(this.formattime)
                this.time.push(this.formattime)
                
      
            } else {

                if((startTime-12) < 12) {
                    
                  this.formattime =  (((startTime - 12 == 0 ? 12 : startTime - 12).toString()).padStart(2,'0')).concat(":00 PM") 
              
                  //console.log(this.formattime)
                  this.time.push(this.formattime)
                    

                } else {
                  this.formattime =  (((startTime - 24 == 0 ? 12 : startTime - 24).toString()).padStart(2,'0')).concat(":00 AM") 
                  //this.formattime = (((startTime - 24 == 0 ? 12 : startTime - 24).toString()).padStart(2,'0')).concat(":00 AM") 
                  //console.log(this.formattime)
                  this.time.push(this.formattime)
 
                }

            }

            startTime += step

            index += 1

            if(index > freq) {

                break

            }

        }

    }
    //console.log(this.time)

    return this.time

}
// exportTime = { hour: this.time.slice(0), minute: this.time.splice(0), meriden: this.time.splice(0), format: 12 };
  edit_desired_outcome(goalType: any,presentValue: any , targetValue: any , presentText: any , targetText: any , targetDate: any , category: any){
    this.submitted = true;
    //console.log(this.submitted)
    if (this.myForm.invalid) {
      //console.log("invalid" , this.myForm.value)
      return
      
    }  
    if(this.myForm.controls['goalType'].status == 'VALID'){
      this.careplangoal = { goalType,presentValue , targetValue , presentText , targetText , targetDate , category}
    
      //console.log(this.careplangoal , this.desiredout.id)
      if( this.desiredout.id){
        this.authservice.edit_careplangoal(this.desiredout.id ,this.careplangoal).subscribe((res:any)=>{
    
          if(res){
            this.toastr.success("edited successfully!!")
          }
        })
      }
   
      
      this.dialog.close({data:this.careplangoal,action:"edit"})
    }


  }
  edit_activities(title: any ,categoryvalue: any,activityvalue: any,priorityvalue: any,description: any ,dueDate: any ,statusvalue: any){
     
    ////console.log(title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue)
     
     
     this.submitted = true;
    //console.log(this.submitted)
    if (this.myForm.invalid) {
      //console.log("invalid" , this.myForm.value)
      return
      
    }  
    this.careplanactivity = {title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue}
     //console.log(this.careplanactivity , this.activity.id)
     if(this.activity.id){
      this.authservice.edit_careplanschedule(this.activity.id, this.careplanactivity).subscribe((res:any)=>{
        //console.log(res)
        if(res){
          this.toastr.success("edited successfully!!!")
        }
      })

     }
   
     this.dialog.close({data:this.careplanactivity,action:"edit"})
 

  }
  edit_medication(medicineName: any ,medCon: any,categoryvalue: any,strength: any,unitvalue: any,freq: any ,startDate: any , endDate?: any , numberOfTimes?: any){
    this.submitted = true;
    //console.log(this.submitted)
    if (this.myForm.invalid) {
      //console.log("invalid" , this.myForm.value)
      return
      
    }  
    this.careplanmed = {medicineName ,medCon,categoryvalue,strength,unitvalue,"timeFrequency":this.medication?.timeFrequency ,freq , startDate , endDate , numberOfTimes}
    if(this.medication.id){
      this.authservice.edit_careplanmed(this.medication.id , this.careplanmed).subscribe((res:any)=>{
        //console.log(res)
        if(res){
          this.toastr.success("edited succesfully!!")
        }
      })

    }
   
   this.dialog.close({data:this.careplanmed,action:"edit"})
    
  }

  delete_desired_outcome(){
    this.submitted = true;
    if(confirm("Are you sure to delete ?")) {
    if(this.myForm.controls['goalType'].status == 'VALID'){
      
     
      //console.log(this.desiredout.id)
     this.authservice.delete_careplangoal(this.desiredout.id).subscribe((res:any)=>{
       //console.log(res)
       this.careplangoal = res
       if(res){
         this.toastr.success("deleted succesfully!!")
         

       } 

       
     })
    }
    //console.log(this.careplangoal)
    this.dialog.close({data:this.careplangoal,action:"delete"})
    
    }


  }
  delete_activities(){
     ////console.log(title ,categoryvalue,activityvalue,priorityvalue,description ,dueDate ,statusvalue)
     this.submitted = true;
     //console.log(this.activity.id)
     if(confirm("Are you sure to delete ?")) {
     this.authservice.delete_careplanschedule(this.activity.id).subscribe((res:any)=>{
       this.careplanactivity = res
       if(res){
         this.toastr.success("deleted successfully!!!")
       }
     })
    }
     this.dialog.close({data:this.careplanactivity,action:"delete"})
 

  }
  delete_medication(){
    this.submitted = true;
    if(confirm("Are you sure to delete ?")) {
    
    this.authservice.delete_careplanmed(this.medication.id ).subscribe((res:any)=>{
      this.careplanmed = res
      if(res){
        this.toastr.success("deleted succesfully!!")
      }
    })
  }
   this.dialog.close({data:this.careplanmed,action:"delete"})
    
  }
  close_dialog(){
    this.dialog.close()
  }

  onNoClick(): void {
    this.dialog.close();
  }


 
  
}






